import { FormFieldData } from "../../components/enhancedForm/withEnhancedForm";
import { FieldEntry } from "../ts/entityFields";
import { isUUID } from "./regexValidateors";

interface TransformStructure {
  [key: string]: any
  fieldsEntry: FieldEntry[]
}

export function getFieldEntriesFromFormData(formFields: FormFieldData[]) {
  let data: TransformStructure = {
    fieldsEntry: []
  }
  formFields.forEach(formField => {
    if (isUUID(formField.key)) data.fieldsEntry.push({ 
      fieldId: formField.key,
      value: formField.value
    })
    else data[formField.key] = formField.value
  })
  return data;
}