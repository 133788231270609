import React from "react";
import { Meeting, EntityFieldType, EntityRelationType, MeetingStatus, User, MeetingField, FieldGroup, Role, MmiPipeline, ClientContact } from "../../../lib/codegen";
import { EnhancedFormFieldsObject, EnhancedFormFieldGroup, FormDataObject } from "../../../components/enhancedForm/withEnhancedForm";
import { mapEntityFieldsToEnhancedFields } from "../../../components/enhancedForm/formHelpers/mapEntityFieldsToEnhancedFields";
import { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { arrayToString } from "../../../lib/helpers/helpers";
import { ContactDrawerDisplay } from "../../contacts/contactPane/ContactDrawer";
import { MeetingStatusTag } from "../meetingPane/meetingStatusTag/MeetingStatusTag";

export const getMeetingFields = (args: {
  meeting?: Meeting
  user: User
  fields: MeetingField[],
  groups: FieldGroup[]
}): EnhancedFormFieldsObject => {
  const { meeting, user, fields, groups } = args;
  const isCreating = meeting ? false : true

  const getSelectedClientId = (_args: { formData: FormDataObject }) =>
    _args.formData["clientId"] as string | null ? _args.formData["clientId"] : meeting && meeting.client ? meeting.client.id : undefined
  
  const getSelectedPartnerId = (_args: { formData: FormDataObject }) =>
    _args.formData["partnerId"] as string | null ? _args.formData["partnerId"] : meeting && meeting.partner ? meeting.partner.id : undefined

  const isFieldVisibleForClient = (_args: { field: MeetingField, formData: FormDataObject }): boolean => {
    let visible = true;
    let { clients, partners } = _args.field;

    if (!clients) clients = []
    if (!partners) partners = []
    if (clients.length === 0 && partners.length === 0) return true;

    const selectedClientId = getSelectedClientId(_args)
    const selectedPartnerId = getSelectedPartnerId(_args)
    
    const foundClient = clients.find(client => client.id === selectedClientId)
    const foundPartner = partners.find(partner => partner.id === selectedPartnerId)

    if (!foundClient && clients.length !== 0) visible = false;
    if (!foundPartner && partners.length !== 0) visible = false;

    return visible
  }

  const displayClientContacts = (clientContactsProp: keyof Meeting) => {
    const records: ClientContact[] = meeting && meeting[clientContactsProp] && Array.isArray(meeting[clientContactsProp]) ? meeting[clientContactsProp] : [];
    if (records.length === 0) return undefined;
    let values: string[] = records.map(record => record.name)
    return arrayToString(values)
  }  

  const mmiDetails: EnhancedFormFieldGroup<{} & EnhnacedFormArgs> = {
    title: "MoreMeetings Details",
    fields: [
      [
        {
          key: "clientId",
          name: "Client",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Client"],
          rules: [{ required: true, message: "Client is required" }],
          getValueFromProps: () => meeting && meeting.client ? meeting.client.id : undefined
        },
        {
          key: "partnerId",
          name: "Partner",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Partner"],
          getValueFromProps: () => meeting && meeting.partner ? meeting.partner.id : undefined
        }
      ],
      [
        {
          key: "campaignId",
          name: "Campaign",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Campaign"],
          getValueFromProps: () => meeting && meeting.campaign ? meeting.campaign.id : undefined
        },
        {
          key: "tradeshowId",
          name: "Tradeshow",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Tradeshow"],
          getValueFromProps: () => meeting && meeting.tradeshow ? meeting.tradeshow.id : undefined
        }
      ],
      [
        {
          key: "salesRepsId",
          name: "Sales Reps",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["ClientContact"],
          isMultiSelection: true,
          getValueFromProps: () => meeting && meeting.salesReps ? meeting.salesReps.map(({ id }) => id) : [],
          getDisplayValue: () => displayClientContacts("salesReps")
        },
        {
          key: "salesEngineersId",
          name: "Sales Engineers",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["ClientContact"],
          isMultiSelection: true,
          getValueFromProps: () => meeting && meeting.salesEngineers ? meeting.salesEngineers.map(({ id }) => id) : [],
          getDisplayValue: () => displayClientContacts("salesEngineers")
        }
      ],
      [
        {
          key: "partnerSalesRepsId",
          name: "Partner Sales Reps",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["ClientContact"],
          isMultiSelection: true,
          getValueFromProps: () => meeting && meeting.partnerSalesReps ? meeting.partnerSalesReps.map(({ id }) => id) : [],
          getDisplayValue: () => displayClientContacts("partnerSalesReps"),
          isVisible: ({ formDataObject }) => getSelectedPartnerId({ formData: formDataObject() }) ? true : false
        },
        {
          key: "partnerSalesEngineersId",
          name: "Partner Sales Engineers",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["ClientContact"],
          isMultiSelection: true,
          getValueFromProps: () => meeting && meeting.partnerSalesEngineers ? meeting.partnerSalesEngineers.map(({ id }) => id) : [],
          getDisplayValue: () => displayClientContacts("partnerSalesEngineers"),
          isVisible: ({ formDataObject }) => getSelectedPartnerId({ formData: formDataObject() }) ? true : false
        }
      ]
    ]
  }

  const details: EnhancedFormFieldGroup<any> = {
    title: "Event",
    fields: [
      {
        key: "MMIPipeline",
        name: "MMI Pipeline",
        type: EntityFieldType["Selection"],
        selectionOptions: [
          {key: MmiPipeline["JustCreated"], name: "Just Created"},
          {key: MmiPipeline["PreReviewed"], name: "Pre Reviewed"},
          {key: MmiPipeline["Processed"], name: "Processed"},
          {key: MmiPipeline["PostApproved"], name: "Post Approved"},
          {key: MmiPipeline["Paid"], name: "Paid"},
          {key: MmiPipeline["NotBillable"], name: "Not Billable"},
        ],
        isVisible: () => user.role === Role["Admin"],
        getValueFromProps: () => meeting ? meeting.MMIPipeline : MmiPipeline["JustCreated"]
      },
      [
        {
          key: "status",
          name: "Status",
          type: EntityFieldType["Selection"],
          selectionOptions: [
            { key: MeetingStatus["Planned"], name: <MeetingStatusTag status={MeetingStatus["Planned"]}/> },
            { key: MeetingStatus["Held"], name: <MeetingStatusTag status={MeetingStatus["Held"]}/> },
            { key: MeetingStatus["ToBeRescheduled"], name: <MeetingStatusTag status={MeetingStatus["ToBeRescheduled"]}/> },
            { key: MeetingStatus["Cancelled"], name: <MeetingStatusTag status={MeetingStatus["Cancelled"]}/> },
          ],
          className: "meeting-status-dropdown",
          rules: [{ required: true }],
          getValueFromProps: () => meeting ? meeting.status : MeetingStatus["Planned"],
          getDisplayValue: () => meeting ? <MeetingStatusTag status={meeting.status}/> : ""
        },
        {
          key: "createdDate",
          name: "Created On",
          type: EntityFieldType["Date"],
          getValueFromProps: () => meeting ? meeting.createdDate : undefined
        },
      ],
      [
        {
          key: "date",
          name: "Date",
          type: EntityFieldType["Date"],
          getValueFromProps: () => meeting ? meeting.date : undefined,
        },
        {
          key: "time",
          name: "Time",
          type: EntityFieldType["Date"],
          getValueFromProps: () => meeting ? meeting.time : undefined,
          isDateTime: "time"
        },
      ],
      [
        {
          key: "duration",
          name: "Duration (min)",
          type: EntityFieldType["Number"],
          getValueFromProps: () => meeting ? meeting.duration : undefined,
        },
        {
          key: "timezone",
          name: "Timezone",
          type: EntityFieldType["Selection"],
          selectionOptions: [
            { key: "EST", name: "EST" },
            { key: "CST", name: "CST" },
            { key: "MST", name: "MST" },
            { key: "PST", name: "PST" },
            { key: "ASKT", name: "ASKT" },
            { key: "HST", name: "HST" },
          ],
          getValueFromProps: () => meeting ? meeting.timezone : undefined
        }
      ],
      {
        key: "name",
        name: "Name",
        type: EntityFieldType["Text"],
        getValueFromProps: () => meeting ? meeting.name : undefined,
        isVisible: () => !isCreating
      },
      {
        key: "ownerId",
        name: "MMI ISR",
        type: EntityFieldType["Relation"],
        entityRelationType: EntityRelationType["User"],
        isVisible: () => user.role === Role["Admin"],
        getValueFromProps: () => meeting && meeting.owner ? meeting.owner.id : undefined
      },
      {
        key: "contactId",
        name: "Contact",
        type: EntityFieldType["Relation"],
        entityRelationType: EntityRelationType["Contact"],
        getValueFromProps: () => meeting && meeting.contact ? meeting.contact.id : undefined,
        getDisplayValue: () => meeting && meeting.contact ? <ContactDrawerDisplay contact={meeting.contact} /> : "---"
      },
      {
        key: "tagsId",
        name: "Meeting Tags",
        type: EntityFieldType["Relation"],
        entityRelationType: EntityRelationType["MeetingTag"],
        isMultiSelection: true,
        getValueFromProps: () => meeting && meeting.tags ? meeting.tags.map(tag => tag.id) : undefined,
        getDisplayValue: () => meeting && meeting.tags ? arrayToString(meeting.tags.map(tag => tag.name)) : undefined,
      }
    ]
  }

  const customFields = mapEntityFieldsToEnhancedFields<MeetingField, EnhnacedFormArgs>({
    entityFields: fields,
    fieldGroups: groups,
    isVisible: (args) => {
      const { field, formDataObject } = args
      const meetingField = fields.find(_field => _field.id === field.key); if (!meetingField) return true;

      if (meetingField.isAdminField && user.role === Role["User"]) return false;

      if (isCreating && !meetingField.isVisibleOnCreate) return false;

      return isFieldVisibleForClient({ field: meetingField, formData: formDataObject() })
    },
    getValueFromProps: (args) => {
      if (!meeting) return;
      const fieldData = meeting.fieldsData.find(({ fieldId }) => fieldId === args.field.key)
      if (fieldData) return fieldData.value;
    }
  })

  return [details, mmiDetails, ...customFields]
}