import React, { useContext } from "react";
import "./Dashboard.scss"
import { AppContext } from "../../lib/helpers/AppContext";
import { Redirect } from "react-router";
import { Row, Col } from "antd";
import { UpcommingMeetings } from "./upcommingMeetings/UpcommingMeetings";
import { MeetingLineChart } from "./meetingLineChart/MeetingLineChart";
import { UserLeaderboard } from "./userLeaderboard/UserLeaderboard";
import { ResponsiveSizes } from "../../lib/helpers/hooks/useResponsive";
import { MeetingStatusPieChart } from "./meetingStatusPieChart/MeetingStatusPieChart";
import { DarkModeToggle } from "./darkModeToggle/DarkModeToggle";
import { CenteredSpinner } from "../../components/display/centeredSpinner/CenteredSpinner";


interface IProps {

}

export const Dashboard: React.FC<IProps> = props => {
  const { user, meQuery, responsiveCalc } = useContext(AppContext);
  
  const colStyle = (fullWithOn: ResponsiveSizes): React.CSSProperties => ({
    marginLeft: responsiveCalc(fullWithOn, "down-include") ? 0 : 20,
    marginBottom: 20
  })
  
  if (meQuery.loading) return <CenteredSpinner />
  if (!user && !meQuery.loading) return <Redirect to="/login" />

  return (
    <div>
      <h2>Dashboard</h2>
      <Row>
        <Col sm={24} md={12} xl={6} xxl={8} style={colStyle("sm")}>
          <UpcommingMeetings />
        </Col>
        <Col sm={24} md={10} xl={6} xxl={6} style={colStyle("sm")}>
          <MeetingStatusPieChart />
        </Col>
        <Col sm={24} md={24} xl={10} xxl={8} style={colStyle("lg")}>
          <MeetingLineChart />
        </Col>
      </Row>
      <Row>
        <Col sm={24} md={12} xl={6} style={colStyle("sm")}>
          <UserLeaderboard />
        </Col>
        <Col sm={24} md={12} xl={6} style={colStyle("sm")}>
          <DarkModeToggle />
        </Col>
      </Row>
    </div>
  );
}