import gql from "graphql-tag";
import * as React from "react";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactComponents from "@apollo/react-components";
import * as ApolloReactHoc from "@apollo/react-hoc";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Valid input types are String, Number, Boolean, and Stringified Arrays */
  FieldValue: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Campaign = {
  id: Scalars["ID"];
  name: Scalars["String"];
  status: CampaignStatus;
  startDate?: Maybe<Scalars["DateTime"]>;
  totalMeetings: Scalars["Int"];
  invoiceName?: Maybe<Scalars["String"]>;
  client?: Maybe<Client>;
  partner?: Maybe<ClientPartner>;
};

export type CampaignStats = {
  meetingsStatus: MeetingsStatusObjectType;
  meetingsCount: Scalars["Int"];
  /** Total number of meetings pledged for the campaign */
  totalMeetings: Scalars["Int"];
};

export type CampaignStatsInput = {
  campaignId?: Maybe<Scalars["String"]>;
  dateRange?: Maybe<LeaderboardDateRange>;
};

export enum CampaignStatus {
  Active = "Active",
  Complete = "Complete"
}

export type Client = {
  id: Scalars["ID"];
  name: Scalars["String"];
  partners?: Maybe<Array<ClientPartner>>;
};

export type ClientContact = {
  id: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  phoneOffice?: Maybe<Scalars["String"]>;
  phoneCell?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  client?: Maybe<Client>;
  partner?: Maybe<ClientPartner>;
  street?: Maybe<Scalars["String"]>;
  suite?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ClientPartner = {
  id: Scalars["ID"];
  name: Scalars["String"];
  clients?: Maybe<Array<Client>>;
};

export type Company = {
  id: Scalars["ID"];
  /** Vtiger Company ID and Domain */
  domain: Scalars["String"];
  name: Scalars["String"];
  iconBase64?: Maybe<Scalars["String"]>;
  fieldsData: Array<FieldDataObjectType>;
  contacts?: Maybe<Array<Contact>>;
  fieldsSchema?: Maybe<Array<EntityField>>;
};

export type CompanyField = {
  id: Scalars["ID"];
  type: EntityFieldType;
  name: Scalars["String"];
  position: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  entityRelationType?: Maybe<EntityRelationType>;
  group?: Maybe<FieldGroup>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

export enum ComparisonOperator {
  Equals = "equals",
  Contains = "contains",
  NotEqual = "not_equal",
  GreaterThan = "greater_than",
  LessThan = "less_than"
}

export type Contact = {
  id: Scalars["ID"];
  companyDomian?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  company?: Maybe<Company>;
  fieldsData: Array<FieldDataObjectType>;
  fieldsSchema?: Maybe<Array<EntityField>>;
  name: Scalars["String"];
};

export type ContactField = {
  id: Scalars["ID"];
  type: EntityFieldType;
  name: Scalars["String"];
  position: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  entityRelationType?: Maybe<EntityRelationType>;
  group?: Maybe<FieldGroup>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

export type CopyReportInput = {
  copyToGroupId?: Maybe<Scalars["String"]>;
  copyFromReportId: Scalars["String"];
};

export type CreateCampaignInput = {
  name: Scalars["String"];
  invoiceName?: Maybe<Scalars["String"]>;
  status?: Maybe<CampaignStatus>;
  startDate?: Maybe<Scalars["DateTime"]>;
  totalMeetings?: Maybe<Scalars["Int"]>;
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
};

export type CreateClientContactInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  phoneOffice?: Maybe<Scalars["String"]>;
  phoneCell?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["ID"]>;
  partnerId?: Maybe<Scalars["ID"]>;
  street?: Maybe<Scalars["String"]>;
  suite?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type CreateClientInput = {
  name: Scalars["String"];
  partnersId?: Maybe<Array<Scalars["ID"]>>;
};

export type CreateClientPartnerInput = {
  name: Scalars["String"];
  clientsId?: Maybe<Array<Scalars["String"]>>;
};

export type CreateCompanyInput = {
  domain: Scalars["String"];
  name: Scalars["String"];
  fieldsEntry?: Maybe<Array<FieldEntryInput>>;
};

export type CreateContactInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  companyId?: Maybe<Scalars["String"]>;
  fieldsEntry?: Maybe<Array<FieldEntryInput>>;
};

export type CreateFieldGroupInput = {
  name: Scalars["String"];
  fieldType: Scalars["String"];
};

export type CreateFieldInput = {
  name: Scalars["String"];
  type: EntityFieldType;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  vtigerFieldId?: Maybe<Scalars["String"]>;
  entityRelationType?: Maybe<EntityRelationType>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

export type CreateMeetingFieldInput = {
  name: Scalars["String"];
  type: EntityFieldType;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  vtigerFieldId?: Maybe<Scalars["String"]>;
  entityRelationType?: Maybe<EntityRelationType>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
  clientsId?: Maybe<Array<Scalars["String"]>>;
  partnersId?: Maybe<Array<Scalars["String"]>>;
};

export type CreateMeetingInput = {
  status?: Maybe<MeetingStatus>;
  name?: Maybe<Scalars["String"]>;
  fieldsEntry?: Maybe<Array<FieldEntryInput>>;
  createdDate?: Maybe<Scalars["DateTime"]>;
  date?: Maybe<Scalars["DateTime"]>;
  time?: Maybe<Scalars["String"]>;
  timezone?: Maybe<MeetingTimezone>;
  /** duration in minutes */
  duration?: Maybe<Scalars["Int"]>;
  MMIPipeline?: Maybe<MmiPipeline>;
  ownerId?: Maybe<Scalars["String"]>;
  tagsId?: Maybe<Array<Scalars["String"]>>;
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
  campaignId?: Maybe<Scalars["String"]>;
  tradeshowId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  salesRepsId?: Maybe<Array<Scalars["String"]>>;
  salesEngineersId?: Maybe<Array<Scalars["String"]>>;
  partnerSalesRepsId?: Maybe<Array<Scalars["String"]>>;
  partnerSalesEngineersId?: Maybe<Array<Scalars["String"]>>;
};

export type CreateMeetingTagInput = {
  name: Scalars["String"];
  color?: Maybe<Scalars["String"]>;
};

export type CreateReportColumnInput = {
  name: Scalars["String"];
  reportId: Scalars["String"];
  dateFormat?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  staticFieldKey?: Maybe<Scalars["String"]>;
  meetingFieldId?: Maybe<Scalars["String"]>;
  contactFieldId?: Maybe<Scalars["String"]>;
  companyFieldId?: Maybe<Scalars["String"]>;
  entityRelationFieldKey?: Maybe<Scalars["String"]>;
};

export type CreateReportFilterInput = {
  entityName: EntityRelationType;
  filterValue?: Maybe<Scalars["FieldValue"]>;
  comparisonOperator: ComparisonOperator;
  staticFieldKey?: Maybe<Scalars["String"]>;
  customFieldId?: Maybe<Scalars["String"]>;
  relativeDateDaysOffset?: Maybe<Scalars["Int"]>;
  reportId: Scalars["String"];
};

export type CreateReportGroupInput = {
  name: Scalars["String"];
  isSummaryTemplate?: Maybe<Scalars["Boolean"]>;
};

export type CreateReportInput = {
  name: Scalars["String"];
  reportGroupId: Scalars["String"];
  headerTemplateString?: Maybe<Scalars["String"]>;
  columnsId?: Maybe<Array<Scalars["String"]>>;
};

export type CreateTradeshowInput = {
  name: Scalars["String"];
  clientId?: Maybe<Scalars["ID"]>;
  partnerId?: Maybe<Scalars["ID"]>;
};

export type EntityField = {
  id: Scalars["String"];
  type: EntityFieldType;
  name: Scalars["String"];
  position: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  entityRelationType?: Maybe<EntityRelationType>;
  group?: Maybe<FieldGroup>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

/** Entity field data type */
export enum EntityFieldType {
  Text = "TEXT",
  Richtext = "RICHTEXT",
  Phone = "PHONE",
  Boolean = "BOOLEAN",
  Number = "NUMBER",
  Date = "DATE",
  Email = "EMAIL",
  Selection = "SELECTION",
  Relation = "RELATION"
}

/** The entity the relation field refrences */
export enum EntityRelationType {
  Contact = "Contact",
  Company = "Company",
  Client = "Client",
  Partner = "Partner",
  ClientContact = "ClientContact",
  Campaign = "Campaign",
  Tradeshow = "Tradeshow",
  MeetingTag = "MeetingTag",
  User = "User",
  Meeting = "Meeting"
}

export type FieldDataObjectType = {
  fieldId: Scalars["String"];
  type: EntityFieldType;
  group?: Maybe<FieldGroup>;
  name: Scalars["String"];
  value?: Maybe<Scalars["FieldValue"]>;
  position: Scalars["Float"];
  isRequired: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  entityRelationType?: Maybe<EntityRelationType>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

export type FieldEntryInput = {
  fieldId: Scalars["String"];
  value?: Maybe<Scalars["FieldValue"]>;
};

export type FieldGroup = {
  id: Scalars["String"];
  name: Scalars["String"];
  position: Scalars["Int"];
  fieldType: FieldGroupType;
};

export enum FieldGroupType {
  ContactField = "ContactField",
  CompanyField = "CompanyField",
  MeetingField = "MeetingField"
}

export type FilterEntry = {
  entityName: EntityRelationType;
  filterValue?: Maybe<Scalars["FieldValue"]>;
  comparisonOperator: ComparisonOperator;
  staticFieldKey?: Maybe<Scalars["String"]>;
  customFieldId?: Maybe<Scalars["String"]>;
  relativeDateDaysOffset?: Maybe<Scalars["Int"]>;
};

export type FilterMeetingsInput = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  filters: Array<FilterEntry>;
  dateAscending?: Maybe<Scalars["Boolean"]>;
};

export enum LeaderboardDateRange {
  Week = "Week",
  Month = "Month",
  Year = "Year",
  AllTime = "AllTime"
}

export type LeaderboardInput = {
  dateRange?: Maybe<LeaderboardDateRange>;
  clientId?: Maybe<Scalars["String"]>;
};

export type LeaderboardUser = {
  userId: Scalars["String"];
  userName: Scalars["String"];
  meetingsHeld: Scalars["Float"];
  meetingsPlanned: Scalars["Float"];
};

export type Meeting = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  status: MeetingStatus;
  MMIPipeline: MmiPipeline;
  createdDate?: Maybe<Scalars["DateTime"]>;
  updatedDate?: Maybe<Scalars["DateTime"]>;
  date?: Maybe<Scalars["DateTime"]>;
  time?: Maybe<Scalars["String"]>;
  timezone?: Maybe<MeetingTimezone>;
  /** duration in minutes */
  duration?: Maybe<Scalars["Int"]>;
  fieldsData: Array<FieldDataObjectType>;
  owner?: Maybe<User>;
  tags?: Maybe<Array<MeetingTag>>;
  client?: Maybe<Client>;
  partner?: Maybe<ClientPartner>;
  campaign?: Maybe<Campaign>;
  tradeshow?: Maybe<Tradeshow>;
  contact?: Maybe<Contact>;
  salesReps?: Maybe<Array<ClientContact>>;
  salesEngineers?: Maybe<Array<ClientContact>>;
  partnerSalesReps?: Maybe<Array<ClientContact>>;
  partnerSalesEngineers?: Maybe<Array<ClientContact>>;
};

export type MeetingField = {
  id: Scalars["ID"];
  type: EntityFieldType;
  name: Scalars["String"];
  position: Scalars["Int"];
  isRequired: Scalars["Boolean"];
  isEnabled: Scalars["Boolean"];
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  entityRelationType?: Maybe<EntityRelationType>;
  group?: Maybe<FieldGroup>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
  /** Null for all clients */
  clients?: Maybe<Array<Client>>;
  /** Null for all partners */
  partners?: Maybe<Array<ClientPartner>>;
};

export type MeetingsStatusObjectType = {
  Planned: Scalars["Int"];
  Held: Scalars["Int"];
  To_Be_Rescheduled: Scalars["Int"];
  Cancelled: Scalars["Int"];
};

export enum MeetingStatus {
  Planned = "Planned",
  Held = "Held",
  ToBeRescheduled = "To_Be_Rescheduled",
  Cancelled = "Cancelled"
}

export type MeetingSummaryOption = {
  reportGroupId: Scalars["String"];
  reportGroupName: Scalars["String"];
};

export type MeetingTag = {
  id: Scalars["ID"];
  name: Scalars["String"];
  color?: Maybe<Scalars["String"]>;
};

export enum MeetingTimezone {
  Est = "EST",
  Cst = "CST",
  Mst = "MST",
  Pst = "PST",
  Akst = "AKST",
  Hst = "HST"
}

export enum MmiPipeline {
  JustCreated = "Just_Created",
  PreReviewed = "Pre_Reviewed",
  Processed = "Processed",
  PostApproved = "Post_Approved",
  Paid = "Paid",
  NotBillable = "Not_Billable"
}

export type MoveReportReportGroupInput = {
  reportId: Scalars["String"];
  moveToReportGroupId: Scalars["String"];
};

export type Mutation = {
  login?: Maybe<User>;
  logout?: Maybe<Scalars["String"]>;
  register: User;
  updateUser?: Maybe<User>;
  updateUserPassword?: Maybe<User>;
  updateUserMeetingFilterGroups?: Maybe<User>;
  deleteUser?: Maybe<Scalars["String"]>;
  updateVtigerDescriptions?: Maybe<Scalars["Boolean"]>;
  createCompany?: Maybe<Company>;
  createCompanyField?: Maybe<EntityField>;
  updateCompany?: Maybe<Company>;
  updateCompanyIcon?: Maybe<Company>;
  updateCompanyField?: Maybe<EntityField>;
  deleteCompany?: Maybe<Scalars["ID"]>;
  reorderCompanyField?: Maybe<Array<EntityField>>;
  deleteCompanyField?: Maybe<Scalars["String"]>;
  createContact?: Maybe<Contact>;
  createContactField?: Maybe<EntityField>;
  deleteContact?: Maybe<Scalars["ID"]>;
  updateContact?: Maybe<Contact>;
  updateContactField?: Maybe<EntityField>;
  reorderContactField?: Maybe<Array<EntityField>>;
  deleteContactField?: Maybe<Scalars["String"]>;
  createClient?: Maybe<Client>;
  updateClient?: Maybe<Client>;
  deleteClient?: Maybe<Scalars["String"]>;
  createTradeshow?: Maybe<Tradeshow>;
  updateTradeshow?: Maybe<Tradeshow>;
  deleteTradeshow?: Maybe<Scalars["String"]>;
  createClientPartner?: Maybe<ClientPartner>;
  updateClientPartner?: Maybe<ClientPartner>;
  deleteClientPartner?: Maybe<Scalars["String"]>;
  createCampaign?: Maybe<Campaign>;
  updateCampaign?: Maybe<Campaign>;
  deleteCampaign?: Maybe<Scalars["String"]>;
  createClientContact?: Maybe<ClientContact>;
  updateClientContact?: Maybe<ClientContact>;
  deleteClientContact?: Maybe<Scalars["String"]>;
  createMeeting?: Maybe<Meeting>;
  updateMeeting?: Maybe<Meeting>;
  deleteMeeting?: Maybe<Scalars["String"]>;
  createMeetingField?: Maybe<MeetingField>;
  updateMeetingField?: Maybe<MeetingField>;
  reorderMeetingField?: Maybe<Array<MeetingField>>;
  deleteMeetingField?: Maybe<Scalars["String"]>;
  createMeetingTag?: Maybe<MeetingTag>;
  updateMeetingTag?: Maybe<MeetingTag>;
  deleteMeetingTag?: Maybe<Scalars["String"]>;
  createFieldGroup?: Maybe<FieldGroup>;
  updateFieldGroup?: Maybe<FieldGroup>;
  deleteFieldGroup?: Maybe<Scalars["String"]>;
  reorderFieldGroup?: Maybe<Array<FieldGroup>>;
  createReport?: Maybe<Report>;
  updateReport?: Maybe<Report>;
  deleteReport?: Maybe<Scalars["String"]>;
  reorderReport?: Maybe<Report>;
  copyReport?: Maybe<Report>;
  moveReportReportGroupResolver?: Maybe<Report>;
  createReportGroup?: Maybe<ReportGroup>;
  updateReportGroup?: Maybe<ReportGroup>;
  deleteReportGroup?: Maybe<Scalars["String"]>;
  reorderReportColumn?: Maybe<Array<ReportColumn>>;
  createReportColumn?: Maybe<ReportColumn>;
  updateReportColumn?: Maybe<ReportColumn>;
  deleteReportColumn?: Maybe<Scalars["String"]>;
  createReportFilter?: Maybe<ReportFilter>;
  updateReportFilter?: Maybe<ReportFilter>;
  deleteReportFilter?: Maybe<Scalars["String"]>;
};

export type MutationLoginArgs = {
  password: Scalars["String"];
  email: Scalars["String"];
};

export type MutationRegisterArgs = {
  data: RegisterInput;
};

export type MutationUpdateUserArgs = {
  data?: Maybe<UpdateUserInput>;
  id: Scalars["String"];
};

export type MutationUpdateUserPasswordArgs = {
  password: Scalars["String"];
  id: Scalars["String"];
};

export type MutationUpdateUserMeetingFilterGroupsArgs = {
  jsonData: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["String"];
};

export type MutationCreateCompanyArgs = {
  data: CreateCompanyInput;
};

export type MutationCreateCompanyFieldArgs = {
  data: CreateFieldInput;
};

export type MutationUpdateCompanyArgs = {
  data: CreateCompanyInput;
  id: Scalars["String"];
};

export type MutationUpdateCompanyIconArgs = {
  iconBase64?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type MutationUpdateCompanyFieldArgs = {
  data: UpdateFieldInput;
  id: Scalars["String"];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars["String"];
};

export type MutationReorderCompanyFieldArgs = {
  groupId?: Maybe<Scalars["String"]>;
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationDeleteCompanyFieldArgs = {
  id: Scalars["String"];
};

export type MutationCreateContactArgs = {
  data: CreateContactInput;
};

export type MutationCreateContactFieldArgs = {
  data: CreateFieldInput;
};

export type MutationDeleteContactArgs = {
  id: Scalars["String"];
};

export type MutationUpdateContactArgs = {
  data: CreateContactInput;
  id: Scalars["String"];
};

export type MutationUpdateContactFieldArgs = {
  data: UpdateFieldInput;
  id: Scalars["String"];
};

export type MutationReorderContactFieldArgs = {
  groupId?: Maybe<Scalars["String"]>;
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationDeleteContactFieldArgs = {
  id: Scalars["String"];
};

export type MutationCreateClientArgs = {
  data: CreateClientInput;
};

export type MutationUpdateClientArgs = {
  data: CreateClientInput;
  id: Scalars["String"];
};

export type MutationDeleteClientArgs = {
  id: Scalars["String"];
};

export type MutationCreateTradeshowArgs = {
  data: CreateTradeshowInput;
};

export type MutationUpdateTradeshowArgs = {
  data: CreateTradeshowInput;
  id: Scalars["String"];
};

export type MutationDeleteTradeshowArgs = {
  id: Scalars["String"];
};

export type MutationCreateClientPartnerArgs = {
  data: CreateClientPartnerInput;
};

export type MutationUpdateClientPartnerArgs = {
  data: CreateClientPartnerInput;
  id: Scalars["String"];
};

export type MutationDeleteClientPartnerArgs = {
  id: Scalars["String"];
};

export type MutationCreateCampaignArgs = {
  data: CreateCampaignInput;
};

export type MutationUpdateCampaignArgs = {
  data: CreateCampaignInput;
  id: Scalars["String"];
};

export type MutationDeleteCampaignArgs = {
  id: Scalars["String"];
};

export type MutationCreateClientContactArgs = {
  data: CreateClientContactInput;
};

export type MutationUpdateClientContactArgs = {
  data: CreateClientContactInput;
  id: Scalars["String"];
};

export type MutationDeleteClientContactArgs = {
  id: Scalars["String"];
};

export type MutationCreateMeetingArgs = {
  data: CreateMeetingInput;
};

export type MutationUpdateMeetingArgs = {
  data: CreateMeetingInput;
  id: Scalars["String"];
};

export type MutationDeleteMeetingArgs = {
  id: Scalars["String"];
};

export type MutationCreateMeetingFieldArgs = {
  data: CreateMeetingFieldInput;
};

export type MutationUpdateMeetingFieldArgs = {
  data: UpdateMeetingFieldInput;
  id: Scalars["String"];
};

export type MutationReorderMeetingFieldArgs = {
  groupId?: Maybe<Scalars["String"]>;
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationDeleteMeetingFieldArgs = {
  id: Scalars["String"];
};

export type MutationCreateMeetingTagArgs = {
  data: CreateMeetingTagInput;
};

export type MutationUpdateMeetingTagArgs = {
  data: CreateMeetingTagInput;
  id: Scalars["String"];
};

export type MutationDeleteMeetingTagArgs = {
  id: Scalars["String"];
};

export type MutationCreateFieldGroupArgs = {
  data: CreateFieldGroupInput;
};

export type MutationUpdateFieldGroupArgs = {
  data: UpdateFieldGroupInput;
  id: Scalars["String"];
};

export type MutationDeleteFieldGroupArgs = {
  id: Scalars["String"];
};

export type MutationReorderFieldGroupArgs = {
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationCreateReportArgs = {
  data: CreateReportInput;
};

export type MutationUpdateReportArgs = {
  data: UpdateReportInput;
  id: Scalars["String"];
};

export type MutationDeleteReportArgs = {
  id: Scalars["String"];
};

export type MutationReorderReportArgs = {
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationCopyReportArgs = {
  data: CopyReportInput;
};

export type MutationMoveReportReportGroupResolverArgs = {
  data: MoveReportReportGroupInput;
};

export type MutationCreateReportGroupArgs = {
  data: CreateReportGroupInput;
};

export type MutationUpdateReportGroupArgs = {
  data: CreateReportGroupInput;
  id: Scalars["String"];
};

export type MutationDeleteReportGroupArgs = {
  id: Scalars["String"];
};

export type MutationReorderReportColumnArgs = {
  position: Scalars["Float"];
  id: Scalars["String"];
};

export type MutationCreateReportColumnArgs = {
  data: CreateReportColumnInput;
};

export type MutationUpdateReportColumnArgs = {
  data: UpdateReportColumnInput;
  id: Scalars["String"];
};

export type MutationDeleteReportColumnArgs = {
  id: Scalars["String"];
};

export type MutationCreateReportFilterArgs = {
  data: CreateReportFilterInput;
};

export type MutationUpdateReportFilterArgs = {
  data: FilterEntry;
  id: Scalars["String"];
};

export type MutationDeleteReportFilterArgs = {
  id: Scalars["String"];
};

export type PaginatedCampaignsResponse = {
  items: Array<Campaign>;
  total: Scalars["Int"];
};

export type PaginatedClientContactsResponse = {
  items: Array<ClientContact>;
  total: Scalars["Int"];
};

export type PaginatedClientPartnersResponse = {
  items: Array<ClientPartner>;
  total: Scalars["Int"];
};

export type PaginatedClientsResponse = {
  items: Array<Client>;
  total: Scalars["Int"];
};

export type PaginatedCompaniesResponse = {
  items: Array<Company>;
  total: Scalars["Int"];
};

export type PaginatedContactResponse = {
  items: Array<Contact>;
  total: Scalars["Int"];
};

export type PaginatedMeetingsResponse = {
  items: Array<Meeting>;
  total: Scalars["Int"];
};

export type PaginatedMeetingTagsResponse = {
  items: Array<MeetingTag>;
  total: Scalars["Int"];
};

export type PaginatedReportGroupsResponse = {
  items: Array<ReportGroup>;
  total: Scalars["Int"];
};

export type PaginatedReportsResponse = {
  items: Array<Report>;
  total: Scalars["Int"];
};

export type PaginatedTradeshowsResponse = {
  items: Array<Tradeshow>;
  total: Scalars["Int"];
};

export type PaginatedUsersResponse = {
  items: Array<User>;
  total: Scalars["Int"];
};

export type Query = {
  me?: Maybe<User>;
  users?: Maybe<PaginatedUsersResponse>;
  user?: Maybe<User>;
  describeVtigerModule?: Maybe<Scalars["String"]>;
  getVtigerCompany?: Maybe<Scalars["String"]>;
  getVtigerContact?: Maybe<Scalars["String"]>;
  company?: Maybe<Company>;
  getFavicon?: Maybe<Scalars["String"]>;
  companies?: Maybe<PaginatedCompaniesResponse>;
  companyFields?: Maybe<Array<EntityField>>;
  contact?: Maybe<Contact>;
  contactFields?: Maybe<Array<EntityField>>;
  contacts?: Maybe<PaginatedContactResponse>;
  client?: Maybe<Client>;
  clients?: Maybe<PaginatedClientsResponse>;
  tradeshow?: Maybe<Tradeshow>;
  tradeshows?: Maybe<PaginatedTradeshowsResponse>;
  clientPartner?: Maybe<ClientPartner>;
  clientPartners?: Maybe<PaginatedClientPartnersResponse>;
  camapign?: Maybe<Campaign>;
  campaigns?: Maybe<PaginatedCampaignsResponse>;
  clientContact?: Maybe<ClientContact>;
  clientContacts?: Maybe<PaginatedClientContactsResponse>;
  meeting?: Maybe<Meeting>;
  meetings?: Maybe<PaginatedMeetingsResponse>;
  filterMeetings?: Maybe<PaginatedMeetingsResponse>;
  meetingSummary?: Maybe<Array<SummarySection>>;
  meetingSummaryOptions: Array<MeetingSummaryOption>;
  meetingField?: Maybe<MeetingField>;
  meetingFields?: Maybe<Array<MeetingField>>;
  meetingTag?: Maybe<MeetingTag>;
  meetingTags?: Maybe<PaginatedMeetingTagsResponse>;
  fieldGroup?: Maybe<FieldGroup>;
  fieldGroups?: Maybe<Array<FieldGroup>>;
  report?: Maybe<Report>;
  reports?: Maybe<PaginatedReportsResponse>;
  generateReport?: Maybe<Scalars["String"]>;
  reportGroup?: Maybe<ReportGroup>;
  reportGroups?: Maybe<PaginatedReportGroupsResponse>;
  reportColumn?: Maybe<ReportColumn>;
  reportColumns?: Maybe<Array<ReportColumn>>;
  reportEntityMetadata?: Maybe<ReportMetadata>;
  reportStaticFieldKeys?: Maybe<Array<Scalars["String"]>>;
  reportHeaderVariableNames?: Maybe<Array<Scalars["String"]>>;
  reportFilter?: Maybe<ReportFilter>;
  reportFilters?: Maybe<Array<ReportFilter>>;
  leaderboard?: Maybe<Array<LeaderboardUser>>;
  campaignStats?: Maybe<CampaignStats>;
  zoomUsers: Array<ZoomUser>;
  zoomCallLog: ZoomCallLogResponse;
  zoomRecordings: ZoomRecordingsResponse;
  zoomCallReport: ZoomCallReport;
};

export type QueryUsersArgs = {
  data?: Maybe<QueryUsersInput>;
};

export type QueryUserArgs = {
  id: Scalars["String"];
};

export type QueryDescribeVtigerModuleArgs = {
  moduleType: Scalars["String"];
};

export type QueryGetVtigerCompanyArgs = {
  domain: Scalars["String"];
};

export type QueryGetVtigerContactArgs = {
  email: Scalars["String"];
};

export type QueryCompanyArgs = {
  domain?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryGetFaviconArgs = {
  baseUrl: Scalars["String"];
};

export type QueryCompaniesArgs = {
  data?: Maybe<QueryCompaniesInput>;
};

export type QueryContactArgs = {
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type QueryContactsArgs = {
  data?: Maybe<QueryContactsInput>;
};

export type QueryClientArgs = {
  id: Scalars["String"];
};

export type QueryClientsArgs = {
  data?: Maybe<QueryClientsInput>;
};

export type QueryTradeshowArgs = {
  id: Scalars["String"];
};

export type QueryTradeshowsArgs = {
  data?: Maybe<QueryTradeshowsInput>;
};

export type QueryClientPartnerArgs = {
  id: Scalars["String"];
};

export type QueryClientPartnersArgs = {
  data?: Maybe<QueryClientPartnersInput>;
};

export type QueryCamapignArgs = {
  id: Scalars["String"];
};

export type QueryCampaignsArgs = {
  data?: Maybe<QueryCampaignsInput>;
};

export type QueryClientContactArgs = {
  id: Scalars["String"];
};

export type QueryClientContactsArgs = {
  data?: Maybe<QueryClientContactsInput>;
};

export type QueryMeetingArgs = {
  id: Scalars["String"];
};

export type QueryMeetingsArgs = {
  data?: Maybe<QueryMeetingsInput>;
};

export type QueryFilterMeetingsArgs = {
  data: FilterMeetingsInput;
};

export type QueryMeetingSummaryArgs = {
  meetingId: Scalars["String"];
  reportGroupId: Scalars["String"];
};

export type QueryMeetingFieldArgs = {
  id: Scalars["String"];
};

export type QueryMeetingFieldsArgs = {
  data?: Maybe<QueryMeetingFieldsInput>;
};

export type QueryMeetingTagArgs = {
  id: Scalars["String"];
};

export type QueryMeetingTagsArgs = {
  data?: Maybe<QueryMeetingTagsInput>;
};

export type QueryFieldGroupArgs = {
  id: Scalars["String"];
};

export type QueryFieldGroupsArgs = {
  fieldType: Scalars["String"];
};

export type QueryReportArgs = {
  id: Scalars["String"];
};

export type QueryReportsArgs = {
  data?: Maybe<QueryReportsInput>;
};

export type QueryGenerateReportArgs = {
  id: Scalars["String"];
};

export type QueryReportGroupArgs = {
  id: Scalars["String"];
};

export type QueryReportGroupsArgs = {
  data?: Maybe<QueryReportGroupsInput>;
};

export type QueryReportColumnArgs = {
  id: Scalars["String"];
};

export type QueryReportColumnsArgs = {
  reportId: Scalars["String"];
};

export type QueryReportEntityMetadataArgs = {
  entityType?: Maybe<Scalars["String"]>;
};

export type QueryReportFilterArgs = {
  id: Scalars["String"];
};

export type QueryReportFiltersArgs = {
  reportId: Scalars["String"];
};

export type QueryLeaderboardArgs = {
  data: LeaderboardInput;
};

export type QueryCampaignStatsArgs = {
  data: CampaignStatsInput;
};

export type QueryZoomCallLogArgs = {
  data: ZoomCallLogInput;
};

export type QueryZoomRecordingsArgs = {
  data: ZoomRecordingInput;
};

export type QueryZoomCallReportArgs = {
  data: ZoomCallReportInput;
};

export type QueryCampaignsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  ids?: Maybe<Array<Scalars["String"]>>;
  name?: Maybe<Scalars["String"]>;
  status?: Maybe<CampaignStatus>;
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
};

export type QueryClientContactsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  ids?: Maybe<Array<Scalars["String"]>>;
  name?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
};

export type QueryClientPartnersInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  clientsId?: Maybe<Array<Scalars["String"]>>;
};

export type QueryClientsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export type QueryCompaniesInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  ids?: Maybe<Array<Scalars["String"]>>;
  domain?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  useOrQuery?: Maybe<Scalars["Boolean"]>;
};

export type QueryContactsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  ids?: Maybe<Array<Scalars["String"]>>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["String"]>;
  useOrQuery?: Maybe<Scalars["Boolean"]>;
};

export type QueryMeetingFieldsInput = {
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
};

export type QueryMeetingsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  status?: Maybe<Scalars["String"]>;
  datetime?: Maybe<Scalars["DateTime"]>;
  ownerId?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["String"]>;
  partnerId?: Maybe<Scalars["String"]>;
  contactId?: Maybe<Scalars["String"]>;
  MMIPipeline?: Maybe<Scalars["String"]>;
  contactCompanyId?: Maybe<Scalars["String"]>;
  campaignId?: Maybe<Scalars["String"]>;
  tradeshowId?: Maybe<Scalars["String"]>;
  salesRepId?: Maybe<Scalars["String"]>;
  salesEngineerId?: Maybe<Scalars["String"]>;
  partnerSalesRepId?: Maybe<Scalars["String"]>;
  partnerSalesEngineerId?: Maybe<Scalars["String"]>;
};

export type QueryMeetingTagsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  tagsId?: Maybe<Array<Scalars["String"]>>;
};

export type QueryReportGroupsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export type QueryReportsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export type QueryTradeshowsInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  clientId?: Maybe<Scalars["String"]>;
};

export type QueryUsersInput = {
  offset?: Maybe<Scalars["Float"]>;
  limit?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  clientsId?: Maybe<Array<Scalars["String"]>>;
  role?: Maybe<Role>;
};

export type RegisterInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
};

export type Report = {
  id: Scalars["String"];
  name: Scalars["String"];
  headerTemplateString?: Maybe<Scalars["String"]>;
  position: Scalars["Int"];
  reportGroup: ReportGroup;
  columns?: Maybe<Array<ReportColumn>>;
  filters?: Maybe<Array<ReportFilter>>;
};

export type ReportColumn = {
  id: Scalars["String"];
  name: Scalars["String"];
  position: Scalars["Int"];
  dateFormat?: Maybe<Scalars["String"]>;
  dataType?: Maybe<Scalars["String"]>;
  report: Report;
  /** Unique field identifier */
  staticFieldKey?: Maybe<Scalars["String"]>;
  meetingField?: Maybe<MeetingField>;
  contactField?: Maybe<ContactField>;
  companyField?: Maybe<CompanyField>;
  /** Unique field identifier */
  entityRelationFieldKey?: Maybe<Scalars["String"]>;
};

export type ReportFieldMetadata = {
  type: Scalars["String"];
  staticFieldKey?: Maybe<Scalars["String"]>;
  propertyKey: Scalars["String"];
  name: Scalars["String"];
  dataType?: Maybe<Scalars["String"]>;
  selectionOptions?: Maybe<Array<ReportFieldSelectionOption>>;
  relationIdType?: Maybe<EntityRelationType>;
};

export type ReportFieldSelectionOption = {
  key: Scalars["String"];
  name: Scalars["String"];
};

export type ReportFilter = {
  id: Scalars["String"];
  entityName: EntityRelationType;
  filterValue: Scalars["FieldValue"];
  comparisonOperator: ComparisonOperator;
  staticFieldKey?: Maybe<Scalars["String"]>;
  customFieldId?: Maybe<Scalars["String"]>;
  relativeDateDaysOffset?: Maybe<Scalars["Int"]>;
  report: Report;
};

export type ReportGroup = {
  id: Scalars["String"];
  name: Scalars["String"];
  reports?: Maybe<Array<Report>>;
  isSummaryTemplate: Scalars["Boolean"];
};

export type ReportMetadata = {
  entityName: Scalars["String"];
  fields: Array<ReportStaticRelationOrFieldData>;
  customFields?: Maybe<Array<EntityField>>;
};

export type ReportStaticRelationOrFieldData = {
  type: Scalars["String"];
  staticFieldKey?: Maybe<Scalars["String"]>;
  propertyKey: Scalars["String"];
  name: Scalars["String"];
  dataType?: Maybe<Scalars["String"]>;
  selectionOptions?: Maybe<Array<ReportFieldSelectionOption>>;
  relationIdType?: Maybe<EntityRelationType>;
  entityName?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<ReportStaticRelationOrFieldData>>;
  customFields?: Maybe<Array<EntityField>>;
};

/** User access role */
export enum Role {
  User = "USER",
  Admin = "ADMIN"
}

export type SummaryField = {
  name: Scalars["String"];
  value?: Maybe<Scalars["FieldValue"]>;
};

export type SummarySection = {
  name: Scalars["String"];
  fields: Array<SummaryField>;
};

export type Tradeshow = {
  id: Scalars["ID"];
  name: Scalars["String"];
  client?: Maybe<Client>;
  partner?: Maybe<ClientPartner>;
};

export type UpdateFieldGroupInput = {
  name: Scalars["String"];
};

export type UpdateFieldInput = {
  name?: Maybe<Scalars["String"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
};

export type UpdateMeetingFieldInput = {
  name?: Maybe<Scalars["String"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  isEnabled?: Maybe<Scalars["Boolean"]>;
  isMultiSelection?: Maybe<Scalars["Boolean"]>;
  selectionOptions?: Maybe<Array<Scalars["String"]>>;
  vtigerFieldId?: Maybe<Scalars["String"]>;
  isVisibleOnCreate?: Maybe<Scalars["Boolean"]>;
  isAdminField?: Maybe<Scalars["Boolean"]>;
  clientsId?: Maybe<Array<Scalars["String"]>>;
  partnersId?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateReportColumnInput = {
  name: Scalars["String"];
  dateFormat?: Maybe<Scalars["String"]>;
  entityRelationFieldKey?: Maybe<Scalars["String"]>;
};

export type UpdateReportInput = {
  name: Scalars["String"];
  headerTemplateString?: Maybe<Scalars["String"]>;
  columnsId?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateUserInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  role: Role;
  clientsId?: Maybe<Array<Scalars["String"]>>;
  vtigerUsername?: Maybe<Scalars["String"]>;
  vtigerAccessKey?: Maybe<Scalars["String"]>;
  vtigerSessionName?: Maybe<Scalars["String"]>;
};

export type User = {
  id: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  role: Role;
  clients?: Maybe<Array<Client>>;
  meetingFilterGroups?: Maybe<Scalars["String"]>;
  zoomAccountId?: Maybe<Scalars["String"]>;
  vtigerUsername?: Maybe<Scalars["String"]>;
  vtigerAccessKey?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ZoomCallLog = {
  id: Scalars["String"];
  caller_number: Scalars["String"];
  caller_number_type: Scalars["String"];
  caller_name: Scalars["String"];
  callee_number: Scalars["String"];
  callee_name: Scalars["String"];
  direction: Scalars["String"];
  duration: Scalars["Float"];
  result: Scalars["String"];
  date_time: Scalars["String"];
};

export type ZoomCallLogInput = {
  /** YYYY-MM-DD format */
  from: Scalars["String"];
  /** YYYY-MM-DD format */
  to: Scalars["String"];
  userId: Scalars["String"];
  page_size?: Maybe<Scalars["Float"]>;
  page_number?: Maybe<Scalars["Float"]>;
};

export type ZoomCallLogResponse = {
  total_records: Scalars["Float"];
  page_count: Scalars["Float"];
  page_number: Scalars["Float"];
  call_logs: Array<ZoomCallLog>;
};

export type ZoomCallRecording = {
  id: Scalars["String"];
  caller_number: Scalars["String"];
  caller_number_type: Scalars["String"];
  caller_name: Scalars["String"];
  callee_number: Scalars["String"];
  callee_name: Scalars["String"];
  direction: Scalars["String"];
  duration: Scalars["Float"];
  date_time: Scalars["String"];
  download_url: Scalars["String"];
};

export type ZoomCallReport = {
  totalCalls: Scalars["Float"];
  totalOutbound: Scalars["Float"];
  totalInbound: Scalars["Float"];
  totalCanceled: Scalars["Float"];
  totalConnected: Scalars["Float"];
  totalDuration: Scalars["Float"];
  avgDuration: Scalars["Float"];
  dateTimes: Array<Scalars["String"]>;
};

export type ZoomCallReportInput = {
  /** YYYY-MM-DD format */
  date: Scalars["String"];
  userId: Scalars["String"];
};

export type ZoomRecordingInput = {
  userId: Scalars["String"];
  page_size?: Maybe<Scalars["Float"]>;
  page_number?: Maybe<Scalars["Float"]>;
};

export type ZoomRecordingsResponse = {
  total_records: Scalars["Float"];
  page_count: Scalars["Float"];
  page_number: Scalars["Float"];
  recordings: Array<ZoomCallRecording>;
};

export type ZoomUser = {
  id: Scalars["String"];
  first_name: Scalars["String"];
  last_name: Scalars["String"];
  email: Scalars["String"];
};
export type ClientFragment = Pick<Client, "id" | "name"> & {
  partners: Maybe<Array<Pick<ClientPartner, "id" | "name">>>;
};

export type ClientQueryVariables = {
  id: Scalars["String"];
};

export type ClientQuery = { client: Maybe<ClientFragment> };

export type ClientsQueryVariables = {
  data?: Maybe<QueryClientsInput>;
};

export type ClientsQuery = {
  clients: Maybe<
    Pick<PaginatedClientsResponse, "total"> & { items: Array<ClientFragment> }
  >;
};

export type CreateClientMutationVariables = {
  data: CreateClientInput;
};

export type CreateClientMutation = { createClient: Maybe<ClientFragment> };

export type UpdateClientMutationVariables = {
  id: Scalars["String"];
  data: CreateClientInput;
};

export type UpdateClientMutation = { updateClient: Maybe<ClientFragment> };

export type DeleteClientMutationVariables = {
  id: Scalars["String"];
};

export type DeleteClientMutation = Pick<Mutation, "deleteClient">;

export type CompanyFragmentFragment = Pick<
  Company,
  "id" | "domain" | "name" | "iconBase64"
> & { fieldsData: Array<FieldDataFragment> };

export type CompanyQueryVariables = {
  id?: Maybe<Scalars["String"]>;
  domain?: Maybe<Scalars["String"]>;
};

export type CompanyQuery = { company: Maybe<CompanyFragmentFragment> };

export type CompaniesQueryVariables = {
  data?: Maybe<QueryCompaniesInput>;
};

export type CompaniesQuery = {
  companies: Maybe<
    Pick<PaginatedCompaniesResponse, "total"> & {
      items: Array<CompanyFragmentFragment>;
    }
  >;
};

export type CompanyFieldsQueryVariables = {};

export type CompanyFieldsQuery = {
  companyFields: Maybe<Array<FieldSchemaFragment>>;
};

export type CreateCompanyMutationVariables = {
  data: CreateCompanyInput;
};

export type CreateCompanyMutation = {
  createCompany: Maybe<CompanyFragmentFragment>;
};

export type CreateCompanyFieldMutationVariables = {
  data: CreateFieldInput;
};

export type CreateCompanyFieldMutation = {
  createCompanyField: Maybe<FieldSchemaFragment>;
};

export type UpdateCompanyMutationVariables = {
  id: Scalars["String"];
  data: CreateCompanyInput;
};

export type UpdateCompanyMutation = {
  updateCompany: Maybe<CompanyFragmentFragment>;
};

export type UpdateCompanyIconMutationVariables = {
  id: Scalars["String"];
  iconBase64?: Maybe<Scalars["String"]>;
};

export type UpdateCompanyIconMutation = {
  updateCompanyIcon: Maybe<CompanyFragmentFragment>;
};

export type UpdateCompanyFieldMutationVariables = {
  id: Scalars["String"];
  data: UpdateFieldInput;
};

export type UpdateCompanyFieldMutation = {
  updateCompanyField: Maybe<FieldSchemaFragment>;
};

export type ReorderCompanyFieldMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
  groupId?: Maybe<Scalars["String"]>;
};

export type ReorderCompanyFieldMutation = {
  reorderCompanyField: Maybe<Array<FieldSchemaFragment>>;
};

export type DeleteCompanyMutationVariables = {
  id: Scalars["String"];
};

export type DeleteCompanyMutation = Pick<Mutation, "deleteCompany">;

export type DeleteCompanyFieldMutationVariables = {
  id: Scalars["String"];
};

export type DeleteCompanyFieldMutation = Pick<Mutation, "deleteCompanyField">;

export type ContactFragmentFragment = Pick<
  Contact,
  "id" | "email" | "firstName" | "lastName" | "name"
> & {
  fieldsData: Array<FieldDataFragment>;
  company: Maybe<CompanyFragmentFragment>;
};

export type ContactQueryVariables = {
  id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type ContactQuery = { contact: Maybe<ContactFragmentFragment> };

export type ContactsQueryVariables = {
  data?: Maybe<QueryContactsInput>;
};

export type ContactsQuery = {
  contacts: Maybe<
    Pick<PaginatedContactResponse, "total"> & {
      items: Array<ContactFragmentFragment>;
    }
  >;
};

export type ContactFieldsQueryVariables = {};

export type ContactFieldsQuery = {
  contactFields: Maybe<Array<FieldSchemaFragment>>;
};

export type CreateContactMutationVariables = {
  data: CreateContactInput;
};

export type CreateContactMutation = {
  createContact: Maybe<ContactFragmentFragment>;
};

export type CreateContactFieldMutationVariables = {
  data: CreateFieldInput;
};

export type CreateContactFieldMutation = {
  createContactField: Maybe<FieldSchemaFragment>;
};

export type UpdateContactMutationVariables = {
  id: Scalars["String"];
  data: CreateContactInput;
};

export type UpdateContactMutation = {
  updateContact: Maybe<ContactFragmentFragment>;
};

export type UpdateContactFieldMutationVariables = {
  id: Scalars["String"];
  data: UpdateFieldInput;
};

export type UpdateContactFieldMutation = {
  updateContactField: Maybe<FieldSchemaFragment>;
};

export type ReorderContactFieldMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
  groupId?: Maybe<Scalars["String"]>;
};

export type ReorderContactFieldMutation = {
  reorderContactField: Maybe<Array<FieldSchemaFragment>>;
};

export type DeleteContactMutationVariables = {
  id: Scalars["String"];
};

export type DeleteContactMutation = Pick<Mutation, "deleteContact">;

export type DeleteContactFieldMutationVariables = {
  id: Scalars["String"];
};

export type DeleteContactFieldMutation = Pick<Mutation, "deleteContactField">;

export type FieldGroupFragment = Pick<
  FieldGroup,
  "id" | "name" | "position" | "fieldType"
>;

export type FieldGroupQueryVariables = {
  id: Scalars["String"];
};

export type FieldGroupQuery = { fieldGroup: Maybe<FieldGroupFragment> };

export type FieldGroupsQueryVariables = {
  fieldType: Scalars["String"];
};

export type FieldGroupsQuery = {
  fieldGroups: Maybe<Array<FieldGroupFragment>>;
};

export type CreateFieldGroupMutationVariables = {
  data: CreateFieldGroupInput;
};

export type CreateFieldGroupMutation = {
  createFieldGroup: Maybe<FieldGroupFragment>;
};

export type UpdateFieldGroupMutationVariables = {
  id: Scalars["String"];
  data: UpdateFieldGroupInput;
};

export type UpdateFieldGroupMutation = {
  updateFieldGroup: Maybe<FieldGroupFragment>;
};

export type ReorderFieldGroupMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
};

export type ReorderFieldGroupMutation = {
  reorderFieldGroup: Maybe<Array<FieldGroupFragment>>;
};

export type DeleteFieldGroupMutationVariables = {
  id: Scalars["String"];
};

export type DeleteFieldGroupMutation = Pick<Mutation, "deleteFieldGroup">;

export type FieldDataFragment = Pick<
  FieldDataObjectType,
  | "fieldId"
  | "name"
  | "type"
  | "value"
  | "position"
  | "isRequired"
  | "isEnabled"
  | "isMultiSelection"
  | "entityRelationType"
  | "selectionOptions"
>;

export type FieldSchemaFragment = Pick<
  EntityField,
  | "id"
  | "type"
  | "name"
  | "position"
  | "isRequired"
  | "isEnabled"
  | "isMultiSelection"
  | "selectionOptions"
  | "vtigerFieldId"
  | "entityRelationType"
  | "isAdminField"
  | "isVisibleOnCreate"
> & {
  group: Maybe<Pick<FieldGroup, "id" | "name" | "position" | "fieldType">>;
};

export type MeetingFragment = Pick<
  Meeting,
  | "id"
  | "name"
  | "status"
  | "createdDate"
  | "date"
  | "time"
  | "timezone"
  | "duration"
  | "MMIPipeline"
> & {
  fieldsData: Array<
    Pick<FieldDataObjectType, "fieldId" | "type" | "name" | "value">
  >;
  owner: Maybe<Pick<User, "id" | "firstName" | "lastName" | "email" | "name">>;
  contact: Maybe<
    Pick<Contact, "id" | "name" | "email"> & {
      company: Maybe<Pick<Company, "id" | "domain" | "name">>;
    }
  >;
  tags: Maybe<Array<Pick<MeetingTag, "id" | "name">>>;
  client: Maybe<Pick<Client, "id" | "name">>;
  partner: Maybe<Pick<ClientPartner, "id" | "name">>;
  campaign: Maybe<Pick<Campaign, "id" | "name">>;
  tradeshow: Maybe<Pick<Tradeshow, "id" | "name">>;
  salesReps: Maybe<
    Array<Pick<ClientContact, "id" | "name" | "title" | "email">>
  >;
  salesEngineers: Maybe<
    Array<Pick<ClientContact, "id" | "name" | "title" | "email">>
  >;
  partnerSalesReps: Maybe<
    Array<Pick<ClientContact, "id" | "name" | "title" | "email">>
  >;
  partnerSalesEngineers: Maybe<
    Array<Pick<ClientContact, "id" | "name" | "title" | "email">>
  >;
};

export type MeetingQueryVariables = {
  id: Scalars["String"];
};

export type MeetingQuery = { meeting: Maybe<MeetingFragment> };

export type MeetingsQueryVariables = {
  data?: Maybe<QueryMeetingsInput>;
};

export type MeetingsQuery = {
  meetings: Maybe<
    Pick<PaginatedMeetingsResponse, "total"> & { items: Array<MeetingFragment> }
  >;
};

export type FilterMeetingsQueryVariables = {
  data: FilterMeetingsInput;
};

export type FilterMeetingsQuery = {
  filterMeetings: Maybe<
    Pick<PaginatedMeetingsResponse, "total"> & { items: Array<MeetingFragment> }
  >;
};

export type CreateMeetingMutationVariables = {
  data: CreateMeetingInput;
};

export type CreateMeetingMutation = { createMeeting: Maybe<MeetingFragment> };

export type UpdateMeetingMutationVariables = {
  id: Scalars["String"];
  data: CreateMeetingInput;
};

export type UpdateMeetingMutation = { updateMeeting: Maybe<MeetingFragment> };

export type DeleteMeetingMutationVariables = {
  id: Scalars["String"];
};

export type DeleteMeetingMutation = Pick<Mutation, "deleteMeeting">;

export type ReportFragment = Pick<
  Report,
  "id" | "name" | "position" | "headerTemplateString"
> & {
  reportGroup: Pick<ReportGroup, "id" | "name">;
  columns: Maybe<Array<Pick<ReportColumn, "id" | "name">>>;
};

export type ReportQueryVariables = {
  id: Scalars["String"];
};

export type ReportQuery = { report: Maybe<ReportFragment> };

export type ReportsQueryVariables = {
  data?: Maybe<QueryReportsInput>;
};

export type ReportsQuery = {
  reports: Maybe<
    Pick<PaginatedReportsResponse, "total"> & { items: Array<ReportFragment> }
  >;
};

export type GenerateReportQueryVariables = {
  id: Scalars["String"];
};

export type GenerateReportQuery = Pick<Query, "generateReport">;

export type CreateReportMutationVariables = {
  data: CreateReportInput;
};

export type CreateReportMutation = { createReport: Maybe<ReportFragment> };

export type CopyReportMutationVariables = {
  data: CopyReportInput;
};

export type CopyReportMutation = { copyReport: Maybe<ReportFragment> };

export type MoveReportReportGroupMutationVariables = {
  data: MoveReportReportGroupInput;
};

export type MoveReportReportGroupMutation = {
  moveReportReportGroupResolver: Maybe<ReportFragment>;
};

export type UpdateReportMutationVariables = {
  id: Scalars["String"];
  data: UpdateReportInput;
};

export type UpdateReportMutation = { updateReport: Maybe<ReportFragment> };

export type DeleteReportMutationVariables = {
  id: Scalars["String"];
};

export type DeleteReportMutation = Pick<Mutation, "deleteReport">;

export type ReorderReportMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
};

export type ReorderReportMutation = { reorderReport: Maybe<ReportFragment> };

export type LeaderboardQueryVariables = {
  data: LeaderboardInput;
};

export type LeaderboardQuery = {
  leaderboard: Maybe<
    Array<
      Pick<
        LeaderboardUser,
        "userId" | "userName" | "meetingsHeld" | "meetingsPlanned"
      >
    >
  >;
};

export type CampaignStatsQueryVariables = {
  data: CampaignStatsInput;
};

export type CampaignStatsQuery = {
  campaignStats: Maybe<
    Pick<CampaignStats, "meetingsCount" | "totalMeetings"> & {
      meetingsStatus: Pick<
        MeetingsStatusObjectType,
        "Planned" | "Held" | "To_Be_Rescheduled" | "Cancelled"
      >;
    }
  >;
};

export type UserFieldsFragment = Pick<
  User,
  | "id"
  | "firstName"
  | "lastName"
  | "name"
  | "email"
  | "role"
  | "meetingFilterGroups"
  | "zoomAccountId"
  | "vtigerUsername"
  | "vtigerAccessKey"
> & { clients: Maybe<Array<Pick<Client, "id" | "name">>> };

export type MeQueryVariables = {};

export type MeQuery = { me: Maybe<UserFieldsFragment> };

export type UserQueryVariables = {
  id: Scalars["String"];
};

export type UserQuery = { user: Maybe<UserFieldsFragment> };

export type UsersQueryVariables = {
  data?: Maybe<QueryUsersInput>;
};

export type UsersQuery = {
  users: Maybe<
    Pick<PaginatedUsersResponse, "total"> & { items: Array<UserFieldsFragment> }
  >;
};

export type RegisterMutationVariables = {
  data: RegisterInput;
};

export type RegisterMutation = { register: UserFieldsFragment };

export type LoginMutationVariables = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LoginMutation = { login: Maybe<UserFieldsFragment> };

export type LogoutMutationVariables = {};

export type LogoutMutation = Pick<Mutation, "logout">;

export type UpdateUserMutationVariables = {
  id: Scalars["String"];
  data: UpdateUserInput;
};

export type UpdateUserMutation = { updateUser: Maybe<UserFieldsFragment> };

export type UpdateUserPasswordMutationVariables = {
  id: Scalars["String"];
  password: Scalars["String"];
};

export type UpdateUserPasswordMutation = {
  updateUserPassword: Maybe<UserFieldsFragment>;
};

export type UpdateUserMeetingFilterGroupsMutationVariables = {
  jsonData: Scalars["String"];
};

export type UpdateUserMeetingFilterGroupsMutation = {
  updateUserMeetingFilterGroups: Maybe<UserFieldsFragment>;
};

export type DeleteUserMutationVariables = {
  id: Scalars["String"];
};

export type DeleteUserMutation = Pick<Mutation, "deleteUser">;

export type DescribeVtigerModuleQueryVariables = {
  moduleType: Scalars["String"];
};

export type DescribeVtigerModuleQuery = Pick<Query, "describeVtigerModule">;

export type GetVtigerCompanyQueryVariables = {
  domain: Scalars["String"];
};

export type GetVtigerCompanyQuery = Pick<Query, "getVtigerCompany">;

export type GetVtigerContactQueryVariables = {
  email: Scalars["String"];
};

export type GetVtigerContactQuery = Pick<Query, "getVtigerContact">;

export type ZoomUsersQueryVariables = {};

export type ZoomUsersQuery = {
  zoomUsers: Array<Pick<ZoomUser, "id" | "first_name" | "last_name" | "email">>;
};

export type ZoomCallLogQueryVariables = {
  data: ZoomCallLogInput;
};

export type ZoomCallLogQuery = {
  zoomCallLog: Pick<
    ZoomCallLogResponse,
    "total_records" | "page_count" | "page_number"
  > & {
    call_logs: Array<
      Pick<
        ZoomCallLog,
        | "id"
        | "caller_number"
        | "caller_number_type"
        | "caller_name"
        | "callee_number"
        | "callee_name"
        | "direction"
        | "duration"
        | "result"
        | "date_time"
      >
    >;
  };
};

export type ZoomRecordingsQueryVariables = {
  data: ZoomRecordingInput;
};

export type ZoomRecordingsQuery = {
  zoomRecordings: Pick<
    ZoomRecordingsResponse,
    "total_records" | "page_count" | "page_number"
  > & {
    recordings: Array<
      Pick<
        ZoomCallRecording,
        | "id"
        | "caller_number"
        | "caller_number_type"
        | "caller_name"
        | "callee_number"
        | "callee_name"
        | "direction"
        | "duration"
        | "date_time"
        | "download_url"
      >
    >;
  };
};

export type ZoomCallReportQueryVariables = {
  data: ZoomCallReportInput;
};

export type ZoomCallReportQuery = {
  zoomCallReport: Pick<
    ZoomCallReport,
    | "totalCalls"
    | "totalOutbound"
    | "totalInbound"
    | "totalCanceled"
    | "totalConnected"
    | "totalDuration"
    | "avgDuration"
    | "dateTimes"
  >;
};

export type CampaignFragment = Pick<
  Campaign,
  "id" | "name" | "status" | "startDate" | "totalMeetings" | "invoiceName"
> & {
  client: Maybe<Pick<Client, "id" | "name">>;
  partner: Maybe<Pick<ClientPartner, "id" | "name">>;
};

export type CampaignQueryVariables = {
  id: Scalars["String"];
};

export type CampaignQuery = { camapign: Maybe<CampaignFragment> };

export type CampaignsQueryVariables = {
  data?: Maybe<QueryCampaignsInput>;
};

export type CampaignsQuery = {
  campaigns: Maybe<
    Pick<PaginatedCampaignsResponse, "total"> & {
      items: Array<CampaignFragment>;
    }
  >;
};

export type CreateCampaignMutationVariables = {
  data: CreateCampaignInput;
};

export type CreateCampaignMutation = {
  createCampaign: Maybe<CampaignFragment>;
};

export type UpdateCampaignMutationVariables = {
  id: Scalars["String"];
  data: CreateCampaignInput;
};

export type UpdateCampaignMutation = {
  updateCampaign: Maybe<CampaignFragment>;
};

export type DeleteCampaignMutationVariables = {
  id: Scalars["String"];
};

export type DeleteCampaignMutation = Pick<Mutation, "deleteCampaign">;

export type ClientContactFragment = Pick<
  ClientContact,
  | "id"
  | "firstName"
  | "lastName"
  | "name"
  | "title"
  | "phoneOffice"
  | "phoneCell"
  | "email"
  | "street"
  | "suite"
  | "city"
  | "state"
  | "zipcode"
  | "country"
> & {
  client: Maybe<Pick<Client, "id" | "name">>;
  partner: Maybe<Pick<ClientPartner, "id" | "name">>;
};

export type ClientContactQueryVariables = {
  id: Scalars["String"];
};

export type ClientContactQuery = {
  clientContact: Maybe<ClientContactFragment>;
};

export type ClientContactsQueryVariables = {
  data?: Maybe<QueryClientContactsInput>;
};

export type ClientContactsQuery = {
  clientContacts: Maybe<
    Pick<PaginatedClientContactsResponse, "total"> & {
      items: Array<ClientContactFragment>;
    }
  >;
};

export type CreateClientContactMutationVariables = {
  data: CreateClientContactInput;
};

export type CreateClientContactMutation = {
  createClientContact: Maybe<ClientContactFragment>;
};

export type UpdateClientContactMutationVariables = {
  id: Scalars["String"];
  data: CreateClientContactInput;
};

export type UpdateClientContactMutation = {
  updateClientContact: Maybe<ClientContactFragment>;
};

export type DeleteClientContactMutationVariables = {
  id: Scalars["String"];
};

export type DeleteClientContactMutation = Pick<Mutation, "deleteClientContact">;

export type ClientPartnerFragment = Pick<ClientPartner, "id" | "name"> & {
  clients: Maybe<Array<Pick<Client, "id" | "name">>>;
};

export type ClientPartnerQueryVariables = {
  id: Scalars["String"];
};

export type ClientPartnerQuery = {
  clientPartner: Maybe<ClientPartnerFragment>;
};

export type ClientPartnersQueryVariables = {
  data?: Maybe<QueryClientPartnersInput>;
};

export type ClientPartnersQuery = {
  clientPartners: Maybe<
    Pick<PaginatedClientPartnersResponse, "total"> & {
      items: Array<ClientPartnerFragment>;
    }
  >;
};

export type CreateClientPartnerMutationVariables = {
  data: CreateClientPartnerInput;
};

export type CreateClientPartnerMutation = {
  createClientPartner: Maybe<ClientPartnerFragment>;
};

export type UpdateClientPartnerMutationVariables = {
  id: Scalars["String"];
  data: CreateClientPartnerInput;
};

export type UpdateClientPartnerMutation = {
  updateClientPartner: Maybe<ClientPartnerFragment>;
};

export type DeleteClientPartnerMutationVariables = {
  id: Scalars["String"];
};

export type DeleteClientPartnerMutation = Pick<Mutation, "deleteClientPartner">;

export type TradeshowFragment = Pick<Tradeshow, "id" | "name"> & {
  client: Maybe<Pick<Client, "id" | "name">>;
  partner: Maybe<Pick<ClientPartner, "id" | "name">>;
};

export type TradeshowQueryVariables = {
  id: Scalars["String"];
};

export type TradeshowQuery = { tradeshow: Maybe<TradeshowFragment> };

export type TradeshowsQueryVariables = {
  data?: Maybe<QueryTradeshowsInput>;
};

export type TradeshowsQuery = {
  tradeshows: Maybe<
    Pick<PaginatedTradeshowsResponse, "total"> & {
      items: Array<TradeshowFragment>;
    }
  >;
};

export type CreateTradeshowMutationVariables = {
  data: CreateTradeshowInput;
};

export type CreateTradeshowMutation = {
  createTradeshow: Maybe<TradeshowFragment>;
};

export type UpdateTradeshowMutationVariables = {
  id: Scalars["String"];
  data: CreateTradeshowInput;
};

export type UpdateTradeshowMutation = {
  updateTradeshow: Maybe<TradeshowFragment>;
};

export type DeleteTradeshowMutationVariables = {
  id: Scalars["String"];
};

export type DeleteTradeshowMutation = Pick<Mutation, "deleteTradeshow">;

export type MeetingFieldFragment = Pick<
  MeetingField,
  | "id"
  | "type"
  | "name"
  | "position"
  | "isRequired"
  | "isEnabled"
  | "isMultiSelection"
  | "selectionOptions"
  | "entityRelationType"
  | "isAdminField"
  | "isVisibleOnCreate"
> & {
  clients: Maybe<Array<Pick<Client, "id" | "name">>>;
  partners: Maybe<Array<Pick<ClientPartner, "id" | "name">>>;
  group: Maybe<Pick<FieldGroup, "id" | "name" | "position" | "fieldType">>;
};

export type MeetingFieldQueryVariables = {
  id: Scalars["String"];
};

export type MeetingFieldQuery = { meetingField: Maybe<MeetingFieldFragment> };

export type MeetingFieldsQueryVariables = {
  data?: Maybe<QueryMeetingFieldsInput>;
};

export type MeetingFieldsQuery = {
  meetingFields: Maybe<Array<MeetingFieldFragment>>;
};

export type CreateMeetingFieldMutationVariables = {
  data: CreateMeetingFieldInput;
};

export type CreateMeetingFieldMutation = {
  createMeetingField: Maybe<MeetingFieldFragment>;
};

export type UpdateMeetingFieldMutationVariables = {
  id: Scalars["String"];
  data: UpdateMeetingFieldInput;
};

export type UpdateMeetingFieldMutation = {
  updateMeetingField: Maybe<MeetingFieldFragment>;
};

export type ReorderMeetingFieldMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
  groupId?: Maybe<Scalars["String"]>;
};

export type ReorderMeetingFieldMutation = {
  reorderMeetingField: Maybe<Array<MeetingFieldFragment>>;
};

export type DeleteMeetingFieldMutationVariables = {
  id: Scalars["String"];
};

export type DeleteMeetingFieldMutation = Pick<Mutation, "deleteMeetingField">;

export type MeetingSummaryOptionsQueryVariables = {};

export type MeetingSummaryOptionsQuery = {
  meetingSummaryOptions: Array<
    Pick<MeetingSummaryOption, "reportGroupId" | "reportGroupName">
  >;
};

export type MeetingSummaryQueryVariables = {
  reportGroupId: Scalars["String"];
  meetingId: Scalars["String"];
};

export type MeetingSummaryQuery = {
  meetingSummary: Maybe<
    Array<
      Pick<SummarySection, "name"> & {
        fields: Array<Pick<SummaryField, "name" | "value">>;
      }
    >
  >;
};

export type MeetingTagFragment = Pick<MeetingTag, "id" | "name" | "color">;

export type MeetingTagQueryVariables = {
  id: Scalars["String"];
};

export type MeetingTagQuery = { meetingTag: Maybe<MeetingTagFragment> };

export type MeetingTagsQueryVariables = {
  data?: Maybe<QueryMeetingTagsInput>;
};

export type MeetingTagsQuery = {
  meetingTags: Maybe<
    Pick<PaginatedMeetingTagsResponse, "total"> & {
      items: Array<MeetingTagFragment>;
    }
  >;
};

export type CreateMeetingTagMutationVariables = {
  data: CreateMeetingTagInput;
};

export type CreateMeetingTagMutation = {
  createMeetingTag: Maybe<MeetingTagFragment>;
};

export type UpdateMeetingTagMutationVariables = {
  id: Scalars["String"];
  data: CreateMeetingTagInput;
};

export type UpdateMeetingTagMutation = {
  updateMeetingTag: Maybe<MeetingTagFragment>;
};

export type DeleteMeetingTagMutationVariables = {
  id: Scalars["String"];
};

export type DeleteMeetingTagMutation = Pick<Mutation, "deleteMeetingTag">;

export type ReportColumnFragment = Pick<
  ReportColumn,
  | "id"
  | "name"
  | "position"
  | "staticFieldKey"
  | "entityRelationFieldKey"
  | "dataType"
  | "dateFormat"
> & {
  meetingField: Maybe<
    Pick<
      MeetingField,
      | "id"
      | "type"
      | "name"
      | "position"
      | "isRequired"
      | "isEnabled"
      | "isMultiSelection"
      | "selectionOptions"
      | "vtigerFieldId"
      | "entityRelationType"
      | "isAdminField"
      | "isVisibleOnCreate"
    >
  >;
  contactField: Maybe<
    Pick<
      ContactField,
      | "id"
      | "type"
      | "name"
      | "position"
      | "isRequired"
      | "isEnabled"
      | "isMultiSelection"
      | "selectionOptions"
      | "vtigerFieldId"
      | "entityRelationType"
      | "isAdminField"
      | "isVisibleOnCreate"
    >
  >;
  companyField: Maybe<
    Pick<
      CompanyField,
      | "id"
      | "type"
      | "name"
      | "position"
      | "isRequired"
      | "isEnabled"
      | "isMultiSelection"
      | "selectionOptions"
      | "vtigerFieldId"
      | "entityRelationType"
      | "isAdminField"
      | "isVisibleOnCreate"
    >
  >;
};

export type ReportColumnQueryVariables = {
  id: Scalars["String"];
};

export type ReportColumnQuery = { reportColumn: Maybe<ReportColumnFragment> };

export type ReportColumnsQueryVariables = {
  reportId: Scalars["String"];
};

export type ReportColumnsQuery = {
  reportColumns: Maybe<Array<ReportColumnFragment>>;
};

export type CreateReportColumnMutationVariables = {
  data: CreateReportColumnInput;
};

export type CreateReportColumnMutation = {
  createReportColumn: Maybe<ReportColumnFragment>;
};

export type UpdateReportColumnMutationVariables = {
  id: Scalars["String"];
  data: UpdateReportColumnInput;
};

export type UpdateReportColumnMutation = {
  updateReportColumn: Maybe<ReportColumnFragment>;
};

export type ReorderReportColumnMutationVariables = {
  id: Scalars["String"];
  position: Scalars["Float"];
};

export type ReorderReportColumnMutation = {
  reorderReportColumn: Maybe<Array<ReportColumnFragment>>;
};

export type DeleteReportColumnMutationVariables = {
  id: Scalars["String"];
};

export type DeleteReportColumnMutation = Pick<Mutation, "deleteReportColumn">;

export type ReportFilterFragment = Pick<
  ReportFilter,
  | "id"
  | "entityName"
  | "filterValue"
  | "comparisonOperator"
  | "staticFieldKey"
  | "customFieldId"
  | "relativeDateDaysOffset"
>;

export type ReportFilterQueryVariables = {
  id: Scalars["String"];
};

export type ReportFilterQuery = { reportFilter: Maybe<ReportFilterFragment> };

export type ReportFiltersQueryVariables = {
  reportId: Scalars["String"];
};

export type ReportFiltersQuery = {
  reportFilters: Maybe<Array<ReportFilterFragment>>;
};

export type CreateReportFilterMutationVariables = {
  data: CreateReportFilterInput;
};

export type CreateReportFilterMutation = {
  createReportFilter: Maybe<ReportFilterFragment>;
};

export type UpdateReportFilterMutationVariables = {
  id: Scalars["String"];
  data: FilterEntry;
};

export type UpdateReportFilterMutation = {
  updateReportFilter: Maybe<ReportFilterFragment>;
};

export type DeleteReportFilterMutationVariables = {
  id: Scalars["String"];
};

export type DeleteReportFilterMutation = Pick<Mutation, "deleteReportFilter">;

export type ReportGroupFragment = Pick<
  ReportGroup,
  "id" | "name" | "isSummaryTemplate"
> & { reports: Maybe<Array<Pick<Report, "id" | "name" | "position">>> };

export type ReportGroupQueryVariables = {
  id: Scalars["String"];
};

export type ReportGroupQuery = { reportGroup: Maybe<ReportGroupFragment> };

export type ReportGroupsQueryVariables = {
  data?: Maybe<QueryReportGroupsInput>;
};

export type ReportGroupsQuery = {
  reportGroups: Maybe<
    Pick<PaginatedReportGroupsResponse, "total"> & {
      items: Array<ReportGroupFragment>;
    }
  >;
};

export type CreateReportGroupMutationVariables = {
  data: CreateReportGroupInput;
};

export type CreateReportGroupMutation = {
  createReportGroup: Maybe<ReportGroupFragment>;
};

export type UpdateReportGroupMutationVariables = {
  id: Scalars["String"];
  data: CreateReportGroupInput;
};

export type UpdateReportGroupMutation = {
  updateReportGroup: Maybe<ReportGroupFragment>;
};

export type DeleteReportGroupMutationVariables = {
  id: Scalars["String"];
};

export type DeleteReportGroupMutation = Pick<Mutation, "deleteReportGroup">;

export type ReportStaticFieldKeysQueryVariables = {};

export type ReportStaticFieldKeysQuery = Pick<Query, "reportStaticFieldKeys">;

export type ReportEntityMetadataQueryVariables = {
  entityType?: Maybe<Scalars["String"]>;
};

export type ReportEntityMetadataQuery = {
  reportEntityMetadata: Maybe<
    Pick<ReportMetadata, "entityName"> & {
      customFields: Maybe<Array<FieldSchemaFragment>>;
      fields: Array<ReportStaticRelationOrFieldDataFragment>;
    }
  >;
};

export type ReportStaticRelationOrFieldDataFragment = Pick<
  ReportStaticRelationOrFieldData,
  | "type"
  | "propertyKey"
  | "name"
  | "staticFieldKey"
  | "entityName"
  | "dataType"
  | "relationIdType"
> & {
  selectionOptions: Maybe<
    Array<Pick<ReportFieldSelectionOption, "key" | "name">>
  >;
  customFields: Maybe<Array<FieldSchemaFragment>>;
  fields: Maybe<
    Array<
      Pick<
        ReportStaticRelationOrFieldData,
        | "type"
        | "propertyKey"
        | "name"
        | "staticFieldKey"
        | "entityName"
        | "dataType"
        | "relationIdType"
      > & {
        selectionOptions: Maybe<
          Array<Pick<ReportFieldSelectionOption, "key" | "name">>
        >;
        customFields: Maybe<Array<FieldSchemaFragment>>;
        fields: Maybe<
          Array<
            Pick<
              ReportStaticRelationOrFieldData,
              | "type"
              | "propertyKey"
              | "name"
              | "staticFieldKey"
              | "entityName"
              | "dataType"
              | "relationIdType"
            > & {
              selectionOptions: Maybe<
                Array<Pick<ReportFieldSelectionOption, "key" | "name">>
              >;
              customFields: Maybe<Array<FieldSchemaFragment>>;
              fields: Maybe<
                Array<
                  Pick<
                    ReportStaticRelationOrFieldData,
                    | "type"
                    | "propertyKey"
                    | "name"
                    | "staticFieldKey"
                    | "entityName"
                    | "dataType"
                    | "relationIdType"
                  > & {
                    selectionOptions: Maybe<
                      Array<Pick<ReportFieldSelectionOption, "key" | "name">>
                    >;
                    customFields: Maybe<Array<FieldSchemaFragment>>;
                    fields: Maybe<
                      Array<
                        Pick<
                          ReportStaticRelationOrFieldData,
                          | "type"
                          | "propertyKey"
                          | "name"
                          | "staticFieldKey"
                          | "entityName"
                          | "dataType"
                          | "relationIdType"
                        > & {
                          selectionOptions: Maybe<
                            Array<
                              Pick<ReportFieldSelectionOption, "key" | "name">
                            >
                          >;
                          customFields: Maybe<Array<FieldSchemaFragment>>;
                        }
                      >
                    >;
                  }
                >
              >;
            }
          >
        >;
      }
    >
  >;
};

export type ReportHeaderVariableNamesQueryVariables = {};

export type ReportHeaderVariableNamesQuery = Pick<
  Query,
  "reportHeaderVariableNames"
>;
export const ClientFragmentDoc = gql`
  fragment Client on Client {
    id
    name
    partners {
      id
      name
    }
  }
`;
export const FieldDataFragmentDoc = gql`
  fragment FieldData on FieldDataObjectType {
    fieldId
    name
    type
    value
    position
    isRequired
    isEnabled
    isMultiSelection
    entityRelationType
    selectionOptions
  }
`;
export const CompanyFragmentFragmentDoc = gql`
  fragment CompanyFragment on Company {
    id
    domain
    name
    iconBase64
    fieldsData {
      ...FieldData
    }
  }
  ${FieldDataFragmentDoc}
`;
export const ContactFragmentFragmentDoc = gql`
  fragment ContactFragment on Contact {
    id
    email
    firstName
    lastName
    name
    fieldsData {
      ...FieldData
    }
    company {
      ...CompanyFragment
    }
  }
  ${FieldDataFragmentDoc}
  ${CompanyFragmentFragmentDoc}
`;
export const FieldGroupFragmentDoc = gql`
  fragment FieldGroup on FieldGroup {
    id
    name
    position
    fieldType
  }
`;
export const MeetingFragmentDoc = gql`
  fragment Meeting on Meeting {
    id
    name
    status
    createdDate
    date
    time
    timezone
    duration
    MMIPipeline
    fieldsData {
      fieldId
      type
      name
      value
    }
    owner {
      id
      firstName
      lastName
      email
      name
    }
    contact {
      id
      name
      email
      company {
        id
        domain
        name
      }
    }
    tags {
      id
      name
    }
    client {
      id
      name
    }
    partner {
      id
      name
    }
    campaign {
      id
      name
    }
    tradeshow {
      id
      name
    }
    salesReps {
      id
      name
      title
      email
    }
    salesEngineers {
      id
      name
      title
      email
    }
    partnerSalesReps {
      id
      name
      title
      email
    }
    partnerSalesEngineers {
      id
      name
      title
      email
    }
  }
`;
export const ReportFragmentDoc = gql`
  fragment Report on Report {
    id
    name
    position
    headerTemplateString
    reportGroup {
      id
      name
    }
    columns {
      id
      name
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    firstName
    lastName
    name
    email
    role
    meetingFilterGroups
    clients {
      id
      name
    }
    zoomAccountId
    vtigerUsername
    vtigerAccessKey
  }
`;
export const CampaignFragmentDoc = gql`
  fragment Campaign on Campaign {
    id
    name
    status
    startDate
    totalMeetings
    invoiceName
    client {
      id
      name
    }
    partner {
      id
      name
    }
  }
`;
export const ClientContactFragmentDoc = gql`
  fragment ClientContact on ClientContact {
    id
    firstName
    lastName
    name
    title
    phoneOffice
    phoneCell
    email
    client {
      id
      name
    }
    partner {
      id
      name
    }
    street
    suite
    city
    state
    zipcode
    country
  }
`;
export const ClientPartnerFragmentDoc = gql`
  fragment ClientPartner on ClientPartner {
    id
    name
    clients {
      id
      name
    }
  }
`;
export const TradeshowFragmentDoc = gql`
  fragment Tradeshow on Tradeshow {
    id
    name
    client {
      id
      name
    }
    partner {
      id
      name
    }
  }
`;
export const MeetingFieldFragmentDoc = gql`
  fragment MeetingField on MeetingField {
    id
    type
    name
    position
    isRequired
    isEnabled
    isMultiSelection
    selectionOptions
    entityRelationType
    isAdminField
    isVisibleOnCreate
    clients {
      id
      name
    }
    partners {
      id
      name
    }
    group {
      id
      name
      position
      fieldType
    }
  }
`;
export const MeetingTagFragmentDoc = gql`
  fragment MeetingTag on MeetingTag {
    id
    name
    color
  }
`;
export const ReportColumnFragmentDoc = gql`
  fragment ReportColumn on ReportColumn {
    id
    name
    position
    staticFieldKey
    entityRelationFieldKey
    dataType
    dateFormat
    meetingField {
      id
      type
      name
      position
      isRequired
      isEnabled
      isMultiSelection
      selectionOptions
      vtigerFieldId
      entityRelationType
      isAdminField
      isVisibleOnCreate
    }
    contactField {
      id
      type
      name
      position
      isRequired
      isEnabled
      isMultiSelection
      selectionOptions
      vtigerFieldId
      entityRelationType
      isAdminField
      isVisibleOnCreate
    }
    companyField {
      id
      type
      name
      position
      isRequired
      isEnabled
      isMultiSelection
      selectionOptions
      vtigerFieldId
      entityRelationType
      isAdminField
      isVisibleOnCreate
    }
  }
`;
export const ReportFilterFragmentDoc = gql`
  fragment ReportFilter on ReportFilter {
    id
    entityName
    filterValue
    comparisonOperator
    staticFieldKey
    customFieldId
    relativeDateDaysOffset
  }
`;
export const ReportGroupFragmentDoc = gql`
  fragment ReportGroup on ReportGroup {
    id
    name
    isSummaryTemplate
    reports {
      id
      name
      position
    }
  }
`;
export const FieldSchemaFragmentDoc = gql`
  fragment FieldSchema on EntityField {
    id
    type
    name
    position
    isRequired
    isEnabled
    isMultiSelection
    selectionOptions
    vtigerFieldId
    entityRelationType
    isAdminField
    isVisibleOnCreate
    group {
      id
      name
      position
      fieldType
    }
  }
`;
export const ReportStaticRelationOrFieldDataFragmentDoc = gql`
  fragment ReportStaticRelationOrFieldData on ReportStaticRelationOrFieldData {
    type
    propertyKey
    name
    staticFieldKey
    entityName
    dataType
    relationIdType
    selectionOptions {
      key
      name
    }
    customFields {
      ...FieldSchema
    }
    fields {
      type
      propertyKey
      name
      staticFieldKey
      entityName
      dataType
      relationIdType
      selectionOptions {
        key
        name
      }
      customFields {
        ...FieldSchema
      }
      fields {
        type
        propertyKey
        name
        staticFieldKey
        entityName
        dataType
        relationIdType
        selectionOptions {
          key
          name
        }
        customFields {
          ...FieldSchema
        }
        fields {
          type
          propertyKey
          name
          staticFieldKey
          entityName
          dataType
          relationIdType
          selectionOptions {
            key
            name
          }
          customFields {
            ...FieldSchema
          }
          fields {
            type
            propertyKey
            name
            staticFieldKey
            entityName
            dataType
            relationIdType
            selectionOptions {
              key
              name
            }
            customFields {
              ...FieldSchema
            }
          }
        }
      }
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export const ClientDocument = gql`
  query Client($id: String!) {
    client(id: $id) {
      ...Client
    }
  }
  ${ClientFragmentDoc}
`;
export type ClientComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientQuery,
    ClientQueryVariables
  >,
  "query"
> &
  ({ variables: ClientQueryVariables; skip?: boolean } | { skip: boolean });

export const ClientComponent = (props: ClientComponentProps) => (
  <ApolloReactComponents.Query<ClientQuery, ClientQueryVariables>
    query={ClientDocument}
    {...props}
  />
);

export type ClientProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientQuery,
  ClientQueryVariables
> &
  TChildProps;
export function withClient<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientQuery,
    ClientQueryVariables,
    ClientProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientQuery,
    ClientQueryVariables,
    ClientProps<TChildProps>
  >(ClientDocument, {
    alias: "withClient",
    ...operationOptions
  });
}

export function useClientQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientQuery,
    ClientQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ClientQuery, ClientQueryVariables>(
    ClientDocument,
    baseOptions
  );
}
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientQueryResult = ApolloReactCommon.QueryResult<
  ClientQuery,
  ClientQueryVariables
>;
export const ClientsDocument = gql`
  query Clients($data: QueryClientsInput) {
    clients(data: $data) {
      total
      items {
        ...Client
      }
    }
  }
  ${ClientFragmentDoc}
`;
export type ClientsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientsQuery,
    ClientsQueryVariables
  >,
  "query"
>;

export const ClientsComponent = (props: ClientsComponentProps) => (
  <ApolloReactComponents.Query<ClientsQuery, ClientsQueryVariables>
    query={ClientsDocument}
    {...props}
  />
);

export type ClientsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientsQuery,
  ClientsQueryVariables
> &
  TChildProps;
export function withClients<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientsQuery,
    ClientsQueryVariables,
    ClientsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientsQuery,
    ClientsQueryVariables,
    ClientsProps<TChildProps>
  >(ClientsDocument, {
    alias: "withClients",
    ...operationOptions
  });
}

export function useClientsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientsQuery,
    ClientsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ClientsQuery, ClientsQueryVariables>(
    ClientsDocument,
    baseOptions
  );
}
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsQueryResult = ApolloReactCommon.QueryResult<
  ClientsQuery,
  ClientsQueryVariables
>;
export const CreateClientDocument = gql`
  mutation CreateClient($data: CreateClientInput!) {
    createClient(data: $data) {
      ...Client
    }
  }
  ${ClientFragmentDoc}
`;
export type CreateClientMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientMutation,
  CreateClientMutationVariables
>;
export type CreateClientComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateClientMutation,
    CreateClientMutationVariables
  >,
  "mutation"
>;

export const CreateClientComponent = (props: CreateClientComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateClientMutation,
    CreateClientMutationVariables
  >
    mutation={CreateClientDocument}
    {...props}
  />
);

export type CreateClientProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateClientMutation,
  CreateClientMutationVariables
> &
  TChildProps;
export function withCreateClient<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateClientMutation,
    CreateClientMutationVariables,
    CreateClientProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateClientMutation,
    CreateClientMutationVariables,
    CreateClientProps<TChildProps>
  >(CreateClientDocument, {
    alias: "withCreateClient",
    ...operationOptions
  });
}

export function useCreateClientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClientMutation,
    CreateClientMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateClientMutation,
    CreateClientMutationVariables
  >(CreateClientDocument, baseOptions);
}
export type CreateClientMutationHookResult = ReturnType<
  typeof useCreateClientMutation
>;
export type CreateClientMutationResult = ApolloReactCommon.MutationResult<
  CreateClientMutation
>;
export type CreateClientMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientMutation,
  CreateClientMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation UpdateClient($id: String!, $data: CreateClientInput!) {
    updateClient(id: $id, data: $data) {
      ...Client
    }
  }
  ${ClientFragmentDoc}
`;
export type UpdateClientMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export type UpdateClientComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >,
  "mutation"
>;

export const UpdateClientComponent = (props: UpdateClientComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >
    mutation={UpdateClientDocument}
    {...props}
  />
);

export type UpdateClientProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateClientMutation,
  UpdateClientMutationVariables
> &
  TChildProps;
export function withUpdateClient<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateClientMutation,
    UpdateClientMutationVariables,
    UpdateClientProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateClientMutation,
    UpdateClientMutationVariables,
    UpdateClientProps<TChildProps>
  >(UpdateClientDocument, {
    alias: "withUpdateClient",
    ...operationOptions
  });
}

export function useUpdateClientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UpdateClientDocument, baseOptions);
}
export type UpdateClientMutationHookResult = ReturnType<
  typeof useUpdateClientMutation
>;
export type UpdateClientMutationResult = ApolloReactCommon.MutationResult<
  UpdateClientMutation
>;
export type UpdateClientMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const DeleteClientDocument = gql`
  mutation DeleteClient($id: String!) {
    deleteClient(id: $id)
  }
`;
export type DeleteClientMutationFn = ApolloReactCommon.MutationFunction<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export type DeleteClientComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >,
  "mutation"
>;

export const DeleteClientComponent = (props: DeleteClientComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >
    mutation={DeleteClientDocument}
    {...props}
  />
);

export type DeleteClientProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteClientMutation,
  DeleteClientMutationVariables
> &
  TChildProps;
export function withDeleteClient<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteClientMutation,
    DeleteClientMutationVariables,
    DeleteClientProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteClientMutation,
    DeleteClientMutationVariables,
    DeleteClientProps<TChildProps>
  >(DeleteClientDocument, {
    alias: "withDeleteClient",
    ...operationOptions
  });
}

export function useDeleteClientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DeleteClientDocument, baseOptions);
}
export type DeleteClientMutationHookResult = ReturnType<
  typeof useDeleteClientMutation
>;
export type DeleteClientMutationResult = ApolloReactCommon.MutationResult<
  DeleteClientMutation
>;
export type DeleteClientMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const CompanyDocument = gql`
  query Company($id: String, $domain: String) {
    company(id: $id, domain: $domain) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type CompanyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CompanyQuery,
    CompanyQueryVariables
  >,
  "query"
>;

export const CompanyComponent = (props: CompanyComponentProps) => (
  <ApolloReactComponents.Query<CompanyQuery, CompanyQueryVariables>
    query={CompanyDocument}
    {...props}
  />
);

export type CompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CompanyQuery,
  CompanyQueryVariables
> &
  TChildProps;
export function withCompany<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompanyQuery,
    CompanyQueryVariables,
    CompanyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CompanyQuery,
    CompanyQueryVariables,
    CompanyProps<TChildProps>
  >(CompanyDocument, {
    alias: "withCompany",
    ...operationOptions
  });
}

export function useCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyQuery,
    CompanyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(
    CompanyDocument,
    baseOptions
  );
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<
  CompanyQuery,
  CompanyQueryVariables
>;
export const CompaniesDocument = gql`
  query Companies($data: QueryCompaniesInput) {
    companies(data: $data) {
      total
      items {
        ...CompanyFragment
      }
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type CompaniesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >,
  "query"
>;

export const CompaniesComponent = (props: CompaniesComponentProps) => (
  <ApolloReactComponents.Query<CompaniesQuery, CompaniesQueryVariables>
    query={CompaniesDocument}
    {...props}
  />
);

export type CompaniesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CompaniesQuery,
  CompaniesQueryVariables
> &
  TChildProps;
export function withCompanies<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompaniesQuery,
    CompaniesQueryVariables,
    CompaniesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CompaniesQuery,
    CompaniesQueryVariables,
    CompaniesProps<TChildProps>
  >(CompaniesDocument, {
    alias: "withCompanies",
    ...operationOptions
  });
}

export function useCompaniesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    baseOptions
  );
}
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<
  CompaniesQuery,
  CompaniesQueryVariables
>;
export const CompanyFieldsDocument = gql`
  query CompanyFields {
    companyFields {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type CompanyFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CompanyFieldsQuery,
    CompanyFieldsQueryVariables
  >,
  "query"
>;

export const CompanyFieldsComponent = (props: CompanyFieldsComponentProps) => (
  <ApolloReactComponents.Query<CompanyFieldsQuery, CompanyFieldsQueryVariables>
    query={CompanyFieldsDocument}
    {...props}
  />
);

export type CompanyFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CompanyFieldsQuery,
  CompanyFieldsQueryVariables
> &
  TChildProps;
export function withCompanyFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompanyFieldsQuery,
    CompanyFieldsQueryVariables,
    CompanyFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CompanyFieldsQuery,
    CompanyFieldsQueryVariables,
    CompanyFieldsProps<TChildProps>
  >(CompanyFieldsDocument, {
    alias: "withCompanyFields",
    ...operationOptions
  });
}

export function useCompanyFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CompanyFieldsQuery,
    CompanyFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CompanyFieldsQuery,
    CompanyFieldsQueryVariables
  >(CompanyFieldsDocument, baseOptions);
}
export type CompanyFieldsQueryHookResult = ReturnType<
  typeof useCompanyFieldsQuery
>;
export type CompanyFieldsQueryResult = ApolloReactCommon.QueryResult<
  CompanyFieldsQuery,
  CompanyFieldsQueryVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($data: CreateCompanyInput!) {
    createCompany(data: $data) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export type CreateCompanyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >,
  "mutation"
>;

export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
    mutation={CreateCompanyDocument}
    {...props}
  />
);

export type CreateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
> &
  TChildProps;
export function withCreateCompany<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCompanyMutation,
    CreateCompanyMutationVariables,
    CreateCompanyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCompanyMutation,
    CreateCompanyMutationVariables,
    CreateCompanyProps<TChildProps>
  >(CreateCompanyDocument, {
    alias: "withCreateCompany",
    ...operationOptions
  });
}

export function useCreateCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, baseOptions);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<
  CreateCompanyMutation
>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateCompanyFieldDocument = gql`
  mutation CreateCompanyField($data: CreateFieldInput!) {
    createCompanyField(data: $data) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type CreateCompanyFieldMutationFn = ApolloReactCommon.MutationFunction<
  CreateCompanyFieldMutation,
  CreateCompanyFieldMutationVariables
>;
export type CreateCompanyFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables
  >,
  "mutation"
>;

export const CreateCompanyFieldComponent = (
  props: CreateCompanyFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables
  >
    mutation={CreateCompanyFieldDocument}
    {...props}
  />
);

export type CreateCompanyFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateCompanyFieldMutation,
  CreateCompanyFieldMutationVariables
> &
  TChildProps;
export function withCreateCompanyField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables,
    CreateCompanyFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables,
    CreateCompanyFieldProps<TChildProps>
  >(CreateCompanyFieldDocument, {
    alias: "withCreateCompanyField",
    ...operationOptions
  });
}

export function useCreateCompanyFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCompanyFieldMutation,
    CreateCompanyFieldMutationVariables
  >(CreateCompanyFieldDocument, baseOptions);
}
export type CreateCompanyFieldMutationHookResult = ReturnType<
  typeof useCreateCompanyFieldMutation
>;
export type CreateCompanyFieldMutationResult = ApolloReactCommon.MutationResult<
  CreateCompanyFieldMutation
>;
export type CreateCompanyFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCompanyFieldMutation,
  CreateCompanyFieldMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($id: String!, $data: CreateCompanyInput!) {
    updateCompany(id: $id, data: $data) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export type UpdateCompanyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >,
  "mutation"
>;

export const UpdateCompanyComponent = (props: UpdateCompanyComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >
    mutation={UpdateCompanyDocument}
    {...props}
  />
);

export type UpdateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
> &
  TChildProps;
export function withUpdateCompany<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables,
    UpdateCompanyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables,
    UpdateCompanyProps<TChildProps>
  >(UpdateCompanyDocument, {
    alias: "withUpdateCompany",
    ...operationOptions
  });
}

export function useUpdateCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UpdateCompanyDocument, baseOptions);
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<
  UpdateCompanyMutation
>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateCompanyIconDocument = gql`
  mutation UpdateCompanyIcon($id: String!, $iconBase64: String) {
    updateCompanyIcon(id: $id, iconBase64: $iconBase64) {
      ...CompanyFragment
    }
  }
  ${CompanyFragmentFragmentDoc}
`;
export type UpdateCompanyIconMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompanyIconMutation,
  UpdateCompanyIconMutationVariables
>;
export type UpdateCompanyIconComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables
  >,
  "mutation"
>;

export const UpdateCompanyIconComponent = (
  props: UpdateCompanyIconComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables
  >
    mutation={UpdateCompanyIconDocument}
    {...props}
  />
);

export type UpdateCompanyIconProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateCompanyIconMutation,
  UpdateCompanyIconMutationVariables
> &
  TChildProps;
export function withUpdateCompanyIcon<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables,
    UpdateCompanyIconProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables,
    UpdateCompanyIconProps<TChildProps>
  >(UpdateCompanyIconDocument, {
    alias: "withUpdateCompanyIcon",
    ...operationOptions
  });
}

export function useUpdateCompanyIconMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyIconMutation,
    UpdateCompanyIconMutationVariables
  >(UpdateCompanyIconDocument, baseOptions);
}
export type UpdateCompanyIconMutationHookResult = ReturnType<
  typeof useUpdateCompanyIconMutation
>;
export type UpdateCompanyIconMutationResult = ApolloReactCommon.MutationResult<
  UpdateCompanyIconMutation
>;
export type UpdateCompanyIconMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompanyIconMutation,
  UpdateCompanyIconMutationVariables
>;
export const UpdateCompanyFieldDocument = gql`
  mutation UpdateCompanyField($id: String!, $data: UpdateFieldInput!) {
    updateCompanyField(id: $id, data: $data) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type UpdateCompanyFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompanyFieldMutation,
  UpdateCompanyFieldMutationVariables
>;
export type UpdateCompanyFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables
  >,
  "mutation"
>;

export const UpdateCompanyFieldComponent = (
  props: UpdateCompanyFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables
  >
    mutation={UpdateCompanyFieldDocument}
    {...props}
  />
);

export type UpdateCompanyFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateCompanyFieldMutation,
  UpdateCompanyFieldMutationVariables
> &
  TChildProps;
export function withUpdateCompanyField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables,
    UpdateCompanyFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables,
    UpdateCompanyFieldProps<TChildProps>
  >(UpdateCompanyFieldDocument, {
    alias: "withUpdateCompanyField",
    ...operationOptions
  });
}

export function useUpdateCompanyFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyFieldMutation,
    UpdateCompanyFieldMutationVariables
  >(UpdateCompanyFieldDocument, baseOptions);
}
export type UpdateCompanyFieldMutationHookResult = ReturnType<
  typeof useUpdateCompanyFieldMutation
>;
export type UpdateCompanyFieldMutationResult = ApolloReactCommon.MutationResult<
  UpdateCompanyFieldMutation
>;
export type UpdateCompanyFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompanyFieldMutation,
  UpdateCompanyFieldMutationVariables
>;
export const ReorderCompanyFieldDocument = gql`
  mutation ReorderCompanyField(
    $id: String!
    $position: Float!
    $groupId: String
  ) {
    reorderCompanyField(id: $id, position: $position, groupId: $groupId) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type ReorderCompanyFieldMutationFn = ApolloReactCommon.MutationFunction<
  ReorderCompanyFieldMutation,
  ReorderCompanyFieldMutationVariables
>;
export type ReorderCompanyFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables
  >,
  "mutation"
>;

export const ReorderCompanyFieldComponent = (
  props: ReorderCompanyFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables
  >
    mutation={ReorderCompanyFieldDocument}
    {...props}
  />
);

export type ReorderCompanyFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReorderCompanyFieldMutation,
  ReorderCompanyFieldMutationVariables
> &
  TChildProps;
export function withReorderCompanyField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables,
    ReorderCompanyFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables,
    ReorderCompanyFieldProps<TChildProps>
  >(ReorderCompanyFieldDocument, {
    alias: "withReorderCompanyField",
    ...operationOptions
  });
}

export function useReorderCompanyFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderCompanyFieldMutation,
    ReorderCompanyFieldMutationVariables
  >(ReorderCompanyFieldDocument, baseOptions);
}
export type ReorderCompanyFieldMutationHookResult = ReturnType<
  typeof useReorderCompanyFieldMutation
>;
export type ReorderCompanyFieldMutationResult = ApolloReactCommon.MutationResult<
  ReorderCompanyFieldMutation
>;
export type ReorderCompanyFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderCompanyFieldMutation,
  ReorderCompanyFieldMutationVariables
>;
export const DeleteCompanyDocument = gql`
  mutation DeleteCompany($id: String!) {
    deleteCompany(id: $id)
  }
`;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>;
export type DeleteCompanyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
  >,
  "mutation"
>;

export const DeleteCompanyComponent = (props: DeleteCompanyComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
  >
    mutation={DeleteCompanyDocument}
    {...props}
  />
);

export type DeleteCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
> &
  TChildProps;
export function withDeleteCompany<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables,
    DeleteCompanyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables,
    DeleteCompanyProps<TChildProps>
  >(DeleteCompanyDocument, {
    alias: "withDeleteCompany",
    ...operationOptions
  });
}

export function useDeleteCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
  >(DeleteCompanyDocument, baseOptions);
}
export type DeleteCompanyMutationHookResult = ReturnType<
  typeof useDeleteCompanyMutation
>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<
  DeleteCompanyMutation
>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>;
export const DeleteCompanyFieldDocument = gql`
  mutation DeleteCompanyField($id: String!) {
    deleteCompanyField(id: $id)
  }
`;
export type DeleteCompanyFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCompanyFieldMutation,
  DeleteCompanyFieldMutationVariables
>;
export type DeleteCompanyFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables
  >,
  "mutation"
>;

export const DeleteCompanyFieldComponent = (
  props: DeleteCompanyFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables
  >
    mutation={DeleteCompanyFieldDocument}
    {...props}
  />
);

export type DeleteCompanyFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteCompanyFieldMutation,
  DeleteCompanyFieldMutationVariables
> &
  TChildProps;
export function withDeleteCompanyField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables,
    DeleteCompanyFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables,
    DeleteCompanyFieldProps<TChildProps>
  >(DeleteCompanyFieldDocument, {
    alias: "withDeleteCompanyField",
    ...operationOptions
  });
}

export function useDeleteCompanyFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCompanyFieldMutation,
    DeleteCompanyFieldMutationVariables
  >(DeleteCompanyFieldDocument, baseOptions);
}
export type DeleteCompanyFieldMutationHookResult = ReturnType<
  typeof useDeleteCompanyFieldMutation
>;
export type DeleteCompanyFieldMutationResult = ApolloReactCommon.MutationResult<
  DeleteCompanyFieldMutation
>;
export type DeleteCompanyFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCompanyFieldMutation,
  DeleteCompanyFieldMutationVariables
>;
export const ContactDocument = gql`
  query Contact($id: String, $email: String) {
    contact(id: $id, email: $email) {
      ...ContactFragment
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type ContactComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ContactQuery,
    ContactQueryVariables
  >,
  "query"
>;

export const ContactComponent = (props: ContactComponentProps) => (
  <ApolloReactComponents.Query<ContactQuery, ContactQueryVariables>
    query={ContactDocument}
    {...props}
  />
);

export type ContactProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ContactQuery,
  ContactQueryVariables
> &
  TChildProps;
export function withContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContactQuery,
    ContactQueryVariables,
    ContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContactQuery,
    ContactQueryVariables,
    ContactProps<TChildProps>
  >(ContactDocument, {
    alias: "withContact",
    ...operationOptions
  });
}

export function useContactQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContactQuery,
    ContactQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ContactQuery, ContactQueryVariables>(
    ContactDocument,
    baseOptions
  );
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactQueryResult = ApolloReactCommon.QueryResult<
  ContactQuery,
  ContactQueryVariables
>;
export const ContactsDocument = gql`
  query Contacts($data: QueryContactsInput) {
    contacts(data: $data) {
      total
      items {
        ...ContactFragment
      }
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type ContactsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ContactsQuery,
    ContactsQueryVariables
  >,
  "query"
>;

export const ContactsComponent = (props: ContactsComponentProps) => (
  <ApolloReactComponents.Query<ContactsQuery, ContactsQueryVariables>
    query={ContactsDocument}
    {...props}
  />
);

export type ContactsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ContactsQuery,
  ContactsQueryVariables
> &
  TChildProps;
export function withContacts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContactsQuery,
    ContactsQueryVariables,
    ContactsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContactsQuery,
    ContactsQueryVariables,
    ContactsProps<TChildProps>
  >(ContactsDocument, {
    alias: "withContacts",
    ...operationOptions
  });
}

export function useContactsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    baseOptions
  );
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsQueryResult = ApolloReactCommon.QueryResult<
  ContactsQuery,
  ContactsQueryVariables
>;
export const ContactFieldsDocument = gql`
  query ContactFields {
    contactFields {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type ContactFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ContactFieldsQuery,
    ContactFieldsQueryVariables
  >,
  "query"
>;

export const ContactFieldsComponent = (props: ContactFieldsComponentProps) => (
  <ApolloReactComponents.Query<ContactFieldsQuery, ContactFieldsQueryVariables>
    query={ContactFieldsDocument}
    {...props}
  />
);

export type ContactFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ContactFieldsQuery,
  ContactFieldsQueryVariables
> &
  TChildProps;
export function withContactFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ContactFieldsQuery,
    ContactFieldsQueryVariables,
    ContactFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ContactFieldsQuery,
    ContactFieldsQueryVariables,
    ContactFieldsProps<TChildProps>
  >(ContactFieldsDocument, {
    alias: "withContactFields",
    ...operationOptions
  });
}

export function useContactFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContactFieldsQuery,
    ContactFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ContactFieldsQuery,
    ContactFieldsQueryVariables
  >(ContactFieldsDocument, baseOptions);
}
export type ContactFieldsQueryHookResult = ReturnType<
  typeof useContactFieldsQuery
>;
export type ContactFieldsQueryResult = ApolloReactCommon.QueryResult<
  ContactFieldsQuery,
  ContactFieldsQueryVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data) {
      ...ContactFragment
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type CreateContactMutationFn = ApolloReactCommon.MutationFunction<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export type CreateContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >,
  "mutation"
>;

export const CreateContactComponent = (props: CreateContactComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >
    mutation={CreateContactDocument}
    {...props}
  />
);

export type CreateContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateContactMutation,
  CreateContactMutationVariables
> &
  TChildProps;
export function withCreateContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateContactMutation,
    CreateContactMutationVariables,
    CreateContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateContactMutation,
    CreateContactMutationVariables,
    CreateContactProps<TChildProps>
  >(CreateContactDocument, {
    alias: "withCreateContact",
    ...operationOptions
  });
}

export function useCreateContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContactMutation,
    CreateContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(CreateContactDocument, baseOptions);
}
export type CreateContactMutationHookResult = ReturnType<
  typeof useCreateContactMutation
>;
export type CreateContactMutationResult = ApolloReactCommon.MutationResult<
  CreateContactMutation
>;
export type CreateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateContactFieldDocument = gql`
  mutation CreateContactField($data: CreateFieldInput!) {
    createContactField(data: $data) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type CreateContactFieldMutationFn = ApolloReactCommon.MutationFunction<
  CreateContactFieldMutation,
  CreateContactFieldMutationVariables
>;
export type CreateContactFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables
  >,
  "mutation"
>;

export const CreateContactFieldComponent = (
  props: CreateContactFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables
  >
    mutation={CreateContactFieldDocument}
    {...props}
  />
);

export type CreateContactFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateContactFieldMutation,
  CreateContactFieldMutationVariables
> &
  TChildProps;
export function withCreateContactField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables,
    CreateContactFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables,
    CreateContactFieldProps<TChildProps>
  >(CreateContactFieldDocument, {
    alias: "withCreateContactField",
    ...operationOptions
  });
}

export function useCreateContactFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateContactFieldMutation,
    CreateContactFieldMutationVariables
  >(CreateContactFieldDocument, baseOptions);
}
export type CreateContactFieldMutationHookResult = ReturnType<
  typeof useCreateContactFieldMutation
>;
export type CreateContactFieldMutationResult = ApolloReactCommon.MutationResult<
  CreateContactFieldMutation
>;
export type CreateContactFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateContactFieldMutation,
  CreateContactFieldMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: String!, $data: CreateContactInput!) {
    updateContact(id: $id, data: $data) {
      ...ContactFragment
    }
  }
  ${ContactFragmentFragmentDoc}
`;
export type UpdateContactMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export type UpdateContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >,
  "mutation"
>;

export const UpdateContactComponent = (props: UpdateContactComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >
    mutation={UpdateContactDocument}
    {...props}
  />
);

export type UpdateContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateContactMutation,
  UpdateContactMutationVariables
> &
  TChildProps;
export function withUpdateContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateContactMutation,
    UpdateContactMutationVariables,
    UpdateContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateContactMutation,
    UpdateContactMutationVariables,
    UpdateContactProps<TChildProps>
  >(UpdateContactDocument, {
    alias: "withUpdateContact",
    ...operationOptions
  });
}

export function useUpdateContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >(UpdateContactDocument, baseOptions);
}
export type UpdateContactMutationHookResult = ReturnType<
  typeof useUpdateContactMutation
>;
export type UpdateContactMutationResult = ApolloReactCommon.MutationResult<
  UpdateContactMutation
>;
export type UpdateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpdateContactFieldDocument = gql`
  mutation UpdateContactField($id: String!, $data: UpdateFieldInput!) {
    updateContactField(id: $id, data: $data) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type UpdateContactFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContactFieldMutation,
  UpdateContactFieldMutationVariables
>;
export type UpdateContactFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables
  >,
  "mutation"
>;

export const UpdateContactFieldComponent = (
  props: UpdateContactFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables
  >
    mutation={UpdateContactFieldDocument}
    {...props}
  />
);

export type UpdateContactFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateContactFieldMutation,
  UpdateContactFieldMutationVariables
> &
  TChildProps;
export function withUpdateContactField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables,
    UpdateContactFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables,
    UpdateContactFieldProps<TChildProps>
  >(UpdateContactFieldDocument, {
    alias: "withUpdateContactField",
    ...operationOptions
  });
}

export function useUpdateContactFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateContactFieldMutation,
    UpdateContactFieldMutationVariables
  >(UpdateContactFieldDocument, baseOptions);
}
export type UpdateContactFieldMutationHookResult = ReturnType<
  typeof useUpdateContactFieldMutation
>;
export type UpdateContactFieldMutationResult = ApolloReactCommon.MutationResult<
  UpdateContactFieldMutation
>;
export type UpdateContactFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateContactFieldMutation,
  UpdateContactFieldMutationVariables
>;
export const ReorderContactFieldDocument = gql`
  mutation ReorderContactField(
    $id: String!
    $position: Float!
    $groupId: String
  ) {
    reorderContactField(id: $id, position: $position, groupId: $groupId) {
      ...FieldSchema
    }
  }
  ${FieldSchemaFragmentDoc}
`;
export type ReorderContactFieldMutationFn = ApolloReactCommon.MutationFunction<
  ReorderContactFieldMutation,
  ReorderContactFieldMutationVariables
>;
export type ReorderContactFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables
  >,
  "mutation"
>;

export const ReorderContactFieldComponent = (
  props: ReorderContactFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables
  >
    mutation={ReorderContactFieldDocument}
    {...props}
  />
);

export type ReorderContactFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReorderContactFieldMutation,
  ReorderContactFieldMutationVariables
> &
  TChildProps;
export function withReorderContactField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables,
    ReorderContactFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables,
    ReorderContactFieldProps<TChildProps>
  >(ReorderContactFieldDocument, {
    alias: "withReorderContactField",
    ...operationOptions
  });
}

export function useReorderContactFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderContactFieldMutation,
    ReorderContactFieldMutationVariables
  >(ReorderContactFieldDocument, baseOptions);
}
export type ReorderContactFieldMutationHookResult = ReturnType<
  typeof useReorderContactFieldMutation
>;
export type ReorderContactFieldMutationResult = ApolloReactCommon.MutationResult<
  ReorderContactFieldMutation
>;
export type ReorderContactFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderContactFieldMutation,
  ReorderContactFieldMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($id: String!) {
    deleteContact(id: $id)
  }
`;
export type DeleteContactMutationFn = ApolloReactCommon.MutationFunction<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export type DeleteContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >,
  "mutation"
>;

export const DeleteContactComponent = (props: DeleteContactComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >
    mutation={DeleteContactDocument}
    {...props}
  />
);

export type DeleteContactProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteContactMutation,
  DeleteContactMutationVariables
> &
  TChildProps;
export function withDeleteContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteContactMutation,
    DeleteContactMutationVariables,
    DeleteContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteContactMutation,
    DeleteContactMutationVariables,
    DeleteContactProps<TChildProps>
  >(DeleteContactDocument, {
    alias: "withDeleteContact",
    ...operationOptions
  });
}

export function useDeleteContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >(DeleteContactDocument, baseOptions);
}
export type DeleteContactMutationHookResult = ReturnType<
  typeof useDeleteContactMutation
>;
export type DeleteContactMutationResult = ApolloReactCommon.MutationResult<
  DeleteContactMutation
>;
export type DeleteContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const DeleteContactFieldDocument = gql`
  mutation DeleteContactField($id: String!) {
    deleteContactField(id: $id)
  }
`;
export type DeleteContactFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteContactFieldMutation,
  DeleteContactFieldMutationVariables
>;
export type DeleteContactFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables
  >,
  "mutation"
>;

export const DeleteContactFieldComponent = (
  props: DeleteContactFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables
  >
    mutation={DeleteContactFieldDocument}
    {...props}
  />
);

export type DeleteContactFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteContactFieldMutation,
  DeleteContactFieldMutationVariables
> &
  TChildProps;
export function withDeleteContactField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables,
    DeleteContactFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables,
    DeleteContactFieldProps<TChildProps>
  >(DeleteContactFieldDocument, {
    alias: "withDeleteContactField",
    ...operationOptions
  });
}

export function useDeleteContactFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteContactFieldMutation,
    DeleteContactFieldMutationVariables
  >(DeleteContactFieldDocument, baseOptions);
}
export type DeleteContactFieldMutationHookResult = ReturnType<
  typeof useDeleteContactFieldMutation
>;
export type DeleteContactFieldMutationResult = ApolloReactCommon.MutationResult<
  DeleteContactFieldMutation
>;
export type DeleteContactFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteContactFieldMutation,
  DeleteContactFieldMutationVariables
>;
export const FieldGroupDocument = gql`
  query fieldGroup($id: String!) {
    fieldGroup(id: $id) {
      ...FieldGroup
    }
  }
  ${FieldGroupFragmentDoc}
`;
export type FieldGroupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldGroupQuery,
    FieldGroupQueryVariables
  >,
  "query"
> &
  ({ variables: FieldGroupQueryVariables; skip?: boolean } | { skip: boolean });

export const FieldGroupComponent = (props: FieldGroupComponentProps) => (
  <ApolloReactComponents.Query<FieldGroupQuery, FieldGroupQueryVariables>
    query={FieldGroupDocument}
    {...props}
  />
);

export type FieldGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  FieldGroupQuery,
  FieldGroupQueryVariables
> &
  TChildProps;
export function withFieldGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FieldGroupQuery,
    FieldGroupQueryVariables,
    FieldGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FieldGroupQuery,
    FieldGroupQueryVariables,
    FieldGroupProps<TChildProps>
  >(FieldGroupDocument, {
    alias: "withFieldGroup",
    ...operationOptions
  });
}

export function useFieldGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldGroupQuery,
    FieldGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FieldGroupQuery, FieldGroupQueryVariables>(
    FieldGroupDocument,
    baseOptions
  );
}
export type FieldGroupQueryHookResult = ReturnType<typeof useFieldGroupQuery>;
export type FieldGroupQueryResult = ApolloReactCommon.QueryResult<
  FieldGroupQuery,
  FieldGroupQueryVariables
>;
export const FieldGroupsDocument = gql`
  query fieldGroups($fieldType: String!) {
    fieldGroups(fieldType: $fieldType) {
      ...FieldGroup
    }
  }
  ${FieldGroupFragmentDoc}
`;
export type FieldGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FieldGroupsQuery,
    FieldGroupsQueryVariables
  >,
  "query"
> &
  (
    | { variables: FieldGroupsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const FieldGroupsComponent = (props: FieldGroupsComponentProps) => (
  <ApolloReactComponents.Query<FieldGroupsQuery, FieldGroupsQueryVariables>
    query={FieldGroupsDocument}
    {...props}
  />
);

export type FieldGroupsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  FieldGroupsQuery,
  FieldGroupsQueryVariables
> &
  TChildProps;
export function withFieldGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FieldGroupsQuery,
    FieldGroupsQueryVariables,
    FieldGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FieldGroupsQuery,
    FieldGroupsQueryVariables,
    FieldGroupsProps<TChildProps>
  >(FieldGroupsDocument, {
    alias: "withFieldGroups",
    ...operationOptions
  });
}

export function useFieldGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FieldGroupsQuery,
    FieldGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<FieldGroupsQuery, FieldGroupsQueryVariables>(
    FieldGroupsDocument,
    baseOptions
  );
}
export type FieldGroupsQueryHookResult = ReturnType<typeof useFieldGroupsQuery>;
export type FieldGroupsQueryResult = ApolloReactCommon.QueryResult<
  FieldGroupsQuery,
  FieldGroupsQueryVariables
>;
export const CreateFieldGroupDocument = gql`
  mutation CreateFieldGroup($data: CreateFieldGroupInput!) {
    createFieldGroup(data: $data) {
      ...FieldGroup
    }
  }
  ${FieldGroupFragmentDoc}
`;
export type CreateFieldGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateFieldGroupMutation,
  CreateFieldGroupMutationVariables
>;
export type CreateFieldGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables
  >,
  "mutation"
>;

export const CreateFieldGroupComponent = (
  props: CreateFieldGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables
  >
    mutation={CreateFieldGroupDocument}
    {...props}
  />
);

export type CreateFieldGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateFieldGroupMutation,
  CreateFieldGroupMutationVariables
> &
  TChildProps;
export function withCreateFieldGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables,
    CreateFieldGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables,
    CreateFieldGroupProps<TChildProps>
  >(CreateFieldGroupDocument, {
    alias: "withCreateFieldGroup",
    ...operationOptions
  });
}

export function useCreateFieldGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateFieldGroupMutation,
    CreateFieldGroupMutationVariables
  >(CreateFieldGroupDocument, baseOptions);
}
export type CreateFieldGroupMutationHookResult = ReturnType<
  typeof useCreateFieldGroupMutation
>;
export type CreateFieldGroupMutationResult = ApolloReactCommon.MutationResult<
  CreateFieldGroupMutation
>;
export type CreateFieldGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFieldGroupMutation,
  CreateFieldGroupMutationVariables
>;
export const UpdateFieldGroupDocument = gql`
  mutation UpdateFieldGroup($id: String!, $data: UpdateFieldGroupInput!) {
    updateFieldGroup(id: $id, data: $data) {
      ...FieldGroup
    }
  }
  ${FieldGroupFragmentDoc}
`;
export type UpdateFieldGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFieldGroupMutation,
  UpdateFieldGroupMutationVariables
>;
export type UpdateFieldGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables
  >,
  "mutation"
>;

export const UpdateFieldGroupComponent = (
  props: UpdateFieldGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables
  >
    mutation={UpdateFieldGroupDocument}
    {...props}
  />
);

export type UpdateFieldGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateFieldGroupMutation,
  UpdateFieldGroupMutationVariables
> &
  TChildProps;
export function withUpdateFieldGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables,
    UpdateFieldGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables,
    UpdateFieldGroupProps<TChildProps>
  >(UpdateFieldGroupDocument, {
    alias: "withUpdateFieldGroup",
    ...operationOptions
  });
}

export function useUpdateFieldGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateFieldGroupMutation,
    UpdateFieldGroupMutationVariables
  >(UpdateFieldGroupDocument, baseOptions);
}
export type UpdateFieldGroupMutationHookResult = ReturnType<
  typeof useUpdateFieldGroupMutation
>;
export type UpdateFieldGroupMutationResult = ApolloReactCommon.MutationResult<
  UpdateFieldGroupMutation
>;
export type UpdateFieldGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFieldGroupMutation,
  UpdateFieldGroupMutationVariables
>;
export const ReorderFieldGroupDocument = gql`
  mutation ReorderFieldGroup($id: String!, $position: Float!) {
    reorderFieldGroup(id: $id, position: $position) {
      ...FieldGroup
    }
  }
  ${FieldGroupFragmentDoc}
`;
export type ReorderFieldGroupMutationFn = ApolloReactCommon.MutationFunction<
  ReorderFieldGroupMutation,
  ReorderFieldGroupMutationVariables
>;
export type ReorderFieldGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables
  >,
  "mutation"
>;

export const ReorderFieldGroupComponent = (
  props: ReorderFieldGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables
  >
    mutation={ReorderFieldGroupDocument}
    {...props}
  />
);

export type ReorderFieldGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReorderFieldGroupMutation,
  ReorderFieldGroupMutationVariables
> &
  TChildProps;
export function withReorderFieldGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables,
    ReorderFieldGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables,
    ReorderFieldGroupProps<TChildProps>
  >(ReorderFieldGroupDocument, {
    alias: "withReorderFieldGroup",
    ...operationOptions
  });
}

export function useReorderFieldGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderFieldGroupMutation,
    ReorderFieldGroupMutationVariables
  >(ReorderFieldGroupDocument, baseOptions);
}
export type ReorderFieldGroupMutationHookResult = ReturnType<
  typeof useReorderFieldGroupMutation
>;
export type ReorderFieldGroupMutationResult = ApolloReactCommon.MutationResult<
  ReorderFieldGroupMutation
>;
export type ReorderFieldGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderFieldGroupMutation,
  ReorderFieldGroupMutationVariables
>;
export const DeleteFieldGroupDocument = gql`
  mutation DeleteFieldGroup($id: String!) {
    deleteFieldGroup(id: $id)
  }
`;
export type DeleteFieldGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFieldGroupMutation,
  DeleteFieldGroupMutationVariables
>;
export type DeleteFieldGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables
  >,
  "mutation"
>;

export const DeleteFieldGroupComponent = (
  props: DeleteFieldGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables
  >
    mutation={DeleteFieldGroupDocument}
    {...props}
  />
);

export type DeleteFieldGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteFieldGroupMutation,
  DeleteFieldGroupMutationVariables
> &
  TChildProps;
export function withDeleteFieldGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables,
    DeleteFieldGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables,
    DeleteFieldGroupProps<TChildProps>
  >(DeleteFieldGroupDocument, {
    alias: "withDeleteFieldGroup",
    ...operationOptions
  });
}

export function useDeleteFieldGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteFieldGroupMutation,
    DeleteFieldGroupMutationVariables
  >(DeleteFieldGroupDocument, baseOptions);
}
export type DeleteFieldGroupMutationHookResult = ReturnType<
  typeof useDeleteFieldGroupMutation
>;
export type DeleteFieldGroupMutationResult = ApolloReactCommon.MutationResult<
  DeleteFieldGroupMutation
>;
export type DeleteFieldGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFieldGroupMutation,
  DeleteFieldGroupMutationVariables
>;
export const MeetingDocument = gql`
  query Meeting($id: String!) {
    meeting(id: $id) {
      ...Meeting
    }
  }
  ${MeetingFragmentDoc}
`;
export type MeetingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingQuery,
    MeetingQueryVariables
  >,
  "query"
> &
  ({ variables: MeetingQueryVariables; skip?: boolean } | { skip: boolean });

export const MeetingComponent = (props: MeetingComponentProps) => (
  <ApolloReactComponents.Query<MeetingQuery, MeetingQueryVariables>
    query={MeetingDocument}
    {...props}
  />
);

export type MeetingProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingQuery,
  MeetingQueryVariables
> &
  TChildProps;
export function withMeeting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingQuery,
    MeetingQueryVariables,
    MeetingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingQuery,
    MeetingQueryVariables,
    MeetingProps<TChildProps>
  >(MeetingDocument, {
    alias: "withMeeting",
    ...operationOptions
  });
}

export function useMeetingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingQuery,
    MeetingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MeetingQuery, MeetingQueryVariables>(
    MeetingDocument,
    baseOptions
  );
}
export type MeetingQueryHookResult = ReturnType<typeof useMeetingQuery>;
export type MeetingQueryResult = ApolloReactCommon.QueryResult<
  MeetingQuery,
  MeetingQueryVariables
>;
export const MeetingsDocument = gql`
  query Meetings($data: QueryMeetingsInput) {
    meetings(data: $data) {
      total
      items {
        ...Meeting
      }
    }
  }
  ${MeetingFragmentDoc}
`;
export type MeetingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingsQuery,
    MeetingsQueryVariables
  >,
  "query"
>;

export const MeetingsComponent = (props: MeetingsComponentProps) => (
  <ApolloReactComponents.Query<MeetingsQuery, MeetingsQueryVariables>
    query={MeetingsDocument}
    {...props}
  />
);

export type MeetingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingsQuery,
  MeetingsQueryVariables
> &
  TChildProps;
export function withMeetings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingsQuery,
    MeetingsQueryVariables,
    MeetingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingsQuery,
    MeetingsQueryVariables,
    MeetingsProps<TChildProps>
  >(MeetingsDocument, {
    alias: "withMeetings",
    ...operationOptions
  });
}

export function useMeetingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingsQuery,
    MeetingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MeetingsQuery, MeetingsQueryVariables>(
    MeetingsDocument,
    baseOptions
  );
}
export type MeetingsQueryHookResult = ReturnType<typeof useMeetingsQuery>;
export type MeetingsQueryResult = ApolloReactCommon.QueryResult<
  MeetingsQuery,
  MeetingsQueryVariables
>;
export const FilterMeetingsDocument = gql`
  query FilterMeetings($data: FilterMeetingsInput!) {
    filterMeetings(data: $data) {
      total
      items {
        ...Meeting
      }
    }
  }
  ${MeetingFragmentDoc}
`;
export type FilterMeetingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables
  >,
  "query"
> &
  (
    | { variables: FilterMeetingsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const FilterMeetingsComponent = (
  props: FilterMeetingsComponentProps
) => (
  <ApolloReactComponents.Query<
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables
  >
    query={FilterMeetingsDocument}
    {...props}
  />
);

export type FilterMeetingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  FilterMeetingsQuery,
  FilterMeetingsQueryVariables
> &
  TChildProps;
export function withFilterMeetings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables,
    FilterMeetingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables,
    FilterMeetingsProps<TChildProps>
  >(FilterMeetingsDocument, {
    alias: "withFilterMeetings",
    ...operationOptions
  });
}

export function useFilterMeetingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FilterMeetingsQuery,
    FilterMeetingsQueryVariables
  >(FilterMeetingsDocument, baseOptions);
}
export type FilterMeetingsQueryHookResult = ReturnType<
  typeof useFilterMeetingsQuery
>;
export type FilterMeetingsQueryResult = ApolloReactCommon.QueryResult<
  FilterMeetingsQuery,
  FilterMeetingsQueryVariables
>;
export const CreateMeetingDocument = gql`
  mutation CreateMeeting($data: CreateMeetingInput!) {
    createMeeting(data: $data) {
      ...Meeting
    }
  }
  ${MeetingFragmentDoc}
`;
export type CreateMeetingMutationFn = ApolloReactCommon.MutationFunction<
  CreateMeetingMutation,
  CreateMeetingMutationVariables
>;
export type CreateMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMeetingMutation,
    CreateMeetingMutationVariables
  >,
  "mutation"
>;

export const CreateMeetingComponent = (props: CreateMeetingComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateMeetingMutation,
    CreateMeetingMutationVariables
  >
    mutation={CreateMeetingDocument}
    {...props}
  />
);

export type CreateMeetingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMeetingMutation,
  CreateMeetingMutationVariables
> &
  TChildProps;
export function withCreateMeeting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMeetingMutation,
    CreateMeetingMutationVariables,
    CreateMeetingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMeetingMutation,
    CreateMeetingMutationVariables,
    CreateMeetingProps<TChildProps>
  >(CreateMeetingDocument, {
    alias: "withCreateMeeting",
    ...operationOptions
  });
}

export function useCreateMeetingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMeetingMutation,
    CreateMeetingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMeetingMutation,
    CreateMeetingMutationVariables
  >(CreateMeetingDocument, baseOptions);
}
export type CreateMeetingMutationHookResult = ReturnType<
  typeof useCreateMeetingMutation
>;
export type CreateMeetingMutationResult = ApolloReactCommon.MutationResult<
  CreateMeetingMutation
>;
export type CreateMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMeetingMutation,
  CreateMeetingMutationVariables
>;
export const UpdateMeetingDocument = gql`
  mutation UpdateMeeting($id: String!, $data: CreateMeetingInput!) {
    updateMeeting(id: $id, data: $data) {
      ...Meeting
    }
  }
  ${MeetingFragmentDoc}
`;
export type UpdateMeetingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables
>;
export type UpdateMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables
  >,
  "mutation"
>;

export const UpdateMeetingComponent = (props: UpdateMeetingComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables
  >
    mutation={UpdateMeetingDocument}
    {...props}
  />
);

export type UpdateMeetingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables
> &
  TChildProps;
export function withUpdateMeeting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables,
    UpdateMeetingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables,
    UpdateMeetingProps<TChildProps>
  >(UpdateMeetingDocument, {
    alias: "withUpdateMeeting",
    ...operationOptions
  });
}

export function useUpdateMeetingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMeetingMutation,
    UpdateMeetingMutationVariables
  >(UpdateMeetingDocument, baseOptions);
}
export type UpdateMeetingMutationHookResult = ReturnType<
  typeof useUpdateMeetingMutation
>;
export type UpdateMeetingMutationResult = ApolloReactCommon.MutationResult<
  UpdateMeetingMutation
>;
export type UpdateMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables
>;
export const DeleteMeetingDocument = gql`
  mutation DeleteMeeting($id: String!) {
    deleteMeeting(id: $id)
  }
`;
export type DeleteMeetingMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMeetingMutation,
  DeleteMeetingMutationVariables
>;
export type DeleteMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables
  >,
  "mutation"
>;

export const DeleteMeetingComponent = (props: DeleteMeetingComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables
  >
    mutation={DeleteMeetingDocument}
    {...props}
  />
);

export type DeleteMeetingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMeetingMutation,
  DeleteMeetingMutationVariables
> &
  TChildProps;
export function withDeleteMeeting<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables,
    DeleteMeetingProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables,
    DeleteMeetingProps<TChildProps>
  >(DeleteMeetingDocument, {
    alias: "withDeleteMeeting",
    ...operationOptions
  });
}

export function useDeleteMeetingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteMeetingMutation,
    DeleteMeetingMutationVariables
  >(DeleteMeetingDocument, baseOptions);
}
export type DeleteMeetingMutationHookResult = ReturnType<
  typeof useDeleteMeetingMutation
>;
export type DeleteMeetingMutationResult = ApolloReactCommon.MutationResult<
  DeleteMeetingMutation
>;
export type DeleteMeetingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMeetingMutation,
  DeleteMeetingMutationVariables
>;
export const ReportDocument = gql`
  query Report($id: String!) {
    report(id: $id) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type ReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportQuery,
    ReportQueryVariables
  >,
  "query"
> &
  ({ variables: ReportQueryVariables; skip?: boolean } | { skip: boolean });

export const ReportComponent = (props: ReportComponentProps) => (
  <ApolloReactComponents.Query<ReportQuery, ReportQueryVariables>
    query={ReportDocument}
    {...props}
  />
);

export type ReportProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportQuery,
  ReportQueryVariables
> &
  TChildProps;
export function withReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportQuery,
    ReportQueryVariables,
    ReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportQuery,
    ReportQueryVariables,
    ReportProps<TChildProps>
  >(ReportDocument, {
    alias: "withReport",
    ...operationOptions
  });
}

export function useReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportQuery,
    ReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ReportQuery, ReportQueryVariables>(
    ReportDocument,
    baseOptions
  );
}
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportQueryResult = ApolloReactCommon.QueryResult<
  ReportQuery,
  ReportQueryVariables
>;
export const ReportsDocument = gql`
  query Reports($data: QueryReportsInput) {
    reports(data: $data) {
      total
      items {
        ...Report
      }
    }
  }
  ${ReportFragmentDoc}
`;
export type ReportsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportsQuery,
    ReportsQueryVariables
  >,
  "query"
>;

export const ReportsComponent = (props: ReportsComponentProps) => (
  <ApolloReactComponents.Query<ReportsQuery, ReportsQueryVariables>
    query={ReportsDocument}
    {...props}
  />
);

export type ReportsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportsQuery,
  ReportsQueryVariables
> &
  TChildProps;
export function withReports<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportsQuery,
    ReportsQueryVariables,
    ReportsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportsQuery,
    ReportsQueryVariables,
    ReportsProps<TChildProps>
  >(ReportsDocument, {
    alias: "withReports",
    ...operationOptions
  });
}

export function useReportsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportsQuery,
    ReportsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ReportsQuery, ReportsQueryVariables>(
    ReportsDocument,
    baseOptions
  );
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsQueryResult = ApolloReactCommon.QueryResult<
  ReportsQuery,
  ReportsQueryVariables
>;
export const GenerateReportDocument = gql`
  query GenerateReport($id: String!) {
    generateReport(id: $id)
  }
`;
export type GenerateReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GenerateReportQuery,
    GenerateReportQueryVariables
  >,
  "query"
> &
  (
    | { variables: GenerateReportQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GenerateReportComponent = (
  props: GenerateReportComponentProps
) => (
  <ApolloReactComponents.Query<
    GenerateReportQuery,
    GenerateReportQueryVariables
  >
    query={GenerateReportDocument}
    {...props}
  />
);

export type GenerateReportProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GenerateReportQuery,
  GenerateReportQueryVariables
> &
  TChildProps;
export function withGenerateReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GenerateReportQuery,
    GenerateReportQueryVariables,
    GenerateReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GenerateReportQuery,
    GenerateReportQueryVariables,
    GenerateReportProps<TChildProps>
  >(GenerateReportDocument, {
    alias: "withGenerateReport",
    ...operationOptions
  });
}

export function useGenerateReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GenerateReportQuery,
    GenerateReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GenerateReportQuery,
    GenerateReportQueryVariables
  >(GenerateReportDocument, baseOptions);
}
export type GenerateReportQueryHookResult = ReturnType<
  typeof useGenerateReportQuery
>;
export type GenerateReportQueryResult = ApolloReactCommon.QueryResult<
  GenerateReportQuery,
  GenerateReportQueryVariables
>;
export const CreateReportDocument = gql`
  mutation CreateReport($data: CreateReportInput!) {
    createReport(data: $data) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type CreateReportMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>;
export type CreateReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >,
  "mutation"
>;

export const CreateReportComponent = (props: CreateReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >
    mutation={CreateReportDocument}
    {...props}
  />
);

export type CreateReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateReportMutation,
  CreateReportMutationVariables
> &
  TChildProps;
export function withCreateReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateReportMutation,
    CreateReportMutationVariables,
    CreateReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateReportMutation,
    CreateReportMutationVariables,
    CreateReportProps<TChildProps>
  >(CreateReportDocument, {
    alias: "withCreateReport",
    ...operationOptions
  });
}

export function useCreateReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(CreateReportDocument, baseOptions);
}
export type CreateReportMutationHookResult = ReturnType<
  typeof useCreateReportMutation
>;
export type CreateReportMutationResult = ApolloReactCommon.MutationResult<
  CreateReportMutation
>;
export type CreateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>;
export const CopyReportDocument = gql`
  mutation CopyReport($data: CopyReportInput!) {
    copyReport(data: $data) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type CopyReportMutationFn = ApolloReactCommon.MutationFunction<
  CopyReportMutation,
  CopyReportMutationVariables
>;
export type CopyReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CopyReportMutation,
    CopyReportMutationVariables
  >,
  "mutation"
>;

export const CopyReportComponent = (props: CopyReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    CopyReportMutation,
    CopyReportMutationVariables
  >
    mutation={CopyReportDocument}
    {...props}
  />
);

export type CopyReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CopyReportMutation,
  CopyReportMutationVariables
> &
  TChildProps;
export function withCopyReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CopyReportMutation,
    CopyReportMutationVariables,
    CopyReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CopyReportMutation,
    CopyReportMutationVariables,
    CopyReportProps<TChildProps>
  >(CopyReportDocument, {
    alias: "withCopyReport",
    ...operationOptions
  });
}

export function useCopyReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyReportMutation,
    CopyReportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CopyReportMutation,
    CopyReportMutationVariables
  >(CopyReportDocument, baseOptions);
}
export type CopyReportMutationHookResult = ReturnType<
  typeof useCopyReportMutation
>;
export type CopyReportMutationResult = ApolloReactCommon.MutationResult<
  CopyReportMutation
>;
export type CopyReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CopyReportMutation,
  CopyReportMutationVariables
>;
export const MoveReportReportGroupDocument = gql`
  mutation MoveReportReportGroup($data: MoveReportReportGroupInput!) {
    moveReportReportGroupResolver(data: $data) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type MoveReportReportGroupMutationFn = ApolloReactCommon.MutationFunction<
  MoveReportReportGroupMutation,
  MoveReportReportGroupMutationVariables
>;
export type MoveReportReportGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables
  >,
  "mutation"
>;

export const MoveReportReportGroupComponent = (
  props: MoveReportReportGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables
  >
    mutation={MoveReportReportGroupDocument}
    {...props}
  />
);

export type MoveReportReportGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  MoveReportReportGroupMutation,
  MoveReportReportGroupMutationVariables
> &
  TChildProps;
export function withMoveReportReportGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables,
    MoveReportReportGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables,
    MoveReportReportGroupProps<TChildProps>
  >(MoveReportReportGroupDocument, {
    alias: "withMoveReportReportGroup",
    ...operationOptions
  });
}

export function useMoveReportReportGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    MoveReportReportGroupMutation,
    MoveReportReportGroupMutationVariables
  >(MoveReportReportGroupDocument, baseOptions);
}
export type MoveReportReportGroupMutationHookResult = ReturnType<
  typeof useMoveReportReportGroupMutation
>;
export type MoveReportReportGroupMutationResult = ApolloReactCommon.MutationResult<
  MoveReportReportGroupMutation
>;
export type MoveReportReportGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MoveReportReportGroupMutation,
  MoveReportReportGroupMutationVariables
>;
export const UpdateReportDocument = gql`
  mutation UpdateReport($id: String!, $data: UpdateReportInput!) {
    updateReport(id: $id, data: $data) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type UpdateReportMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReportMutation,
  UpdateReportMutationVariables
>;
export type UpdateReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >,
  "mutation"
>;

export const UpdateReportComponent = (props: UpdateReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >
    mutation={UpdateReportDocument}
    {...props}
  />
);

export type UpdateReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateReportMutation,
  UpdateReportMutationVariables
> &
  TChildProps;
export function withUpdateReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateReportMutation,
    UpdateReportMutationVariables,
    UpdateReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateReportMutation,
    UpdateReportMutationVariables,
    UpdateReportProps<TChildProps>
  >(UpdateReportDocument, {
    alias: "withUpdateReport",
    ...operationOptions
  });
}

export function useUpdateReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReportMutation,
    UpdateReportMutationVariables
  >(UpdateReportDocument, baseOptions);
}
export type UpdateReportMutationHookResult = ReturnType<
  typeof useUpdateReportMutation
>;
export type UpdateReportMutationResult = ApolloReactCommon.MutationResult<
  UpdateReportMutation
>;
export type UpdateReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReportMutation,
  UpdateReportMutationVariables
>;
export const DeleteReportDocument = gql`
  mutation DeleteReport($id: String!) {
    deleteReport(id: $id)
  }
`;
export type DeleteReportMutationFn = ApolloReactCommon.MutationFunction<
  DeleteReportMutation,
  DeleteReportMutationVariables
>;
export type DeleteReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteReportMutation,
    DeleteReportMutationVariables
  >,
  "mutation"
>;

export const DeleteReportComponent = (props: DeleteReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteReportMutation,
    DeleteReportMutationVariables
  >
    mutation={DeleteReportDocument}
    {...props}
  />
);

export type DeleteReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteReportMutation,
  DeleteReportMutationVariables
> &
  TChildProps;
export function withDeleteReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteReportMutation,
    DeleteReportMutationVariables,
    DeleteReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteReportMutation,
    DeleteReportMutationVariables,
    DeleteReportProps<TChildProps>
  >(DeleteReportDocument, {
    alias: "withDeleteReport",
    ...operationOptions
  });
}

export function useDeleteReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteReportMutation,
    DeleteReportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteReportMutation,
    DeleteReportMutationVariables
  >(DeleteReportDocument, baseOptions);
}
export type DeleteReportMutationHookResult = ReturnType<
  typeof useDeleteReportMutation
>;
export type DeleteReportMutationResult = ApolloReactCommon.MutationResult<
  DeleteReportMutation
>;
export type DeleteReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteReportMutation,
  DeleteReportMutationVariables
>;
export const ReorderReportDocument = gql`
  mutation ReorderReport($id: String!, $position: Float!) {
    reorderReport(id: $id, position: $position) {
      ...Report
    }
  }
  ${ReportFragmentDoc}
`;
export type ReorderReportMutationFn = ApolloReactCommon.MutationFunction<
  ReorderReportMutation,
  ReorderReportMutationVariables
>;
export type ReorderReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderReportMutation,
    ReorderReportMutationVariables
  >,
  "mutation"
>;

export const ReorderReportComponent = (props: ReorderReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    ReorderReportMutation,
    ReorderReportMutationVariables
  >
    mutation={ReorderReportDocument}
    {...props}
  />
);

export type ReorderReportProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ReorderReportMutation,
  ReorderReportMutationVariables
> &
  TChildProps;
export function withReorderReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderReportMutation,
    ReorderReportMutationVariables,
    ReorderReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderReportMutation,
    ReorderReportMutationVariables,
    ReorderReportProps<TChildProps>
  >(ReorderReportDocument, {
    alias: "withReorderReport",
    ...operationOptions
  });
}

export function useReorderReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderReportMutation,
    ReorderReportMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderReportMutation,
    ReorderReportMutationVariables
  >(ReorderReportDocument, baseOptions);
}
export type ReorderReportMutationHookResult = ReturnType<
  typeof useReorderReportMutation
>;
export type ReorderReportMutationResult = ApolloReactCommon.MutationResult<
  ReorderReportMutation
>;
export type ReorderReportMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderReportMutation,
  ReorderReportMutationVariables
>;
export const LeaderboardDocument = gql`
  query Leaderboard($data: LeaderboardInput!) {
    leaderboard(data: $data) {
      userId
      userName
      meetingsHeld
      meetingsPlanned
    }
  }
`;
export type LeaderboardComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LeaderboardQuery,
    LeaderboardQueryVariables
  >,
  "query"
> &
  (
    | { variables: LeaderboardQueryVariables; skip?: boolean }
    | { skip: boolean });

export const LeaderboardComponent = (props: LeaderboardComponentProps) => (
  <ApolloReactComponents.Query<LeaderboardQuery, LeaderboardQueryVariables>
    query={LeaderboardDocument}
    {...props}
  />
);

export type LeaderboardProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LeaderboardQuery,
  LeaderboardQueryVariables
> &
  TChildProps;
export function withLeaderboard<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LeaderboardQuery,
    LeaderboardQueryVariables,
    LeaderboardProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LeaderboardQuery,
    LeaderboardQueryVariables,
    LeaderboardProps<TChildProps>
  >(LeaderboardDocument, {
    alias: "withLeaderboard",
    ...operationOptions
  });
}

export function useLeaderboardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LeaderboardQuery,
    LeaderboardQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LeaderboardQuery, LeaderboardQueryVariables>(
    LeaderboardDocument,
    baseOptions
  );
}
export type LeaderboardQueryHookResult = ReturnType<typeof useLeaderboardQuery>;
export type LeaderboardQueryResult = ApolloReactCommon.QueryResult<
  LeaderboardQuery,
  LeaderboardQueryVariables
>;
export const CampaignStatsDocument = gql`
  query CampaignStats($data: CampaignStatsInput!) {
    campaignStats(data: $data) {
      meetingsCount
      totalMeetings
      meetingsStatus {
        Planned
        Held
        To_Be_Rescheduled
        Cancelled
      }
    }
  }
`;
export type CampaignStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CampaignStatsQuery,
    CampaignStatsQueryVariables
  >,
  "query"
> &
  (
    | { variables: CampaignStatsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const CampaignStatsComponent = (props: CampaignStatsComponentProps) => (
  <ApolloReactComponents.Query<CampaignStatsQuery, CampaignStatsQueryVariables>
    query={CampaignStatsDocument}
    {...props}
  />
);

export type CampaignStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CampaignStatsQuery,
  CampaignStatsQueryVariables
> &
  TChildProps;
export function withCampaignStats<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CampaignStatsQuery,
    CampaignStatsQueryVariables,
    CampaignStatsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CampaignStatsQuery,
    CampaignStatsQueryVariables,
    CampaignStatsProps<TChildProps>
  >(CampaignStatsDocument, {
    alias: "withCampaignStats",
    ...operationOptions
  });
}

export function useCampaignStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CampaignStatsQuery,
    CampaignStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CampaignStatsQuery,
    CampaignStatsQueryVariables
  >(CampaignStatsDocument, baseOptions);
}
export type CampaignStatsQueryHookResult = ReturnType<
  typeof useCampaignStatsQuery
>;
export type CampaignStatsQueryResult = ApolloReactCommon.QueryResult<
  CampaignStatsQuery,
  CampaignStatsQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type MeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>,
  "query"
>;

export const MeComponent = (props: MeComponentProps) => (
  <ApolloReactComponents.Query<MeQuery, MeQueryVariables>
    query={MeDocument}
    {...props}
  />
);

export type MeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeQuery,
  MeQueryVariables
> &
  TChildProps;
export function withMe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeQuery,
    MeQueryVariables,
    MeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeQuery,
    MeQueryVariables,
    MeProps<TChildProps>
  >(MeDocument, {
    alias: "withMe",
    ...operationOptions
  });
}

export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>
) {
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    baseOptions
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
  MeQuery,
  MeQueryVariables
>;
export const UserDocument = gql`
  query User($id: String!) {
    user(id: $id) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>,
  "query"
> &
  ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean });

export const UserComponent = (props: UserComponentProps) => (
  <ApolloReactComponents.Query<UserQuery, UserQueryVariables>
    query={UserDocument}
    {...props}
  />
);

export type UserProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserQuery,
  UserQueryVariables
> &
  TChildProps;
export function withUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps>
  >(UserDocument, {
    alias: "withUser",
    ...operationOptions
  });
}

export function useUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    baseOptions
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<
  UserQuery,
  UserQueryVariables
>;
export const UsersDocument = gql`
  query Users($data: QueryUsersInput) {
    users(data: $data) {
      total
      items {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>,
  "query"
>;

export const UsersComponent = (props: UsersComponentProps) => (
  <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables>
    query={UsersDocument}
    {...props}
  />
);

export type UsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UsersQuery,
  UsersQueryVariables
> &
  TChildProps;
export function withUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UsersQuery,
    UsersQueryVariables,
    UsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UsersQuery,
    UsersQueryVariables,
    UsersProps<TChildProps>
  >(UsersDocument, {
    alias: "withUsers",
    ...operationOptions
  });
}

export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    baseOptions
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const RegisterDocument = gql`
  mutation Register($data: RegisterInput!) {
    register(data: $data) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;
export type RegisterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
  "mutation"
>;

export const RegisterComponent = (props: RegisterComponentProps) => (
  <ApolloReactComponents.Mutation<RegisterMutation, RegisterMutationVariables>
    mutation={RegisterDocument}
    {...props}
  />
);

export type RegisterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RegisterMutation,
  RegisterMutationVariables
> &
  TChildProps;
export function withRegister<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterMutation,
    RegisterMutationVariables,
    RegisterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterMutation,
    RegisterMutationVariables,
    RegisterProps<TChildProps>
  >(RegisterDocument, {
    alias: "withRegister",
    ...operationOptions
  });
}

export function useRegisterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterMutation,
    RegisterMutationVariables
  >(RegisterDocument, baseOptions);
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<
  RegisterMutation
>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LoginMutation,
    LoginMutationVariables
  >,
  "mutation"
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  LoginMutation,
  LoginMutationVariables
> &
  TChildProps;
export function withLogin<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    LoginMutation,
    LoginMutationVariables,
    LoginProps<TChildProps>
  >(LoginDocument, {
    alias: "withLogin",
    ...operationOptions
  });
}

export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<
  LoginMutation
>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;
export type LogoutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
  "mutation"
>;

export const LogoutComponent = (props: LogoutComponentProps) => (
  <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables>
    mutation={LogoutDocument}
    {...props}
  />
);

export type LogoutProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  LogoutMutation,
  LogoutMutationVariables
> &
  TChildProps;
export function withLogout<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LogoutMutation,
    LogoutMutationVariables,
    LogoutProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    LogoutMutation,
    LogoutMutationVariables,
    LogoutProps<TChildProps>
  >(LogoutDocument, {
    alias: "withLogout",
    ...operationOptions
  });
}

export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    baseOptions
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<
  LogoutMutation
>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: String!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export type UpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
  "mutation"
>;

export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
    mutation={UpdateUserDocument}
    {...props}
  />
);

export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserMutation,
  UpdateUserMutationVariables
> &
  TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, {
    alias: "withUpdateUser",
    ...operationOptions
  });
}

export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation UpdateUserPassword($id: String!, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export type UpdateUserPasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >,
  "mutation"
>;

export const UpdateUserPasswordComponent = (
  props: UpdateUserPasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
    mutation={UpdateUserPasswordDocument}
    {...props}
  />
);

export type UpdateUserPasswordProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
> &
  TChildProps;
export function withUpdateUserPassword<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables,
    UpdateUserPasswordProps<TChildProps>
  >(UpdateUserPasswordDocument, {
    alias: "withUpdateUserPassword",
    ...operationOptions
  });
}

export function useUpdateUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument, baseOptions);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
  typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const UpdateUserMeetingFilterGroupsDocument = gql`
  mutation UpdateUserMeetingFilterGroups($jsonData: String!) {
    updateUserMeetingFilterGroups(jsonData: $jsonData) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserMeetingFilterGroupsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMeetingFilterGroupsMutation,
  UpdateUserMeetingFilterGroupsMutationVariables
>;
export type UpdateUserMeetingFilterGroupsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables
  >,
  "mutation"
>;

export const UpdateUserMeetingFilterGroupsComponent = (
  props: UpdateUserMeetingFilterGroupsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables
  >
    mutation={UpdateUserMeetingFilterGroupsDocument}
    {...props}
  />
);

export type UpdateUserMeetingFilterGroupsProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateUserMeetingFilterGroupsMutation,
  UpdateUserMeetingFilterGroupsMutationVariables
> &
  TChildProps;
export function withUpdateUserMeetingFilterGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables,
    UpdateUserMeetingFilterGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables,
    UpdateUserMeetingFilterGroupsProps<TChildProps>
  >(UpdateUserMeetingFilterGroupsDocument, {
    alias: "withUpdateUserMeetingFilterGroups",
    ...operationOptions
  });
}

export function useUpdateUserMeetingFilterGroupsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserMeetingFilterGroupsMutation,
    UpdateUserMeetingFilterGroupsMutationVariables
  >(UpdateUserMeetingFilterGroupsDocument, baseOptions);
}
export type UpdateUserMeetingFilterGroupsMutationHookResult = ReturnType<
  typeof useUpdateUserMeetingFilterGroupsMutation
>;
export type UpdateUserMeetingFilterGroupsMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserMeetingFilterGroupsMutation
>;
export type UpdateUserMeetingFilterGroupsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMeetingFilterGroupsMutation,
  UpdateUserMeetingFilterGroupsMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export type DeleteUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >,
  "mutation"
>;

export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
    mutation={DeleteUserDocument}
    {...props}
  />
);

export type DeleteUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserMutation,
  DeleteUserMutationVariables
> &
  TChildProps;
export function withDeleteUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >(DeleteUserDocument, {
    alias: "withDeleteUser",
    ...operationOptions
  });
}

export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DeleteUserDocument, baseOptions);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserMutation
>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const DescribeVtigerModuleDocument = gql`
  query DescribeVtigerModule($moduleType: String!) {
    describeVtigerModule(moduleType: $moduleType)
  }
`;
export type DescribeVtigerModuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables
  >,
  "query"
> &
  (
    | { variables: DescribeVtigerModuleQueryVariables; skip?: boolean }
    | { skip: boolean });

export const DescribeVtigerModuleComponent = (
  props: DescribeVtigerModuleComponentProps
) => (
  <ApolloReactComponents.Query<
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables
  >
    query={DescribeVtigerModuleDocument}
    {...props}
  />
);

export type DescribeVtigerModuleProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  DescribeVtigerModuleQuery,
  DescribeVtigerModuleQueryVariables
> &
  TChildProps;
export function withDescribeVtigerModule<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables,
    DescribeVtigerModuleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables,
    DescribeVtigerModuleProps<TChildProps>
  >(DescribeVtigerModuleDocument, {
    alias: "withDescribeVtigerModule",
    ...operationOptions
  });
}

export function useDescribeVtigerModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DescribeVtigerModuleQuery,
    DescribeVtigerModuleQueryVariables
  >(DescribeVtigerModuleDocument, baseOptions);
}
export type DescribeVtigerModuleQueryHookResult = ReturnType<
  typeof useDescribeVtigerModuleQuery
>;
export type DescribeVtigerModuleQueryResult = ApolloReactCommon.QueryResult<
  DescribeVtigerModuleQuery,
  DescribeVtigerModuleQueryVariables
>;
export const GetVtigerCompanyDocument = gql`
  query GetVtigerCompany($domain: String!) {
    getVtigerCompany(domain: $domain)
  }
`;
export type GetVtigerCompanyComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables
  >,
  "query"
> &
  (
    | { variables: GetVtigerCompanyQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetVtigerCompanyComponent = (
  props: GetVtigerCompanyComponentProps
) => (
  <ApolloReactComponents.Query<
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables
  >
    query={GetVtigerCompanyDocument}
    {...props}
  />
);

export type GetVtigerCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetVtigerCompanyQuery,
  GetVtigerCompanyQueryVariables
> &
  TChildProps;
export function withGetVtigerCompany<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables,
    GetVtigerCompanyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables,
    GetVtigerCompanyProps<TChildProps>
  >(GetVtigerCompanyDocument, {
    alias: "withGetVtigerCompany",
    ...operationOptions
  });
}

export function useGetVtigerCompanyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetVtigerCompanyQuery,
    GetVtigerCompanyQueryVariables
  >(GetVtigerCompanyDocument, baseOptions);
}
export type GetVtigerCompanyQueryHookResult = ReturnType<
  typeof useGetVtigerCompanyQuery
>;
export type GetVtigerCompanyQueryResult = ApolloReactCommon.QueryResult<
  GetVtigerCompanyQuery,
  GetVtigerCompanyQueryVariables
>;
export const GetVtigerContactDocument = gql`
  query GetVtigerContact($email: String!) {
    getVtigerContact(email: $email)
  }
`;
export type GetVtigerContactComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables
  >,
  "query"
> &
  (
    | { variables: GetVtigerContactQueryVariables; skip?: boolean }
    | { skip: boolean });

export const GetVtigerContactComponent = (
  props: GetVtigerContactComponentProps
) => (
  <ApolloReactComponents.Query<
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables
  >
    query={GetVtigerContactDocument}
    {...props}
  />
);

export type GetVtigerContactProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  GetVtigerContactQuery,
  GetVtigerContactQueryVariables
> &
  TChildProps;
export function withGetVtigerContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables,
    GetVtigerContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables,
    GetVtigerContactProps<TChildProps>
  >(GetVtigerContactDocument, {
    alias: "withGetVtigerContact",
    ...operationOptions
  });
}

export function useGetVtigerContactQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetVtigerContactQuery,
    GetVtigerContactQueryVariables
  >(GetVtigerContactDocument, baseOptions);
}
export type GetVtigerContactQueryHookResult = ReturnType<
  typeof useGetVtigerContactQuery
>;
export type GetVtigerContactQueryResult = ApolloReactCommon.QueryResult<
  GetVtigerContactQuery,
  GetVtigerContactQueryVariables
>;
export const ZoomUsersDocument = gql`
  query ZoomUsers {
    zoomUsers {
      id
      first_name
      last_name
      email
    }
  }
`;
export type ZoomUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ZoomUsersQuery,
    ZoomUsersQueryVariables
  >,
  "query"
>;

export const ZoomUsersComponent = (props: ZoomUsersComponentProps) => (
  <ApolloReactComponents.Query<ZoomUsersQuery, ZoomUsersQueryVariables>
    query={ZoomUsersDocument}
    {...props}
  />
);

export type ZoomUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ZoomUsersQuery,
  ZoomUsersQueryVariables
> &
  TChildProps;
export function withZoomUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ZoomUsersQuery,
    ZoomUsersQueryVariables,
    ZoomUsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ZoomUsersQuery,
    ZoomUsersQueryVariables,
    ZoomUsersProps<TChildProps>
  >(ZoomUsersDocument, {
    alias: "withZoomUsers",
    ...operationOptions
  });
}

export function useZoomUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ZoomUsersQuery,
    ZoomUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ZoomUsersQuery, ZoomUsersQueryVariables>(
    ZoomUsersDocument,
    baseOptions
  );
}
export type ZoomUsersQueryHookResult = ReturnType<typeof useZoomUsersQuery>;
export type ZoomUsersQueryResult = ApolloReactCommon.QueryResult<
  ZoomUsersQuery,
  ZoomUsersQueryVariables
>;
export const ZoomCallLogDocument = gql`
  query ZoomCallLog($data: ZoomCallLogInput!) {
    zoomCallLog(data: $data) {
      total_records
      page_count
      page_number
      call_logs {
        id
        caller_number
        caller_number_type
        caller_name
        callee_number
        callee_name
        direction
        duration
        result
        date_time
      }
    }
  }
`;
export type ZoomCallLogComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ZoomCallLogQuery,
    ZoomCallLogQueryVariables
  >,
  "query"
> &
  (
    | { variables: ZoomCallLogQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ZoomCallLogComponent = (props: ZoomCallLogComponentProps) => (
  <ApolloReactComponents.Query<ZoomCallLogQuery, ZoomCallLogQueryVariables>
    query={ZoomCallLogDocument}
    {...props}
  />
);

export type ZoomCallLogProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ZoomCallLogQuery,
  ZoomCallLogQueryVariables
> &
  TChildProps;
export function withZoomCallLog<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ZoomCallLogQuery,
    ZoomCallLogQueryVariables,
    ZoomCallLogProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ZoomCallLogQuery,
    ZoomCallLogQueryVariables,
    ZoomCallLogProps<TChildProps>
  >(ZoomCallLogDocument, {
    alias: "withZoomCallLog",
    ...operationOptions
  });
}

export function useZoomCallLogQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ZoomCallLogQuery,
    ZoomCallLogQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ZoomCallLogQuery, ZoomCallLogQueryVariables>(
    ZoomCallLogDocument,
    baseOptions
  );
}
export type ZoomCallLogQueryHookResult = ReturnType<typeof useZoomCallLogQuery>;
export type ZoomCallLogQueryResult = ApolloReactCommon.QueryResult<
  ZoomCallLogQuery,
  ZoomCallLogQueryVariables
>;
export const ZoomRecordingsDocument = gql`
  query ZoomRecordings($data: ZoomRecordingInput!) {
    zoomRecordings(data: $data) {
      total_records
      page_count
      page_number
      recordings {
        id
        caller_number
        caller_number_type
        caller_name
        callee_number
        callee_name
        direction
        duration
        date_time
        download_url
      }
    }
  }
`;
export type ZoomRecordingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables
  >,
  "query"
> &
  (
    | { variables: ZoomRecordingsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ZoomRecordingsComponent = (
  props: ZoomRecordingsComponentProps
) => (
  <ApolloReactComponents.Query<
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables
  >
    query={ZoomRecordingsDocument}
    {...props}
  />
);

export type ZoomRecordingsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ZoomRecordingsQuery,
  ZoomRecordingsQueryVariables
> &
  TChildProps;
export function withZoomRecordings<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables,
    ZoomRecordingsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables,
    ZoomRecordingsProps<TChildProps>
  >(ZoomRecordingsDocument, {
    alias: "withZoomRecordings",
    ...operationOptions
  });
}

export function useZoomRecordingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ZoomRecordingsQuery,
    ZoomRecordingsQueryVariables
  >(ZoomRecordingsDocument, baseOptions);
}
export type ZoomRecordingsQueryHookResult = ReturnType<
  typeof useZoomRecordingsQuery
>;
export type ZoomRecordingsQueryResult = ApolloReactCommon.QueryResult<
  ZoomRecordingsQuery,
  ZoomRecordingsQueryVariables
>;
export const ZoomCallReportDocument = gql`
  query ZoomCallReport($data: ZoomCallReportInput!) {
    zoomCallReport(data: $data) {
      totalCalls
      totalOutbound
      totalInbound
      totalCanceled
      totalConnected
      totalDuration
      avgDuration
      dateTimes
    }
  }
`;
export type ZoomCallReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables
  >,
  "query"
> &
  (
    | { variables: ZoomCallReportQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ZoomCallReportComponent = (
  props: ZoomCallReportComponentProps
) => (
  <ApolloReactComponents.Query<
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables
  >
    query={ZoomCallReportDocument}
    {...props}
  />
);

export type ZoomCallReportProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ZoomCallReportQuery,
  ZoomCallReportQueryVariables
> &
  TChildProps;
export function withZoomCallReport<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables,
    ZoomCallReportProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables,
    ZoomCallReportProps<TChildProps>
  >(ZoomCallReportDocument, {
    alias: "withZoomCallReport",
    ...operationOptions
  });
}

export function useZoomCallReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ZoomCallReportQuery,
    ZoomCallReportQueryVariables
  >(ZoomCallReportDocument, baseOptions);
}
export type ZoomCallReportQueryHookResult = ReturnType<
  typeof useZoomCallReportQuery
>;
export type ZoomCallReportQueryResult = ApolloReactCommon.QueryResult<
  ZoomCallReportQuery,
  ZoomCallReportQueryVariables
>;
export const CampaignDocument = gql`
  query Campaign($id: String!) {
    camapign(id: $id) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;
export type CampaignComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CampaignQuery,
    CampaignQueryVariables
  >,
  "query"
> &
  ({ variables: CampaignQueryVariables; skip?: boolean } | { skip: boolean });

export const CampaignComponent = (props: CampaignComponentProps) => (
  <ApolloReactComponents.Query<CampaignQuery, CampaignQueryVariables>
    query={CampaignDocument}
    {...props}
  />
);

export type CampaignProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CampaignQuery,
  CampaignQueryVariables
> &
  TChildProps;
export function withCampaign<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CampaignQuery,
    CampaignQueryVariables,
    CampaignProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CampaignQuery,
    CampaignQueryVariables,
    CampaignProps<TChildProps>
  >(CampaignDocument, {
    alias: "withCampaign",
    ...operationOptions
  });
}

export function useCampaignQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CampaignQuery,
    CampaignQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CampaignQuery, CampaignQueryVariables>(
    CampaignDocument,
    baseOptions
  );
}
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignQueryResult = ApolloReactCommon.QueryResult<
  CampaignQuery,
  CampaignQueryVariables
>;
export const CampaignsDocument = gql`
  query Campaigns($data: QueryCampaignsInput) {
    campaigns(data: $data) {
      total
      items {
        ...Campaign
      }
    }
  }
  ${CampaignFragmentDoc}
`;
export type CampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CampaignsQuery,
    CampaignsQueryVariables
  >,
  "query"
>;

export const CampaignsComponent = (props: CampaignsComponentProps) => (
  <ApolloReactComponents.Query<CampaignsQuery, CampaignsQueryVariables>
    query={CampaignsDocument}
    {...props}
  />
);

export type CampaignsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CampaignsQuery,
  CampaignsQueryVariables
> &
  TChildProps;
export function withCampaigns<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CampaignsQuery,
    CampaignsQueryVariables,
    CampaignsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CampaignsQuery,
    CampaignsQueryVariables,
    CampaignsProps<TChildProps>
  >(CampaignsDocument, {
    alias: "withCampaigns",
    ...operationOptions
  });
}

export function useCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CampaignsQuery,
    CampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CampaignsQuery, CampaignsQueryVariables>(
    CampaignsDocument,
    baseOptions
  );
}
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsQueryResult = ApolloReactCommon.QueryResult<
  CampaignsQuery,
  CampaignsQueryVariables
>;
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($data: CreateCampaignInput!) {
    createCampaign(data: $data) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;
export type CreateCampaignMutationFn = ApolloReactCommon.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export type CreateCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >,
  "mutation"
>;

export const CreateCampaignComponent = (
  props: CreateCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
    mutation={CreateCampaignDocument}
    {...props}
  />
);

export type CreateCampaignProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
> &
  TChildProps;
export function withCreateCampaign<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps>
  >(CreateCampaignDocument, {
    alias: "withCreateCampaign",
    ...operationOptions
  });
}

export function useCreateCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >(CreateCampaignDocument, baseOptions);
}
export type CreateCampaignMutationHookResult = ReturnType<
  typeof useCreateCampaignMutation
>;
export type CreateCampaignMutationResult = ApolloReactCommon.MutationResult<
  CreateCampaignMutation
>;
export type CreateCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = gql`
  mutation UpdateCampaign($id: String!, $data: CreateCampaignInput!) {
    updateCampaign(id: $id, data: $data) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;
export type UpdateCampaignMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export type UpdateCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >,
  "mutation"
>;

export const UpdateCampaignComponent = (
  props: UpdateCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >
    mutation={UpdateCampaignDocument}
    {...props}
  />
);

export type UpdateCampaignProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
> &
  TChildProps;
export function withUpdateCampaign<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables,
    UpdateCampaignProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables,
    UpdateCampaignProps<TChildProps>
  >(UpdateCampaignDocument, {
    alias: "withUpdateCampaign",
    ...operationOptions
  });
}

export function useUpdateCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >(UpdateCampaignDocument, baseOptions);
}
export type UpdateCampaignMutationHookResult = ReturnType<
  typeof useUpdateCampaignMutation
>;
export type UpdateCampaignMutationResult = ApolloReactCommon.MutationResult<
  UpdateCampaignMutation
>;
export type UpdateCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const DeleteCampaignDocument = gql`
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id)
  }
`;
export type DeleteCampaignMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>;
export type DeleteCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables
  >,
  "mutation"
>;

export const DeleteCampaignComponent = (
  props: DeleteCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables
  >
    mutation={DeleteCampaignDocument}
    {...props}
  />
);

export type DeleteCampaignProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
> &
  TChildProps;
export function withDeleteCampaign<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables,
    DeleteCampaignProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables,
    DeleteCampaignProps<TChildProps>
  >(DeleteCampaignDocument, {
    alias: "withDeleteCampaign",
    ...operationOptions
  });
}

export function useDeleteCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables
  >(DeleteCampaignDocument, baseOptions);
}
export type DeleteCampaignMutationHookResult = ReturnType<
  typeof useDeleteCampaignMutation
>;
export type DeleteCampaignMutationResult = ApolloReactCommon.MutationResult<
  DeleteCampaignMutation
>;
export type DeleteCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCampaignMutation,
  DeleteCampaignMutationVariables
>;
export const ClientContactDocument = gql`
  query ClientContact($id: String!) {
    clientContact(id: $id) {
      ...ClientContact
    }
  }
  ${ClientContactFragmentDoc}
`;
export type ClientContactComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientContactQuery,
    ClientContactQueryVariables
  >,
  "query"
> &
  (
    | { variables: ClientContactQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ClientContactComponent = (props: ClientContactComponentProps) => (
  <ApolloReactComponents.Query<ClientContactQuery, ClientContactQueryVariables>
    query={ClientContactDocument}
    {...props}
  />
);

export type ClientContactProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientContactQuery,
  ClientContactQueryVariables
> &
  TChildProps;
export function withClientContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientContactQuery,
    ClientContactQueryVariables,
    ClientContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientContactQuery,
    ClientContactQueryVariables,
    ClientContactProps<TChildProps>
  >(ClientContactDocument, {
    alias: "withClientContact",
    ...operationOptions
  });
}

export function useClientContactQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientContactQuery,
    ClientContactQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientContactQuery,
    ClientContactQueryVariables
  >(ClientContactDocument, baseOptions);
}
export type ClientContactQueryHookResult = ReturnType<
  typeof useClientContactQuery
>;
export type ClientContactQueryResult = ApolloReactCommon.QueryResult<
  ClientContactQuery,
  ClientContactQueryVariables
>;
export const ClientContactsDocument = gql`
  query ClientContacts($data: QueryClientContactsInput) {
    clientContacts(data: $data) {
      total
      items {
        ...ClientContact
      }
    }
  }
  ${ClientContactFragmentDoc}
`;
export type ClientContactsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientContactsQuery,
    ClientContactsQueryVariables
  >,
  "query"
>;

export const ClientContactsComponent = (
  props: ClientContactsComponentProps
) => (
  <ApolloReactComponents.Query<
    ClientContactsQuery,
    ClientContactsQueryVariables
  >
    query={ClientContactsDocument}
    {...props}
  />
);

export type ClientContactsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientContactsQuery,
  ClientContactsQueryVariables
> &
  TChildProps;
export function withClientContacts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientContactsQuery,
    ClientContactsQueryVariables,
    ClientContactsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientContactsQuery,
    ClientContactsQueryVariables,
    ClientContactsProps<TChildProps>
  >(ClientContactsDocument, {
    alias: "withClientContacts",
    ...operationOptions
  });
}

export function useClientContactsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientContactsQuery,
    ClientContactsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientContactsQuery,
    ClientContactsQueryVariables
  >(ClientContactsDocument, baseOptions);
}
export type ClientContactsQueryHookResult = ReturnType<
  typeof useClientContactsQuery
>;
export type ClientContactsQueryResult = ApolloReactCommon.QueryResult<
  ClientContactsQuery,
  ClientContactsQueryVariables
>;
export const CreateClientContactDocument = gql`
  mutation CreateClientContact($data: CreateClientContactInput!) {
    createClientContact(data: $data) {
      ...ClientContact
    }
  }
  ${ClientContactFragmentDoc}
`;
export type CreateClientContactMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientContactMutation,
  CreateClientContactMutationVariables
>;
export type CreateClientContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >,
  "mutation"
>;

export const CreateClientContactComponent = (
  props: CreateClientContactComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >
    mutation={CreateClientContactDocument}
    {...props}
  />
);

export type CreateClientContactProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateClientContactMutation,
  CreateClientContactMutationVariables
> &
  TChildProps;
export function withCreateClientContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateClientContactMutation,
    CreateClientContactMutationVariables,
    CreateClientContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateClientContactMutation,
    CreateClientContactMutationVariables,
    CreateClientContactProps<TChildProps>
  >(CreateClientContactDocument, {
    alias: "withCreateClientContact",
    ...operationOptions
  });
}

export function useCreateClientContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateClientContactMutation,
    CreateClientContactMutationVariables
  >(CreateClientContactDocument, baseOptions);
}
export type CreateClientContactMutationHookResult = ReturnType<
  typeof useCreateClientContactMutation
>;
export type CreateClientContactMutationResult = ApolloReactCommon.MutationResult<
  CreateClientContactMutation
>;
export type CreateClientContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientContactMutation,
  CreateClientContactMutationVariables
>;
export const UpdateClientContactDocument = gql`
  mutation UpdateClientContact($id: String!, $data: CreateClientContactInput!) {
    updateClientContact(id: $id, data: $data) {
      ...ClientContact
    }
  }
  ${ClientContactFragmentDoc}
`;
export type UpdateClientContactMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientContactMutation,
  UpdateClientContactMutationVariables
>;
export type UpdateClientContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >,
  "mutation"
>;

export const UpdateClientContactComponent = (
  props: UpdateClientContactComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >
    mutation={UpdateClientContactDocument}
    {...props}
  />
);

export type UpdateClientContactProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateClientContactMutation,
  UpdateClientContactMutationVariables
> &
  TChildProps;
export function withUpdateClientContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables,
    UpdateClientContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables,
    UpdateClientContactProps<TChildProps>
  >(UpdateClientContactDocument, {
    alias: "withUpdateClientContact",
    ...operationOptions
  });
}

export function useUpdateClientContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateClientContactMutation,
    UpdateClientContactMutationVariables
  >(UpdateClientContactDocument, baseOptions);
}
export type UpdateClientContactMutationHookResult = ReturnType<
  typeof useUpdateClientContactMutation
>;
export type UpdateClientContactMutationResult = ApolloReactCommon.MutationResult<
  UpdateClientContactMutation
>;
export type UpdateClientContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientContactMutation,
  UpdateClientContactMutationVariables
>;
export const DeleteClientContactDocument = gql`
  mutation DeleteClientContact($id: String!) {
    deleteClientContact(id: $id)
  }
`;
export type DeleteClientContactMutationFn = ApolloReactCommon.MutationFunction<
  DeleteClientContactMutation,
  DeleteClientContactMutationVariables
>;
export type DeleteClientContactComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables
  >,
  "mutation"
>;

export const DeleteClientContactComponent = (
  props: DeleteClientContactComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables
  >
    mutation={DeleteClientContactDocument}
    {...props}
  />
);

export type DeleteClientContactProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteClientContactMutation,
  DeleteClientContactMutationVariables
> &
  TChildProps;
export function withDeleteClientContact<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables,
    DeleteClientContactProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables,
    DeleteClientContactProps<TChildProps>
  >(DeleteClientContactDocument, {
    alias: "withDeleteClientContact",
    ...operationOptions
  });
}

export function useDeleteClientContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteClientContactMutation,
    DeleteClientContactMutationVariables
  >(DeleteClientContactDocument, baseOptions);
}
export type DeleteClientContactMutationHookResult = ReturnType<
  typeof useDeleteClientContactMutation
>;
export type DeleteClientContactMutationResult = ApolloReactCommon.MutationResult<
  DeleteClientContactMutation
>;
export type DeleteClientContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteClientContactMutation,
  DeleteClientContactMutationVariables
>;
export const ClientPartnerDocument = gql`
  query ClientPartner($id: String!) {
    clientPartner(id: $id) {
      ...ClientPartner
    }
  }
  ${ClientPartnerFragmentDoc}
`;
export type ClientPartnerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientPartnerQuery,
    ClientPartnerQueryVariables
  >,
  "query"
> &
  (
    | { variables: ClientPartnerQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ClientPartnerComponent = (props: ClientPartnerComponentProps) => (
  <ApolloReactComponents.Query<ClientPartnerQuery, ClientPartnerQueryVariables>
    query={ClientPartnerDocument}
    {...props}
  />
);

export type ClientPartnerProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientPartnerQuery,
  ClientPartnerQueryVariables
> &
  TChildProps;
export function withClientPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientPartnerQuery,
    ClientPartnerQueryVariables,
    ClientPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientPartnerQuery,
    ClientPartnerQueryVariables,
    ClientPartnerProps<TChildProps>
  >(ClientPartnerDocument, {
    alias: "withClientPartner",
    ...operationOptions
  });
}

export function useClientPartnerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientPartnerQuery,
    ClientPartnerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientPartnerQuery,
    ClientPartnerQueryVariables
  >(ClientPartnerDocument, baseOptions);
}
export type ClientPartnerQueryHookResult = ReturnType<
  typeof useClientPartnerQuery
>;
export type ClientPartnerQueryResult = ApolloReactCommon.QueryResult<
  ClientPartnerQuery,
  ClientPartnerQueryVariables
>;
export const ClientPartnersDocument = gql`
  query ClientPartners($data: QueryClientPartnersInput) {
    clientPartners(data: $data) {
      total
      items {
        ...ClientPartner
      }
    }
  }
  ${ClientPartnerFragmentDoc}
`;
export type ClientPartnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ClientPartnersQuery,
    ClientPartnersQueryVariables
  >,
  "query"
>;

export const ClientPartnersComponent = (
  props: ClientPartnersComponentProps
) => (
  <ApolloReactComponents.Query<
    ClientPartnersQuery,
    ClientPartnersQueryVariables
  >
    query={ClientPartnersDocument}
    {...props}
  />
);

export type ClientPartnersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ClientPartnersQuery,
  ClientPartnersQueryVariables
> &
  TChildProps;
export function withClientPartners<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ClientPartnersQuery,
    ClientPartnersQueryVariables,
    ClientPartnersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ClientPartnersQuery,
    ClientPartnersQueryVariables,
    ClientPartnersProps<TChildProps>
  >(ClientPartnersDocument, {
    alias: "withClientPartners",
    ...operationOptions
  });
}

export function useClientPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ClientPartnersQuery,
    ClientPartnersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ClientPartnersQuery,
    ClientPartnersQueryVariables
  >(ClientPartnersDocument, baseOptions);
}
export type ClientPartnersQueryHookResult = ReturnType<
  typeof useClientPartnersQuery
>;
export type ClientPartnersQueryResult = ApolloReactCommon.QueryResult<
  ClientPartnersQuery,
  ClientPartnersQueryVariables
>;
export const CreateClientPartnerDocument = gql`
  mutation CreateClientPartner($data: CreateClientPartnerInput!) {
    createClientPartner(data: $data) {
      ...ClientPartner
    }
  }
  ${ClientPartnerFragmentDoc}
`;
export type CreateClientPartnerMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientPartnerMutation,
  CreateClientPartnerMutationVariables
>;
export type CreateClientPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables
  >,
  "mutation"
>;

export const CreateClientPartnerComponent = (
  props: CreateClientPartnerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables
  >
    mutation={CreateClientPartnerDocument}
    {...props}
  />
);

export type CreateClientPartnerProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateClientPartnerMutation,
  CreateClientPartnerMutationVariables
> &
  TChildProps;
export function withCreateClientPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables,
    CreateClientPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables,
    CreateClientPartnerProps<TChildProps>
  >(CreateClientPartnerDocument, {
    alias: "withCreateClientPartner",
    ...operationOptions
  });
}

export function useCreateClientPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateClientPartnerMutation,
    CreateClientPartnerMutationVariables
  >(CreateClientPartnerDocument, baseOptions);
}
export type CreateClientPartnerMutationHookResult = ReturnType<
  typeof useCreateClientPartnerMutation
>;
export type CreateClientPartnerMutationResult = ApolloReactCommon.MutationResult<
  CreateClientPartnerMutation
>;
export type CreateClientPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientPartnerMutation,
  CreateClientPartnerMutationVariables
>;
export const UpdateClientPartnerDocument = gql`
  mutation UpdateClientPartner($id: String!, $data: CreateClientPartnerInput!) {
    updateClientPartner(id: $id, data: $data) {
      ...ClientPartner
    }
  }
  ${ClientPartnerFragmentDoc}
`;
export type UpdateClientPartnerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientPartnerMutation,
  UpdateClientPartnerMutationVariables
>;
export type UpdateClientPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables
  >,
  "mutation"
>;

export const UpdateClientPartnerComponent = (
  props: UpdateClientPartnerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables
  >
    mutation={UpdateClientPartnerDocument}
    {...props}
  />
);

export type UpdateClientPartnerProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateClientPartnerMutation,
  UpdateClientPartnerMutationVariables
> &
  TChildProps;
export function withUpdateClientPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables,
    UpdateClientPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables,
    UpdateClientPartnerProps<TChildProps>
  >(UpdateClientPartnerDocument, {
    alias: "withUpdateClientPartner",
    ...operationOptions
  });
}

export function useUpdateClientPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateClientPartnerMutation,
    UpdateClientPartnerMutationVariables
  >(UpdateClientPartnerDocument, baseOptions);
}
export type UpdateClientPartnerMutationHookResult = ReturnType<
  typeof useUpdateClientPartnerMutation
>;
export type UpdateClientPartnerMutationResult = ApolloReactCommon.MutationResult<
  UpdateClientPartnerMutation
>;
export type UpdateClientPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientPartnerMutation,
  UpdateClientPartnerMutationVariables
>;
export const DeleteClientPartnerDocument = gql`
  mutation DeleteClientPartner($id: String!) {
    deleteClientPartner(id: $id)
  }
`;
export type DeleteClientPartnerMutationFn = ApolloReactCommon.MutationFunction<
  DeleteClientPartnerMutation,
  DeleteClientPartnerMutationVariables
>;
export type DeleteClientPartnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables
  >,
  "mutation"
>;

export const DeleteClientPartnerComponent = (
  props: DeleteClientPartnerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables
  >
    mutation={DeleteClientPartnerDocument}
    {...props}
  />
);

export type DeleteClientPartnerProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteClientPartnerMutation,
  DeleteClientPartnerMutationVariables
> &
  TChildProps;
export function withDeleteClientPartner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables,
    DeleteClientPartnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables,
    DeleteClientPartnerProps<TChildProps>
  >(DeleteClientPartnerDocument, {
    alias: "withDeleteClientPartner",
    ...operationOptions
  });
}

export function useDeleteClientPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteClientPartnerMutation,
    DeleteClientPartnerMutationVariables
  >(DeleteClientPartnerDocument, baseOptions);
}
export type DeleteClientPartnerMutationHookResult = ReturnType<
  typeof useDeleteClientPartnerMutation
>;
export type DeleteClientPartnerMutationResult = ApolloReactCommon.MutationResult<
  DeleteClientPartnerMutation
>;
export type DeleteClientPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteClientPartnerMutation,
  DeleteClientPartnerMutationVariables
>;
export const TradeshowDocument = gql`
  query Tradeshow($id: String!) {
    tradeshow(id: $id) {
      ...Tradeshow
    }
  }
  ${TradeshowFragmentDoc}
`;
export type TradeshowComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TradeshowQuery,
    TradeshowQueryVariables
  >,
  "query"
> &
  ({ variables: TradeshowQueryVariables; skip?: boolean } | { skip: boolean });

export const TradeshowComponent = (props: TradeshowComponentProps) => (
  <ApolloReactComponents.Query<TradeshowQuery, TradeshowQueryVariables>
    query={TradeshowDocument}
    {...props}
  />
);

export type TradeshowProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TradeshowQuery,
  TradeshowQueryVariables
> &
  TChildProps;
export function withTradeshow<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TradeshowQuery,
    TradeshowQueryVariables,
    TradeshowProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TradeshowQuery,
    TradeshowQueryVariables,
    TradeshowProps<TChildProps>
  >(TradeshowDocument, {
    alias: "withTradeshow",
    ...operationOptions
  });
}

export function useTradeshowQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TradeshowQuery,
    TradeshowQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TradeshowQuery, TradeshowQueryVariables>(
    TradeshowDocument,
    baseOptions
  );
}
export type TradeshowQueryHookResult = ReturnType<typeof useTradeshowQuery>;
export type TradeshowQueryResult = ApolloReactCommon.QueryResult<
  TradeshowQuery,
  TradeshowQueryVariables
>;
export const TradeshowsDocument = gql`
  query Tradeshows($data: QueryTradeshowsInput) {
    tradeshows(data: $data) {
      total
      items {
        ...Tradeshow
      }
    }
  }
  ${TradeshowFragmentDoc}
`;
export type TradeshowsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TradeshowsQuery,
    TradeshowsQueryVariables
  >,
  "query"
>;

export const TradeshowsComponent = (props: TradeshowsComponentProps) => (
  <ApolloReactComponents.Query<TradeshowsQuery, TradeshowsQueryVariables>
    query={TradeshowsDocument}
    {...props}
  />
);

export type TradeshowsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  TradeshowsQuery,
  TradeshowsQueryVariables
> &
  TChildProps;
export function withTradeshows<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    TradeshowsQuery,
    TradeshowsQueryVariables,
    TradeshowsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    TradeshowsQuery,
    TradeshowsQueryVariables,
    TradeshowsProps<TChildProps>
  >(TradeshowsDocument, {
    alias: "withTradeshows",
    ...operationOptions
  });
}

export function useTradeshowsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TradeshowsQuery,
    TradeshowsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TradeshowsQuery, TradeshowsQueryVariables>(
    TradeshowsDocument,
    baseOptions
  );
}
export type TradeshowsQueryHookResult = ReturnType<typeof useTradeshowsQuery>;
export type TradeshowsQueryResult = ApolloReactCommon.QueryResult<
  TradeshowsQuery,
  TradeshowsQueryVariables
>;
export const CreateTradeshowDocument = gql`
  mutation CreateTradeshow($data: CreateTradeshowInput!) {
    createTradeshow(data: $data) {
      ...Tradeshow
    }
  }
  ${TradeshowFragmentDoc}
`;
export type CreateTradeshowMutationFn = ApolloReactCommon.MutationFunction<
  CreateTradeshowMutation,
  CreateTradeshowMutationVariables
>;
export type CreateTradeshowComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables
  >,
  "mutation"
>;

export const CreateTradeshowComponent = (
  props: CreateTradeshowComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables
  >
    mutation={CreateTradeshowDocument}
    {...props}
  />
);

export type CreateTradeshowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateTradeshowMutation,
  CreateTradeshowMutationVariables
> &
  TChildProps;
export function withCreateTradeshow<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables,
    CreateTradeshowProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables,
    CreateTradeshowProps<TChildProps>
  >(CreateTradeshowDocument, {
    alias: "withCreateTradeshow",
    ...operationOptions
  });
}

export function useCreateTradeshowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateTradeshowMutation,
    CreateTradeshowMutationVariables
  >(CreateTradeshowDocument, baseOptions);
}
export type CreateTradeshowMutationHookResult = ReturnType<
  typeof useCreateTradeshowMutation
>;
export type CreateTradeshowMutationResult = ApolloReactCommon.MutationResult<
  CreateTradeshowMutation
>;
export type CreateTradeshowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTradeshowMutation,
  CreateTradeshowMutationVariables
>;
export const UpdateTradeshowDocument = gql`
  mutation UpdateTradeshow($id: String!, $data: CreateTradeshowInput!) {
    updateTradeshow(id: $id, data: $data) {
      ...Tradeshow
    }
  }
  ${TradeshowFragmentDoc}
`;
export type UpdateTradeshowMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTradeshowMutation,
  UpdateTradeshowMutationVariables
>;
export type UpdateTradeshowComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables
  >,
  "mutation"
>;

export const UpdateTradeshowComponent = (
  props: UpdateTradeshowComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables
  >
    mutation={UpdateTradeshowDocument}
    {...props}
  />
);

export type UpdateTradeshowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateTradeshowMutation,
  UpdateTradeshowMutationVariables
> &
  TChildProps;
export function withUpdateTradeshow<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables,
    UpdateTradeshowProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables,
    UpdateTradeshowProps<TChildProps>
  >(UpdateTradeshowDocument, {
    alias: "withUpdateTradeshow",
    ...operationOptions
  });
}

export function useUpdateTradeshowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateTradeshowMutation,
    UpdateTradeshowMutationVariables
  >(UpdateTradeshowDocument, baseOptions);
}
export type UpdateTradeshowMutationHookResult = ReturnType<
  typeof useUpdateTradeshowMutation
>;
export type UpdateTradeshowMutationResult = ApolloReactCommon.MutationResult<
  UpdateTradeshowMutation
>;
export type UpdateTradeshowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTradeshowMutation,
  UpdateTradeshowMutationVariables
>;
export const DeleteTradeshowDocument = gql`
  mutation DeleteTradeshow($id: String!) {
    deleteTradeshow(id: $id)
  }
`;
export type DeleteTradeshowMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTradeshowMutation,
  DeleteTradeshowMutationVariables
>;
export type DeleteTradeshowComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables
  >,
  "mutation"
>;

export const DeleteTradeshowComponent = (
  props: DeleteTradeshowComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables
  >
    mutation={DeleteTradeshowDocument}
    {...props}
  />
);

export type DeleteTradeshowProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteTradeshowMutation,
  DeleteTradeshowMutationVariables
> &
  TChildProps;
export function withDeleteTradeshow<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables,
    DeleteTradeshowProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables,
    DeleteTradeshowProps<TChildProps>
  >(DeleteTradeshowDocument, {
    alias: "withDeleteTradeshow",
    ...operationOptions
  });
}

export function useDeleteTradeshowMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteTradeshowMutation,
    DeleteTradeshowMutationVariables
  >(DeleteTradeshowDocument, baseOptions);
}
export type DeleteTradeshowMutationHookResult = ReturnType<
  typeof useDeleteTradeshowMutation
>;
export type DeleteTradeshowMutationResult = ApolloReactCommon.MutationResult<
  DeleteTradeshowMutation
>;
export type DeleteTradeshowMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTradeshowMutation,
  DeleteTradeshowMutationVariables
>;
export const MeetingFieldDocument = gql`
  query MeetingField($id: String!) {
    meetingField(id: $id) {
      ...MeetingField
    }
  }
  ${MeetingFieldFragmentDoc}
`;
export type MeetingFieldComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingFieldQuery,
    MeetingFieldQueryVariables
  >,
  "query"
> &
  (
    | { variables: MeetingFieldQueryVariables; skip?: boolean }
    | { skip: boolean });

export const MeetingFieldComponent = (props: MeetingFieldComponentProps) => (
  <ApolloReactComponents.Query<MeetingFieldQuery, MeetingFieldQueryVariables>
    query={MeetingFieldDocument}
    {...props}
  />
);

export type MeetingFieldProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingFieldQuery,
  MeetingFieldQueryVariables
> &
  TChildProps;
export function withMeetingField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingFieldQuery,
    MeetingFieldQueryVariables,
    MeetingFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingFieldQuery,
    MeetingFieldQueryVariables,
    MeetingFieldProps<TChildProps>
  >(MeetingFieldDocument, {
    alias: "withMeetingField",
    ...operationOptions
  });
}

export function useMeetingFieldQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingFieldQuery,
    MeetingFieldQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MeetingFieldQuery,
    MeetingFieldQueryVariables
  >(MeetingFieldDocument, baseOptions);
}
export type MeetingFieldQueryHookResult = ReturnType<
  typeof useMeetingFieldQuery
>;
export type MeetingFieldQueryResult = ApolloReactCommon.QueryResult<
  MeetingFieldQuery,
  MeetingFieldQueryVariables
>;
export const MeetingFieldsDocument = gql`
  query MeetingFields($data: QueryMeetingFieldsInput) {
    meetingFields(data: $data) {
      ...MeetingField
    }
  }
  ${MeetingFieldFragmentDoc}
`;
export type MeetingFieldsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingFieldsQuery,
    MeetingFieldsQueryVariables
  >,
  "query"
>;

export const MeetingFieldsComponent = (props: MeetingFieldsComponentProps) => (
  <ApolloReactComponents.Query<MeetingFieldsQuery, MeetingFieldsQueryVariables>
    query={MeetingFieldsDocument}
    {...props}
  />
);

export type MeetingFieldsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingFieldsQuery,
  MeetingFieldsQueryVariables
> &
  TChildProps;
export function withMeetingFields<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingFieldsQuery,
    MeetingFieldsQueryVariables,
    MeetingFieldsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingFieldsQuery,
    MeetingFieldsQueryVariables,
    MeetingFieldsProps<TChildProps>
  >(MeetingFieldsDocument, {
    alias: "withMeetingFields",
    ...operationOptions
  });
}

export function useMeetingFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingFieldsQuery,
    MeetingFieldsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MeetingFieldsQuery,
    MeetingFieldsQueryVariables
  >(MeetingFieldsDocument, baseOptions);
}
export type MeetingFieldsQueryHookResult = ReturnType<
  typeof useMeetingFieldsQuery
>;
export type MeetingFieldsQueryResult = ApolloReactCommon.QueryResult<
  MeetingFieldsQuery,
  MeetingFieldsQueryVariables
>;
export const CreateMeetingFieldDocument = gql`
  mutation CreateMeetingField($data: CreateMeetingFieldInput!) {
    createMeetingField(data: $data) {
      ...MeetingField
    }
  }
  ${MeetingFieldFragmentDoc}
`;
export type CreateMeetingFieldMutationFn = ApolloReactCommon.MutationFunction<
  CreateMeetingFieldMutation,
  CreateMeetingFieldMutationVariables
>;
export type CreateMeetingFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables
  >,
  "mutation"
>;

export const CreateMeetingFieldComponent = (
  props: CreateMeetingFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables
  >
    mutation={CreateMeetingFieldDocument}
    {...props}
  />
);

export type CreateMeetingFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateMeetingFieldMutation,
  CreateMeetingFieldMutationVariables
> &
  TChildProps;
export function withCreateMeetingField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables,
    CreateMeetingFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables,
    CreateMeetingFieldProps<TChildProps>
  >(CreateMeetingFieldDocument, {
    alias: "withCreateMeetingField",
    ...operationOptions
  });
}

export function useCreateMeetingFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMeetingFieldMutation,
    CreateMeetingFieldMutationVariables
  >(CreateMeetingFieldDocument, baseOptions);
}
export type CreateMeetingFieldMutationHookResult = ReturnType<
  typeof useCreateMeetingFieldMutation
>;
export type CreateMeetingFieldMutationResult = ApolloReactCommon.MutationResult<
  CreateMeetingFieldMutation
>;
export type CreateMeetingFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMeetingFieldMutation,
  CreateMeetingFieldMutationVariables
>;
export const UpdateMeetingFieldDocument = gql`
  mutation UpdateMeetingField($id: String!, $data: UpdateMeetingFieldInput!) {
    updateMeetingField(id: $id, data: $data) {
      ...MeetingField
    }
  }
  ${MeetingFieldFragmentDoc}
`;
export type UpdateMeetingFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMeetingFieldMutation,
  UpdateMeetingFieldMutationVariables
>;
export type UpdateMeetingFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables
  >,
  "mutation"
>;

export const UpdateMeetingFieldComponent = (
  props: UpdateMeetingFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables
  >
    mutation={UpdateMeetingFieldDocument}
    {...props}
  />
);

export type UpdateMeetingFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateMeetingFieldMutation,
  UpdateMeetingFieldMutationVariables
> &
  TChildProps;
export function withUpdateMeetingField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables,
    UpdateMeetingFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables,
    UpdateMeetingFieldProps<TChildProps>
  >(UpdateMeetingFieldDocument, {
    alias: "withUpdateMeetingField",
    ...operationOptions
  });
}

export function useUpdateMeetingFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMeetingFieldMutation,
    UpdateMeetingFieldMutationVariables
  >(UpdateMeetingFieldDocument, baseOptions);
}
export type UpdateMeetingFieldMutationHookResult = ReturnType<
  typeof useUpdateMeetingFieldMutation
>;
export type UpdateMeetingFieldMutationResult = ApolloReactCommon.MutationResult<
  UpdateMeetingFieldMutation
>;
export type UpdateMeetingFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMeetingFieldMutation,
  UpdateMeetingFieldMutationVariables
>;
export const ReorderMeetingFieldDocument = gql`
  mutation ReorderMeetingField(
    $id: String!
    $position: Float!
    $groupId: String
  ) {
    reorderMeetingField(id: $id, position: $position, groupId: $groupId) {
      ...MeetingField
    }
  }
  ${MeetingFieldFragmentDoc}
`;
export type ReorderMeetingFieldMutationFn = ApolloReactCommon.MutationFunction<
  ReorderMeetingFieldMutation,
  ReorderMeetingFieldMutationVariables
>;
export type ReorderMeetingFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables
  >,
  "mutation"
>;

export const ReorderMeetingFieldComponent = (
  props: ReorderMeetingFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables
  >
    mutation={ReorderMeetingFieldDocument}
    {...props}
  />
);

export type ReorderMeetingFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReorderMeetingFieldMutation,
  ReorderMeetingFieldMutationVariables
> &
  TChildProps;
export function withReorderMeetingField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables,
    ReorderMeetingFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables,
    ReorderMeetingFieldProps<TChildProps>
  >(ReorderMeetingFieldDocument, {
    alias: "withReorderMeetingField",
    ...operationOptions
  });
}

export function useReorderMeetingFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderMeetingFieldMutation,
    ReorderMeetingFieldMutationVariables
  >(ReorderMeetingFieldDocument, baseOptions);
}
export type ReorderMeetingFieldMutationHookResult = ReturnType<
  typeof useReorderMeetingFieldMutation
>;
export type ReorderMeetingFieldMutationResult = ApolloReactCommon.MutationResult<
  ReorderMeetingFieldMutation
>;
export type ReorderMeetingFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderMeetingFieldMutation,
  ReorderMeetingFieldMutationVariables
>;
export const DeleteMeetingFieldDocument = gql`
  mutation DeleteMeetingField($id: String!) {
    deleteMeetingField(id: $id)
  }
`;
export type DeleteMeetingFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMeetingFieldMutation,
  DeleteMeetingFieldMutationVariables
>;
export type DeleteMeetingFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables
  >,
  "mutation"
>;

export const DeleteMeetingFieldComponent = (
  props: DeleteMeetingFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables
  >
    mutation={DeleteMeetingFieldDocument}
    {...props}
  />
);

export type DeleteMeetingFieldProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteMeetingFieldMutation,
  DeleteMeetingFieldMutationVariables
> &
  TChildProps;
export function withDeleteMeetingField<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables,
    DeleteMeetingFieldProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables,
    DeleteMeetingFieldProps<TChildProps>
  >(DeleteMeetingFieldDocument, {
    alias: "withDeleteMeetingField",
    ...operationOptions
  });
}

export function useDeleteMeetingFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteMeetingFieldMutation,
    DeleteMeetingFieldMutationVariables
  >(DeleteMeetingFieldDocument, baseOptions);
}
export type DeleteMeetingFieldMutationHookResult = ReturnType<
  typeof useDeleteMeetingFieldMutation
>;
export type DeleteMeetingFieldMutationResult = ApolloReactCommon.MutationResult<
  DeleteMeetingFieldMutation
>;
export type DeleteMeetingFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMeetingFieldMutation,
  DeleteMeetingFieldMutationVariables
>;
export const MeetingSummaryOptionsDocument = gql`
  query MeetingSummaryOptions {
    meetingSummaryOptions {
      reportGroupId
      reportGroupName
    }
  }
`;
export type MeetingSummaryOptionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables
  >,
  "query"
>;

export const MeetingSummaryOptionsComponent = (
  props: MeetingSummaryOptionsComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables
  >
    query={MeetingSummaryOptionsDocument}
    {...props}
  />
);

export type MeetingSummaryOptionsProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  MeetingSummaryOptionsQuery,
  MeetingSummaryOptionsQueryVariables
> &
  TChildProps;
export function withMeetingSummaryOptions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables,
    MeetingSummaryOptionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables,
    MeetingSummaryOptionsProps<TChildProps>
  >(MeetingSummaryOptionsDocument, {
    alias: "withMeetingSummaryOptions",
    ...operationOptions
  });
}

export function useMeetingSummaryOptionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MeetingSummaryOptionsQuery,
    MeetingSummaryOptionsQueryVariables
  >(MeetingSummaryOptionsDocument, baseOptions);
}
export type MeetingSummaryOptionsQueryHookResult = ReturnType<
  typeof useMeetingSummaryOptionsQuery
>;
export type MeetingSummaryOptionsQueryResult = ApolloReactCommon.QueryResult<
  MeetingSummaryOptionsQuery,
  MeetingSummaryOptionsQueryVariables
>;
export const MeetingSummaryDocument = gql`
  query MeetingSummary($reportGroupId: String!, $meetingId: String!) {
    meetingSummary(reportGroupId: $reportGroupId, meetingId: $meetingId) {
      name
      fields {
        name
        value
      }
    }
  }
`;
export type MeetingSummaryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables
  >,
  "query"
> &
  (
    | { variables: MeetingSummaryQueryVariables; skip?: boolean }
    | { skip: boolean });

export const MeetingSummaryComponent = (
  props: MeetingSummaryComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables
  >
    query={MeetingSummaryDocument}
    {...props}
  />
);

export type MeetingSummaryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingSummaryQuery,
  MeetingSummaryQueryVariables
> &
  TChildProps;
export function withMeetingSummary<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables,
    MeetingSummaryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables,
    MeetingSummaryProps<TChildProps>
  >(MeetingSummaryDocument, {
    alias: "withMeetingSummary",
    ...operationOptions
  });
}

export function useMeetingSummaryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MeetingSummaryQuery,
    MeetingSummaryQueryVariables
  >(MeetingSummaryDocument, baseOptions);
}
export type MeetingSummaryQueryHookResult = ReturnType<
  typeof useMeetingSummaryQuery
>;
export type MeetingSummaryQueryResult = ApolloReactCommon.QueryResult<
  MeetingSummaryQuery,
  MeetingSummaryQueryVariables
>;
export const MeetingTagDocument = gql`
  query MeetingTag($id: String!) {
    meetingTag(id: $id) {
      ...MeetingTag
    }
  }
  ${MeetingTagFragmentDoc}
`;
export type MeetingTagComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingTagQuery,
    MeetingTagQueryVariables
  >,
  "query"
> &
  ({ variables: MeetingTagQueryVariables; skip?: boolean } | { skip: boolean });

export const MeetingTagComponent = (props: MeetingTagComponentProps) => (
  <ApolloReactComponents.Query<MeetingTagQuery, MeetingTagQueryVariables>
    query={MeetingTagDocument}
    {...props}
  />
);

export type MeetingTagProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingTagQuery,
  MeetingTagQueryVariables
> &
  TChildProps;
export function withMeetingTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingTagQuery,
    MeetingTagQueryVariables,
    MeetingTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingTagQuery,
    MeetingTagQueryVariables,
    MeetingTagProps<TChildProps>
  >(MeetingTagDocument, {
    alias: "withMeetingTag",
    ...operationOptions
  });
}

export function useMeetingTagQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingTagQuery,
    MeetingTagQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MeetingTagQuery, MeetingTagQueryVariables>(
    MeetingTagDocument,
    baseOptions
  );
}
export type MeetingTagQueryHookResult = ReturnType<typeof useMeetingTagQuery>;
export type MeetingTagQueryResult = ApolloReactCommon.QueryResult<
  MeetingTagQuery,
  MeetingTagQueryVariables
>;
export const MeetingTagsDocument = gql`
  query MeetingTags($data: QueryMeetingTagsInput) {
    meetingTags(data: $data) {
      total
      items {
        ...MeetingTag
      }
    }
  }
  ${MeetingTagFragmentDoc}
`;
export type MeetingTagsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingTagsQuery,
    MeetingTagsQueryVariables
  >,
  "query"
>;

export const MeetingTagsComponent = (props: MeetingTagsComponentProps) => (
  <ApolloReactComponents.Query<MeetingTagsQuery, MeetingTagsQueryVariables>
    query={MeetingTagsDocument}
    {...props}
  />
);

export type MeetingTagsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MeetingTagsQuery,
  MeetingTagsQueryVariables
> &
  TChildProps;
export function withMeetingTags<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MeetingTagsQuery,
    MeetingTagsQueryVariables,
    MeetingTagsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MeetingTagsQuery,
    MeetingTagsQueryVariables,
    MeetingTagsProps<TChildProps>
  >(MeetingTagsDocument, {
    alias: "withMeetingTags",
    ...operationOptions
  });
}

export function useMeetingTagsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MeetingTagsQuery,
    MeetingTagsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MeetingTagsQuery, MeetingTagsQueryVariables>(
    MeetingTagsDocument,
    baseOptions
  );
}
export type MeetingTagsQueryHookResult = ReturnType<typeof useMeetingTagsQuery>;
export type MeetingTagsQueryResult = ApolloReactCommon.QueryResult<
  MeetingTagsQuery,
  MeetingTagsQueryVariables
>;
export const CreateMeetingTagDocument = gql`
  mutation CreateMeetingTag($data: CreateMeetingTagInput!) {
    createMeetingTag(data: $data) {
      ...MeetingTag
    }
  }
  ${MeetingTagFragmentDoc}
`;
export type CreateMeetingTagMutationFn = ApolloReactCommon.MutationFunction<
  CreateMeetingTagMutation,
  CreateMeetingTagMutationVariables
>;
export type CreateMeetingTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables
  >,
  "mutation"
>;

export const CreateMeetingTagComponent = (
  props: CreateMeetingTagComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables
  >
    mutation={CreateMeetingTagDocument}
    {...props}
  />
);

export type CreateMeetingTagProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateMeetingTagMutation,
  CreateMeetingTagMutationVariables
> &
  TChildProps;
export function withCreateMeetingTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables,
    CreateMeetingTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables,
    CreateMeetingTagProps<TChildProps>
  >(CreateMeetingTagDocument, {
    alias: "withCreateMeetingTag",
    ...operationOptions
  });
}

export function useCreateMeetingTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMeetingTagMutation,
    CreateMeetingTagMutationVariables
  >(CreateMeetingTagDocument, baseOptions);
}
export type CreateMeetingTagMutationHookResult = ReturnType<
  typeof useCreateMeetingTagMutation
>;
export type CreateMeetingTagMutationResult = ApolloReactCommon.MutationResult<
  CreateMeetingTagMutation
>;
export type CreateMeetingTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMeetingTagMutation,
  CreateMeetingTagMutationVariables
>;
export const UpdateMeetingTagDocument = gql`
  mutation UpdateMeetingTag($id: String!, $data: CreateMeetingTagInput!) {
    updateMeetingTag(id: $id, data: $data) {
      ...MeetingTag
    }
  }
  ${MeetingTagFragmentDoc}
`;
export type UpdateMeetingTagMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMeetingTagMutation,
  UpdateMeetingTagMutationVariables
>;
export type UpdateMeetingTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables
  >,
  "mutation"
>;

export const UpdateMeetingTagComponent = (
  props: UpdateMeetingTagComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables
  >
    mutation={UpdateMeetingTagDocument}
    {...props}
  />
);

export type UpdateMeetingTagProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateMeetingTagMutation,
  UpdateMeetingTagMutationVariables
> &
  TChildProps;
export function withUpdateMeetingTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables,
    UpdateMeetingTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables,
    UpdateMeetingTagProps<TChildProps>
  >(UpdateMeetingTagDocument, {
    alias: "withUpdateMeetingTag",
    ...operationOptions
  });
}

export function useUpdateMeetingTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateMeetingTagMutation,
    UpdateMeetingTagMutationVariables
  >(UpdateMeetingTagDocument, baseOptions);
}
export type UpdateMeetingTagMutationHookResult = ReturnType<
  typeof useUpdateMeetingTagMutation
>;
export type UpdateMeetingTagMutationResult = ApolloReactCommon.MutationResult<
  UpdateMeetingTagMutation
>;
export type UpdateMeetingTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMeetingTagMutation,
  UpdateMeetingTagMutationVariables
>;
export const DeleteMeetingTagDocument = gql`
  mutation DeleteMeetingTag($id: String!) {
    deleteMeetingTag(id: $id)
  }
`;
export type DeleteMeetingTagMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMeetingTagMutation,
  DeleteMeetingTagMutationVariables
>;
export type DeleteMeetingTagComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables
  >,
  "mutation"
>;

export const DeleteMeetingTagComponent = (
  props: DeleteMeetingTagComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables
  >
    mutation={DeleteMeetingTagDocument}
    {...props}
  />
);

export type DeleteMeetingTagProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteMeetingTagMutation,
  DeleteMeetingTagMutationVariables
> &
  TChildProps;
export function withDeleteMeetingTag<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables,
    DeleteMeetingTagProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables,
    DeleteMeetingTagProps<TChildProps>
  >(DeleteMeetingTagDocument, {
    alias: "withDeleteMeetingTag",
    ...operationOptions
  });
}

export function useDeleteMeetingTagMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteMeetingTagMutation,
    DeleteMeetingTagMutationVariables
  >(DeleteMeetingTagDocument, baseOptions);
}
export type DeleteMeetingTagMutationHookResult = ReturnType<
  typeof useDeleteMeetingTagMutation
>;
export type DeleteMeetingTagMutationResult = ApolloReactCommon.MutationResult<
  DeleteMeetingTagMutation
>;
export type DeleteMeetingTagMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMeetingTagMutation,
  DeleteMeetingTagMutationVariables
>;
export const ReportColumnDocument = gql`
  query ReportColumn($id: String!) {
    reportColumn(id: $id) {
      ...ReportColumn
    }
  }
  ${ReportColumnFragmentDoc}
`;
export type ReportColumnComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportColumnQuery,
    ReportColumnQueryVariables
  >,
  "query"
> &
  (
    | { variables: ReportColumnQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ReportColumnComponent = (props: ReportColumnComponentProps) => (
  <ApolloReactComponents.Query<ReportColumnQuery, ReportColumnQueryVariables>
    query={ReportColumnDocument}
    {...props}
  />
);

export type ReportColumnProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportColumnQuery,
  ReportColumnQueryVariables
> &
  TChildProps;
export function withReportColumn<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportColumnQuery,
    ReportColumnQueryVariables,
    ReportColumnProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportColumnQuery,
    ReportColumnQueryVariables,
    ReportColumnProps<TChildProps>
  >(ReportColumnDocument, {
    alias: "withReportColumn",
    ...operationOptions
  });
}

export function useReportColumnQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportColumnQuery,
    ReportColumnQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportColumnQuery,
    ReportColumnQueryVariables
  >(ReportColumnDocument, baseOptions);
}
export type ReportColumnQueryHookResult = ReturnType<
  typeof useReportColumnQuery
>;
export type ReportColumnQueryResult = ApolloReactCommon.QueryResult<
  ReportColumnQuery,
  ReportColumnQueryVariables
>;
export const ReportColumnsDocument = gql`
  query ReportColumns($reportId: String!) {
    reportColumns(reportId: $reportId) {
      ...ReportColumn
    }
  }
  ${ReportColumnFragmentDoc}
`;
export type ReportColumnsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportColumnsQuery,
    ReportColumnsQueryVariables
  >,
  "query"
> &
  (
    | { variables: ReportColumnsQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ReportColumnsComponent = (props: ReportColumnsComponentProps) => (
  <ApolloReactComponents.Query<ReportColumnsQuery, ReportColumnsQueryVariables>
    query={ReportColumnsDocument}
    {...props}
  />
);

export type ReportColumnsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportColumnsQuery,
  ReportColumnsQueryVariables
> &
  TChildProps;
export function withReportColumns<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportColumnsQuery,
    ReportColumnsQueryVariables,
    ReportColumnsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportColumnsQuery,
    ReportColumnsQueryVariables,
    ReportColumnsProps<TChildProps>
  >(ReportColumnsDocument, {
    alias: "withReportColumns",
    ...operationOptions
  });
}

export function useReportColumnsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportColumnsQuery,
    ReportColumnsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportColumnsQuery,
    ReportColumnsQueryVariables
  >(ReportColumnsDocument, baseOptions);
}
export type ReportColumnsQueryHookResult = ReturnType<
  typeof useReportColumnsQuery
>;
export type ReportColumnsQueryResult = ApolloReactCommon.QueryResult<
  ReportColumnsQuery,
  ReportColumnsQueryVariables
>;
export const CreateReportColumnDocument = gql`
  mutation CreateReportColumn($data: CreateReportColumnInput!) {
    createReportColumn(data: $data) {
      ...ReportColumn
    }
  }
  ${ReportColumnFragmentDoc}
`;
export type CreateReportColumnMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportColumnMutation,
  CreateReportColumnMutationVariables
>;
export type CreateReportColumnComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables
  >,
  "mutation"
>;

export const CreateReportColumnComponent = (
  props: CreateReportColumnComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables
  >
    mutation={CreateReportColumnDocument}
    {...props}
  />
);

export type CreateReportColumnProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateReportColumnMutation,
  CreateReportColumnMutationVariables
> &
  TChildProps;
export function withCreateReportColumn<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables,
    CreateReportColumnProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables,
    CreateReportColumnProps<TChildProps>
  >(CreateReportColumnDocument, {
    alias: "withCreateReportColumn",
    ...operationOptions
  });
}

export function useCreateReportColumnMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateReportColumnMutation,
    CreateReportColumnMutationVariables
  >(CreateReportColumnDocument, baseOptions);
}
export type CreateReportColumnMutationHookResult = ReturnType<
  typeof useCreateReportColumnMutation
>;
export type CreateReportColumnMutationResult = ApolloReactCommon.MutationResult<
  CreateReportColumnMutation
>;
export type CreateReportColumnMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportColumnMutation,
  CreateReportColumnMutationVariables
>;
export const UpdateReportColumnDocument = gql`
  mutation UpdateReportColumn($id: String!, $data: UpdateReportColumnInput!) {
    updateReportColumn(id: $id, data: $data) {
      ...ReportColumn
    }
  }
  ${ReportColumnFragmentDoc}
`;
export type UpdateReportColumnMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReportColumnMutation,
  UpdateReportColumnMutationVariables
>;
export type UpdateReportColumnComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables
  >,
  "mutation"
>;

export const UpdateReportColumnComponent = (
  props: UpdateReportColumnComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables
  >
    mutation={UpdateReportColumnDocument}
    {...props}
  />
);

export type UpdateReportColumnProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateReportColumnMutation,
  UpdateReportColumnMutationVariables
> &
  TChildProps;
export function withUpdateReportColumn<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables,
    UpdateReportColumnProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables,
    UpdateReportColumnProps<TChildProps>
  >(UpdateReportColumnDocument, {
    alias: "withUpdateReportColumn",
    ...operationOptions
  });
}

export function useUpdateReportColumnMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReportColumnMutation,
    UpdateReportColumnMutationVariables
  >(UpdateReportColumnDocument, baseOptions);
}
export type UpdateReportColumnMutationHookResult = ReturnType<
  typeof useUpdateReportColumnMutation
>;
export type UpdateReportColumnMutationResult = ApolloReactCommon.MutationResult<
  UpdateReportColumnMutation
>;
export type UpdateReportColumnMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReportColumnMutation,
  UpdateReportColumnMutationVariables
>;
export const ReorderReportColumnDocument = gql`
  mutation ReorderReportColumn($id: String!, $position: Float!) {
    reorderReportColumn(id: $id, position: $position) {
      ...ReportColumn
    }
  }
  ${ReportColumnFragmentDoc}
`;
export type ReorderReportColumnMutationFn = ApolloReactCommon.MutationFunction<
  ReorderReportColumnMutation,
  ReorderReportColumnMutationVariables
>;
export type ReorderReportColumnComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables
  >,
  "mutation"
>;

export const ReorderReportColumnComponent = (
  props: ReorderReportColumnComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables
  >
    mutation={ReorderReportColumnDocument}
    {...props}
  />
);

export type ReorderReportColumnProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  ReorderReportColumnMutation,
  ReorderReportColumnMutationVariables
> &
  TChildProps;
export function withReorderReportColumn<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables,
    ReorderReportColumnProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables,
    ReorderReportColumnProps<TChildProps>
  >(ReorderReportColumnDocument, {
    alias: "withReorderReportColumn",
    ...operationOptions
  });
}

export function useReorderReportColumnMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ReorderReportColumnMutation,
    ReorderReportColumnMutationVariables
  >(ReorderReportColumnDocument, baseOptions);
}
export type ReorderReportColumnMutationHookResult = ReturnType<
  typeof useReorderReportColumnMutation
>;
export type ReorderReportColumnMutationResult = ApolloReactCommon.MutationResult<
  ReorderReportColumnMutation
>;
export type ReorderReportColumnMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReorderReportColumnMutation,
  ReorderReportColumnMutationVariables
>;
export const DeleteReportColumnDocument = gql`
  mutation DeleteReportColumn($id: String!) {
    deleteReportColumn(id: $id)
  }
`;
export type DeleteReportColumnMutationFn = ApolloReactCommon.MutationFunction<
  DeleteReportColumnMutation,
  DeleteReportColumnMutationVariables
>;
export type DeleteReportColumnComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables
  >,
  "mutation"
>;

export const DeleteReportColumnComponent = (
  props: DeleteReportColumnComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables
  >
    mutation={DeleteReportColumnDocument}
    {...props}
  />
);

export type DeleteReportColumnProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteReportColumnMutation,
  DeleteReportColumnMutationVariables
> &
  TChildProps;
export function withDeleteReportColumn<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables,
    DeleteReportColumnProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables,
    DeleteReportColumnProps<TChildProps>
  >(DeleteReportColumnDocument, {
    alias: "withDeleteReportColumn",
    ...operationOptions
  });
}

export function useDeleteReportColumnMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteReportColumnMutation,
    DeleteReportColumnMutationVariables
  >(DeleteReportColumnDocument, baseOptions);
}
export type DeleteReportColumnMutationHookResult = ReturnType<
  typeof useDeleteReportColumnMutation
>;
export type DeleteReportColumnMutationResult = ApolloReactCommon.MutationResult<
  DeleteReportColumnMutation
>;
export type DeleteReportColumnMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteReportColumnMutation,
  DeleteReportColumnMutationVariables
>;
export const ReportFilterDocument = gql`
  query ReportFilter($id: String!) {
    reportFilter(id: $id) {
      ...ReportFilter
    }
  }
  ${ReportFilterFragmentDoc}
`;
export type ReportFilterComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportFilterQuery,
    ReportFilterQueryVariables
  >,
  "query"
> &
  (
    | { variables: ReportFilterQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ReportFilterComponent = (props: ReportFilterComponentProps) => (
  <ApolloReactComponents.Query<ReportFilterQuery, ReportFilterQueryVariables>
    query={ReportFilterDocument}
    {...props}
  />
);

export type ReportFilterProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportFilterQuery,
  ReportFilterQueryVariables
> &
  TChildProps;
export function withReportFilter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportFilterQuery,
    ReportFilterQueryVariables,
    ReportFilterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportFilterQuery,
    ReportFilterQueryVariables,
    ReportFilterProps<TChildProps>
  >(ReportFilterDocument, {
    alias: "withReportFilter",
    ...operationOptions
  });
}

export function useReportFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportFilterQuery,
    ReportFilterQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportFilterQuery,
    ReportFilterQueryVariables
  >(ReportFilterDocument, baseOptions);
}
export type ReportFilterQueryHookResult = ReturnType<
  typeof useReportFilterQuery
>;
export type ReportFilterQueryResult = ApolloReactCommon.QueryResult<
  ReportFilterQuery,
  ReportFilterQueryVariables
>;
export const ReportFiltersDocument = gql`
  query ReportFilters($reportId: String!) {
    reportFilters(reportId: $reportId) {
      ...ReportFilter
    }
  }
  ${ReportFilterFragmentDoc}
`;
export type ReportFiltersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportFiltersQuery,
    ReportFiltersQueryVariables
  >,
  "query"
> &
  (
    | { variables: ReportFiltersQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ReportFiltersComponent = (props: ReportFiltersComponentProps) => (
  <ApolloReactComponents.Query<ReportFiltersQuery, ReportFiltersQueryVariables>
    query={ReportFiltersDocument}
    {...props}
  />
);

export type ReportFiltersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportFiltersQuery,
  ReportFiltersQueryVariables
> &
  TChildProps;
export function withReportFilters<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportFiltersQuery,
    ReportFiltersQueryVariables,
    ReportFiltersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportFiltersQuery,
    ReportFiltersQueryVariables,
    ReportFiltersProps<TChildProps>
  >(ReportFiltersDocument, {
    alias: "withReportFilters",
    ...operationOptions
  });
}

export function useReportFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportFiltersQuery,
    ReportFiltersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportFiltersQuery,
    ReportFiltersQueryVariables
  >(ReportFiltersDocument, baseOptions);
}
export type ReportFiltersQueryHookResult = ReturnType<
  typeof useReportFiltersQuery
>;
export type ReportFiltersQueryResult = ApolloReactCommon.QueryResult<
  ReportFiltersQuery,
  ReportFiltersQueryVariables
>;
export const CreateReportFilterDocument = gql`
  mutation CreateReportFilter($data: CreateReportFilterInput!) {
    createReportFilter(data: $data) {
      ...ReportFilter
    }
  }
  ${ReportFilterFragmentDoc}
`;
export type CreateReportFilterMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportFilterMutation,
  CreateReportFilterMutationVariables
>;
export type CreateReportFilterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables
  >,
  "mutation"
>;

export const CreateReportFilterComponent = (
  props: CreateReportFilterComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables
  >
    mutation={CreateReportFilterDocument}
    {...props}
  />
);

export type CreateReportFilterProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateReportFilterMutation,
  CreateReportFilterMutationVariables
> &
  TChildProps;
export function withCreateReportFilter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables,
    CreateReportFilterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables,
    CreateReportFilterProps<TChildProps>
  >(CreateReportFilterDocument, {
    alias: "withCreateReportFilter",
    ...operationOptions
  });
}

export function useCreateReportFilterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateReportFilterMutation,
    CreateReportFilterMutationVariables
  >(CreateReportFilterDocument, baseOptions);
}
export type CreateReportFilterMutationHookResult = ReturnType<
  typeof useCreateReportFilterMutation
>;
export type CreateReportFilterMutationResult = ApolloReactCommon.MutationResult<
  CreateReportFilterMutation
>;
export type CreateReportFilterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportFilterMutation,
  CreateReportFilterMutationVariables
>;
export const UpdateReportFilterDocument = gql`
  mutation UpdateReportFilter($id: String!, $data: FilterEntry!) {
    updateReportFilter(id: $id, data: $data) {
      ...ReportFilter
    }
  }
  ${ReportFilterFragmentDoc}
`;
export type UpdateReportFilterMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReportFilterMutation,
  UpdateReportFilterMutationVariables
>;
export type UpdateReportFilterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables
  >,
  "mutation"
>;

export const UpdateReportFilterComponent = (
  props: UpdateReportFilterComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables
  >
    mutation={UpdateReportFilterDocument}
    {...props}
  />
);

export type UpdateReportFilterProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateReportFilterMutation,
  UpdateReportFilterMutationVariables
> &
  TChildProps;
export function withUpdateReportFilter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables,
    UpdateReportFilterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables,
    UpdateReportFilterProps<TChildProps>
  >(UpdateReportFilterDocument, {
    alias: "withUpdateReportFilter",
    ...operationOptions
  });
}

export function useUpdateReportFilterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReportFilterMutation,
    UpdateReportFilterMutationVariables
  >(UpdateReportFilterDocument, baseOptions);
}
export type UpdateReportFilterMutationHookResult = ReturnType<
  typeof useUpdateReportFilterMutation
>;
export type UpdateReportFilterMutationResult = ApolloReactCommon.MutationResult<
  UpdateReportFilterMutation
>;
export type UpdateReportFilterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReportFilterMutation,
  UpdateReportFilterMutationVariables
>;
export const DeleteReportFilterDocument = gql`
  mutation DeleteReportFilter($id: String!) {
    deleteReportFilter(id: $id)
  }
`;
export type DeleteReportFilterMutationFn = ApolloReactCommon.MutationFunction<
  DeleteReportFilterMutation,
  DeleteReportFilterMutationVariables
>;
export type DeleteReportFilterComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables
  >,
  "mutation"
>;

export const DeleteReportFilterComponent = (
  props: DeleteReportFilterComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables
  >
    mutation={DeleteReportFilterDocument}
    {...props}
  />
);

export type DeleteReportFilterProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteReportFilterMutation,
  DeleteReportFilterMutationVariables
> &
  TChildProps;
export function withDeleteReportFilter<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables,
    DeleteReportFilterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables,
    DeleteReportFilterProps<TChildProps>
  >(DeleteReportFilterDocument, {
    alias: "withDeleteReportFilter",
    ...operationOptions
  });
}

export function useDeleteReportFilterMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteReportFilterMutation,
    DeleteReportFilterMutationVariables
  >(DeleteReportFilterDocument, baseOptions);
}
export type DeleteReportFilterMutationHookResult = ReturnType<
  typeof useDeleteReportFilterMutation
>;
export type DeleteReportFilterMutationResult = ApolloReactCommon.MutationResult<
  DeleteReportFilterMutation
>;
export type DeleteReportFilterMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteReportFilterMutation,
  DeleteReportFilterMutationVariables
>;
export const ReportGroupDocument = gql`
  query ReportGroup($id: String!) {
    reportGroup(id: $id) {
      ...ReportGroup
    }
  }
  ${ReportGroupFragmentDoc}
`;
export type ReportGroupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportGroupQuery,
    ReportGroupQueryVariables
  >,
  "query"
> &
  (
    | { variables: ReportGroupQueryVariables; skip?: boolean }
    | { skip: boolean });

export const ReportGroupComponent = (props: ReportGroupComponentProps) => (
  <ApolloReactComponents.Query<ReportGroupQuery, ReportGroupQueryVariables>
    query={ReportGroupDocument}
    {...props}
  />
);

export type ReportGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportGroupQuery,
  ReportGroupQueryVariables
> &
  TChildProps;
export function withReportGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportGroupQuery,
    ReportGroupQueryVariables,
    ReportGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportGroupQuery,
    ReportGroupQueryVariables,
    ReportGroupProps<TChildProps>
  >(ReportGroupDocument, {
    alias: "withReportGroup",
    ...operationOptions
  });
}

export function useReportGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportGroupQuery,
    ReportGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ReportGroupQuery, ReportGroupQueryVariables>(
    ReportGroupDocument,
    baseOptions
  );
}
export type ReportGroupQueryHookResult = ReturnType<typeof useReportGroupQuery>;
export type ReportGroupQueryResult = ApolloReactCommon.QueryResult<
  ReportGroupQuery,
  ReportGroupQueryVariables
>;
export const ReportGroupsDocument = gql`
  query ReportGroups($data: QueryReportGroupsInput) {
    reportGroups(data: $data) {
      items {
        ...ReportGroup
      }
      total
    }
  }
  ${ReportGroupFragmentDoc}
`;
export type ReportGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportGroupsQuery,
    ReportGroupsQueryVariables
  >,
  "query"
>;

export const ReportGroupsComponent = (props: ReportGroupsComponentProps) => (
  <ApolloReactComponents.Query<ReportGroupsQuery, ReportGroupsQueryVariables>
    query={ReportGroupsDocument}
    {...props}
  />
);

export type ReportGroupsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ReportGroupsQuery,
  ReportGroupsQueryVariables
> &
  TChildProps;
export function withReportGroups<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportGroupsQuery,
    ReportGroupsQueryVariables,
    ReportGroupsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportGroupsQuery,
    ReportGroupsQueryVariables,
    ReportGroupsProps<TChildProps>
  >(ReportGroupsDocument, {
    alias: "withReportGroups",
    ...operationOptions
  });
}

export function useReportGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportGroupsQuery,
    ReportGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportGroupsQuery,
    ReportGroupsQueryVariables
  >(ReportGroupsDocument, baseOptions);
}
export type ReportGroupsQueryHookResult = ReturnType<
  typeof useReportGroupsQuery
>;
export type ReportGroupsQueryResult = ApolloReactCommon.QueryResult<
  ReportGroupsQuery,
  ReportGroupsQueryVariables
>;
export const CreateReportGroupDocument = gql`
  mutation CreateReportGroup($data: CreateReportGroupInput!) {
    createReportGroup(data: $data) {
      ...ReportGroup
    }
  }
  ${ReportGroupFragmentDoc}
`;
export type CreateReportGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateReportGroupMutation,
  CreateReportGroupMutationVariables
>;
export type CreateReportGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables
  >,
  "mutation"
>;

export const CreateReportGroupComponent = (
  props: CreateReportGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables
  >
    mutation={CreateReportGroupDocument}
    {...props}
  />
);

export type CreateReportGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  CreateReportGroupMutation,
  CreateReportGroupMutationVariables
> &
  TChildProps;
export function withCreateReportGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables,
    CreateReportGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables,
    CreateReportGroupProps<TChildProps>
  >(CreateReportGroupDocument, {
    alias: "withCreateReportGroup",
    ...operationOptions
  });
}

export function useCreateReportGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateReportGroupMutation,
    CreateReportGroupMutationVariables
  >(CreateReportGroupDocument, baseOptions);
}
export type CreateReportGroupMutationHookResult = ReturnType<
  typeof useCreateReportGroupMutation
>;
export type CreateReportGroupMutationResult = ApolloReactCommon.MutationResult<
  CreateReportGroupMutation
>;
export type CreateReportGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateReportGroupMutation,
  CreateReportGroupMutationVariables
>;
export const UpdateReportGroupDocument = gql`
  mutation UpdateReportGroup($id: String!, $data: CreateReportGroupInput!) {
    updateReportGroup(id: $id, data: $data) {
      ...ReportGroup
    }
  }
  ${ReportGroupFragmentDoc}
`;
export type UpdateReportGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateReportGroupMutation,
  UpdateReportGroupMutationVariables
>;
export type UpdateReportGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables
  >,
  "mutation"
>;

export const UpdateReportGroupComponent = (
  props: UpdateReportGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables
  >
    mutation={UpdateReportGroupDocument}
    {...props}
  />
);

export type UpdateReportGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  UpdateReportGroupMutation,
  UpdateReportGroupMutationVariables
> &
  TChildProps;
export function withUpdateReportGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables,
    UpdateReportGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables,
    UpdateReportGroupProps<TChildProps>
  >(UpdateReportGroupDocument, {
    alias: "withUpdateReportGroup",
    ...operationOptions
  });
}

export function useUpdateReportGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateReportGroupMutation,
    UpdateReportGroupMutationVariables
  >(UpdateReportGroupDocument, baseOptions);
}
export type UpdateReportGroupMutationHookResult = ReturnType<
  typeof useUpdateReportGroupMutation
>;
export type UpdateReportGroupMutationResult = ApolloReactCommon.MutationResult<
  UpdateReportGroupMutation
>;
export type UpdateReportGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateReportGroupMutation,
  UpdateReportGroupMutationVariables
>;
export const DeleteReportGroupDocument = gql`
  mutation DeleteReportGroup($id: String!) {
    deleteReportGroup(id: $id)
  }
`;
export type DeleteReportGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteReportGroupMutation,
  DeleteReportGroupMutationVariables
>;
export type DeleteReportGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables
  >,
  "mutation"
>;

export const DeleteReportGroupComponent = (
  props: DeleteReportGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables
  >
    mutation={DeleteReportGroupDocument}
    {...props}
  />
);

export type DeleteReportGroupProps<
  TChildProps = {}
> = ApolloReactHoc.MutateProps<
  DeleteReportGroupMutation,
  DeleteReportGroupMutationVariables
> &
  TChildProps;
export function withDeleteReportGroup<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables,
    DeleteReportGroupProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables,
    DeleteReportGroupProps<TChildProps>
  >(DeleteReportGroupDocument, {
    alias: "withDeleteReportGroup",
    ...operationOptions
  });
}

export function useDeleteReportGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteReportGroupMutation,
    DeleteReportGroupMutationVariables
  >(DeleteReportGroupDocument, baseOptions);
}
export type DeleteReportGroupMutationHookResult = ReturnType<
  typeof useDeleteReportGroupMutation
>;
export type DeleteReportGroupMutationResult = ApolloReactCommon.MutationResult<
  DeleteReportGroupMutation
>;
export type DeleteReportGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteReportGroupMutation,
  DeleteReportGroupMutationVariables
>;
export const ReportStaticFieldKeysDocument = gql`
  query ReportStaticFieldKeys {
    reportStaticFieldKeys
  }
`;
export type ReportStaticFieldKeysComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables
  >,
  "query"
>;

export const ReportStaticFieldKeysComponent = (
  props: ReportStaticFieldKeysComponentProps
) => (
  <ApolloReactComponents.Query<
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables
  >
    query={ReportStaticFieldKeysDocument}
    {...props}
  />
);

export type ReportStaticFieldKeysProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  ReportStaticFieldKeysQuery,
  ReportStaticFieldKeysQueryVariables
> &
  TChildProps;
export function withReportStaticFieldKeys<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables,
    ReportStaticFieldKeysProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables,
    ReportStaticFieldKeysProps<TChildProps>
  >(ReportStaticFieldKeysDocument, {
    alias: "withReportStaticFieldKeys",
    ...operationOptions
  });
}

export function useReportStaticFieldKeysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportStaticFieldKeysQuery,
    ReportStaticFieldKeysQueryVariables
  >(ReportStaticFieldKeysDocument, baseOptions);
}
export type ReportStaticFieldKeysQueryHookResult = ReturnType<
  typeof useReportStaticFieldKeysQuery
>;
export type ReportStaticFieldKeysQueryResult = ApolloReactCommon.QueryResult<
  ReportStaticFieldKeysQuery,
  ReportStaticFieldKeysQueryVariables
>;
export const ReportEntityMetadataDocument = gql`
  query ReportEntityMetadata($entityType: String) {
    reportEntityMetadata(entityType: $entityType) {
      entityName
      customFields {
        ...FieldSchema
      }
      fields {
        ...ReportStaticRelationOrFieldData
      }
    }
  }
  ${FieldSchemaFragmentDoc}
  ${ReportStaticRelationOrFieldDataFragmentDoc}
`;
export type ReportEntityMetadataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables
  >,
  "query"
>;

export const ReportEntityMetadataComponent = (
  props: ReportEntityMetadataComponentProps
) => (
  <ApolloReactComponents.Query<
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables
  >
    query={ReportEntityMetadataDocument}
    {...props}
  />
);

export type ReportEntityMetadataProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  ReportEntityMetadataQuery,
  ReportEntityMetadataQueryVariables
> &
  TChildProps;
export function withReportEntityMetadata<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables,
    ReportEntityMetadataProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables,
    ReportEntityMetadataProps<TChildProps>
  >(ReportEntityMetadataDocument, {
    alias: "withReportEntityMetadata",
    ...operationOptions
  });
}

export function useReportEntityMetadataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportEntityMetadataQuery,
    ReportEntityMetadataQueryVariables
  >(ReportEntityMetadataDocument, baseOptions);
}
export type ReportEntityMetadataQueryHookResult = ReturnType<
  typeof useReportEntityMetadataQuery
>;
export type ReportEntityMetadataQueryResult = ApolloReactCommon.QueryResult<
  ReportEntityMetadataQuery,
  ReportEntityMetadataQueryVariables
>;
export const ReportHeaderVariableNamesDocument = gql`
  query ReportHeaderVariableNames {
    reportHeaderVariableNames
  }
`;
export type ReportHeaderVariableNamesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables
  >,
  "query"
>;

export const ReportHeaderVariableNamesComponent = (
  props: ReportHeaderVariableNamesComponentProps
) => (
  <ApolloReactComponents.Query<
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables
  >
    query={ReportHeaderVariableNamesDocument}
    {...props}
  />
);

export type ReportHeaderVariableNamesProps<
  TChildProps = {}
> = ApolloReactHoc.DataProps<
  ReportHeaderVariableNamesQuery,
  ReportHeaderVariableNamesQueryVariables
> &
  TChildProps;
export function withReportHeaderVariableNames<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables,
    ReportHeaderVariableNamesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables,
    ReportHeaderVariableNamesProps<TChildProps>
  >(ReportHeaderVariableNamesDocument, {
    alias: "withReportHeaderVariableNames",
    ...operationOptions
  });
}

export function useReportHeaderVariableNamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ReportHeaderVariableNamesQuery,
    ReportHeaderVariableNamesQueryVariables
  >(ReportHeaderVariableNamesDocument, baseOptions);
}
export type ReportHeaderVariableNamesQueryHookResult = ReturnType<
  typeof useReportHeaderVariableNamesQuery
>;
export type ReportHeaderVariableNamesQueryResult = ApolloReactCommon.QueryResult<
  ReportHeaderVariableNamesQuery,
  ReportHeaderVariableNamesQueryVariables
>;
