import React, { useState, useEffect } from 'react'
import ReactQuill, { Quill } from "react-quill";
import { Limiter } from '../../../lib/helpers/helpers';

interface TextEditorProps {
  value?: string
  onChange?: (value: string) => void
}

const limiter = new Limiter(750)

export const TextEditor: React.FC<TextEditorProps> = props => {
  const [value, setValue] = useState<string>(props.value ? props.value : "")

  useEffect(() => {
    if (props.value && props.value !== value)
      setValue(props.value)
  }, [props.value])

  return (
    <div className="text-editor">
      <ReactQuill
        value={value}
        onChange={val => {
          setValue(val)
          if (props.onChange) 
            limiter.execute(() => props.onChange!(val))
        }}
      />
    </div>
  );
}