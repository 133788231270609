import React, { useState, useEffect } from "react";
import { Button } from "antd";
import axios from "axios"

interface AudioPlayerProps {
  src: string
  corsUrl: string
}

export const AudioPlayer: React.FC<AudioPlayerProps> = props => {
  const [blobUrl, setBlobUrl] = useState<string | null>(null)

  const getFile = async () => {
    const result = await axios.get(props.src, {
      withCredentials: true,
      responseType: 'arraybuffer'
    }).catch(err => console.log(err))
    if (!result) return
    const blob = new Blob([result.data], { type: 'audio/mpeg-3' })
    console.log('GETTING BLOB')
    setBlobUrl(URL.createObjectURL(blob))
  }

  useEffect(() => {
    getFile()
  }, [])

  return (
    <div
      style={{
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={() => { if (!blobUrl) getFile() }}
    >
      <audio
        controls
        src={blobUrl ? blobUrl : ''}
        datatype='audio/mpeg-3'
        controlsList='nodownload'
      />
      <a target="__blank" href={props.src}>
        <Button
          icon='cloud-download'
          type='ghost'
          shape='circle'
          style={{
            marginLeft: 20
          }}
        />
      </a>
    </div>
  );
}