import React from "react";
import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { useReportHeaderVariableNamesQuery } from "../../../../lib/codegen";
import { UseState } from "../../../../lib/ts/types";

interface IProps {
  visibleState: UseState<boolean>
}

export const HeaderVarsList: React.FC<IProps> = props => {
  const headerVarNamesQuery = useReportHeaderVariableNamesQuery()
  const varNames = headerVarNamesQuery.data && headerVarNamesQuery.data.reportHeaderVariableNames ? headerVarNamesQuery.data.reportHeaderVariableNames : []

  const bracesStyle: React.CSSProperties = {
    color: '#a5a5a5'
  }

  return(
    <Modal 
      visible={props.visibleState[0]}
      onOk={() => props.visibleState[1](false)}
      onCancel={() => props.visibleState[1](false)}
      okText='Done'
      cancelButtonProps={{ style: { display: 'none' } }}
      title="Header Variables"
    >
      {varNames.map(name => <div>
        <span style={bracesStyle}>{'{{'}</span>
        <span>{name}</span>
        <span style={bracesStyle}>{'}}'}</span>
      </div>)}
    </Modal>
  );
}