import React from "react";
import { Spin } from "antd";
import { Title } from "../title/Title";

interface IProps {
  title?: React.ReactNode
}

export const CenteredSpinner: React.FC<IProps> = props => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    }}>
      <Spin />
      <Title size="xs" style={{ 
        marginLeft: 10,
        marginBottom: 4
      }}>Loading</Title>
    </div>
  );
}