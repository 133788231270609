import { EntityFieldType } from "../codegen";
import { FieldType } from "../ts/entityFields";

export const getIconFromType = (type: EntityFieldType | FieldType): string => {
  switch (type) {
    case EntityFieldType["Text"]: return "font-size";
    case EntityFieldType["Richtext"]: return "align-left";
    case EntityFieldType["Email"]: return "mail";
    case EntityFieldType["Phone"]: return "phone";
    case EntityFieldType["Number"]: return "number";
    case EntityFieldType["Boolean"]: return "check-square";
    case EntityFieldType["Date"]: return "calendar";
    case EntityFieldType["Selection"]: return "unordered-list";
    case EntityFieldType["Relation"]: return "deployment-unit";
    default: return "";
  }
}