import React from "react";
import { EnhancedFormFieldsObject } from "../../../../components/enhancedForm/withEnhancedForm";
import { EntityFieldType, EntityRelationType, ClientContact } from "../../../../lib/codegen";

export function clientContactFields(defaultClientId: string | null, clientContact?: ClientContact): EnhancedFormFieldsObject {
  const getValue = (key: keyof ClientContact) => clientContact ? clientContact[key] : undefined;
  return [
    {
      title: "Details",
      fields: [
        [
          {
            key: "firstName",
            name: "First Name",
            type: EntityFieldType["Text"],
            rules: [{ required: true }],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
          },
          {
            key: "lastName",
            name: "Last Name",
            type: EntityFieldType["Text"],
            rules: [{ required: true }],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
          }
        ],
        [
          {
            key: "phoneOffice",
            name: "Office Phone",
            type: EntityFieldType["Phone"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
          },
          {
            key: "phoneCell",
            name: "Cell Phone",
            type: EntityFieldType["Phone"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
          }
        ],
        {
          key: "title",
          name: "Title",
          type: EntityFieldType["Text"],
          getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
        },
        {
          key: "email",
          name: "Email",
          type: EntityFieldType["Email"],
          getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact)
        },
      ]
    },
    {
      title: "Relations",
      fields: [
        [
          {
            key: "clientId",
            name: "Client",
            type: EntityFieldType["Relation"],
            entityRelationType: EntityRelationType["Client"],
            getValueFromProps: () => {
              const client = getValue("client") as ClientContact["client"];
              if (client) return client.id
              else if (defaultClientId) return defaultClientId
            }
          },
          {
            key: "partnerId",
            name: "Partner",
            type: EntityFieldType["Relation"],
            entityRelationType: EntityRelationType["Partner"],
            getValueFromProps: () => {
              const partner = getValue("partner") as ClientContact["partner"];
              if (partner) return partner.id
            },
          }
        ]
      ]
    },
    {
      title: "Address",
      fields: [
        [
          {
            key: "street",
            name: "Street",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          },
          {
            key: "suite",
            name: "Suite",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          }
        ],
        [
          {
            key: "city",
            name: "City",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          },
          {
            key: "state",
            name: "State",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          }
        ],
        [
          {
            key: "zipcode",
            name: "Zip Code",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          },
          {
            key: "country",
            name: "Country",
            type: EntityFieldType["Text"],
            getValueFromProps: ({ field }) => getValue(field.key as keyof ClientContact),
          }
        ]
      ]
    },
  ]
}