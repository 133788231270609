import React from "react";
import "./ColumnList.scss"
import { IReportColumn, ReportColumnItem } from "../reportColumn/ReportColumnItem";
import { Droppable } from "react-beautiful-dnd";
import { Spin } from "antd";

interface IProps {
  columns: IReportColumn[]
  droppableId: string;
  loading?: boolean
}

export const ColumnList: React.FC<IProps> = props => {
  if (!props.droppableId) return <span />
  return (
    <Droppable droppableId={props.droppableId} type="report-column">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} className="report-column">
          {props.loading && <Spin style={{ marginLeft: 'calc(50% - (25px / 2))' }} />}
          {!props.loading && props.columns.map(column => <ReportColumnItem key={column.id} {...column} />)}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}