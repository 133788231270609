import React, { useContext, useState } from "react";
import { GenerateReportComponent } from "../../../../../lib/codegen";
import { ReportContext } from "../../../helpers/ReportContext";
import { FieldValue } from "../../../../../lib/ts/entityFields";
import ReactDataGrid from 'react-data-grid';
import { Button, Icon } from "antd";
import { Link } from "react-router-dom";
import { getEnvVars } from "../../../../../lib/helpers/getEnvVars";

type IReportResultColumn = {
  key: string,  // Column ID
  name: string  // Column name
}
type IReportResultRow = { [key: string]: FieldValue }

interface IReportResult {
  columns: IReportResultColumn[]
  rows: IReportResultRow[]
}

export const getReportDownloadUrl = (type: "report" | "reportGroup", id: string) => {
  const envVars = getEnvVars();
  const urlSection = type === "report" ? "exportReport" : "exportReportGroup";
  if (process.env.NODE_ENV === "development") {
    return `${envVars.baseUrl}:3001/${urlSection}/${id}`
  } else {
    return `${envVars.baseUrl}/${urlSection}/${id}`
  }
}

export const ReportResult: React.FC = () => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const { activeReportIdState } = useContext(ReportContext);

  function mapColumns(columns: IReportResultColumn[]): ReactDataGrid.Column<IReportResultRow>[] {
    return [
      {
        key: "_actions",
        name: "",
        getRowMetaData: (row) => ({ ...row }),
        width: 31
      },
      ...columns.map((column): ReactDataGrid.Column<IReportResultRow> => ({
        ...column,
        resizable: true,
        getRowMetaData: (row) => ({ ...row, ...column }),
        width: 150
      }))
    ] as ReactDataGrid.Column<IReportResultRow>[]
  }

  function mapRows(rows: IReportResultRow[]): IReportResultRow[] {
    let rowArray: IReportResultRow[] = [];
    rows.forEach((row, i) => {
      let mappedRow = row;
      // @ts-ignore
      mappedRow['_actions'] = <Link to={`/meetings/{}/${rows[i]['_meetingId']}`} title="View Meeting"><Icon type="eye" /></Link>;
      rowArray.push(mappedRow)
    })
    return rowArray
  }

  return (
    <GenerateReportComponent variables={{ id: activeReportIdState[0]! }} skip={!activeReportIdState[0]}>
      {({ error, loading, data, refetch }) => {
        const reportResult = JSON.parse(data && data.generateReport ? data.generateReport : '{"columns":[],"rows":[]}') as IReportResult;
        const { columns, rows } = reportResult;
        return (
          <div className="report-result-wrapper">
            <div>
              <Button
                onClick={() => { setRefreshLoading(true); refetch().then(() => setRefreshLoading(false)) }}
                loading={loading || refreshLoading}
                style={{ marginRight: 10 }}
              >
                Refresh
              </Button>
              <a target="_blank" href={activeReportIdState[0] ? getReportDownloadUrl("report", activeReportIdState[0]) : ""}>
                <Button>Download</Button>
              </a>
            </div>
            <br />
            <ReactDataGrid
              columns={mapColumns(columns)}
              rowGetter={i => mapRows(rows)[i]}

              rowsCount={rows.length}
              minColumnWidth={80}
              enableCellSelect={true}
            />
          </div>
        )
      }}
    </GenerateReportComponent>
  );
}