import React, { useEffect } from "react";
import "./NewMeeting.scss";
import { PopForm } from "../../../components/popform/PopForm";
import { FormDataObject } from "../../../components/enhancedForm/withEnhancedForm";
import { CreateMeetingComponent, CreateMeetingMutation, CreateMeetingMutationVariables, MeComponent, FieldGroupsComponent, FieldGroupType, MeetingFieldsComponent } from "../../../lib/codegen";
import { MutationFunction } from "react-apollo";
import { MeetingFields } from "../meetingFields/MeetingFields";
import moment from "moment"

interface IProps {
  visible: boolean
  onClose: () => void
  refetch: () => Promise<any>
}

export const NewMeeting: React.FC<IProps> = props => {
  const handleSubmit = (formData: FormDataObject, createMeeting: MutationFunction<CreateMeetingMutation, CreateMeetingMutationVariables>) => {
    if (formData['time']) formData['time'] = moment(formData['time'] as any).format("h:mm a")
    return createMeeting({
      variables: { data: formData as CreateMeetingMutationVariables["data"] }
    }).then(() => { props.refetch(); })
  }
  
  let refetchFn: () => Promise<any> = () => new Promise((resolve, reject) => { console.log("useless func call") })

  useEffect(() => {
    if (props.visible) {
      setTimeout(() => {
        refetchFn();
      }, 500);
    }
  }, [props.visible])

  return (
    <CreateMeetingComponent>
      {createMeeting => (
        <MeetingFields>
          {(getFields, refetch) => {
            refetchFn = refetch;
            return (
              <PopForm
                title="New Meeting"
                formFields={getFields()}
                onClose={props.onClose}
                onSubmit={formData => handleSubmit(formData, createMeeting)}
                visible={props.visible}
                modalProps={{
                  width: 900
                }}
              />
            )
          }}
        </MeetingFields>
      )}
    </CreateMeetingComponent>
  );
}

