import React, { useState } from "react";
import { UseState } from "../../../lib/ts/types";
import { Drawer, Button, message, Icon } from "antd";
import EnhancedForm from "../../../components/enhancedForm/EnhancedForm";
import { useContactFields } from "../contactFields/useContactFields";
import { useContactQuery, useUpdateContactMutation, Contact } from "../../../lib/codegen";
import { getFieldEntriesFromFormData } from "../../../lib/helpers/getFieldEntriesFromFormData";

interface IProps {
  contactId: string
  visibleState: UseState<boolean>
}

export const ContactDrawerDisplay = (props: { contact: Contact, style?: React.CSSProperties, className?: string }) => {
  const visibleState = useState(false);
  return <>
    <ContactDrawer visibleState={visibleState} contactId={props.contact.id} />
    <a onClick={() => visibleState[1](true)} style={props.style} className={props.className}>{props.contact.name}</a>
  </>
}

export const ContactDrawer: React.FC<IProps> = props => {
  // state
  const [isVisible, setIsVisible] = props.visibleState;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  // queries
  const [updateContact] = useUpdateContactMutation();
  const contactQuery = useContactQuery({ variables: { id: props.contactId } });
  const contact = contactQuery.data && contactQuery.data.contact ? contactQuery.data.contact : undefined;
  // hooks
  const fields = useContactFields(contact)
  return (
    <EnhancedForm
      formFields={fields}
      isEditing={isEditing}
    >
      {form => (
        <Drawer
          title={
            <div style={{
              display: "flex",
              alignItems: "center",
              marginRight: 30
            }}>
              <span>Contact</span>
              <div style={{
                marginLeft: "auto",
                fontSize: 14
              }}>
                {!isEditing && <Button onClick={() => setIsEditing(true)} type="ghost"><Icon type="edit"/> Edit</Button>}
                {isEditing && <span>
                  {!updateLoading && <Button style={{ marginRight: 10 }} onClick={() => { setIsEditing(false) }} type="danger"><Icon type="close" /> Cancel</Button>}
                  <Button
                    loading={updateLoading}
                    type="primary"
                    onClick={() => {
                      if (form.formValidate().length === 0 && contact) {
                        updateContact({ variables: { id: contact.id, data: getFieldEntriesFromFormData(form.formData()) as any } })
                          .then(() => {
                            message.success("Contact Saved")
                            setUpdateLoading(false)
                            setIsEditing(false);
                          })
                          .finally(() => {
                            setUpdateLoading(false);
                            setIsEditing(false);
                          })
                        setUpdateLoading(true)
                      }
                    }}
                  >
                    {!updateLoading && <Icon type="save"/>} Save
                  </Button>
                </span>}
              </div>
            </div>
          }
          visible={isVisible}
          onClose={() => setIsVisible(false)}
          width={600}
        >
          {form.renderForm()}
        </Drawer>
      )}
    </EnhancedForm>
  );
}