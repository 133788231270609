export interface EnvVars {
  baseUrl: string
  port: number
  graphqlUrl: string
  serverURL: string
}

export function getEnvVars(): EnvVars {
  if (process.env.NODE_ENV === "production") return ({
    baseUrl: "https://meetingtracker.online",
    port: 80,
    graphqlUrl: "https://meetingtracker.online/graphql",
    serverURL: "https://meetingtracker.online"
  })
  return ({
    baseUrl: "http://localhost",
    port: 3000,
    graphqlUrl: "http://localhost:3001/graphql",
    serverURL: "http://localhost:3001"
  })
}