import React, { useState, useContext, useEffect } from "react";
import "./ReportList.scss";
import { ReportGroup, Report, useReorderReportMutation } from "../../../../lib/codegen";
import { Button, Dropdown, Menu, Icon, Popconfirm } from "antd";
import { ReportCopyForm } from "../../reportCopyForm/ReportCopyForm";
import { ReportPopForm } from "../../reportPopForm/ReportPopForm";
import { ReportContext } from "../../helpers/ReportContext";
import { DragDropContext, DropResult, ResponderProvided, Droppable, Draggable } from "react-beautiful-dnd";
import { UseState } from "../../../../lib/ts/types";
import { getReportDownloadUrl } from "../reportPane/reportResult/ReportResult";

interface IProps {
  reportGroup: ReportGroup
}

const RowItem = (props: {
  report: Report,
  createVisibleState: UseState<boolean>,
  updateIdState: UseState<string | null>,
  moveReportReportGroupIdState: UseState<string | null>,
  copyIdState: UseState<string | null>
}) => {
  const { deleteReport, reportGroupsQuery, activeReportIdState } = useContext(ReportContext);
  const { id, position, name } = props.report;
  // menu
  const menu = (<Menu>
    <Menu.Item>
      <a target="_blank" href={getReportDownloadUrl("report", id)}>
        <Icon type="cloud-download" style={{ color: "#0a78ff", marginRight: 8 }} /> Download
      </a>
    </Menu.Item>
    <Menu.Item onClick={() => props.updateIdState[1](id)}>
      <Icon type="setting" style={{ color: "#0a78ff" }} /> Settings
    </Menu.Item>
    <Menu.Item onClick={() => props.copyIdState[1](id)}>
      <Icon type="copy" style={{ color: "#0a78ff" }} /> Copy
    </Menu.Item>
    {/* <Menu.Item onClick={() => props.moveReportReportGroupIdState[1](id)}>
      <Icon type="swap" style={{ color: "#0a78ff" }} /> Move To
    </Menu.Item> */}
    <Menu.Item>
      <Popconfirm
        onConfirm={() => deleteReport({ variables: { id } }).then(() => reportGroupsQuery.refetch())}
        title={`Delete Report ${name}?`}
        okText="Delete"
        okButtonProps={{ type: "danger" }}
        cancelButtonProps={{ type: "primary" }}
      >
        <Icon type="delete" style={{ color: "#f5222d", marginRight: 8 }} /> Delete
      </Popconfirm>
    </Menu.Item>

  </Menu>)
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={position}
      type="report"
    >
      {(provided, snapshot) => (
        <div className={`report-row ${id === activeReportIdState[0] ? "active" : ""}`} ref={provided.innerRef} {...provided.draggableProps}>
          <span>
            <span {...provided.dragHandleProps}><Icon type="drag" style={{ color: "#0000004a", marginRight: 8 }} /></span>
            <span className="name" onClick={() => activeReportIdState[1](id)}>{name}</span>
          </span>
          <Dropdown overlay={menu} placement="bottomLeft">
            <Button icon="more" size="small" shape="circle" style={{ float: "right", background: "none" }} />
          </Dropdown>
        </div>
      )}
    </Draggable>
  )
}

export const ReportList: React.FC<IProps> = props => {
  // context
  const { activeReportIdState, reportGroupsQuery } = useContext(ReportContext);
  // state
  const [createVisible, setCreateVisible] = useState(false);
  const [copyId, setCopyId] = useState<string | null>(null);
  const [moveReportReportGroupId, setMoveReportReportGroupId] = useState<string | null>(null);
  const [updateId, setUpdateId] = useState<string | null>(null);
  const [reports, setReports] = useState<Report[]>(props.reportGroup.reports ? props.reportGroup.reports : []);
  // mutation
  const [reorderReport] = useReorderReportMutation()

  // refresh state
  useEffect(() => {
    setReports(props.reportGroup.reports ? props.reportGroup.reports : [])
  }, [props.reportGroup])

  // drag and drop
  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;

    const targetReport = reports.find(report => report.id === result.draggableId)
    if (!targetReport) return;

    const oldPos = targetReport.position;
    const newPos = result.destination.index;

    // graphql mutation
    reorderReport({ variables: { id: targetReport.id, position: newPos } }).then(() => {
      reportGroupsQuery.refetch();
    })

    // set temp state
    setReports(reports.map(report => {
      if (report.id === targetReport.id) return ({
        ...report,
        position: newPos
      });

      // POSITION INCREASE
      if (newPos > oldPos) {
        if (report.position <= newPos && report.position >= oldPos) {
          return ({
            ...report,
            position: report.position - 1
          });
        };
      }

      // POSITION DECREASE
      if (newPos < oldPos) {
        if (report.position >= newPos && report.position <= oldPos) {
          return ({
            ...report,
            position: report.position + 1
          });
        };
      }

      // DEFAULT CATCH
      return report;
    }))
  }
  return (
    <DragDropContext onDragEnd={(result, provided) => onDragEnd(result, provided)}>
      <div className="report-list">
        <ReportPopForm
          visibleState={[createVisible, setCreateVisible]}
          createData={{ reportGroupId: props.reportGroup.id }}
        />
        <ReportCopyForm
          copyFromReportId={copyId}
          isOpen={typeof copyId === "string"}
          handleClose={() => setCopyId(null)}
        />
        <ReportPopForm
          visibleState={[updateId ? true : false, (visibleState) => setUpdateId(visibleState && activeReportIdState[0] ? activeReportIdState[0] : null)]}
          reportId={updateId ? updateId : undefined}
        />
        <Droppable droppableId={props.reportGroup.id} type="report-group-reports-list">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="report-group-reports-list">
              {reports.sort((a, b) => a.position - b.position).map(report =>
                <RowItem
                  report={report}
                  key={report.id}
                  createVisibleState={[createVisible, setCreateVisible]}
                  copyIdState={[copyId, setCopyId]}
                  moveReportReportGroupIdState={[moveReportReportGroupId, setMoveReportReportGroupId]}
                  updateIdState={[updateId, setUpdateId]}
                />
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Button
          className="btn-no-outline report-list-add-btn"
          icon="plus"
          size="small"
          type="ghost"
          onClick={() => setCreateVisible(true)}
        >
          Report
        </Button>
      </div>
    </DragDropContext>
  );
}