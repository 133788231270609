import { VtigerFieldType } from "../../ts/vtiger";
import { EntityFieldType } from "../../codegen";

// Unused Vtiger Fields
// owner = "owner",
// metricpicklist = "metricpicklist",
// reference = "reference",

export function getFieldTypeFromVtigerFieldType(vtigerField: VtigerFieldType): EntityFieldType | void {
  if (vtigerField === "string" || vtigerField === "text" || vtigerField === "url" || vtigerField === "autogenerated" || vtigerField === "multicurrency") return EntityFieldType["Text"]
  else if (vtigerField === "richtext") return EntityFieldType["Richtext"]
  else if (vtigerField === "email") return EntityFieldType["Email"]
  else if (vtigerField === "phone") return EntityFieldType["Phone"]
  else if (vtigerField === "datetime" || vtigerField === "date") return EntityFieldType["Date"]
  else if (vtigerField === "picklist") return EntityFieldType["Selection"]
  else if (vtigerField === "integer") return EntityFieldType["Number"]
  else if (vtigerField === "boolean") return EntityFieldType["Boolean"]
}