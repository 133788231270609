import React, { useState } from "react";
import { MeetingStatus, EntityRelationType, LeaderboardDateRange, EntityFieldType } from "../../../lib/codegen";
import { Pie } from "react-chartjs-2";
import { Card } from "antd";
import { Title } from "../../../components/display/title/Title";
import { LeaderboardDateRangeSelect } from "../userLeaderboard/UserLeaderboard";
import { CampaignRelationField } from "../../../components/enhancedForm/relationFields";
import { CampaignStatusPieChart } from "./CampaignStatusPieChart";
import { CamapginMeetingBarChart } from "./CamapginMeetingBarChart";

type MeetingFragment = {
  id: string,
  date?: Date,
  status?: MeetingStatus
}

interface IProps {

}

export const MeetingStatusPieChart: React.FC<IProps> = props => {
  const [campaignIdFilter, setCampaignIdFilter] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<LeaderboardDateRange>(LeaderboardDateRange["Week"])
  
  return (
    <Card
      title={<Title>Meetings Status</Title>}
      actions={[
        <>
          <LeaderboardDateRangeSelect selectState={[dateRange, setDateRange]} />
          <CampaignRelationField
            field={{
              entityRelationType: EntityRelationType["Campaign"],
              key: "campaign",
              type: EntityFieldType["Relation"],
              style: {
                minWidth: 200,
                marginLeft: 10
              }
            }}
            value={campaignIdFilter ? campaignIdFilter : undefined}
            onChange={id => setCampaignIdFilter(id)}
            placeholder="Campaign Filter"
          />
        </>
      ]}
    >
      {<CamapginMeetingBarChart campaignId={campaignIdFilter ? campaignIdFilter : undefined} dateRange={dateRange}/>}
    </Card>
  );
}