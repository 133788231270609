import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import { Line } from "react-chartjs-2";

interface CallsOverTimeChartProps {
  dateTimes: string[]
  className?: string
  style?: React.CSSProperties
}

const minFromMidnight = (date: Moment | string): number => moment(date).local().diff(moment(date).startOf('day').local(), 'minutes')

const getChartData = (dateTimes: string[]): { data: { x: number, y: number }[], yMax: number } => {
  const xInterval = 60 // minutes
  let data: { x: number, y: number }[] = [], yMax = 0

  // create x intervals
  for (let i = 0; i < 60 * 24; i += xInterval)
    data.push({ x: i, y: 0 })

  // tally data to interval slots
  for (let i = 0; i < dateTimes.length; i++) {
    const minFromMid = minFromMidnight(dateTimes[i])
    const xIntervalSlot = Math.floor(minFromMid / xInterval) * xInterval
    data = data.map((dataPoint) => {
      if (dataPoint.x === xIntervalSlot)
        return ({ x: dataPoint.x, y: dataPoint.y + 1 })
      else
        return dataPoint
    })
  }

  // define start and end trim points
  let trimStart: number = -1
  let trimEnd: number = -1
  let trimmedData: { x: number, y: number }[] = []
  for (let i = 0; i < data.length; i++) {
    // current item has data and start point not set, then start one point before
    if (data[i].y > 0 && trimStart === -1)
      trimStart = data[i === 0 ? 0 : i - 1].x
    // always set last item, and end one point afterd
    if (data[i].y > 0)
      trimEnd = data[i === data.length - 1 ? i : i + 1].x

    // set yMax
    if (data[i].y > yMax)
      yMax = data[i].y
  }

  // trim data 
  for (let i = 0; i < data.length; i++)
    if (data[i].x >= trimStart && data[i].x <= trimEnd)
      trimmedData.push(data[i])

  return { data: trimmedData, yMax }
}

export const CallsOverTimeChart: React.FC<CallsOverTimeChartProps> = props => {
  const [chartData, setChartData] = useState(getChartData(props.dateTimes))

  useEffect(() => setChartData(getChartData(props.dateTimes)), [props.dateTimes])

  return (
    <div className={props.className} style={props.style}>
      <Line
        data={{
          labels: chartData.data.map(({ x }) => moment().startOf('day').add(x, 'minutes').format('h:mm a')),
          datasets: [{
            label: 'Calls',
            data: chartData.data,
          }]
        }}
        options={{
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                maxTicksLimit: 10,
                max: chartData.yMax % 5 === 0 ? chartData.yMax + 5 : chartData.yMax + (5 - (chartData.yMax % 5))
              }
            }]
          }
        }}
      />
    </div>
  );
}