import React, { useState } from "react";
import "./FiltersList.scss";
import { ReportEntityMetadataComponent, FilterEntry, EntityRelationType, ReportStaticRelationOrFieldData, EntityField, Omit } from "../../lib/codegen";
import { FilterOption, FilterOptionProps, getFilterOptionName } from "./filterOption/FilterOption";
import { getFilterId } from "./helpers/getFilterId";
import { FilterItem } from "./filterItem/FilterItem";
import { Input } from "antd";

export type FiltersState = [(FilterEntry & { id?: string })[], (values: (FilterEntry & { id?: string })[]) => void];

interface FiltersListProps {
  entityType?: EntityRelationType
  filterState: FiltersState
  useFilterEntryId?: boolean
  handleFilterCreate: (filterEntry: (FilterEntry & { id?: string })) => void
  handleFilterUpdate: (filterEntry: (FilterEntry & { id?: string })) => void
  handleFilterDelete: (filterEntry: (FilterEntry & { id?: string })) => void
}

export function FiltersList(props: FiltersListProps) {
  const [searchValue, setSearchValue] = useState<string | null>(null);

  function getFilterOptions(entityName: EntityRelationType, staticFields: ReportStaticRelationOrFieldData[], relationName: string | null, customFields?: EntityField[]) {
    let filterOptions: FilterOptionProps[] = [];

    function addFilterOption(id: string, args: Omit<Omit<FilterOptionProps, "optionTaken">, "id">) {
      const optionTaken = (): boolean => {
        const entries = props.filterState[0];
        for (let i = 0; i < entries.length; i++)
          if (getFilterId(entries[i]) === id) return true;
        return false;
      }
      filterOptions.push({ id, optionTaken: optionTaken(), ...args })
    }

    staticFields.forEach(fieldOrRelation => {
      if (fieldOrRelation.type === "FIELD") addFilterOption(fieldOrRelation.staticFieldKey!, {
        relationName,
        filterState: props.filterState,
        entityName,
        fieldMetadata: fieldOrRelation,
        handleFilterCreate: props.handleFilterCreate
      })
      else if (fieldOrRelation.type === "RELATION")
        filterOptions.push(...getFilterOptions(fieldOrRelation.entityName as any, fieldOrRelation.fields!, fieldOrRelation.name, fieldOrRelation.customFields ? fieldOrRelation.customFields : undefined))
    })
    
    if (customFields) customFields.forEach(customField => addFilterOption(customField.id, {
      relationName,
      filterState: props.filterState,
      entityName,
      entityField: customField,
      handleFilterCreate: props.handleFilterCreate
    }))

    return filterOptions;
  }

  function renderFilterEntries(filterOptions: FilterOptionProps[]): React.ReactNode[] {
    return props.filterState[0].map((filterEntry, i) =>
      <FilterItem
        key={filterEntry.id ? filterEntry.id : i}
        useFilterEntryId={props.useFilterEntryId}
        filterEntry={filterEntry}
        filterOptions={filterOptions}
        handleFilterUpdate={props.handleFilterUpdate}
        handleFilterDelete={props.handleFilterDelete}
      />
    )
  }

  return (
    <ReportEntityMetadataComponent>
      {metadataQuery => {
        const data = metadataQuery.data && metadataQuery.data.reportEntityMetadata ? metadataQuery.data.reportEntityMetadata : undefined;
        const filterOptions = getFilterOptions(data ? data.entityName : "Meeting" as any, data && data.fields ? data.fields : [], null, data && data.customFields ? data.customFields : []);
        return (
          <div>
            <div className="filter-entries-lsit">
              {renderFilterEntries(filterOptions)}
            </div>
            <Input.Search
              className="filter-options-search"
              placeholder="Search Filters"
              value={searchValue ? searchValue : undefined}
              onChange={e => setSearchValue(e.target.value.trim() === '' ? null : e.target.value)}
            />
            <div className="filter-options-list">
              {!metadataQuery.loading &&
                filterOptions
                  .filter(filterOption => {
                    if (!searchValue) return filterOption
                    else if (getFilterOptionName(filterOption).toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) return filterOption
                  })
                  .map(filterOption => <FilterOption key={filterOption.id} {...filterOption} useFilterEntryId={props.useFilterEntryId} />)
              }
            </div>
          </div>
        )
      }}
    </ReportEntityMetadataComponent>
  );
}