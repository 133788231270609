import React, { useContext, useState, useEffect } from "react";
import { DataPane, ActiveIdState } from "../../../components/dataPane/DataPane";
import { withRouter, RouteComponentProps } from "react-router";
import { ReportContext } from "../helpers/ReportContext";
import { ReportGroup, QueryReportGroupsInput } from "../../../lib/codegen";
import { getColumnSearchProps } from "../../../components/tableFilter/TableFilterDropdown";
import { Button, Icon, Dropdown, Menu, Popconfirm } from "antd";
import { ReportGroupPopForm } from "../reportGroupPopForm/ReportGroupPopForm";
import { getActionColumn } from "../../../components/tableFilter/getActionColumn";
import { ReportList } from "./reportList/ReportList";
import { ReportPane } from "./reportPane/ReportPane";
import { getReportDownloadUrl } from "./reportPane/reportResult/ReportResult";

interface IProps extends RouteComponentProps<{ reportGroupId?: string, reportId?: string }> {
  pageSize: number
}

const _ReportTablePane: React.FC<IProps> = props => {
  // constants
  const { pageSize } = props;
  // context
  const {
    reportGroupsQuery,
    deleteReportGroup,
    activeReportGroupIdState,
    activeReportIdState
  } = useContext(ReportContext);
  // state
  const [createGroupVisible, setCreateGroupVisible] = useState(false);
  const [updateGroupId, setUpdateGroupId] = useState<string | null>(null);
  const [loadedReportId, setLoadedReportId] = useState<string | null>(null);

  // filter data
  function setQueryData(data: QueryReportGroupsInput) {
    reportGroupsQuery.refetch({ data: { ...reportGroupsQuery.variables.data, ...data } })
  }

  return (
    <>
      <ReportGroupPopForm
        visibleState={[createGroupVisible, setCreateGroupVisible]}
      />
      <ReportGroupPopForm
        visibleState={[updateGroupId ? true : false, (visibleState) => setUpdateGroupId(visibleState && activeReportGroupIdState[0] ? activeReportGroupIdState[0] : null)]}
        reportGroupId={updateGroupId ? updateGroupId : undefined}
      />
      <DataPane<ReportGroup>
        activeIdState={activeReportGroupIdState}
        title='Reports'
        // @ts-ignore
        data={reportGroupsQuery.data && reportGroupsQuery.data.reportGroups ? reportGroupsQuery.data.reportGroups : ({ items: [], total: 0 })}
        loading={reportGroupsQuery.loading}
        footerActions={
          <>
            <Button
              onClick={() => setCreateGroupVisible(true)}
            >
              <Icon type="plus" /> Group
            </Button>
          </>
        }
        tableProps={{
          handlePageChange: offset => setQueryData({ offset }),
          widthPercent: 35,
          initialPage: () => 0,
          pageSize,
          renderExpandedRow: item => <ReportList reportGroup={item} />,
          columns: [
            {
              key: "name",
              dataIndex: "name",
              title: "Group",
              ...getColumnSearchProps({
                dataIndex: "Name",
                onSearch: value => setQueryData({ name: value, offset: 0 }),
                isFiltered: () => reportGroupsQuery.variables && reportGroupsQuery.variables.data && reportGroupsQuery.variables.data.name ? true : false,
                onReset: () => setQueryData({ name: undefined, offset: 0 })
              }),
              render: (text, item) => text
            },
            {
              key: "actions",
              title: <span style={{ fontWeight: 100, marginLeft: 5 }}>Actions</span>,
              width: 95,
              render: (text, reportGroup) => <div>
                <Dropdown overlay={
                  <Menu>
                    <Menu.Item>
                      <a target="_blank" href={getReportDownloadUrl("reportGroup", reportGroup.id)}>
                        <Icon type="cloud-download" style={{ color: "#0a78ff", marginRight: 8 }} /> Download
                      </a>
                    </Menu.Item>
                    <Menu.Item onClick={() => setUpdateGroupId(reportGroup.id)}>
                      <Icon type="setting" style={{ color: "#0a78ff" }} /> Settings
                    </Menu.Item>
                    <Menu.Item>
                      <Popconfirm
                        onConfirm={() => deleteReportGroup({ variables: { id: reportGroup.id } }).then(() => reportGroupsQuery.refetch())}
                        title={`Delete ${reportGroup.name}?`}
                        okText="Delete"
                        okButtonProps={{ type: "danger" }}
                        cancelButtonProps={{ type: "primary" }}
                      >
                        <Icon type="delete" style={{ color: "#f5222d", marginRight: 8 }} /> Delete
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                } placement="bottomLeft">
                  <Button icon="more" size="small" shape="circle" style={{ float: "right", background: "none" }} />
                </Dropdown>
              </div>
            }
          ]
        }}
        renderPane={args => { 
          const activeReportId = activeReportIdState[0];
          if (activeReportId && loadedReportId === activeReportId) {
            return <ReportPane />
          } else if (activeReportId && loadedReportId !== activeReportId) {
            setLoadedReportId(activeReportId);
            return <span />
          } else {
            return <div>Select a report</div>
          }
          
        }}
      />
    </>
  );
}

export const ReportTablePane = withRouter<IProps>(_ReportTablePane);