import React, { useContext } from "react";
import { Tag } from "antd";
import { MeetingStatus } from "../../../../lib/codegen";
import { AppContext } from "../../../../lib/helpers/AppContext";

interface IProps {
  status: MeetingStatus
}

export function getMeetingStatusColor(status: MeetingStatus): string {
  switch (status) {
    case MeetingStatus["Planned"]:
      return "#40a9ff"
    case MeetingStatus["Held"]:
      return "#73d13d"
    case MeetingStatus["ToBeRescheduled"]:
      return "#ffa940"
    case MeetingStatus["Cancelled"]:
      return "#ff4d4f"
  }
}

export const MeetingStatusTag: React.FC<IProps> = ({ status }) => {
  const { responsiveCalc } = useContext(AppContext);
  let name = status.replace(/_/g, " "),
    color = "blue";
  
  // Shorten name on mobile
  if (status === MeetingStatus["ToBeRescheduled"] && responsiveCalc("md", "down-include"))
    name = "Reschedule"
  
  switch (status) {
    case MeetingStatus["Planned"]:
      color = "blue"
      break
    case MeetingStatus["Held"]:
      color = "green"
      break
    case MeetingStatus["ToBeRescheduled"]:
      color = "orange"
      break
    case MeetingStatus["Cancelled"]:
      color = "red"
      break
  }

  return (
    <Tag color={color}>{name}</Tag>
  );
}