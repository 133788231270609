import React, { useState } from "react";
import "./helpers/Reports.scss";
import { ReportsComponent, CreateReportComponent, UpdateReportComponent, DeleteReportComponent, ReportComponent, CreateReportColumnComponent, UpdateReportColumnComponent, DeleteReportColumnComponent, ReportColumnsComponent, ReportColumnComponent, ReorderReportColumnComponent, useCreateReportGroupMutation, useUpdateReportGroupMutation, useDeleteReportGroupMutation, useReportGroupsQuery, useReportGroupQuery, useCopyReportMutation, useMoveReportReportGroupMutation } from "../../lib/codegen";
import { ReportTablePane } from "./ReportTablePane/ReportTablePane";
import { RouteComponentProps } from "react-router";
import { ReportContext, IReportContext } from "./helpers/ReportContext";
import { ActiveIdState } from "../../components/dataPane/DataPane";

interface IProps {

}

export const Reports: React.FC<IProps & RouteComponentProps<{ reportId?: string }>> = props => {
  const { reportId } = props.match.params;
  const activeReportIdState: ActiveIdState = [reportId ? reportId : null, (id: string | null | undefined) => props.history.push(`/admin/reports/${id ? id : ''}`)]
  const activeReportGroupIdState = useState<string | null | undefined>(null) as ActiveIdState;

  // graphql hooks
  const [createReportGroup] = useCreateReportGroupMutation();
  const [updateReportGroup] = useUpdateReportGroupMutation();
  const [deleteReportGroup] = useDeleteReportGroupMutation();
  const [copyReport] = useCopyReportMutation();
  const [moveReportReportGroup] = useMoveReportReportGroupMutation();
  const reportGroupsQuery = useReportGroupsQuery({ variables: { data: { limit: 10 }}});
  const reportGroupQuery = useReportGroupQuery({ variables: { id: activeReportGroupIdState[0]! }, skip: !activeReportGroupIdState[0] });

  // report groups filter limit
  const pageSize = 10;

  return (
    <CreateReportComponent>
      {createReport => (
        <UpdateReportComponent>
          {updateReport => (
            <DeleteReportComponent>
              {deleteReport => (
                <ReportsComponent>
                  {reportsQuery => (
                    <ReportComponent variables={{ id: reportId! }} skip={!reportId}>
                      {reportQuery => (
                        <CreateReportColumnComponent>
                          {createColumn => (
                            <UpdateReportColumnComponent>
                              {updateColumn => (
                                <ReorderReportColumnComponent>
                                  {reorderColumn => (
                                    <DeleteReportColumnComponent>
                                      {deleteColumn => (
                                        <ReportColumnsComponent variables={{ reportId: activeReportIdState[0]! }} skip={!activeReportIdState[0]}>
                                          {columnsQuery => (
                                            <ReportContext.Provider
                                              value={{
                                                activeReportIdState,
                                                activeReportGroupIdState,
                                                // report
                                                createReport,
                                                updateReport,
                                                deleteReport,
                                                copyReport,
                                                moveReportReportGroup,
                                                reportsQuery,
                                                reportQuery,
                                                // report group
                                                createReportGroup,
                                                deleteReportGroup,
                                                updateReportGroup,
                                                reportGroupsQuery,
                                                reportGroupQuery,
                                                // column
                                                createColumn,
                                                updateColumn,
                                                reorderColumn,
                                                deleteColumn,
                                                columnsQuery
                                              }}
                                            >
                                              <ReportTablePane pageSize={pageSize} />
                                            </ReportContext.Provider>
                                          )}
                                        </ReportColumnsComponent>
                                      )}
                                    </DeleteReportColumnComponent>
                                  )}
                                </ReorderReportColumnComponent>
                              )}
                            </UpdateReportColumnComponent>
                          )}
                        </CreateReportColumnComponent>
                      )}
                    </ReportComponent>
                  )}
                </ReportsComponent>
              )}
            </DeleteReportComponent>
          )}
        </UpdateReportComponent>
      )}
    </CreateReportComponent>
  );
}

