import React, { useState } from "react";
import { Card, Alert, Button, Icon } from "antd";
import { MeComponent, UserDocument, UserComponent, withUser, UserProps, User, Client, UpdateUserComponent, UpdateUserMutation, UpdateUserMutationVariables } from "../../../lib/codegen";
import { ApolloError } from "apollo-boost";
import withEnhancedForm from "../../../components/enhancedForm/withEnhancedForm";
import { EnhancedFormProps } from "../../../components/enhancedForm/withEnhancedForm";
import { userFields } from "./userFields";
import { MutationFunction } from "react-apollo";
import { EnhancedFormField } from "../../../components/enhancedForm/createFieldFromObject";
import { UpdatePassword } from "./updatePassword/UpdatePassword";

interface IProps {
  userId: string
}

type ExtendedProps = IProps & EnhancedFormProps & UserProps;

const _UserDetails: React.FC<ExtendedProps> = props => {
  const [resetPasswordVisible, setResetPasswordVisible] = useState<boolean>(false);
  const [loadedId, setLoadedId] = useState<string | null>(null);
  const { error, loading, user } = props.data!;
  const title = () => {
    if (error) return error.message
    else if (loading) return "loading"
    else if (user) return user.name
  }

  // set clients dropdown
  if (!loading && !error && user && loadedId !== user.id) {
    setLoadedId(user.id);
    userFields().flat().forEach((field: EnhancedFormField<IProps>) =>
      props.formSetFieldsValue([{ key: field.key, value: field.getValueFromProps ? field.getValueFromProps({ ...props, field }) : null }])
    )
  }

  const formHasChanges = () => {
    let hasChanges = false;
    if (loading || error || !user) return false;
    userFields().flat().forEach((field: EnhancedFormField<IProps>) => {
      let currVal = props.formData().find(({ key, value }) => key === field.key);
      if (currVal) currVal = currVal["value"] as any;
      const startVal = field.getValueFromProps ? field.getValueFromProps({ ...props, field }) : undefined
      if (startVal !== currVal) {
        if (Array.isArray(startVal) && Array.isArray(currVal)) {
          if (startVal.toString() !== currVal.toString()) hasChanges = true;
        } else {
          hasChanges = true;
        }
      }
    })
    return hasChanges;
  }

  const onSave = (mutateFnc: MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>) => {
    const formData = props.formDataObject() as any;
    const data = {
      firstName: formData["firstName"],
      lastName: formData["lastName"],
      email: formData["email"],
      role: formData["role"],
      clientsId: formData["clients"],
      vtigerAccessKey: formData["vtigerAccessKey"],
      vtigerUsername: formData["vtigerUsername"],
    };
    mutateFnc({ variables: { id: props.userId, data } })
  }

  return (
    <UpdateUserComponent>
      {updateUser => (
        <Card
          title={title()}
          extra={<span>
            <Button type="dashed" style={{ marginRight: 20 }} onClick={() => setResetPasswordVisible(true)}>
              <Icon type="reload" />
              Reset Password
            </Button>
            <Button
              type="primary"
              disabled={!formHasChanges()}
              onClick={() => onSave(updateUser)}
            >Save</Button>
          </span>}
        >
          <UpdatePassword
            isVisible={resetPasswordVisible}
            handleClose={() => setResetPasswordVisible(false)}
            userId={props.userId}
          />
          {props.renderForm()}
        </Card>
      )}
    </UpdateUserComponent>
  )
}

export const UserDetails = withUser<IProps>({
  options: props => ({
    variables: { id: props.userId }
  })
})(withEnhancedForm<ExtendedProps>({ formFields: userFields() })(_UserDetails))