import React from "react";
import { withApollo, WithApolloClient } from "react-apollo";
import { EnhancedFormProps, FormDataObject, EnhancedFormFieldsObject } from "../enhancedForm/withEnhancedForm";
import { Modal } from "antd";
import { TableCardProps } from "./TableCard";
import EnhancedForm, { EnhnacedFormArgs } from "../enhancedForm/EnhancedForm";

export interface TableCardModalParentProps<T> {
  title: string | React.ReactNode
  fields: EnhancedFormFieldsObject<ExtendedProps<T>>
  onSave: (formDataObject: FormDataObject, props: TableCardProps<T> & WithApolloClient<TableCardModalProps<T>>) => void
  getFieldsData?: (args: ExtendedProps<T>) => Promise<FormDataObject | null>
}

export interface TableCardModalProps<T> extends TableCardModalParentProps<T> {
  isVisible: boolean
  entityId: string | null
  handleClose: () => void
  getItemProps: TableCardProps<T>["getItemProps"]
  clearFormOnClose?: boolean
}

type ExtendedProps<T> = TableCardProps<T> & EnhancedFormProps<any> & WithApolloClient<TableCardModalProps<T>>;

export function _TableCardModal<T = any>(props: TableCardModalProps<T> & WithApolloClient<TableCardModalProps<T>>) {
  async function onSave(form: EnhnacedFormArgs<any>) {
    const errors = form.formValidate()
    if (errors.length !== 0) return;
    //@ts-ignore
    await props.onSave(form.formDataObject(), props)
    form.formClear()
    props.handleClose()
  }

  function onClose(form: EnhnacedFormArgs<any>) {
    props.handleClose()
    if (props.clearFormOnClose) form.formClear();
  }

  return (
    <EnhancedForm formFields={props.fields}>
      {form => (
        <Modal
          visible={props.isVisible}
          title={props.title}
          onOk={() => onSave(form)}
          onCancel={() => onClose(form)}
          okText="Save"
        >
          {form.renderForm()}
        </Modal>
      )}
    </EnhancedForm>
  )
}


export const TableCardModal = withApollo<TableCardModalProps<any>>(_TableCardModal);