import React from "react";
import { Divider } from "antd";
import { ZoomCallStats } from "./components/zoomCallStats/ZoomCallStats";
import { ZoomRecordingList } from "./components/zoomRecordingList/ZoomRecordingList";

interface ZoomUserPaneProps {
  zoomUserId?: string | null
}

export const ZoomUserPane: React.FC<ZoomUserPaneProps> = props => {
  return (
    <div>
      <ZoomCallStats zoomUserId={props.zoomUserId} />
      <Divider />
      <ZoomRecordingList zoomUserId={props.zoomUserId} />
    </div>
  );
}