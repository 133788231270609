import React, { useContext } from "react";
import { PageHeader, Button, Switch } from "antd";
import { FieldManagerContext } from "../../FieldManager";

interface IProps {
  title: string | React.ReactNode
  setNewFieldFormVisible: (value: boolean) => void
  setNewGroupFormVisible: (value: boolean) => void
}

export const HeaderBar: React.FC<IProps> = props => {
  const { positionVisibleState, disabledFieldsVisibleState } = useContext(FieldManagerContext)
  return (
    <div>
      <PageHeader
        className="page-header"
        title={props.title}
        extra={[
          <Button key="1" icon="plus" onClick={() => props.setNewFieldFormVisible(true)} style={{ marginRight: "20px" }} >Field</Button>,
          <Button key="2" icon="plus" style={{ marginRight: "20px" }} onClick={() => props.setNewGroupFormVisible(true)}>Group</Button>,
          <span key="3">View Disabled Fields: <Switch checked={disabledFieldsVisibleState[0]} onClick={checked => disabledFieldsVisibleState[1](checked)} /></span>,
          <span key="4">Show Position: <Switch checked={positionVisibleState[0]} onClick={checked => positionVisibleState[1](checked)} /></span>
        ]}
      />
    </div>
  );
}