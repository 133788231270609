import React from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { ComparisonOperator } from "../../../lib/codegen";
import { IFilterDataType } from "../helpers/normalizeEntityFieldType";
import { getComparisonsByType } from "../helpers/getComparisonsByType";

interface IProps extends SelectProps {
  type: IFilterDataType
}

export const ComparisonSelect: React.FC<IProps> = props => {
  const getName = (str: string) => str.replace(/_/g, " ");
  const options = getComparisonsByType(props.type).map(operator => 
    <Select.Option key={operator} value={operator}>{getName(operator)}</Select.Option>)
  return(
    <Select {...props} defaultValue="equals">
      {options}
    </Select>
  );
}