import React from "react";
import { Empty, Button } from "antd";
import { UseState } from "../../../lib/ts/types";

interface IProps {
  createState: UseState<boolean>
}

export const MeetingPaneEmpty: React.FC<IProps> = props => {
  return (
    <Empty
      image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
      imageStyle={{
        height: 60,
      }}
      description={
        <span>
          No Meeting Selected
        </span>
      }
    >
      <Button type="primary" onClick={() => props.createState[1](true)}>Create Meeting</Button>
    </Empty>
  );
}