import React from "react";
import { useMeetingQuery } from "../../../../lib/codegen";
import moment from "moment";
import { Title } from "../../../../components/display/title/Title";

interface IProps {
  meetingId: string | null
}

export const MeetingSummaryTitle: React.FC<IProps> = props => {
  const meetingQuery = useMeetingQuery({ variables: { id: props.meetingId! }, skip: !props.meetingId });
  const meeting = meetingQuery.data && meetingQuery.data.meeting ? meetingQuery.data.meeting : null;

  if (!meeting) return <span />

  const date: string | null = meeting.date ? moment(meeting.date).format("M-D-YY") : null;
  const time: string | null = meeting.time ? meeting.time : null;
  const timezone = meeting.timezone ? meeting.timezone : null;
  const companyName = meeting.contact && meeting.contact.company ? meeting.contact.company.name : null;

  return <Title size="sm">Meeting with {companyName} on {date} at {time} {timezone}</Title>;
}