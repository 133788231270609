export type FieldValue = string | number | boolean | string[] | Date | null;

/**
 * @deprecated `FieldType` is deprecated, use `EntityField` instead
 * 
 */
export enum FieldType {
  TEXT = "TEXT",
  RICHTEXT = "RICHTEXT",
  PHONE = "PHONE",
  BOOLEAN = "BOOLEAN",
  NUMBER = "NUMBER",
  DATE = "DATE",
  EMAIL = "EMAIL",
  SELECTION = "SELECTION",
  Relation = "RELATION"
}

export interface FieldEntry {
  fieldId: string
  value: FieldValue
}

/**
 * @deprecated `FieldData` is deprecated, use `FieldData` from codegen instead
 * 
 */
export interface FieldData {
  fieldId: string
  type: FieldType
  name: string
  value: FieldValue
  position: number
  isRequired: boolean
  isEnabled: boolean
  selectionOptions: string[]
}