import React, { useState, useContext } from "react";
import "./Meetings.scss";
import { DeleteMeetingComponent, Meeting, FilterMeetingsComponent, FilterMeetingsInput, MeetingStatus, Company, FilterEntry } from "./../../lib/codegen";
import { NewMeeting } from "./newMeeting/NewMeeting";
import { RouteComponentProps } from "react-router";
import { MeetingPane } from "./meetingPane/MeetingPane";
import { DataPane, DataPaneProps } from "../../components/dataPane/DataPane";
import { AppContext } from "../../lib/helpers/AppContext";
import { getActionColumn } from "../../components/tableFilter/getActionColumn";
import { Button, Icon, Dropdown, Menu, message } from "antd";
import { EntityFiltersProps } from "../../components/entityFilters/EntityFilters";
import moment from "moment";
import { MeetingSummary } from "./meetingSummary/MeetingSummary";
import { CompanyDrawerDisplay } from "../companies/companyDrawer/CompanyDrawer";
import { MeetingStatusTag } from "./meetingPane/meetingStatusTag/MeetingStatusTag";
import { MeetingFiltersContext } from "./meetingPane/meetingFilters/helpers/MeetingFiltersContext";
import { getFilterId } from "../../components/filtersList/helpers/getFilterId";
import { MeetingFilterGroups } from "./meetingFilterGroups/MeetingFilterGroups";
import { uuidv4 } from "../../lib/helpers/helpers";
import { FilterEntryWithId } from "./meetingFilterGroups/components/MeetingFilterGroupDrawer";

interface IProps {
  limit?: number
}

const parseQueryData = (): FilterMeetingsInputWithFilterEntryId => {
  const urlQueryData = decodeURI(window.location.hash).match(/({\S*})/g);
  let parsedData: FilterMeetingsInputWithFilterEntryId = { filters: [] };
  if (urlQueryData)
    try {
      parsedData = JSON.parse(urlQueryData[0]);
      if (!parsedData || !Array.isArray(parsedData.filters)) parsedData.filters = [];
    } catch (err) { }
  return parsedData;
}

interface FilterMeetingsInputWithFilterEntryId extends FilterMeetingsInput {
  filters: FilterEntryWithId[]
}

export const Meetings: React.FC<IProps & RouteComponentProps<{ meetingId?: string, filters: string }>> = props => {
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [isFilterGroupsVisible, setIsFilterGroupssVisible] = useState(false); 
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [shouldUpdateMeeting, setShouldUpdateMeeting] = useState(false);
  const { user } = useContext(AppContext);
  const activeIdState: DataPaneProps<Meeting>["activeIdState"] = [props.match.params.meetingId, (id: string | null | undefined) => props.history.push(`/meetings/${props.match.params.filters ? props.match.params.filters : "{}"}/${id ? id : ""}`)]
  const defaultLimit = 30;

  // Meeting Filters 
  const queryData = parseQueryData();
  const filterState: EntityFiltersProps["filterState"] = [
    queryData.filters,
    filters => {
      if (filters !== queryData.filters) setQueryData({ ...queryData, filters })
    }
  ]

  function setQueryData(data: FilterMeetingsInputWithFilterEntryId) {
    props.history.push(`/meetings/${encodeURI(JSON.stringify(data))}`)
  }

  function addFilter(filter: FilterEntryWithId) {
    if (!filter.id) filter.id = uuidv4()
    setQueryData({ ...queryData, filters: [...queryData.filters, filter], offset: 0 })
  }

  function setFilters(filters: FilterEntryWithId[]) {
    filters.map((filter): FilterEntryWithId => ({ ...filter, id: filter.id ? filter.id : uuidv4() }))
    setQueryData({ ...queryData, filters: [...filters ], offset: 0 })
  }

  function updateFilter(updatedFilter: FilterEntryWithId) {
    const updateFilterId = updatedFilter.id ? updatedFilter.id : getFilterId(updatedFilter) ;
    const updatedFilters = queryData.filters.map(filter => {
      const filterId = filter.id ? filter.id : getFilterId(filter);
      if (filterId === updateFilterId)
        return (updatedFilter)
      else
        return filter
    })
    setQueryData({ ...queryData, filters: updatedFilters, offset: 0 })
  }

  function removeFilter(deleteFilter: FilterEntryWithId) {
    const deleteFilterId = deleteFilter.id ? deleteFilter.id : getFilterId(deleteFilter);
    const updatedFilters = queryData.filters.filter(filter => {
      const filterId = filter.id ? filter.id : getFilterId(filter);
      if (filterId !== deleteFilterId)
        return filter
    })
    setQueryData({ ...queryData, filters: updatedFilters, offset: 0 })
  }

  const removeIdFromFilterEntries = (entries: FilterEntryWithId[]) => entries.map(filter => ({ ...filter, id: undefined }))

  const queryDataWithoutIds: FilterMeetingsInputWithFilterEntryId = ({ ...queryData, filters: removeIdFromFilterEntries(queryData.filters) })

  return (
    <MeetingFiltersContext.Provider value={{
      filters: queryData.filters,
      addFilter,
      setFilters,
      updateFilter,
      removeFilter
    }}>
      <DeleteMeetingComponent>
        {deleteMutation => (
          <FilterMeetingsComponent variables={{ data: { limit: props.limit ? props.limit : defaultLimit, ...queryDataWithoutIds } }} >
            {meetingsQuery => {
              return (
                <>
                  <MeetingSummary visibleState={[isSummaryVisible, setIsSummaryVisible]} meetingId={activeIdState[0] ? activeIdState[0] : null} />
                  <NewMeeting refetch={() => meetingsQuery.refetch()} onClose={() => setIsCreateVisible(false)} visible={isCreateVisible} />
                  <MeetingFilterGroups visibleState={[isFilterGroupsVisible, setIsFilterGroupssVisible]} />
                  <DataPane<Meeting>
                    title={() => <>
                      My Meetings
                        <div style={{ float: "right" }}>
                        <Dropdown
                          overlayStyle={{ display: isFiltersVisible ? 'none' : undefined }}
                          overlay={<Menu>
                            <Menu.Item id="btn-show-filters" onClick={() => setIsFiltersVisible(true)}> <Icon type="eye"/> Show Filters</Menu.Item>
                            <Menu.Item id="btn-show-filter-groups" onClick={() => setIsFilterGroupssVisible(true)}> <Icon type="folder-open"/> Filter Groups</Menu.Item>
                          </Menu>}
                        >
                          <Button icon={isFiltersVisible ? "eye-invisible" : "filter"} type={isFiltersVisible ? "primary" : "default"} onClick={() => { if (isFiltersVisible) setIsFiltersVisible(false)}}>
                            {isFiltersVisible ? "Hide Filters" : ""}
                          </Button>
                        </Dropdown>
                        {filterState[0].length > 0 && <Button style={{ marginLeft: 10 }} icon="close" type="danger" onClick={() => { setIsFiltersVisible(false); setQueryData({ filters: [], offset: 0 }); }}>
                          Clear Filters
                          </Button>}
                      </div>
                    </>}
                    onRowClick={() => { setIsEditing(false); setIsFiltersVisible(false); }}
                    loading={meetingsQuery.loading}
                    activeIdState={activeIdState}
                    data={meetingsQuery.data && meetingsQuery.data.filterMeetings ? meetingsQuery.data.filterMeetings as any : { items: [], total: 0 }}
                    renderPane={args => <MeetingPane
                      meetingId={props.match.params.meetingId}
                      filterState={filterState}
                      isFiltersVisible={isFiltersVisible}
                      isEditingState={[isEditing, setIsEditing]}
                      createState={[isCreateVisible, setIsCreateVisible]}
                      shouldUpdateState={[shouldUpdateMeeting, setShouldUpdateMeeting]}
                      updateLoadingSate={[isUpdateLoading, setIsUpdateLoading]}
                    />}
                    footerActions={<Button onClick={() => setIsCreateVisible(true)}><Icon type="plus" />Meeting</Button>}
                    paneTitle={({ activeItem }) => <>
                      {activeItem ? activeItem.name : ''}
                      {!isEditing && activeIdState[0] && <Button onClick={() => setIsSummaryVisible(true)} icon="file-text" type="ghost" style={{ marginLeft: "auto" }}>Summary</Button>}
                      {!isEditing && activeIdState[0] && <Button onClick={() => setIsEditing(true)} icon="edit" type="ghost" style={{ marginLeft: "10px" }}>Edit</Button>}
                      {isEditing && <>
                        {!isUpdateLoading && <Button type="ghost" style={{ marginLeft: "auto" }} onClick={() => setIsEditing(false)}>Cancel</Button>}
                        <Button className="save-mtg-btn" type="primary" loading={isUpdateLoading} style={{ marginLeft: isUpdateLoading ? "auto" : 10 }} onClick={() => setShouldUpdateMeeting(true)}>Save</Button>
                      </>}
                    </>}
                    tableProps={{
                      initialPage: () => {
                        const offset = meetingsQuery.variables.data && meetingsQuery.variables.data.offset ? meetingsQuery.variables.data.offset : 0;
                        const limit = meetingsQuery.variables.data && meetingsQuery.variables.data.limit ? meetingsQuery.variables.data.limit : defaultLimit;
                        return (offset / limit) + 1;
                      },
                      handlePageChange: offset => setQueryData({ ...queryData, offset }),
                      pageSize: meetingsQuery.variables.data && meetingsQuery.variables.data.limit ? meetingsQuery.variables.data.limit : defaultLimit,
                      columns: [
                        {
                          title: "Status",
                          dataIndex: "status",
                          render: (data: Meeting["status"]) => data ? <MeetingStatusTag status={data} /> : "---",
                        },
                        {
                          dataIndex: "date",
                          title: "Date",
                          render: (data: Meeting["date"], record) => data ? <><div>{moment(data).format("M/D/YY")}</div></> : "---",
                        },
                        {
                          title: "Company",
                          dataIndex: "contact",
                          key: "company",
                          render: (data: Meeting["contact"]) => data && data.company ? <div style={{ maxWidth: 250 }}><CompanyDrawerDisplay company={data.company} /></div> : "---",
                        },
                        {
                          dataIndex: "campaign",
                          title: "Campaign",
                          render: (data: Meeting["campaign"]) => data ? data.name : "---",
                        },
                        getActionColumn<Meeting>({
                          onDelete: id => deleteMutation({ variables: { id } }).then(() => {
                            meetingsQuery.refetch()
                            message.success('Meeting Deleted')
                          }),
                          onEdit: id => setIsEditing(true),
                          activeIdState
                        })
                      ]
                    }}
                  />
                </>
              )
            }}
          </FilterMeetingsComponent>
        )}
      </DeleteMeetingComponent>
    </MeetingFiltersContext.Provider>
  );
}