import * as React from "react";
import { MeetingField, MeetingFieldsComponent, FieldGroupType, UpdateMeetingFieldComponent, CreateMeetingFieldComponent, ReorderMeetingFieldComponent, CreateContactFieldComponent, UpdateContactFieldComponent, ReorderContactFieldComponent, ContactFieldsComponent, ContactField, DescribeVtigerModuleComponent, CreateCompanyFieldComponent, UpdateCompanyFieldComponent, ReorderCompanyFieldComponent, CompanyFieldsComponent, CompanyField, DeleteMeetingFieldComponent, DeleteCompanyFieldComponent, DeleteContactFieldComponent } from "../../../lib/codegen";
import { FieldManager } from "../../../components/fieldManager/FieldManager";

export const ManageCompanyFields = () => (
  <CreateCompanyFieldComponent>
    {createField => (
      <UpdateCompanyFieldComponent>
        {updateField => (
          <DeleteCompanyFieldComponent>
            {deleteField => (
              <ReorderCompanyFieldComponent>
                {reorderField => (
                  <DescribeVtigerModuleComponent variables={{ moduleType: "company" }}>
                    {(vtigerModuleQuery) => (
                      <CompanyFieldsComponent>
                        {({ loading, error, data, refetch }) => {
                          if (loading && !(data && data.companyFields)) return <div></div>
                          return (
                            <FieldManager<CompanyField>
                              title="Company Fields"
                              fieldType={FieldGroupType["CompanyField"]}
                              vtigerModule={vtigerModuleQuery.data && vtigerModuleQuery.data.describeVtigerModule ? vtigerModuleQuery.data.describeVtigerModule : undefined}
                              fields={data && data.companyFields ? data.companyFields : []}
                              handleRefreshFields={refetch}
                              handleCreateField={data => createField({ variables: { data: data as any } })}
                              handleUpdateField={(id, data) => updateField({ variables: { id, data: data as any } })}
                              handleDeleteField={id => deleteField({ variables: { id } })}
                              handleReorderField={({ field, position, targetGroup }) => reorderField({ variables: { id: field.id, position, groupId: targetGroup ? targetGroup.id : undefined } })}
                            />
                          )
                        }}
                      </CompanyFieldsComponent>
                    )}
                  </DescribeVtigerModuleComponent>
                )}
              </ReorderCompanyFieldComponent>
            )}
          </DeleteCompanyFieldComponent>
        )}
      </UpdateCompanyFieldComponent>
    )}
  </CreateCompanyFieldComponent>
)

export const ManageContactFields = () => (
  <CreateContactFieldComponent>
    {createField => (
      <UpdateContactFieldComponent>
        {updateField => (
          <DeleteContactFieldComponent>
            {deleteField => (
              <ReorderContactFieldComponent>
                {reorderField => (
                  <DescribeVtigerModuleComponent variables={{ moduleType: "contact" }}>
                    {(vtigerModuleQuery) => (
                      <ContactFieldsComponent>
                        {({ loading, error, data, refetch }) => {
                          if (loading && !(data && data.contactFields)) return <div></div>
                          return (
                            <FieldManager<ContactField>
                              title="Contact Fields"
                              fieldType={FieldGroupType["ContactField"]}
                              vtigerModule={vtigerModuleQuery.data && vtigerModuleQuery.data.describeVtigerModule ? vtigerModuleQuery.data.describeVtigerModule : undefined}
                              fields={data && data.contactFields ? data.contactFields : []}
                              handleRefreshFields={refetch}
                              handleCreateField={data => createField({ variables: { data: data as any } })}
                              handleUpdateField={(id, data) => updateField({ variables: { id, data: data as any } })}
                              handleDeleteField={id => deleteField({ variables: { id } })}
                              handleReorderField={({ field, position, targetGroup }) => reorderField({ variables: { id: field.id, position, groupId: targetGroup ? targetGroup.id : undefined } })}
                            />
                          )
                        }}
                      </ContactFieldsComponent>
                    )}
                  </DescribeVtigerModuleComponent>
                )}
              </ReorderContactFieldComponent>
            )}
          </DeleteContactFieldComponent>
        )}
      </UpdateContactFieldComponent>
    )}
  </CreateContactFieldComponent>
)

export const ManageMeetingFields = () => (
  <CreateMeetingFieldComponent>
    {createField => (
      <UpdateMeetingFieldComponent>
        {updateField => (
          <DeleteMeetingFieldComponent>
            {deleteField => (
              <ReorderMeetingFieldComponent>
                {reorderField => (
                  <MeetingFieldsComponent>
                    {({ loading, error, data, refetch }) => {
                      if (loading && !(data && data.meetingFields)) return <div></div>
                      return (
                        <FieldManager<MeetingField>
                          title="Meeting Fields"
                          fieldType={FieldGroupType["MeetingField"]}
                          fields={data && data.meetingFields ? data.meetingFields : []}
                          handleRefreshFields={refetch}
                          handleCreateField={data => createField({ variables: { data: data as any } })}
                          handleUpdateField={(id, data) => updateField({ variables: { id, data: data as any } })}
                          handleDeleteField={id => deleteField({ variables: { id } })}
                          handleReorderField={({ field, position, targetGroup }) => reorderField({ variables: { id: field.id, position, groupId: targetGroup ? targetGroup.id : undefined } })}
                        />
                      )
                    }}
                  </MeetingFieldsComponent>
                )}
              </ReorderMeetingFieldComponent>
            )}
          </DeleteMeetingFieldComponent>
        )}
      </UpdateMeetingFieldComponent>
    )}
  </CreateMeetingFieldComponent>
) 