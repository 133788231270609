import React from "react";
import { EnhancedFormFieldsObject } from "../../../../../../components/enhancedForm/withEnhancedForm";
import { ReportColumn, EntityFieldType, EntityField, ReportMetadata, EntityRelationType } from "../../../../../../lib/codegen";
import { SelectionOption } from "../../../../../../components/enhancedForm/createFieldFromObject";
import { Popover } from "antd";

export const getEntityField = ({ meetingField, contactField, companyField }: ReportColumn): EntityField | undefined => {
  if (meetingField) return meetingField
  else if (contactField) return contactField
  else if (companyField) return companyField
  else return undefined
}

const DateFormatTitle = () =>
  <Popover
    title="Date Format"
    content={<div>
      <div>Examples:</div>
      <ul>
        <li><b>hh:mm a</b>: 10:00 am</li>
        <li><b>MM-DD-YYYY</b>: 09-08-2010</li>
        <li><b>M-D-YY</b>: 9-8-10</li>
      </ul>
      <a href="https://devhints.io/moment" target="_blank">Learn More</a>
    </div>}
  >
    <a><span>Date Format</span></a>
  </Popover>

export const reportColumnFields = (args: {
  reportColumn?: ReportColumn,
  entityMetadata?: ReportMetadata
}): EnhancedFormFieldsObject<{}> => {
  const { entityMetadata, reportColumn } = args;
  return [
    {
      key: "name",
      name: "Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true }],
      getValueFromProps: () => reportColumn ? reportColumn.name : ""
    },
    {
      key: "dateFormat",
      name: <DateFormatTitle />,
      type: EntityFieldType["Text"],
      getValueFromProps: () => reportColumn && reportColumn.dateFormat ? reportColumn.dateFormat : undefined,
      isVisible: () => {
        if (reportColumn && reportColumn.dataType === "date") return true;
        return false;
      }
    },
    {
      title: "Relation",
      fields: [
        {
          key: "entityRelationFieldKey",
          type: EntityFieldType["Selection"],
          name: "Field",
          rules: [{ required: true }],
          isVisible: () => entityMetadata ? true : false,
          selectionOptions: ({ formDataObject, field }) => {
            if (!entityMetadata) return [];
            return entityMetadata.fields.filter(item => item.type === "FIELD").map((item): SelectionOption => ({
              key: item.staticFieldKey!,
              name: item.name
            }))
          },
          getValueFromProps: () => reportColumn ? reportColumn.entityRelationFieldKey : undefined
        }
      ]
    }
  ]
}