import React from "react";
import { FieldType } from "../../lib/ts/entityFields";
import { EntityFieldType, EntityRelationType } from "../../lib/codegen";
import Form, { GetFieldDecoratorOptions, FormComponentProps } from "antd/lib/form/Form";
import { Select, Input, Icon, Switch, InputNumber, DatePicker, Row, Col, TimePicker } from "antd";
import { CompanyRelationField, ContactRelationField, ClientRelationField, PartnerRelationField, ClientContactRelationField, CampaignRelationField, TradeshowRelationField, MeetingTagRelationField, UserRelationField } from "./relationFields";
import moment from "moment";
import { EnhnacedFormArgs } from "./EnhancedForm";
import Maybe from "graphql/tsutils/Maybe";
import { TextEditor } from "./textEditor/TextEditor";
import { getMomentFromTime } from "./formHelpers/getMomentFromTime";
import { OptionProps } from "antd/lib/select";
import { ParentRelationFieldProps } from "./RelationField";

export interface ColSize {
  label: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
  field: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
}

export interface SelectionOption {
  key: string
  name: string | React.ReactNode
  optionProps?: OptionProps
}

type SelectionOptionFnc<P = {}> = (props: P) => SelectionOption[];

export interface EnhancedFormField<P = {}> {
  key: string
  type: FieldType | EntityFieldType
  name?: string | React.ReactNode
  placeholder?: string
  selectionOptions?: SelectionOption[] | SelectionOptionFnc<P & { field: EnhancedFormField<P> } & Maybe<EnhnacedFormArgs<P>>>
  isMultiSelection?: boolean
  isTagSelection?: boolean
  isDateTime?: boolean | "time"
  isTextArea?: boolean
  entityRelationType?: EntityRelationType
  className?: string
  style?: React.CSSProperties
  rules?: GetFieldDecoratorOptions["rules"]
  isVisible?: (props: P & { field: EnhancedFormField<P> }) => boolean;
  getValueFromProps?: (props: P & { field: EnhancedFormField<P> }) => any
  getDisplayValue?: (props: P & { field: EnhancedFormField<P> }) => string | React.ReactNode
}

const createSelectionOptions = (selectionOptions: SelectionOption[] | null | undefined) => {
  if (!selectionOptions) selectionOptions = [];
  else return selectionOptions.map(({ key, name, optionProps }) => <Select.Option key={key} {...optionProps}>{name}</Select.Option>)
}

export function createFieldFromObject<P = any>(field: EnhancedFormField<P>, form: FormComponentProps["form"], props: P, colSize?: ColSize) {
  if (field.isVisible && !field.isVisible({ ...props, field })) return;
  if (!field.rules) field.rules = [];

  const { getFieldDecorator } = form;
  const { className, placeholder, style, rules, entityRelationType } = field
  const isRequired = field.rules.find(rule => rule.required ? true : false);
  if (!colSize) colSize = {
    label: {
      xs: 6
    },
    field: {
      xs: 18
    }
  }
  const FormItem = (input: React.ReactNode, options: GetFieldDecoratorOptions) => (
    <Form.Item key={field.key}>
      <Row className={`field-wrapper ${field.type === EntityFieldType["Richtext"] ? "richtext" : ""}`} >
        <Col className="name" {...colSize!.label}>
          {field.name}{isRequired ? <span className="required">*</span> : null}
        </Col>
        <Col {...colSize!.field} className="field">
          {getFieldDecorator(field.key, {
            initialValue: field.getValueFromProps ? field.getValueFromProps({ ...props, field }) : undefined,
            ...options
          })(input)}
        </Col>
      </Row>
    </Form.Item>
  )

  switch (field.type) {
    case "TEXT":
      if (field.isTextArea)
        return FormItem(<Input.TextArea placeholder={placeholder} className={className ? className : ""} style={style ? style : undefined} />, { rules })
      else
        return FormItem(<Input placeholder={placeholder} className={className ? className : ""} style={style ? style : undefined} />, { rules })
    case "RICHTEXT":
      return FormItem(<TextEditor />, { rules })
    case "PHONE":
      return FormItem(<Input prefix={<Icon type="phone" />} placeholder={placeholder} className={className ? className : ""} style={style ? style : undefined} />, { rules })
    case "EMAIL":
      return FormItem(<Input prefix={<Icon type="mail" />} placeholder={placeholder} className={className ? className : ""} style={style ? style : undefined} />, {
        rules: [...rules, { type: "email", message: "Invalid email" }]
      })
    case "BOOLEAN":
      return FormItem(<Switch className={className ? className : ""} style={style ? style : undefined} />, {
        rules, valuePropName: "checked"
      })
    case "NUMBER":
      return FormItem(<InputNumber className={className ? className : ""} style={style ? style : undefined} />, { rules })
    case "DATE":
      if (field.isDateTime === "time")
        return FormItem(<TimePicker use12Hours={true} allowClear={true} format="h:mm a" />, {
          initialValue: field.getValueFromProps ? getMomentFromTime(field.getValueFromProps({ ...props, field })) : undefined,
          rules,
        })
      return FormItem(<DatePicker showTime={field.isDateTime ? { use12Hours: true, format: "h:mm a" } : undefined} className={className ? className : ""} placeholder="Select Date" format={field.isDateTime ? "M/D/YYYY h:mm a" : "M/D/YYYY"} />, {
        initialValue: field.getValueFromProps ? moment(field.getValueFromProps({ ...props, field })) : undefined,
        normalize: value => value ? moment(value, "h:mm a") : undefined,
        rules,
      })
    case "SELECTION":
      let options: SelectionOption[] = [];
      // @ts-ignore
      if (typeof field.selectionOptions === "function") options = field.selectionOptions({ ...props, field });
      else if (typeof field.selectionOptions === "object") options = field.selectionOptions;
      return FormItem(
        <Select
          mode={field.isTagSelection ? "tags" : field.isMultiSelection ? "multiple" : undefined}
          className={className ? className + " select-field" : "select-field"}
          style={style ? style : undefined}
          placeholder={placeholder}
        >
          {createSelectionOptions(options)}
        </Select>, { rules }
      )
    case "RELATION":
      const hanldeReturn = (field: React.ReactNode) => FormItem(field, { rules, getValueFromEvent: (value: string) => value })
      if (entityRelationType === EntityRelationType["Company"])
        return hanldeReturn(<CompanyRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["Contact"])
        return hanldeReturn(<ContactRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["Client"])
        return hanldeReturn(<ClientRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["Partner"])
        return hanldeReturn(<PartnerRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["ClientContact"])
        return hanldeReturn(<ClientContactRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["Campaign"])
        return hanldeReturn(<CampaignRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["Tradeshow"])
        return hanldeReturn(<TradeshowRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["MeetingTag"])
        return hanldeReturn(<MeetingTagRelationField field={field} placeholder={placeholder} />)
      else if (entityRelationType === EntityRelationType["User"])
        return hanldeReturn(<UserRelationField field={field} placeholder={placeholder} />)
      break;
    default:
      return <span>Field type not found!</span>
  }
}

export function getRelationFieldByType(type: EntityRelationType): React.FunctionComponent<ParentRelationFieldProps> | undefined {
  if (type === EntityRelationType["Company"])
    return CompanyRelationField
  else if (type === EntityRelationType["Contact"])
    return ContactRelationField
  else if (type === EntityRelationType["Client"])
    return ClientRelationField
  else if (type === EntityRelationType["Partner"])
    return PartnerRelationField
  else if (type === EntityRelationType["ClientContact"])
    return ClientContactRelationField
  else if (type === EntityRelationType["Campaign"])
    return CampaignRelationField
  else if (type === EntityRelationType["Tradeshow"])
    return TradeshowRelationField
  else if (type === EntityRelationType["MeetingTag"])
    return MeetingTagRelationField
  else if (type === EntityRelationType["User"])
    return UserRelationField
}