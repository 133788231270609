import React, { useState } from "react";
import { useCompaniesQuery, QueryCompaniesInput, Company, useDeleteCompanyMutation, useUpdateCompanyMutation, useCompanyQuery, useUpdateCompanyIconMutation } from "../../lib/codegen";
import { DataPane, ActiveIdState } from "../../components/dataPane/DataPane";
import { RouteComponentProps } from "react-router";
import { CompanyPane } from "./companyPane/CompanyPane";
import { getColumnSearchProps } from "../../components/tableFilter/TableFilterDropdown";
import { getActionColumn } from "../../components/tableFilter/getActionColumn";
import { Button, Icon } from "antd";
import { CreateCompany } from "./createCompany/CreateCompany";
import { useCompanyFields } from "./comapnyFields/useCompanyFields";
import EnhancedForm, { EnhnacedFormArgs } from "../../components/enhancedForm/EnhancedForm";
import { getFieldEntriesFromFormData } from "../../lib/helpers/getFieldEntriesFromFormData";
import { Favicon } from "../../components/display/favicon/Favicon";

interface IProps extends RouteComponentProps<{ companyId?: string }> {

}

export const Companies: React.FC<IProps> = props => {
  const queryLimit = 20;

  // State
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const activeIdState: ActiveIdState = [props.match.params.companyId, (id: string | null | undefined) => props.history.push(`/companies/${id ? id : ""}`)];
  const companiesQuery = useCompaniesQuery({ variables: { data: { offset: 0, limit: queryLimit } } })
  const [deleteCompany] = useDeleteCompanyMutation();
  const [updateCompany] = useUpdateCompanyMutation();
  const [updateCompanyIcon] = useUpdateCompanyIconMutation();
  const companyQuery = useCompanyQuery({ variables: { id: activeIdState[0] }, skip: !activeIdState[0] });
  const company = companyQuery.data && companyQuery.data.company ? companyQuery.data.company as Company : undefined;
  const fields = useCompanyFields(company)

  // Helpers
  const hanldeRefresh = (data: QueryCompaniesInput) => companiesQuery.refetch({ data: { ...companiesQuery.variables.data, ...data } })
  const isFiltered = (filterProp: keyof QueryCompaniesInput) => companiesQuery.variables.data && companiesQuery.variables.data[filterProp] ? true : false
  const handleUpdate = (form: EnhnacedFormArgs) => {
    if (!activeIdState[0]) return;
    updateCompany({ variables: { id: activeIdState[0], data: getFieldEntriesFromFormData(form.formData()) as any } })
      .then(() => {
        hanldeRefresh({});
        setIsUpdateLoading(false);
        setIsEditing(false);
      })
      .catch(() => {
        setIsUpdateLoading(false);
      })
    setIsUpdateLoading(true);
  }

  return (
    <EnhancedForm
      isEditing={isEditing}
      formFields={fields}
    >
      {form => (
        <>
          <CreateCompany visibleState={[isCreateVisible, setIsCreateVisible]} />
          <DataPane<Company>
            title="Companies"
            activeIdState={activeIdState}
            data={companiesQuery.data && companiesQuery.data.companies ? companiesQuery.data.companies : { items: [], total: 0 }}
            loading={companiesQuery.loading}
            renderPane={() => <div>{form.renderForm()}</div>}
            onRowClick={() => setIsEditing(false)}
            paneTitle={({ activeItem }) => <>
              {activeItem ? <span><Favicon iconBase64={activeItem.iconBase64}/> {activeItem.name}</span> : ''}
              {!isEditing && activeIdState[0] && <Button onClick={() => setIsEditing(true)} type="ghost" style={{ marginLeft: "auto" }}>Edit</Button>}
              {isEditing && <>
                {!isUpdateLoading && <Button type="ghost" style={{ marginLeft: "auto" }} onClick={() => setIsEditing(false)}>Cancel</Button>}
                <Button type="primary" loading={isUpdateLoading} style={{ marginLeft: isUpdateLoading ? "auto" : 10 }} onClick={() => handleUpdate(form)}>Save</Button>
              </>}
            </>}
            footerActions={<Button onClick={() => setIsCreateVisible(true)}><Icon type="plus" />Company</Button>}
            tableProps={{
              initialPage: () => {
                const offset = companiesQuery.variables.data && companiesQuery.variables.data.offset ? companiesQuery.variables.data.offset : 0;
                const limit = companiesQuery.variables.data && companiesQuery.variables.data.limit ? companiesQuery.variables.data.limit : queryLimit;
                return (offset / limit) + 1;
              },
              handlePageChange: offset => hanldeRefresh({ offset }),
              pageSize: queryLimit,
              columns: [
                {
                  key: "name",
                  dataIndex: "name",
                  title: "Name",
                  ...getColumnSearchProps({
                    dataIndex: "name",
                    isFiltered: () => isFiltered("name"),
                    onSearch: searchValue => hanldeRefresh({ name: searchValue, offset: 0 }),
                    onReset: () => hanldeRefresh({ name: undefined, offset: 0 }),
                  }),
                  render: (text, record) => <span>
                    <Favicon iconBase64={record.iconBase64}/>
                    {text}
                  </span> 
                },
                {
                  key: "domain",
                  dataIndex: "domain",
                  title: "Domain",
                  ...getColumnSearchProps({
                    dataIndex: "domain",
                    isFiltered: () => isFiltered("domain"),
                    onSearch: searchValue => hanldeRefresh({ domain: searchValue, offset: 0 }),
                    onReset: () => hanldeRefresh({ domain: undefined, offset: 0 }),
                  })
                },
                getActionColumn<Company>({
                  onDelete: id => deleteCompany({ variables: { id } }),
                  onEdit: id => {
                    setIsEditing(false)
                    activeIdState[1](id);
                    setIsEditing(true)
                  },
                  moreOptions: [
                    {
                      icon: 'global',
                      text: 'Get Icon',
                      onClick: (id) => updateCompanyIcon({ variables: { id } })
                    }
                  ]
                })
              ]
            }}
          />
        </>
      )}
    </EnhancedForm>
  );
}