import React from "react";
import { FormFieldData, FormDataObject, EnhancedFormFieldsObject } from "../enhancedForm/withEnhancedForm";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { renderFields } from "../enhancedForm/renderFields";
import { FieldValue, FieldEntry, FieldType } from "../../lib/ts/entityFields";
import { EnhancedDataDisplay, EnhancedDataDisplayOptions } from "./enhancedDataDisplay/EnhancedDataDisplay";
import { flattenFieldsFromObject } from "./formHelpers/formHelpers";
import { isUUID } from "../../lib/helpers/regexValidateors";

export interface EnhnacedFormArgs<P = any> {
  renderForm: (renderArgs?: P) => React.ReactNode | React.ReactNodeArray
  formSetFieldsValue: (fieldData: FormFieldData[]) => void
  formClear: () => void
  formData: () => FormFieldData[]
  formDataObject: (args?: { formatFieldsEntry?: boolean }) => FormDataObject & { fieldsEntry?: FieldEntry[] }
  formIsValid: () => boolean
  formValidate: () => { key: string, errors: string[] }[]
}

export interface EnhancedFormComponentProps<P = any> {
  formFields: EnhancedFormFieldsObject<P>
  isEditing?: boolean
  displayOptions?: EnhancedDataDisplayOptions
  className?: string
  children: (args: EnhnacedFormArgs) => React.ReactNode
}

type ExtendedProps<P = any> = EnhancedFormComponentProps<P> & FormComponentProps;

interface IState {
  defaultValuesSet: boolean
  didComponentMount: boolean
}

class EnhancedForm<P = any> extends React.Component<ExtendedProps<P>, IState> {
  constructor(props: ExtendedProps<P>) {
    super(props);
    this.state = {
      defaultValuesSet: false,
      didComponentMount: false
    }
  }

  componentDidMount = () => this.setState({ didComponentMount: true })

  clearDateValues() {
    if (!this.state.defaultValuesSet) {
      const interval = setInterval(() => {
        if (this.state.didComponentMount) {
          clearInterval(interval)
          this.setState({ defaultValuesSet: true });
          flattenFieldsFromObject(this.props.formFields).forEach(field => {
            // console.log(field);
            if (field.type === FieldType["DATE"]) { 
              if ((typeof field.getValueFromProps === "function" && typeof field.getValueFromProps({ ...this.getFormArgs(), field} as any) === "undefined") || typeof field.getValueFromProps === "undefined")
              this.props.form.setFieldsValue({ [field.key]: undefined });
            }
          })
        }
      }, 2000)
    }
  }

  formSetFieldsValue(fieldData: FormFieldData[]) {
    fieldData.forEach(field => this.props.form.setFieldsValue({ [field.key]: field.value }))
  }

  formData(): FormFieldData[] {
    return flattenFieldsFromObject(this.props.formFields).map(field => ({
      key: field.key,
      value: this.props.form.getFieldValue(field.key)
    }));
  }

  formDataObject(args?: { formatFieldsEntry?: boolean }): { [key: string]: any, fieldsEntry?: FieldEntry[] } {
    let dataObject: { [key: string]: any, fieldsEntry?: FieldEntry[] } = {};
    flattenFieldsFromObject(this.props.formFields).forEach(field => {
      if (args && args.formatFieldsEntry && isUUID(field.key)) {
        if (!dataObject.fieldsEntry) dataObject.fieldsEntry = [];
        let fieldValue = this.props.form.getFieldValue(field.key);
        dataObject.fieldsEntry.push({
          fieldId: field.key,
          value: typeof fieldValue !== "undefined" ? fieldValue : null
        })
      } else dataObject[field.key] = this.props.form.getFieldValue(field.key)
    });
    return dataObject;
  }

  formValidate(): { key: string, errors: string[] }[] {
    this.props.form.validateFields();
    const formErrors = this.props.form.getFieldsError();
    let errorsArray: { key: string, errors: string[] }[] = [];
    flattenFieldsFromObject(this.props.formFields).forEach(({ key }) => {
      const formError = formErrors[key];
      if (typeof formError === "string") errorsArray.push({ key, errors: [formError] })
      else if (formError) errorsArray.push({ key, errors: formError })
    })
    this.props.form.validateFieldsAndScroll();
    return errorsArray;
  }

  formIsValid(): boolean {
    const { getFieldError, isFieldTouched } = this.props.form;
    let allowSubmit = false;
    flattenFieldsFromObject(this.props.formFields).forEach(field => {
      const isTouched = isFieldTouched(field.key);
      if (!isTouched) allowSubmit = true;
      if (isTouched) if (getFieldError(field.key)) allowSubmit = true;
    });
    return allowSubmit;
  }

  getFormArgs(): EnhnacedFormArgs {
    let args: EnhnacedFormArgs = {
      formClear: () => this.props.form.resetFields(),
      formData: this.formData.bind(this),
      formDataObject: this.formDataObject.bind(this),
      formIsValid: this.formIsValid.bind(this),
      formSetFieldsValue: this.formSetFieldsValue.bind(this),
      formValidate: this.formValidate.bind(this)
    } as EnhnacedFormArgs;
    args.renderForm = (renderArgs?: any) => {
      if (typeof this.props.isEditing === "boolean" && !this.props.isEditing) {
        if (this.state.defaultValuesSet) this.setState({ defaultValuesSet: false });
        return <EnhancedDataDisplay displayOptions={this.props.displayOptions} formFields={this.props.formFields} renderArgs={{ ...this.props, ...args, ...renderArgs }} />
      }
      else {
        this.clearDateValues();
        return renderFields<any>(this.props.formFields, this.props.form, { ...this.props, ...args, ...renderArgs });
      }
    }
    return args
  }

  render() {
    return (
      <>
        {this.props.children(this.getFormArgs())}
      </>
    );
  };
}

export default Form.create<ExtendedProps>()(EnhancedForm);