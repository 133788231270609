import React from "react";
import { RouteComponentProps } from "react-router";
import { ClientComponent } from "../../../lib/codegen";
import { Alert, Layout, Row, Col } from "antd";
import Title from "antd/lib/typography/Title";
import { ClientContactPane } from "./clientContactPane/ClientContactPane";
import { TradeshowTable } from "./tradeshowTable/TradeshowTable";
import { PartnerTable } from "../adminDashboard/partnerTable/PartnerTable";
import { CampaignPane } from "./campaignPane/CampaignPane";

interface IProps extends RouteComponentProps<{ clientId: string }> {

}

export const ClientPage: React.FC<IProps> = props => (
  <ClientComponent variables={{ id: props.match.params.clientId }}>
    {({ loading, error, data }) => {
      if (error) return <Alert type="error" message="Client not found!" />
      else {
        let client = data ? data.client : null;
        return (
          <Layout>
            <Title >{client ? client.name : "Client"}</Title>
            <CampaignPane limit={15} clientId={props.match.params.clientId} />
            <br />
            <ClientContactPane limit={5} clientId={props.match.params.clientId} />
            <br />
            <Row>
              <Col span={12}>
                <TradeshowTable limit={10} clientId={ client  ? client.id : undefined }/>
              </Col>
              <Col span={12}>
                <PartnerTable limit={5} clientsId={[props.match.params.clientId]}/>
              </Col>
            </Row>         
          </Layout>
        )
      }
    }}
  </ClientComponent>
)