import React, { useState, useEffect } from "react";
import { useCreateCampaignMutation, useUpdateCampaignMutation, useDeleteCampaignMutation, useCampaignsQuery, CampaignFragment, useCampaignQuery, Campaign, QueryCampaignsInput, EntityFieldType, EntityRelationType, useCampaignStatsQuery } from "../../../../lib/codegen";
import { DataPane } from "../../../../components/dataPane/DataPane";
import EnhancedForm from "../../../../components/enhancedForm/EnhancedForm";
import { campaignFields } from "./campaignFields";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";
import { Select, Button, Icon, message, Popover, Typography } from "antd";
import { ClientRelationField } from "../../../../components/enhancedForm/relationFields";
import { PopForm } from "../../../../components/popform/PopForm";
import { getActionColumn } from "../../../../components/tableFilter/getActionColumn";
import { useResponsive } from "../../../../lib/helpers/hooks/useResponsive";
import { EmptyDataPane, emptyDataPaneCenterStyle } from "../../../../components/dataPane/components/EmptyDataPane";
import { CampaignStatusPieChart } from "../../../dashboard/meetingStatusPieChart/CampaignStatusPieChart";
import { CamapginMeetingBarChart } from "../../../dashboard/meetingStatusPieChart/CamapginMeetingBarChart";

const { Title } = Typography

interface IProps {
  limit?: number
  clientId?: string
}

function renderTextBold<T extends { id: string }>(text: string, item: T, activeId: string | null | undefined) { return item.id === activeId ? <b>{text}</b> : <span>{text}</span> }

export const CampaignPane: React.FC<IProps> = props => {
  // constants
  const pageSize = props.limit ? props.limit : 20;

  // state
  const [activeId, setActiveId] = useState<string | null | undefined>(null);
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const { responsiveCalc } = useResponsive();

  // graphql hooks
  const [createMutation] = useCreateCampaignMutation();
  const [updateMutation] = useUpdateCampaignMutation();
  const [deleteMutation] = useDeleteCampaignMutation();
  const campaignsQuery = useCampaignsQuery({ variables: { data: { clientId: props.clientId, limit: pageSize } } })
  const campaignQuery = useCampaignQuery({ variables: { id: activeId! }, skip: !activeId })

  // calculated
  const campaigns = campaignsQuery.data && campaignsQuery.data.campaigns ? campaignsQuery.data.campaigns : { items: [], total: 0 };
  const campaign = campaignQuery.data && campaignQuery.data.camapign ? campaignQuery.data.camapign : null;

  // helpers
  const handleFilter = (args: QueryCampaignsInput) => campaignsQuery.refetch({ data: { ...campaignsQuery.variables.data, ...args } })
  const isFiltered = (filterProp: keyof QueryCampaignsInput) =>
    campaignsQuery.variables && campaignsQuery.variables.data && campaignsQuery.variables.data[filterProp] ? true : false

  // set active id
  useEffect(() => {
    setActiveId(null);
  }, [props.clientId])

  const StatsCard: React.FC<{ campaignId: string, style?: React.CSSProperties }> = ({ campaignId, style }) => {
    const { loading, error, data } = useCampaignStatsQuery({ variables: { data: { campaignId } } })

    if (loading || error || !(data && data.campaignStats))
      return <div></div>

    return (
      <div style={style}>
        <CamapginMeetingBarChart campaignId={campaignId} width={300} height={200} />
      </div>
    )

  }

  return <EnhancedForm formFields={campaignFields(props.clientId, campaign as Campaign | undefined)} isEditing={isEditing}>
    {form => (
      <div>
        <PopForm
          formFields={campaignFields(props.clientId)}
          title="Create Campaign"
          onSubmit={formData => createMutation({ variables: { data: formData as any } }).then(result => {
            setIsCreateVisible(false);
            if (result && result.data && result.data.createCampaign)
              handleFilter({ name: result.data.createCampaign.name })
                .then(() => setActiveId(result!.data!.createCampaign!.id))
          })}
          onClose={() => setIsCreateVisible(false)}
          visible={isCreateVisible}
        />
        <DataPane<CampaignFragment>
          activeIdState={[activeId, setActiveId]}
          data={campaigns}
          loading={campaignsQuery.loading}
          title="Campaigns"
          footerActions={<Button onClick={() => setIsCreateVisible(true)}><Icon type="plus" />Campaign</Button>}
          height={responsiveCalc("md", "down-include") ? undefined : 600}
          paneTitle={() => <>
            <span>{campaign ? campaign.name : ''}</span>
            {!isEditing && activeId && <Button onClick={() => setIsEditing(true)} icon="edit" type="ghost" style={{ marginLeft: "auto" }}>Edit</Button>}
            {isEditing && <>
              {!isUpdateLoading && <Button type="ghost" style={{ marginLeft: "auto" }} onClick={() => setIsEditing(false)}>Cancel</Button>}
              <Button type="primary" loading={isUpdateLoading} style={{ marginLeft: isUpdateLoading ? "auto" : 10 }} onClick={() => {
                if (form.formValidate().length !== 0 || !activeId) return;
                setIsUpdateLoading(true);
                updateMutation({ variables: { id: activeId!, data: form.formDataObject() as any } })
                  .then(() => {
                    campaignsQuery.refetch();
                    setIsUpdateLoading(false);
                    setIsEditing(false);
                  })
                  .catch(() => {
                    setIsUpdateLoading(false);
                    message.error("Warning, campaign not saved!")
                  })
              }}>Save</Button>
            </>}
          </>}
          tableProps={{
            columns: [
              {
                key: "name",
                dataIndex: "name",
                title: "Name",
                ...getColumnSearchProps({
                  dataIndex: "Name",
                  onSearch: value => handleFilter({ name: value }),
                  isFiltered: () => isFiltered("name"),
                  onReset: () => handleFilter({ name: undefined })
                }),
                render: (text, item) =>
                  <Popover
                    overlayStyle={{ padding: 0 }}
                    title={item.name}
                    mouseEnterDelay={0.5}
                    content={
                      <div style={{ minWidth: 300, minHeight: 190 }}>
                        <StatsCard campaignId={item.id} style={{ marginTop: 0, marginBottom: -12 }}/>
                      </div>
                    }
                  >
                    {renderTextBold(text, item, activeId)}
                  </Popover>
              },
              {
                key: "status",
                dataIndex: "status",
                title: "Status",
                ...getColumnSearchProps({
                  dataIndex: "status",
                  renderMenu: _props => (
                    <Select onChange={value => _props.onSearch(value as string)} allowClear={true} style={{ width: 140 }} placeholder="No Selection">
                      <Select.Option value="Active">Active</Select.Option>
                      <Select.Option value="Complete">Complete</Select.Option>
                    </Select>
                  ),
                  onSearch: value => handleFilter({ status: value as any }),
                  isFiltered: () => isFiltered("status"),
                  onReset: () => handleFilter({ status: undefined })
                }),
                render: (text, item) => renderTextBold(text, item, activeId)
              },
              {
                key: "client",
                dataIndex: "client",
                title: "Client",
                ...getColumnSearchProps({
                  dataIndex: "client",
                  renderMenu: _props => (
                    <ClientRelationField
                      onChange={value => { _props.onSearch(value); }}
                      value={campaignsQuery.variables && campaignsQuery.variables.data && campaignsQuery.variables.data.clientId ? campaignsQuery.variables.data.clientId : undefined}
                      field={{
                        placeholder: "No Selection",
                        style: { minWidth: 150 },
                        key: "clientId",
                        type: EntityFieldType["Relation"],
                        entityRelationType: EntityRelationType["Client"]
                      }}
                    />
                  ),
                  onSearch: value => handleFilter({ clientId: value }),
                  isFiltered: () => isFiltered("clientId"),
                  onReset: () => handleFilter({ clientId: undefined })
                }),
                render: (text, item: Campaign) => renderTextBold(item.client ? item.client.name : "", item, activeId)
              },
              getActionColumn<CampaignFragment>({
                onDelete: id => deleteMutation({ variables: { id } }).then(() => campaignsQuery.refetch()),
                onEdit: id => setIsEditing(true),
                activeIdState: [activeId, setActiveId]
              })
            ],
            handlePageChange: offset => handleFilter({ offset }),
            initialPage: () => 0,
            pageSize,
          }}
          renderPane={({ activeItem }) => {
            if (activeItem)
              return <div>
                {form.renderForm()}
                <div className="ant-descriptions-title">Meetings Status</div>
                <CamapginMeetingBarChart campaignId={activeItem.id} />
              </div>
            else
              return <div style={emptyDataPaneCenterStyle}>
                <EmptyDataPane
                  description={"No Campaign Selected"}
                  body={<Button type="primary" onClick={() => setIsCreateVisible(true)}>Create Campaign</Button>}
                />
              </div>
          }}
        />
      </div>
    )}
  </EnhancedForm>
}