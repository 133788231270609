import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataPane } from "../../../components/dataPane/DataPane";
import { useZoomUsersQuery, ZoomUser } from "../../../lib/codegen";
import { ZoomUserPane } from "./ZoomUserPane";
import { EmptyDataPane, emptyDataPaneCenterStyle } from "../../../components/dataPane/components/EmptyDataPane";

interface ZoomIndexProps {

}

export const ZoomIndex: React.FC<ZoomIndexProps & RouteComponentProps<{ userId?: string }>> = props => {
  const { loading, error, data } = useZoomUsersQuery()
  const activeUserId = props.match.params.userId,
    setActiveUserId = (id: string | null | undefined) => props.history.push(`/admin/zoom/${id ? id : ''}`)
  return (
    <DataPane<ZoomUser>
      loading={loading}
      hideFooter={true}
      title='Zoom Admin Portal'
      activeIdState={[activeUserId, setActiveUserId]}
      data={data && data.zoomUsers ? { items: data.zoomUsers, total: data.zoomUsers.length } : { items: [], total: 0 }}
      renderPane={() =>
        activeUserId ? <ZoomUserPane zoomUserId={activeUserId} /> : <div style={emptyDataPaneCenterStyle}><EmptyDataPane description='No Zoom user selected' /></div>
      }
      paneTitle={({ activeItem }) => activeItem ? `${activeItem.first_name} ${activeItem.last_name}` : ''}
      tableProps={{
        widthPercent: 40,
        columns: [
          {
            key: 'name',
            dataIndex: 'firstName',
            title: 'Name',
            render: (text, { first_name, last_name }) => `${first_name} ${last_name}`
          },
          {
            key: 'email',
            dataIndex: 'email',
            title: 'Email',
            render: (text, record) => text
          }
        ],
        pageSize: 300,
        initialPage: () => 1,
        handlePageChange: () => { },
      }}
    />
  );
}