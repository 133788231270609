import React from "react";
import { Modal, notification } from "antd";
import EnhancedForm, { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { EntityFieldType, useRegisterMutation, UsersQuery, UsersQueryVariables } from "../../../lib/codegen";
import { QueryResult } from "react-apollo";
import { ApolloError } from "apollo-boost";

interface CreateUserFormProps {
  visibleState: [boolean, (state: boolean) => void]
  userQuery?: QueryResult<UsersQuery, UsersQueryVariables>
}

export const CreateUserForm: React.FC<CreateUserFormProps> = props => {
  const [createUserMutation] = useRegisterMutation()

  function handleSubmit(form: EnhnacedFormArgs<any>) {
    if (form.formValidate().length !== 0) return
    const data = form.formDataObject() as any
    createUserMutation({ variables: { data } })
      .then(() => {
        props.visibleState[1](false)
        notification['success']({ message: 'User Created' })
        if (props.userQuery) props.userQuery.refetch()
      })
      .catch((err: ApolloError) => notification['error']({ message: 'Check if email is already taken' }))
  }

  return (
    <EnhancedForm
      formFields={[
        {
          key: "firstName",
          name: "First Name",
          type: EntityFieldType["Text"],
          rules: [{ required: true, message: "First name is required" }]
        },
        {
          key: "lastName",
          name: "Last Name",
          type: EntityFieldType["Text"],
          rules: [{ required: true, message: "Last name is required" }]
        },
        {
          key: "email",
          name: "Email",
          type: EntityFieldType["Email"],
          rules: [{ required: true, message: "Email is required" }]
        },
        {
          key: "password",
          name: "Password",
          type: EntityFieldType["Text"],
          rules: [{ required: true, message: "User password is required" }],
        }
      ]}
    >
      {form =>
        <Modal
          title='Create User'
          visible={props.visibleState[0]}
          onCancel={() => props.visibleState[1](false)}
          onOk={() => handleSubmit(form)}
          okText="Create"
        >
          {form.renderForm()}
        </Modal>
      }
    </EnhancedForm>
  );
}