import './ZoomRecordingList.scss'
import React, { useState } from "react";
import { useZoomRecordingsQuery, ZoomCallRecording, ZoomRecordingsResponse, ZoomRecordingsQuery } from "../../../../../lib/codegen";
import { Table } from "antd";
import { getEnvVars } from '../../../../../lib/helpers/getEnvVars';
import { AudioPlayer } from './AudioPlayer';
import moment from 'moment';
import { getColumnSearchProps } from '../../../../../components/tableFilter/TableFilterDropdown';
import { Highlight } from '../../../../../components/display/highlight/Highlight';

interface ZoomRecordingListProps {
  zoomUserId?: string | null
}

const getDuration = (minDuration: number, getTextDuration?: boolean) => {
  let hours = Math.round((minDuration / 60) / 60)
  let min = Math.round(minDuration / 60)
  let sec = Math.round(minDuration % 60)
  if (getTextDuration)
    return `${hours > 0 ? `${hours} hr` : ''} ${min > 0 ? `${min} min` : ''} ${hours === 0 && min === 0 ? `${sec} sec` : ''}`.trim()
  else
    return `${hours}:${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`
}

export const ZoomRecordingList: React.FC<ZoomRecordingListProps> = props => {
  const [filterValues, setFilterValues] = useState<{
    callee_number: string | undefined
  }>({ callee_number: undefined })
  const zoomRecordingsQuery = useZoomRecordingsQuery({
    variables: {
      data: {
        userId: props.zoomUserId!,
        page_number: 1,
        page_size: 300
      }
    },
    skip: !props.zoomUserId
  })

  const data = zoomRecordingsQuery.data && zoomRecordingsQuery.data.zoomRecordings ? zoomRecordingsQuery.data.zoomRecordings : undefined

  return (
    <Table<ZoomCallRecording>
      className='zoom-call-recording-table'
      // style={{
      //   height: 200,
      //   overflowY: 'auto'
      // }}
      loading={zoomRecordingsQuery.loading}
      columns={[
        {
          title: 'Phone',
          key: 'phone',
          dataIndex: 'callee_number',
          render: (text, record) => filterValues.callee_number ?
            <Highlight
              searchValue={filterValues.callee_number}
              text={text}
            /> : text,
          ...getColumnSearchProps({
            dataIndex: 'phone',
            isFiltered: () => filterValues.callee_number ? true : false,
            onReset: () => setFilterValues({ ...filterValues, callee_number: undefined }),
            onSearch: (value) => setFilterValues({ ...filterValues, callee_number: value })
          }),
          filteredValue: filterValues.callee_number ? [filterValues.callee_number] : undefined,
          onFilter: (value, record) => record.callee_number.indexOf(value) > -1 || !filterValues.callee_number
        },
        {
          title: 'Date Time',
          key: 'date_time',
          dataIndex: 'date_time',
          render: (text, record) => moment(text).local().format("M/Y/DD") + " at " + moment(text).local().format("h:mm a"),
          sortDirections: ['ascend'],
          sorter: (a, b) => moment(a.date_time).diff(moment(b.date_time), 'milliseconds') 
        },
        {
          title: 'Duration',
          key: 'duration',
          dataIndex: 'duration',
          render: (text, record) => <span>{getDuration(record.duration)}</span>,
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.duration - b.duration
        },
        {
          title: 'Direction',
          key: 'direction',
          dataIndex: 'direction',
          render: (text, record) => text,
          filters: [
            {
              text: 'Outbound',
              value: 'outbound',
            },
            {
              text: 'Inbound',
              value: 'inbound',
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.direction === value
        }
      ]}
      dataSource={data ? data.recordings : []}
      rowKey={record => record.id}
      size='small'
      expandRowByClick={true}
      expandedRowRender={(record) => <AudioPlayer src={getEnvVars().serverURL + record.download_url} corsUrl={getEnvVars().serverURL} />}
      pagination={{
        pageSize: 300,
        total: data ? data.total_records : 0,
        onChange: (page) => zoomRecordingsQuery.refetch({ data: { ...zoomRecordingsQuery.variables.data, page_number: page } })
      }}
    />
  );
}