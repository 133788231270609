import React, { useState } from "react";
import { Drawer, Button, Modal, Input } from "antd";
import { UseState } from "../../../lib/ts/types";
import EnhancedForm, { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { useCompanyFields } from "../comapnyFields/useCompanyFields";
import { useCreateCompanyMutation, useGetVtigerCompanyQuery, useCompanyFieldsQuery } from "../../../lib/codegen";
import { getFieldEntriesFromFormData } from "../../../lib/helpers/getFieldEntriesFromFormData";
import { PopForm } from "../../../components/popform/PopForm";
import { useForm } from "../../../lib/helpers/hooks/useForm";
import { withApollo } from "react-apollo";
import { ApolloConsumerProps, ApolloConsumer } from "@apollo/react-common/lib/context/ApolloConsumer";
import { ImportCompany } from "./ImportCompany";
import { withRouter, RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps {
  visibleState: UseState<boolean>
}

const _CreateCompany: React.FC<IProps> = props => {
  const [importVisible, setImportVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const fields = useCompanyFields();
  const companyFieldsQuery = useCompanyFieldsQuery();
  const companyFields = companyFieldsQuery.data && companyFieldsQuery.data.companyFields ? companyFieldsQuery.data.companyFields : [];
  const [createCompany] = useCreateCompanyMutation();

  const handleCreate = (data: any, form: EnhnacedFormArgs) => {
    if (form.formValidate().length !== 0) return;
    createCompany({ variables: { data } })
      .then(result => {
        if (result && result.data && result.data.createCompany) props.history.push(`/companies/${result.data.createCompany.id}`)
        props.visibleState[1](false)
      })
      .finally(() => {
        setLoading(false)
      })
    setLoading(true)
  }

  const handleImport = (importData: any, form: EnhnacedFormArgs) => {
    if (!importData || !companyFields) return;
    let mappedValues: { key: string, value: string }[] = [];
    companyFields.forEach(field => {
      if (field.vtigerFieldId) {
        const mapValue = importData[field.vtigerFieldId]
        if (mapValue) mappedValues.push({ key: field.id, value: mapValue })
      }
    })
    if (importData["accountname"]) mappedValues.push({ key: "domain", value: importData["accountname"] })
    if (importData["cf_accounts_organizationname"]) mappedValues.push({ key: "name", value: importData["cf_accounts_organizationname"] })
    form.formSetFieldsValue(mappedValues);
  }

  return (
    <Drawer
      title={<><span style={{ marginRight: 20 }}>Create Company</span> <Button onClick={() => setImportVisible(true)}>Vtiger Import</Button></>}
      placement="right"
      closable={true}
      maskClosable={false}
      onClose={() => props.visibleState[1](false)}
      visible={props.visibleState[0]}
      width={500}
    >
      <EnhancedForm
        formFields={fields}
      >
        {form => (
          <div>
            <ImportCompany
              importVisibleState={[importVisible, setImportVisible]}
              onQueryFinished={data => handleImport(data, form)}
            />

            {form.renderForm()}

            <div style={{ float: "right", marginBottom: 20 }}>
              <Button type="danger" onClick={() => form.formClear()} style={{ marginRight: 20 }}>Clear</Button>
              <Button loading={loading} type="primary" onClick={() => handleCreate(getFieldEntriesFromFormData(form.formData()), form)}>Save</Button>
            </div>
          </div>
        )}
      </EnhancedForm>
    </Drawer>
  );
}

export const CreateCompany = withRouter<IProps>(_CreateCompany)