import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './lib/helpers/serviceWorker';
import ApolloClient, { InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { HashRouter } from "react-router-dom";
import "antd/dist/antd.css";
import './lib/scss/index.scss';
import { AppLayout } from './AppLayout';
import { notification } from 'antd';
import { getEnvVars } from './lib/helpers/getEnvVars';

const envVars = getEnvVars();

export const apolloClient = new ApolloClient({
  uri: envVars.graphqlUrl,
  credentials: "include",
  cache: new InMemoryCache({ addTypename: true, dataIdFromObject: (object) => object.id }),
  fragmentMatcher: item => item.id,
  headers: {
    "Access-Control-Allow-Origin": envVars.graphqlUrl
  },
  onError: (err) => {
    if (err.networkError) {
      notification["error"]({
        message: err.networkError.name,
        description: err.networkError.message,
        duration: 10
      })
    } else if (err.graphQLErrors) {
      err.graphQLErrors.forEach(err => {
        notification["error"]({
          message: "Error",
          description: err.message.replace("Unexpected error value: ", "").replace(/"/g, ""),
          duration: 10
        })
      })
    }
  }
});

export const AppWithApolloProvider = () => (
  <ApolloProvider client={apolloClient}>
    <HashRouter>
      <AppLayout />
    </HashRouter>
  </ApolloProvider>
)

ReactDOM.render(<AppWithApolloProvider />, document.getElementById('root') || document.createElement('div'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
