import React from "react";
import { useMeetingsQuery, Meeting, MeetingFragment, MeetingStatus, EntityRelationType, ComparisonOperator, useFilterMeetingsQuery } from "../../../lib/codegen";
import moment from "moment"
import { ListCard } from "../../../components/listCard/ListCard";
import { TableCard } from "../../../components/tableCard/TableCard";
import { withRouter, RouteComponentProps } from "react-router";
import { Title } from "../../../components/display/title/Title";

interface IProps extends RouteComponentProps {

}

const _UpcommingMeetings: React.FC<IProps> = props => {
  const pageSize = 5;
  const meetingsQuery = useFilterMeetingsQuery({
    variables: {
      data: {
        dateAscending: true,
        limit: pageSize,
        filters: [
          {
            entityName: EntityRelationType["Meeting"],
            filterValue: moment().startOf("day").subtract(1, "day").format("YYYY-MM-DD"),
            comparisonOperator: ComparisonOperator["GreaterThan"],
            staticFieldKey: "date"
          },
          {
            entityName: EntityRelationType["Meeting"],
            filterValue: ["Planned"],
            comparisonOperator: ComparisonOperator["Equals"],
            staticFieldKey: "status"
          }
        ]
      }
    }
  })

  return(
    <div>
      <TableCard<MeetingFragment>
        pageSize={pageSize}
        loading={meetingsQuery.loading}
        title={<Title>Upcomming Meetings</Title>}
        dataSource={meetingsQuery.data && meetingsQuery.data.filterMeetings ? meetingsQuery.data.filterMeetings : { items: [], total: 0 }}
        handlePageChange={Pager => {
          meetingsQuery.refetch({
            data: {
              offset: (Pager.current! - 1) * pageSize,
              limit: pageSize,
              dateAscending: true,
              filters: meetingsQuery.variables.data.filters,
            },
          })
        }}
        onRowClick={meeting => props.history.push(`/meetings/{}/${meeting.id}`)}
        getItemProps={meeting => ({ id: meeting.id })}
        columns={[
          {
            dataIndex: "date",
            title: "Date",
            width: 100,
            render: (data: Meeting["date"], record) => data ? <><div> {moment(data).format("M/D/YY")} </div></> : "---",
          },
          {
            dataIndex: "time",
            title: "Time",
            key: "time",
            width: 120,
            render: (data: Meeting["time"], record) => data ? <><div> {data} {(record.timezone ? " " + record.timezone : "")}</div></> : "---",
          },
          {
            title: "Company",
            dataIndex: "contact",
            key: "company",
            render: (data: Meeting["contact"]) => data && data.company ? data.company.name : "---",
          }
        ]}
      />
    </div>
  );
}

export const UpcommingMeetings = withRouter<IProps>(_UpcommingMeetings)