import React, { useContext, useState, Props } from 'react';
import { PopForm } from '../../../components/popform/PopForm';
import { EntityFieldType, ReportGroup, useReportGroupQuery, useReportQuery, Report, ReportFragment } from '../../../lib/codegen';
import { UseState } from '../../../lib/ts/types';
import { FormDataObject } from '../../../components/enhancedForm/withEnhancedForm';
import { ReportContext } from '../helpers/ReportContext';
import { Modal } from 'antd';
import EnhancedForm, { EnhnacedFormArgs } from '../../../components/enhancedForm/EnhancedForm';
import { HeaderVarsList } from './components/HeaderVarsList';

interface IProps {
  visibleState: UseState<boolean>,
  createData?: {
    reportGroupId: string
  }
  reportId?: string
}

export const ReportPopForm: React.FC<IProps> = props => {
  const [loadedId, setLoadedId] = useState<null | string>(null)
  const headerVarsListState = useState<boolean>(false)
  const { createReport, updateReport, reportGroupsQuery } = useContext(ReportContext);
  const reportQuery = useReportQuery({ variables: { id: props.reportId! }, skip: !props.reportId })
  const report: ReportFragment | undefined = reportQuery.data && reportQuery.data.report ? reportQuery.data.report : undefined;

  function handleSubmit(form: EnhnacedFormArgs): Promise<any> {
    const formData = form.formDataObject();
    if (report)
      return updateReport({ variables: { id: report.id, data: formData as any } }).then(() => {
        reportGroupsQuery.refetch()
        props.visibleState[1](false)
      })
    else if (props.createData)
      return createReport({ variables: { data: { ...formData as any, reportGroupId: props.createData.reportGroupId } } }).then(() => {
        reportGroupsQuery.refetch()
        props.visibleState[1](false)
        form.formClear();
      })
    else return new Promise((resolve, reject) => { resolve() })
  }

  if (reportQuery.loading) return <span />

  return (
    <EnhancedForm
      formFields={[
        {
          key: 'name',
          name: 'Name',
          type: EntityFieldType['Text'],
          rules: [{ required: true }],
          getValueFromProps: () => report ? report.name : ''
        },
        {
          key: 'headerTemplateString',
          name: <a onClick={() => headerVarsListState[1](true)}>Header Template</a>,
          type: EntityFieldType['Text'],
          isTextArea: true,
          getValueFromProps: () => report && report.headerTemplateString ? report.headerTemplateString : undefined
        }
      ]}
    >
      {form => {
        if ((report && loadedId !== report.id)) {
          setLoadedId(report.id);
          form.formSetFieldsValue([
            { key: 'name', value: report.name },
            { key: 'headerTemplateString', value: report.headerTemplateString ? report.headerTemplateString : null }
          ])
        }
        return (
          <Modal
            title={`${props.reportId ? 'Update' : 'Create'} Report`}
            onCancel={() => props.visibleState[1](false)}
            onOk={() => {
              if (form.formValidate().length === 0) {
                handleSubmit(form);
              }
            }}
            visible={props.visibleState[0]}
          >
            <HeaderVarsList visibleState={headerVarsListState}/>
            {form.renderForm()}
          </Modal>
        )
      }}
    </EnhancedForm>
  );
}