import "./Favicon.scss"
import React from "react"
import { Icon } from "antd"

interface IProps {
  iconBase64?: string | null
  style?: React.CSSProperties
  className?: string
}

export const Favicon: React.FC<IProps> = props => {
  return (
    <span style={props.style} className={`favicon-img-wrapper ${props.className ? props.className : ''}`}>
      <span>
        {props.iconBase64 && <img className="favicon-img" style={{ marginRight: 10 }} src={props.iconBase64} />}
        {!props.iconBase64 && <Icon style={{ marginLeft: 6, marginRight: 15 }} type="global" />}
      </span>
    </span>
  );
}