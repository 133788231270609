import React, { useState } from "react";
import "./MeetingSummary.scss";
import { UseState } from "../../../lib/ts/types";
import { Modal, Select, Spin, Divider } from "antd";
import { useMeetingSummaryOptionsQuery, useMeetingSummaryQuery } from "../../../lib/codegen";
import { SummarySection } from "./components/SummarySection";
import { MeetingSummaryTitle } from "./components/MeetingSummaryTitle";
import { Title } from "../../../components/display/title/Title";

interface IProps {
  visibleState: UseState<boolean>
  meetingId: string | null
}

export const MeetingSummary: React.FC<IProps> = props => {
  // state
  const [isSummaryVisible, setIsSummaryVisible] = props.visibleState;
  const [summaryOptionId, setSummaryOptionId] = useState<undefined | string>(undefined);

  // query
  const summaryOptionsQuery = useMeetingSummaryOptionsQuery();
  const meetingSummaryQuery = useMeetingSummaryQuery({ variables: { reportGroupId: summaryOptionId!, meetingId: props.meetingId! }, skip: !summaryOptionId || !props.meetingId })
  const summaryOptions = summaryOptionsQuery.data && summaryOptionsQuery.data.meetingSummaryOptions ? summaryOptionsQuery.data.meetingSummaryOptions : [];
  const meetingSummary = meetingSummaryQuery.data && meetingSummaryQuery.data.meetingSummary ? meetingSummaryQuery.data.meetingSummary : null;

  if (!props.meetingId) return <span />;

  return (
    <Modal
      className="meeting-summary"
      title="Meeting Summary"
      visible={isSummaryVisible}
      onOk={() => setIsSummaryVisible(false)}
      okText="Done"
      onCancel={() => setIsSummaryVisible(false)}
      cancelButtonProps={{ style: { display: "none" } }}
      style={{ maxWidth: 700, width: "100%" }}
      footer={
        <div>
          <Select
            value={summaryOptionId}
            onChange={(value: string | undefined) => setSummaryOptionId(value)}
            style={{ minWidth: 250 }}
            placeholder="Summary Template"
            loading={summaryOptionsQuery.loading}
          >
            {summaryOptions.map(opt => <Select.Option key={opt.reportGroupId} value={opt.reportGroupId}>{opt.reportGroupName}</Select.Option>)}
          </Select>
        </div>
      }
    >
      {!meetingSummary && !meetingSummaryQuery.loading && <div>
        <Title size="xs">Select a Summary Template</Title>
      </div>}
      {meetingSummaryQuery.loading &&
        <div style={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Spin style={{ marginRight: 10 }} /> Loading
        </div>
      }
      {meetingSummary && !meetingSummaryQuery.loading && <div>
        <MeetingSummaryTitle meetingId={props.meetingId} />
        <Divider />
        <b><i>** Team please note do not contact the prospect. MoreMeetings will maintain exclusive contact with the prospect and will confirm this meeting. **</i></b>
        <br />
        <br />
        {meetingSummary.map((summarySection, i) => <SummarySection key={i} summarySection={summarySection} />)}
      </div>}
    </Modal>
  );
}



