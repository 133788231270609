import React, { useContext, useState, useEffect } from "react";
import { FiltersList, FiltersState } from "../../../../components/filtersList/FiltersList";
import { MeetingFiltersContext } from "./helpers/MeetingFiltersContext";
import { FilterEntry } from "../../../../lib/codegen";
import { Select } from "antd";
import { AppContext } from "../../../../lib/helpers/AppContext";
import { getMeetingFilterGroupsFromUser } from "../../meetingFilterGroups/MeetingFilterGroups";

interface IProps {

}

export const MeetingFilters: React.FC<IProps> = props => {
  const { filters, addFilter, setFilters, updateFilter, removeFilter } = useContext(MeetingFiltersContext);
  const [filterGroupId, setFilterGroupId] = useState<string | undefined>(undefined);
  
  const { user } = useContext(AppContext);
  const groups = user ? getMeetingFilterGroupsFromUser(user) : []

  useEffect(() => {
    const group = groups.find(_group => filterGroupId === _group.id)
    if (group)
      setFilters(group.filters)
  }, [filterGroupId])

  return (
    <div>
      <h2>Meeting Filters</h2>
      <Select
        value={filterGroupId}
        onChange={(value: any) => { setFilterGroupId(value); if (!value) setFilters([]); }}
        style={{
          marginBottom: 10,
          minWidth: 300
        }}
        placeholder="Custom Filter"
        allowClear={true}
      >
        {groups.map(group => <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option>)}
      </Select>
      <FiltersList
        filterState={[filters, (_filters: FilterEntry[]) => { }]}
        useFilterEntryId={true}
        handleFilterCreate={addFilter}
        handleFilterUpdate={updateFilter}
        handleFilterDelete={removeFilter}
      />
    </div>
  );
}