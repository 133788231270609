import { FormComponentProps } from "antd/lib/form";
import { EntityField } from "../codegen";
import { EnhancedFormField, SelectionOption } from "../../components/enhancedForm/createFieldFromObject";
import moment, { Moment } from "moment";
import "moment-timezone";

export const displayDate = (date: Moment | Date | string, format: string): string => moment(date).format("YYYY-MM-DDTHH:mm:ss");

export const arrayToString = (array: any[], delimiter?: string) => array.toString().replace(/,/g, delimiter ? delimiter : ", ");

export function allowFormSubmit(fieldNames: string[], formProps: FormComponentProps["form"]) {
  const { getFieldError, isFieldTouched } = formProps;
  let allowSubmit = false;
  fieldNames.forEach(field => {
    const isTouched = isFieldTouched(field);
    if (!isTouched) allowSubmit = true;
    if (isTouched) if (getFieldError(field)) allowSubmit = true;
  });
  return allowSubmit;
}

export function mapEntityFieldsToEnhancedFormFields<P=any>(entityFields: EntityField[], getValueFromProps?: (entityField: EntityField, props: P) => any, parentProps?: P): EnhancedFormField<any>[] {
  let formFields: EnhancedFormField<P>[] = [];
  entityFields.forEach(entityField => {
    const { id, type, isEnabled, isMultiSelection, isRequired, name, position, selectionOptions } = entityField;
    if (isEnabled) formFields.push({
      key: id,
      type,
      name,
      isMultiSelection: typeof isMultiSelection === "boolean" ? isMultiSelection : undefined,
      selectionOptions: selectionOptions ? selectionOptions.map((opt): SelectionOption => ({ key: opt, name: opt })) : undefined,
      rules: [{ required: isRequired, message: `${name} is required` }],
      getValueFromProps: (props) => getValueFromProps && parentProps ? getValueFromProps(entityField, parentProps) : undefined
    })
  })
  return formFields;
}

export class Debounce {
  private duration: number
  private execRanAt: Date = new Date()
  private funcToRun: Function | null = null;
  private countdownInterval: NodeJS.Timeout
  private countDown: number = 0;

  private timeBetweenInterval = 10;

  constructor(duration: number) {
    this.duration = duration
    this.countDown = duration
    this.countdownInterval = setInterval(() => {
      if (this.countDown > this.timeBetweenInterval)
        this.countDown = this.countDown - this.timeBetweenInterval;
      else if (this.funcToRun) {
        this.funcToRun()
        this.funcToRun = null;
      }
    }, this.timeBetweenInterval)
  }

  exec(func: () => any) {
    this.funcToRun = func;
    this.countDown = this.duration
    this.execRanAt = new Date()
    // if (this.execTimeout) clearTimeout(this.execTimeout)
    // this.execTimeout = setTimeout(fnc, this.duration)
    // this.execCreatedAt = new Date()
  }

  isWaiting(): boolean {
    if (!this.execRanAt) return false;
    const timeDiff = new Date().getTime() - this.execRanAt.getTime()
    return timeDiff < this.duration;
  }
}

export class Limiter {
  func: Function
  interval: number
  lastRun: Date = new Date()
  lastCalled: Date = new Date()
  timeout: NodeJS.Timeout

  constructor(interval: number) {
    this.interval = interval
  }

  execute(func: Function) {
    this.func = func
    this.lastCalled = new Date()
    if (this.lastCalled.getTime() - this.lastRun.getTime() < this.interval) {
      this.lastRun = new Date()
      this.func()
    } else {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.lastRun = new Date()
        this.func()
      }, this.interval)
    }
  }

  isWaiting(): boolean {
    if (!this.lastRun) return false;
    const timeDiff = new Date().getTime() - this.lastRun.getTime()
    return timeDiff < this.interval;
  }
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
