import React, { useCallback } from "react";

export interface HighlightProps {
  text: string
  searchValue: string
  regexFlags?: string
  hideOnNoMatch?: boolean
  style?: React.CSSProperties
  matchStyle?: React.CSSProperties
}

interface RegExpStringIterator {
  index: number
  input: string
}

export const Highlight: React.FC<HighlightProps> = props => {
  // @ts-ignore
  const matchResults: any[] = [...props.text.matchAll(new RegExp(props.searchValue, props.regexFlags ? props.regexFlags : "gi"))]
  const sourceText = props.text;

  const Match = (_props: { text: string }) => <span className="highlight-match" style={{ backgroundColor: 'rgb(191, 224, 255)', ...props.matchStyle }}>{_props.text}</span>

  const renderHighlight = useCallback((): React.ReactNodeArray => {
    let elements: React.ReactNodeArray = [];
    if (matchResults.length === 0 && !(typeof props.hideOnNoMatch === "boolean" && props.hideOnNoMatch)) {
      elements.push(<span key="no-match">{sourceText}</span>)
    }
    matchResults.forEach((result, i) => {
      const isFirst = i === 0, isLast = (i + 1) === matchResults.length;
      const matchText = result[0], index = result.index;
      
      const beforeText = isFirst ? sourceText.substring(0, index) : sourceText.substring(matchResults[i - 1].index + matchResults[i - 1][0].length, index)

      elements.push(<span key={`before-${i}-match`}>{beforeText}</span>)
      elements.push(<Match key={i} text={sourceText.substr(index, matchText.length)} />)
      if (isLast) elements.push(<span key="after-last-match">{sourceText.substring(index + matchText.length)}</span>)
    })
    return elements;
  }, [props.text, props.searchValue])

  return (
    <span className="highlight-wrapper" style={{ ...props.style }}>
        {renderHighlight()}
    </span>
  );
}