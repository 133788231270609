import React, { useState, useContext } from "react";
import "./FieldGroup.scss"
import { FieldGroup, EntityField, UpdateFieldGroupComponent, DeleteFieldGroupComponent, FieldGroupsDocument, FieldGroupsQueryVariables, DeleteFieldGroupMutation, DeleteFieldGroupMutationVariables } from "../../../../lib/codegen";
import { FieldItem } from "../fieldItem/FieldItem";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Card, Button, Popconfirm, Tooltip } from "antd";
import { FieldManagerProps, FieldManagerContext } from "../../FieldManager";
import { PopForm } from "../../../popform/PopForm";
import { fieldGroupFields } from "./fieldGroupFields";
import { MutationFunction } from "react-apollo";

interface IProps {
  group: FieldGroup
  fields: EntityField[]
}

export const FieldGroupContainer: React.FC<IProps> = props => {
  const [editFormVisible, setEditFormVisible] = useState(false);
  const { positionVisibleState } = useContext(FieldManagerContext)
  const { id, name, position } = props.group;

  const renderDeleteBtn = (deleteGroup: MutationFunction<DeleteFieldGroupMutation, DeleteFieldGroupMutationVariables>) => {
    const deleteDisabled = props.fields.length !== 0
    if (!deleteDisabled) return (
      <Popconfirm title="Delete Group" onConfirm={() => deleteGroup({
        variables: { id: props.group.id },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: FieldGroupsDocument, variables: { fieldType: props.group.fieldType } as FieldGroupsQueryVariables }]
      })}>
        <Button>Delete</Button>
      </Popconfirm>
    )
    else return (
      <Tooltip title="Remove all fields to delete group">
        <Button disabled={true}>Delete</Button>
      </Tooltip>
    )
  }

  if (id === "UNDEFINED") return (
    <Card className="field-group no-group" bordered={false}>
      <h3 className="title">{name}</h3>
      <Droppable droppableId={id} type="field">
        {(provided2, snapshot2) => (
          <div className="drop-zone" ref={provided2.innerRef}>
            {props.fields.sort((a, b) => a.position - b.position).map(field => <FieldItem key={field.id} field={field} />)}
            {provided2.placeholder}
          </div>
        )}
      </Droppable>
    </Card>
  )

  return (
    <DeleteFieldGroupComponent>
      {deleteGroup => (
        <UpdateFieldGroupComponent>
          {updateFieldGroup => (
            <Draggable draggableId={props.group.id} index={position} type="group">
              {(provided, snapshot) => (
                <div className="field-group" ref={provided.innerRef} {...provided.draggableProps}>
                  <PopForm
                    title="Edit Group"
                    formFields={fieldGroupFields(props.group)}
                    visible={editFormVisible}
                    onSubmit={formData => updateFieldGroup({ variables: { id: props.group.id, data: { name: formData["name"] as any } } })}
                    onClose={() => setEditFormVisible(false)}
                  />

                  <Card
                    title={
                      <div className="card-title" {...provided.dragHandleProps}>
                        <span>{name}</span>
                        {positionVisibleState[0] && <span style={{ marginLeft: 5 }}>({position})</span>}
                      </div>
                    }
                    bordered={false}
                    extra={
                      <span>
                        <Button onClick={() => setEditFormVisible(true)}>Edit</Button>
                        {renderDeleteBtn(deleteGroup)}
                      </span>
                    }
                  >
                    <Droppable droppableId={id} type="field">
                      {(provided2, snapshot2) => (
                        <div className="drop-zone" ref={provided2.innerRef}>
                          {props.fields.sort((a, b) => a.position - b.position).map(field => <FieldItem key={field.id} field={field} />)}
                          {provided2.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Card>
                </div>
              )}
            </Draggable>
          )}
        </UpdateFieldGroupComponent>
      )}
    </DeleteFieldGroupComponent>
  );
}