import React, { useState } from "react";
import { DeleteClientPartnerComponent, CreateClientPartnerComponent, UpdateClientPartnerComponent, ClientPartnersComponent, QueryClientPartnersInput, ClientPartner, EntityFieldType, EntityRelationType, ClientPartnersQueryVariables, ClientPartnersQuery, ClientPartnerQueryVariables, ClientPartnerDocument, ClientPartnersDocument, ClientPartnerComponent } from "../../../../lib/codegen";
import { TableCard } from "../../../../components/tableCard/TableCard";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";
import { EnhancedFormField } from "../../../../components/enhancedForm/createFieldFromObject";

interface IProps {
  limit?: number
  clientsId?: string[]
}

const partnerFields = (defaultClientsId?: string[] | null, partner?: ClientPartner): EnhancedFormField<any>[] => [
  {
    key: "name",
    name: "Name",
    type: EntityFieldType["Text"],
    rules: [{ required: true, message: "Partner name is required" }],
    getValueFromProps: () => partner ? partner.name : undefined
  },
  {
    key: "clientsId",
    name: "Clients",
    type: EntityFieldType["Relation"],
    isMultiSelection: true,
    getValueFromProps: props => {
      if (partner && partner.clients) return partner.clients.map(client => client.id)
      else if (defaultClientsId) return defaultClientsId;
    },
    entityRelationType: EntityRelationType["Client"]
  }
]

export const PartnerTable: React.FC<IProps> = props => {
  const [entityQueryId, setEntityQueryId] = useState<string | null>(null);
  return (
    <DeleteClientPartnerComponent>
      {deleteMutation => (
        <CreateClientPartnerComponent>
          {createMutation => (
            <UpdateClientPartnerComponent>
              {updateMutation => (
                <ClientPartnerComponent variables={{ id: entityQueryId! }} skip={!entityQueryId}>
                  {entityQuery => (
                    <ClientPartnersComponent variables={{ data: { limit: props.limit, clientsId: props.clientsId } }}>
                      {({ loading, error, data, refetch, variables }) => {
                        const reloadDataArgs = { refetchQueries: [{ query: ClientPartnersDocument, variables }], awaitRefetchQueries: true }
                        return (
                          <TableCard<ClientPartner>
                            loading={loading}
                            pageSize={variables ? variables.data ? variables.data.limit ? variables.data.limit : 10 : 10 : 10}
                            dataSource={data ? data.clientPartners : undefined}
                            columns={[
                              {
                                key: "name",
                                dataIndex: "name",
                                title: <span className="header-title">Partners</span>,
                                ...getColumnSearchProps({
                                  dataIndex: "Partners",
                                  onSearch: value => refetch({ data: { ...variables.data, name: value } }),
                                  isFiltered: () => variables ? variables.data ? variables.data.name ? typeof variables.data.name === "string" : false : false : false,
                                  onReset: () => refetch({ data: { ...variables.data, name: undefined } })
                                })
                              }
                            ]}
                            getItemProps={item => ({
                              id: item.id
                            })}
                            actionBtn={{ title: "Partner", useCreateModel: true }}
                            handlePageChange={(Pager) => refetch({
                              data: {
                                ...variables.data,
                                limit: Pager.pageSize,
                                offset: (Pager.current ? Pager.current - 1 : 0) * Pager.pageSize!,
                              }
                            })}
                            onRowEdit={item => setEntityQueryId(item.id)}
                            onRowDelete={item => deleteMutation({ variables: { id: item.id }, ...reloadDataArgs })}
                            createModal={{
                              title: "Create Partner",
                              fields: partnerFields(props.clientsId),
                              onSave: (formData, props) => createMutation({ variables: { data: formData as any }, ...reloadDataArgs })
                            }}
                            updateModal={{
                              title: "Update Partner",
                              fields: partnerFields(null, entityQuery.data && entityQuery.data.clientPartner ? entityQuery.data.clientPartner : undefined),
                              onSave: (formData, props) => updateMutation({ variables: { id: props.entityId!, data: formData as any, ...reloadDataArgs } }),
                            }}
                          />
                        )
                      }}
                    </ClientPartnersComponent>
                  )}
                </ClientPartnerComponent>
              )}
            </UpdateClientPartnerComponent>
          )}
        </CreateClientPartnerComponent>
      )}
    </DeleteClientPartnerComponent>
  )
};