import React, { useState } from "react";
import { ReportEntityMetadataComponent, ReportMetadata, MeetingFieldsComponent, ContactFieldsComponent, CompanyFieldsComponent, EntityField } from "../../../../../../lib/codegen";
import { ColumnList } from "../columnList/ColumnList";
import { Input, Icon } from "antd";
import { IReportColumn, IReportColumnFieldType } from "../reportColumn/ReportColumnItem";
import { ColumnListState } from "../ReportSettings";

interface IProps {
  columnListState: [ColumnListState, React.Dispatch<React.SetStateAction<ColumnListState>>]
}

export const AvailableColumns: React.FC<IProps> = props => {
  const [columnsLoaded, setColumnsLoaded] = useState<Boolean>(false);
  const [columnListState, setColumnListState] = props.columnListState;
  const [searchValue, setSearchValue] = useState("");

  function getStaticColumns(fields?: ReportMetadata["fields"], parentName?: string): IReportColumn[] {
    if (!fields) return [];
    let columns: IReportColumn[] = [];
    fields.forEach((field, i) => {
      if (field.type === "FIELD") {
        columns.push({ id: field.staticFieldKey!, name: parentName + " " + field.name, columnFieldType: "STATIC", position: i })
      } else if (field.type === "RELATION" && field.fields && field.entityName) {
        columns.push(...getStaticColumns(field.fields, field.name))
      }
    })
    return columns;
  }

  function getEntityFieldColumns(entityName: string, columnFieldType: IReportColumnFieldType, fields?: Array<Object & EntityField> | null): IReportColumn[] {
    if (!fields) return [];
    return fields.map((field, i): IReportColumn => ({
      id: field.id,
      name: entityName + " " + field.name,
      columnFieldType,
      position: i
    }))
  }

  return (
    <MeetingFieldsComponent>
      {meetingFieldsQuery => (
        <ContactFieldsComponent>
          {contactFieldsQuery => (
            <CompanyFieldsComponent>
              {companyFieldsQuery => (
                <ReportEntityMetadataComponent>
                  {({ error, data, loading }) => {
                    const metadata = data && data.reportEntityMetadata ? data.reportEntityMetadata : null
                    const meetingFields = meetingFieldsQuery.data && meetingFieldsQuery.data.meetingFields ? meetingFieldsQuery.data.meetingFields : null
                    const contactFields = contactFieldsQuery.data && contactFieldsQuery.data.contactFields ? contactFieldsQuery.data.contactFields : null
                    const companyFields = companyFieldsQuery.data && companyFieldsQuery.data.companyFields ? companyFieldsQuery.data.companyFields : null
                    const mergeColumns = [
                      ...getStaticColumns(metadata ? metadata.fields : [], metadata ? metadata.entityName : ""),
                      ...getEntityFieldColumns("Meeting", "MEETING", meetingFields),
                      ...getEntityFieldColumns("Contact", "CONTACT", contactFields),
                      ...getEntityFieldColumns("Company", "COMPANY", companyFields),
                    ].filter(col => col.name.toLowerCase().search(searchValue.toLowerCase()) > -1).map((field, i) => ({ ...field, position: i }));
                    if (!columnsLoaded && !loading && !meetingFieldsQuery.loading && !contactFieldsQuery.loading && !companyFieldsQuery.loading) {
                      setColumnListState({ ...columnListState, available: mergeColumns })
                      setColumnsLoaded(true);
                    }
                    return (
                      <div>
                        <h3>Available Columns</h3>
                        <Input
                          suffix={<Icon type="search" />}
                          placeholder="Search Columns"
                          value={searchValue}
                          onChange={e => setSearchValue(e.target.value)}
                        />
                        <ColumnList
                          columns={mergeColumns}
                          droppableId="available-columns"
                          loading={loading}
                        />
                      </div>
                    )
                  }}
                </ReportEntityMetadataComponent>
              )}
            </CompanyFieldsComponent>
          )}
        </ContactFieldsComponent>
      )}
    </MeetingFieldsComponent>
  )
}