import React, { useState } from "react";
import { EnhancedFormField } from "../../../../components/enhancedForm/createFieldFromObject";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";
import { TableCard } from "../../../../components/tableCard/TableCard";
import { EntityFieldType, EntityRelationType, UpdateMeetingTagComponent, DeleteMeetingTagComponent, CreateMeetingTagComponent, MeetingTagsDocument, MeetingTagsComponent, MeetingTag, MeetingTagQueryVariables, MeetingTagDocument, MeetingTagComponent } from "../../../../lib/codegen";

interface IProps {
  limit?: number
}

function getFields(meetingTag?: MeetingTag): EnhancedFormField<any>[] {
  return [
    {
      key: "name",
      name: "Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "Partner name is required" }],
      getValueFromProps: () => meetingTag ? meetingTag.name : undefined
    }
  ]
}

export const MeetingTagTable: React.FC<IProps> = props => {
  const [entityQueryId, setEntityQueryId] = useState<string | null>(null);
  return (
    <UpdateMeetingTagComponent>
      {updateMutation => (
        <DeleteMeetingTagComponent>
          {deleteMutation => (
            <CreateMeetingTagComponent>
              {createMutation => (
                <MeetingTagComponent variables={{ id: entityQueryId! }} skip={!entityQueryId}>
                  {entityQuery => (
                    <MeetingTagsComponent variables={{ data: { limit: props.limit } }}>
                      {({ loading, error, data, refetch, variables }) => {
                        const reloadDataArgs = { refetchQueries: [{ query: MeetingTagsDocument, variables }], awaitRefetchQueries: true }
                        return (
                          <TableCard<MeetingTag>
                            loading={loading}
                            pageSize={variables ? variables.data ? variables.data.limit ? variables.data.limit : 10 : 10 : 10}
                            dataSource={data ? data.meetingTags : undefined}
                            columns={[
                              {
                                key: "name",
                                dataIndex: "name",
                                title: <span className="header-title">Meeting Tags</span>,
                                ...getColumnSearchProps({
                                  dataIndex: "Meeting Tags",
                                  onSearch: value => refetch({ data: { ...variables.data, name: value } }),
                                  isFiltered: () => variables ? variables.data ? variables.data.name ? typeof variables.data.name === "string" : false : false : false,
                                  onReset: () => refetch({ data: { ...variables.data, name: undefined } })
                                })
                              }
                            ]}
                            getItemProps={item => ({
                              id: item.id
                            })}
                            actionBtn={{ title: "Meeting Tag", useCreateModel: true }}
                            createModal={{
                              title: "Create Meeting Tag",
                              fields: getFields(),
                              onSave: (formData, props) => createMutation({ variables: { data: formData as any }, ...reloadDataArgs })
                            }}
                            updateModal={{
                              title: "Update Meeting Tag",
                              fields: getFields(entityQuery && entityQuery.data && entityQuery.data.meetingTag ? entityQuery.data.meetingTag : undefined),
                              onSave: (formData, props) => updateMutation({ variables: { id: props.entityId!, data: formData as any, ...reloadDataArgs } })
                            }}
                            handlePageChange={(Pager) => refetch({
                              data: {
                                ...variables.data,
                                limit: Pager.pageSize,
                                offset: (Pager.current ? Pager.current - 1 : 0) * Pager.pageSize!,
                              }
                            })}
                            onRowDelete={item => deleteMutation({ variables: { id: item.id }, ...reloadDataArgs })}
                            onRowEdit={item => setEntityQueryId(item.id)}
                          />
                        )
                      }}
                    </MeetingTagsComponent>
                  )}
                </MeetingTagComponent>
              )}
            </CreateMeetingTagComponent>
          )}
        </DeleteMeetingTagComponent>
      )}
    </UpdateMeetingTagComponent>
  )
}