import React from "react";
import { Row, Col } from "antd";
import { PartnerTable } from "./partnerTable/PartnerTable";
import { ClientTable } from "./clientTable/ClientTable";
import { MeetingTagTable } from "./meetingTagTable/MeetingTagTable";
import { UserTable } from "./userTable/UserTable";
import { CampaignPane } from "../clientPage/campaignPane/CampaignPane";
import { TradeshowTable } from "../clientPage/tradeshowTable/TradeshowTable";

interface IProps {

}

export const AdminDashboard: React.FC<IProps> = props => {
  return (
    <div className="admin-dashboard">
      <CampaignPane limit={15} />
      <br />
      <Row>
        <Col md={12}>
          <MeetingTagTable limit={5}/>
        </Col>
        <Col md={12}>
          <TradeshowTable limit={5}/>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <ClientTable limit={5}/>
        </Col>
        <Col md={12}>
          <PartnerTable limit={5} />
        </Col>
      </Row>
    </div>
  );
}