import React from "react";
import { EnhancedFormFieldsObject, EnhancedFormFieldGroup, EnhancedFormFieldOrFieldArray } from "./withEnhancedForm";
import { FormComponentProps } from "antd/lib/form";
import { createFieldFromObject, EnhancedFormField } from "./createFieldFromObject";
import { Row, Col } from "antd";
import { getNestedFieldObjectType } from "./formHelpers/formHelpers";
import { FieldGroupTitle } from "./formHelpers/FieldGroupTitle";

function renderNestedFields<P = any>(fields: EnhancedFormField<P>[], form: FormComponentProps["form"], props: P, key: string | number) {
  let colSize = Math.floor(24 / fields.length);
  if (colSize === 24) return (
    createFieldFromObject(fields[0], form, props, {
      label: {
        sm: 24,
        md: 6,
        lg: 5,
      },
      field: {
        sm: 24,
        md: 18,
        lg: 19,
      }
    })
  )
  return (
    <Row key={key}>
      {fields.map((field, fieldIndex) => (
        <Col className="field-column" key={field.key} sm={24} md={colSize} style={{ paddingLeft: fieldIndex !== 0 ? 10 : 0 }}>{createFieldFromObject(field, form, props, {
          label: {
            xs: 24,
            md: colSize,
            lg: colSize - 2,
          },
          field: {
            xs: 24,
            md: colSize,
            lg: colSize + 2,
          }
        })}</Col>
      ))}
    </Row>
  )
}

export function renderFields<P = any>(fieldsObject: EnhancedFormFieldsObject<P>, form: FormComponentProps["form"], props: P) {
  function renderFieldOrFieldArray<P = any>(maybeField: EnhancedFormFieldOrFieldArray<P>, form: FormComponentProps["form"], props: P, key: string | number): React.ReactNode | void {
    const objectType = getNestedFieldObjectType(maybeField);
    switch (objectType) {
      case "FIELD": return renderNestedFields([maybeField as EnhancedFormField<P>], form, props, key)
      case "FIELD_ARRAY": return renderNestedFields(maybeField as EnhancedFormField<P>[], form, props, key)
    }
  }
  
  let elements: React.ReactNodeArray = [];

  for (let i = 0; i < fieldsObject.length; i++) {
    let fieldObject = fieldsObject[i];
    let objectType = getNestedFieldObjectType(fieldObject);
    if (objectType === "GROUP") {
      const group = fieldObject as EnhancedFormFieldGroup<P>;
      const groupFields = group.fields.map((maybeField, index) => {
        const element = renderFieldOrFieldArray(maybeField as EnhancedFormFieldOrFieldArray<P>, form, props, `${i}-${index}`);
        if (element) return element
      }).filter(value => typeof value !== "undefined")
      if (groupFields.length > 0) {
        elements.push([<FieldGroupTitle key={i} title={group.title} />]);
        elements.push(...groupFields)
      }
    } else {
      const element = renderFieldOrFieldArray(fieldObject as EnhancedFormFieldOrFieldArray<P>, form, props, `${i}`);
      if (element) elements.push(element)
    }
  }

  return elements;
}