import * as React from "react";
import SideMenu, { SideMenuOption, SideMenuSubOption } from "../../components/sideMenu/SideMenu";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router";
import { ManageCompanyFields, ManageContactFields, ManageMeetingFields } from "./manageFields/ManageFields";
import { AdminDashboard } from "./adminDashboard/AdminDashboard";
import { ClientsComponent, Client, MeComponent, Role } from "../../lib/codegen";
import { ClientPage } from "./clientPage/ClientPage";
import { UserPage } from "./userPage/UserPage";
import { Reports } from "../reports/Reports";
import { ZoomIndex } from "./zoom/ZoomIndex";

export const AdminSideMenu: React.FC = props => {
  const getClientOptions = (clients: Client[]) => clients.map(({ id, name }): SideMenuSubOption => ({
    key: `/admin/clients/${id}`,
    title: name,
    linkTo: `/admin/clients/${id}`
  }))

  const getSideMenuOptions = (data: { clients: Client[] }): SideMenuOption[] => ([
    {
      key: "dashboard",
      title: "Dashboard",
      icon: "dashboard",
      linkTo: "/admin"
    },
    {
      key: "reports",
      title: "Reports",
      icon: "bar-chart",
      linkTo: "/admin/reports",
      matcher: / \/admin\/reports\/.* /g
    },
    {
      key: "zoom",
      title: "Zoom",
      icon: <img style={{ height: 15, width: 15, marginRight: 10 }} src='/media/icons/zoom-icon.svg' />,
      linkTo: "/admin/zoom",
      matcher: / \/admin\/zoom\/.* /g
    },
    {
      key: "users",
      title: "Users",
      icon: "team",
      linkTo: "/admin/users",
      matcher: /\/admin\/users\/.*/g
    },
    {
      key: "clients",
      title: "Clients",
      icon: "gold",
      subOptions: getClientOptions(data.clients)
    },
    {
      key: "manage-fields",
      title: "Manage Fields",
      icon: "control",
      subOptions: [
        {
          key: "company",
          title: "Company",
          linkTo: "/admin/manage-fields/company"
        },
        {
          key: "contact",
          title: "Contact",
          linkTo: "/admin/manage-fields/contact"
        },
        {
          key: "meeting",
          title: "Meeting",
          linkTo: "/admin/manage-fields/meeting"
        }
      ]
    }
  ])

  return (
    <ClientsComponent>
      {({ loading, error, data }) => (
        <SideMenu
          optionsLoading={loading}
          sideMenuOptions={getSideMenuOptions({ clients: data ? data.clients ? data.clients.items ? data.clients.items : [] : [] : [] })}
        />
      )}
    </ClientsComponent>
  )
}

const Admin: React.FC<RouteComponentProps> = (props) => (
  <MeComponent>
    {({ error, loading, data }) => {
      const isNotAdmin: boolean = !loading && data && data.me && data.me.role && data.me.role !== Role["Admin"] ? true : false;
      if (isNotAdmin) props.history.push("/meetings");
      return (
        <Switch>
          <Route path="/admin/manage-fields/company" component={ManageCompanyFields} />
          <Route path="/admin/manage-fields/contact" component={ManageContactFields} />
          <Route path="/admin/manage-fields/meeting" component={ManageMeetingFields} />
          <Route path="/admin/clients/:clientId" component={ClientPage} />
          <Route path="/admin/zoom/:userId?" component={ZoomIndex} />
          <Route path="/admin/users/:userId?" component={UserPage} />
          <Route path="/admin/reports/:reportId?/:pageName?" component={Reports} />
          <Route path="/admin" component={AdminDashboard} />
        </Switch>
      )
    }}
  </MeComponent>
)

export default Admin;