import React from "react";
import { EnhancedFormField } from "./createFieldFromObject";
import { Select, Icon } from "antd";
import { Debounce } from "../../lib/helpers/helpers";
import { SelectProps } from "antd/lib/select";

type StringFunc = () => string;

export interface IEntityData {
  key: string | number
  name: string | React.ReactNode
}

export interface ParentRelationFieldProps {
  field: EnhancedFormField<any>
  value?: string | StringFunc
  placeholder?: string
  onChange?: (value: string) => void
}

export interface RelationFieldProps<T> {
  field: EnhancedFormField<any>
  entities: T[]
  loading?: boolean
  debounce?: number
  value?: string | StringFunc
  ref?: React.Ref<any>
  placeholder?: string
  getEntityData: (option: T) => IEntityData
  onSearch: (value: string) => void
  onChange?: (value: string) => void
  selectProps?: SelectProps<string>
}

export function RelationField<T>(props: RelationFieldProps<T>) {
  let defaultValue = typeof props.value !== "undefined" && typeof props.value !== "function" ? { value: props.value } : {};
  if (typeof props.value === "function") defaultValue = { value: props.value() }
  const debounce = new Debounce(props.debounce ? props.debounce : 500);

  function onSearch(value: string) {
    debounce.exec(() => props.onSearch(value))
  }

  return (
    <Select
      ref={props.ref}
      showSearch={true}
      onSearch={onSearch}
      filterOption={false}
      defaultActiveFirstOption={false}
      allowClear={props.loading ? false : true}
      mode={props.field.isMultiSelection ? "multiple" : undefined}
      className={props.field.className ? props.field.className + "select-field" : "select-field"}
      style={props.field.style ? props.field.style : undefined}
      onChange={props.onChange}
      {...defaultValue}
      suffixIcon={<Icon type={props.loading ? "loading" : "search"} className="icon-no-spin" />}
      placeholder={props.placeholder}
      {...props.selectProps}
    >
      {props.entities.map(entity => {
        const { key, name } = props.getEntityData(entity);
        return <Select.Option key={key}>{name}</Select.Option>
      })}
    </Select>
  )
}