import React from "react";
import { FilterEntry } from "../../../lib/codegen";
import { Card, Button } from "antd";
import { useForm } from "../../../lib/helpers/hooks/useForm";
import { FilterOptionProps, getFilterOptionName } from "../filterOption/FilterOption";
import { getFilterId } from "../helpers/getFilterId";
import { FilterForm, FilterFormFields } from "../filterForm/FilterForm";
import { FilterEntryWithId } from "../../../pages/meetings/meetingFilterGroups/components/MeetingFilterGroupDrawer";

export interface FilterItemProps {
  filterEntry: (FilterEntry | FilterEntryWithId)
  filterOptions: FilterOptionProps[]
  useFilterEntryId?: boolean
  handleFilterUpdate: (filterEntry: (FilterEntry | FilterEntryWithId)) => void
  handleFilterDelete: (filterEntry: (FilterEntry | FilterEntryWithId)) => void
}

export const FilterItem: React.FC<FilterItemProps> = props => {
  const form = useForm<FilterFormFields>({
    comparisonOperator: props.filterEntry.comparisonOperator,
    filterValue: props.filterEntry.filterValue,
    relativeDateDaysOffset: props.filterEntry.relativeDateDaysOffset
  })
  const filterOption: FilterOptionProps | undefined = props.filterOptions.find(filterOption => filterOption.id === getFilterId(props.filterEntry));
  const name = filterOption ? getFilterOptionName(filterOption) : props.filterEntry.entityName;

  const filterHasChanges = (): boolean =>
    props.filterEntry.filterValue !== form.getFieldValue("filterValue") ||
    props.filterEntry.comparisonOperator !== form.getFieldValue("comparisonOperator") ||
    props.filterEntry.relativeDateDaysOffset !== form.getFieldValue("relativeDateDaysOffset")

  const getFilterEntry = (): (FilterEntry | FilterEntryWithId) => ({
    comparisonOperator: form.getFieldValue("comparisonOperator"),
    filterValue: form.getFieldValue("filterValue"),
    entityName: props.filterEntry.entityName,
    customFieldId: props.filterEntry.customFieldId,
    staticFieldKey: props.filterEntry.staticFieldKey,
    relativeDateDaysOffset: form.getFieldValue("relativeDateDaysOffset"),
    // @ts-ignore
    id: props.useFilterEntryId ? props.filterEntry.id : undefined
  })

  return (
    <Card
      title={name}
      className="filter-card"
      extra={<>
        <Button icon="close" type="danger" onClick={() => props.handleFilterDelete(props.filterEntry)} />
        <Button icon="check" type="primary" disabled={!filterHasChanges()} onClick={() => props.handleFilterUpdate(getFilterEntry())} style={{ marginLeft: 10 }} />
      </>}
    >
      {filterOption && <FilterForm
        form={form}
        filterOption={filterOption}
        filterEntry={props.filterEntry}
      />}
    </Card>
  );
}