import React, { useState } from "react";
import { FilterEntry, ComparisonOperator, EntityFieldType } from "../../../lib/codegen";
import { UseFormMethods } from "../../../lib/helpers/hooks/useForm";
import { IFilterDataType, normalizeEntityFieldType } from "../helpers/normalizeEntityFieldType";
import { FilterOptionProps } from "../filterOption/FilterOption";
import { SelectionOption, getRelationFieldByType } from "../../enhancedForm/createFieldFromObject";
import { ComparisonSelect } from "../helpers/ComparisonSelect";
import { Input, Checkbox, DatePicker, Select, Switch, Icon, InputNumber } from "antd";
import moment from "moment"

export interface FilterFormFields {
  comparisonOperator: ComparisonOperator
  filterValue: any
  relativeDateDaysOffset?: number | null
}

export interface FilterFormProps {
  form: UseFormMethods<FilterFormFields>
  filterOption: FilterOptionProps
  filterEntry?: FilterEntry
  onChange?: (values: FilterFormFields) => void
}

export const FilterForm: React.FC<FilterFormProps> = props => {
  // consts
  const form = props.form;
  const { getFieldValue } = form;

  // state
  const [isRelativeDate, setIsRelativeDate] = useState<boolean>(getFieldValue("relativeDateDaysOffset") ? getFieldValue("relativeDateDaysOffset") : false)

  function setFieldValue(key: keyof FilterFormFields, value: any) {
    form.setFieldValue(key, value);
    if (props.onChange) props.onChange(form.fieldsValue);
  }

  // calculated
  const type: IFilterDataType = props.filterOption.fieldMetadata && props.filterOption.fieldMetadata.dataType ? props.filterOption.fieldMetadata.dataType as IFilterDataType : props.filterOption.entityField ? normalizeEntityFieldType(props.filterOption.entityField.type) : "string";
  const selectonOptions: SelectionOption[] | undefined =
    props.filterOption.fieldMetadata && props.filterOption.fieldMetadata.selectionOptions ? props.filterOption.fieldMetadata.selectionOptions as SelectionOption[] :
      props.filterOption.entityField && props.filterOption.entityField.selectionOptions ? props.filterOption.entityField.selectionOptions.map(key => ({ key, name: key })) : undefined

  const RelationField = type === "id" && props.filterOption.fieldMetadata && props.filterOption.fieldMetadata.relationIdType ? getRelationFieldByType(props.filterOption.fieldMetadata.relationIdType) : undefined;

  return (
    <div>
      <ComparisonSelect
        style={{ width: "125px", marginBottom: 10 }}
        onChange={value => setFieldValue("comparisonOperator", value)}
        value={getFieldValue("comparisonOperator")}
        type={type}
      />
      {type === "string" && <Input
        style={{ width: "100%" }}
        onChange={e => setFieldValue("filterValue", e.target.value)}
        value={getFieldValue("filterValue")}
      />}
      {type === "number" && <Input
        style={{ width: "100%" }}
        onChange={e => setFieldValue("filterValue", e.target.value)}
        value={getFieldValue("filterValue")}
      />}
      {type === "boolean" && <Checkbox
        style={{ width: "100%" }}
        onChange={e => setFieldValue("filterValue", e.target.checked)}
        checked={getFieldValue("filterValue")}
      />}

      {/* date options */}
      {type === "id" && RelationField && <RelationField
        field={{
          type: EntityFieldType["Relation"],
          key: 'id',
          entityRelationType: props.filterOption!.fieldMetadata!.relationIdType!,
          style: {
            minWidth: 250
          }
        }}
        value={getFieldValue("filterValue")}
        onChange={value => setFieldValue("filterValue", value)}
      />}

      {/* date options */}
      {type === "date" && <div>
        <Switch
          title="Is Relative Date?"
          checked={isRelativeDate}
          onChange={value => {
            setIsRelativeDate(value)
            if (value === false) setFieldValue("relativeDateDaysOffset", null)
            if (value && !getFieldValue("relativeDateDaysOffset") && props.filterEntry && props.filterEntry.relativeDateDaysOffset) setFieldValue("relativeDateDaysOffset", props.filterEntry.relativeDateDaysOffset)
          }}
          checkedChildren={<Icon type="unlock" />}
          unCheckedChildren={<Icon type="lock" />}
          style={{ marginRight: 10 }}
        />
        {!isRelativeDate && <DatePicker
          format="M/D/YYYY"
          onChange={date => setFieldValue("filterValue", date ? date.format("YYYY-MM-DD") : null)}
          value={getFieldValue("filterValue") ? moment(getFieldValue("filterValue"), "YYYY-MM-DD") : undefined}
        />}
        {isRelativeDate && <>
          <InputNumber
            style={{ marginRight: 10, width: 60 }}
            onChange={value => setFieldValue("relativeDateDaysOffset", value)}
            value={getFieldValue("relativeDateDaysOffset")}
            precision={0}
          />
          <span style={{ fontWeight: 500 }}>Days from Today</span>
        </>}
      </div>}

      {/* selection */}
      {selectonOptions && <Select
        onChange={(value: any) => setFieldValue("filterValue", value)}
        value={getFieldValue("filterValue") ? getFieldValue("filterValue") : undefined}
        style={{ width: "100%" }}
        mode="tags"
      >
        {selectonOptions.map(({ key, name }) => <Select.Option key={key} value={key}>{name}</Select.Option>)}
      </Select>}
    </div>
  );
}