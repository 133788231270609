import { EnhancedFormFieldOrFieldArray, EnhancedFormFieldGroup, EnhancedFormFieldsObject } from "../withEnhancedForm";
import { EnhancedFormField } from "../createFieldFromObject";

export type NestedFieldObjectType = "FIELD" | "FIELD_ARRAY" | "GROUP";

export function getNestedFieldObjectType<P=any>(object: EnhancedFormFieldOrFieldArray<P> | EnhancedFormFieldGroup<P>): NestedFieldObjectType  {
  if (Array.isArray(object)) return "FIELD_ARRAY";
  else {
    const maybeField = object as EnhancedFormField<any>;
    if (typeof maybeField.key === "string") return "FIELD";
    else return "GROUP";
  }
}

export function flattenFieldsFromObject<P=any>(fieldsObject: EnhancedFormFieldsObject<P>): EnhancedFormField<P>[] {
  let fields: Array<EnhancedFormField<P>> = [];
  fieldsObject.forEach(maybeField => {
    const objectType = getNestedFieldObjectType(maybeField);
    if (objectType === "FIELD") fields.push(maybeField as EnhancedFormField<P>);
    else if (objectType === "FIELD_ARRAY") {
      const fieldArray = maybeField as EnhancedFormField<P>[];
      fieldArray.forEach(field => fields.push(field)) 
    } else if (objectType === "GROUP") {
      const group = maybeField as EnhancedFormFieldGroup<P>;
      group.fields.forEach(field => {
        const fieldType = getNestedFieldObjectType(field);
        if (fieldType === "FIELD") fields.push(field as EnhancedFormField<P>);
        else if (fieldType === "FIELD_ARRAY") {
          const fieldArray = field as EnhancedFormField<P>[];
          fieldArray.forEach(field => fields.push(field)) 
        } 
      })
    }
  })
  return fields;
}