import React from "react";
import { MeetingFilterGroup } from "../MeetingFilterGroups";
import { FiltersList } from "../../../../components/filtersList/FiltersList";
import { FilterEntry } from "../../../../lib/codegen";
import { getFilterId } from "../../../../components/filtersList/helpers/getFilterId";
import { uuidv4 } from "../../../../lib/helpers/helpers";

interface IProps {
  group: MeetingFilterGroup
  updateGroup: (group: MeetingFilterGroup) => void
}

export type FilterEntryWithId = FilterEntry & { id?: string}

export const MeetingFilterGroupDrawer: React.FC<IProps> = props => {
  return (
    <div>
      <FiltersList
        filterState={[props.group.filters, (filters: FilterEntryWithId[]) => { }]}
        handleFilterCreate={filter => {
          filter = { ...filter, id: uuidv4() };
          props.updateGroup({ ...props.group, filters: [...props.group.filters, filter ] })
        }}
        handleFilterUpdate={newFilter => {
          props.updateGroup({
            ...props.group, filters: props.group.filters.map(filter => {
              if (filter.id && newFilter.id && filter.id === newFilter.id)
                return newFilter
              else
                return filter
            }
            )
          })
        }}
        handleFilterDelete={deleteFilter => {
          props.updateGroup({
            ...props.group, filters: props.group.filters.filter(filter => {
              if (!(filter.id && deleteFilter.id && filter.id === deleteFilter.id))
                return filter
            })
          })
        }}
      />
    </div>
  );
}