import React, { useState } from "react";
import { DeleteTradeshowComponent, CreateTradeshowComponent, UpdateTradeshowComponent, TradeshowsComponent, TradeshowComponent, Tradeshow, EntityFieldType, EntityRelationType } from "../../../../lib/codegen";
import { TableCard } from "../../../../components/tableCard/TableCard";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";
import { EnhancedFormField } from "../../../../components/enhancedForm/createFieldFromObject";

interface IProps {
  limit?: number;
  clientId?: string
}

const tradeshowFields = (defaultClientId: string | null, tradeshow?: Tradeshow): EnhancedFormField<any>[] => [
  {
    key: "name",
    name: "Name",
    type: EntityFieldType["Text"],
    rules: [{ required: true, message: "Tradeshow name is required" }],
    getValueFromProps: () => tradeshow ? tradeshow.name : null
  },
  {
    key: "clientId",
    name: "Client",
    type: EntityFieldType["Relation"],
    entityRelationType: EntityRelationType["Client"],
    rules: [{ required: true, message: "Client is required" }],
    getValueFromProps: () => {
      if (tradeshow && tradeshow.client) return tradeshow.client.id
      else if (defaultClientId) return defaultClientId
    }
  },
  {
    key: "partnerId",
    name: "Partner",
    type: EntityFieldType["Relation"],
    entityRelationType: EntityRelationType["Partner"],
    getValueFromProps: () => tradeshow && tradeshow.partner ? tradeshow.partner.id : null
  },
]

export const TradeshowTable: React.FC<IProps> = props => {
  const [queryTradeshowId, setQueryTradeshowId] = useState<string | null>(null);
  return (
    <DeleteTradeshowComponent>
      {deleteMutation => (
        <CreateTradeshowComponent>
          {createMutation => (
            <UpdateTradeshowComponent>
              {updateMutation => (
                <TradeshowComponent variables={{ id: queryTradeshowId! }} skip={!queryTradeshowId}>
                  {entityQuery => (
                    <TradeshowsComponent variables={{ data: { limit: props.limit, clientId: props.clientId } }}>
                      {({ loading, error, data, refetch, variables }) => {
                        const reloadDataArgs = { refetchQueries: [{ query: TradeshowsComponent, variables }], awaitRefetchQueries: true }
                        return (
                          <TableCard<Tradeshow>
                            loading={loading}
                            pageSize={variables ? variables.data ? variables.data.limit ? variables.data.limit : 10 : 10 : 10}
                            dataSource={data ? data.tradeshows : undefined}
                            columns={[
                              {
                                key: "name",
                                dataIndex: "name",
                                title: <span className="header-title">Tradeshows</span>,
                                ...getColumnSearchProps({
                                  dataIndex: "name",
                                  onSearch: value => refetch({ data: { ...variables.data, name: value } }),
                                  isFiltered: () => variables ? variables.data ? variables.data.name ? typeof variables.data.name === "string" : false : false : false,
                                  onReset: () => refetch({ data: { ...variables.data, name: undefined } })
                                })
                              }
                            ]}
                            getItemProps={item => ({
                              id: item.id
                            })}
                            actionBtn={{ title: "Tradeshow", useCreateModel: true }}
                            handlePageChange={(Pager) => refetch({
                              data: {
                                ...variables.data,
                                limit: Pager.pageSize,
                                offset: (Pager.current ? Pager.current - 1 : 0) * Pager.pageSize!,
                              }
                            })}
                            onRowEdit={item => setQueryTradeshowId(item.id)}
                            onRowDelete={item => deleteMutation({ variables: { id: item.id } }).then(() => refetch())}
                            createModal={{
                              title: "Create Partner",
                              fields: tradeshowFields(props.clientId ? props.clientId : null),
                              onSave: (formData) => createMutation({ variables: { data: formData as any } }).then(() => refetch())
                            }}
                            updateModal={{
                              title: "Update Partner",
                              fields: tradeshowFields(null, entityQuery && entityQuery.data && entityQuery.data.tradeshow ? entityQuery.data.tradeshow : undefined),
                              onSave: (formData, props) => updateMutation({ variables: { id: props.entityId!, data: formData as any, ...reloadDataArgs } }),
                            }}
                          />
                        )
                      }}
                    </TradeshowsComponent>
                  )}
                </TradeshowComponent>
              )}
            </UpdateTradeshowComponent>
          )}
        </CreateTradeshowComponent>
      )}
    </DeleteTradeshowComponent>
  )
}