import { EntityFieldType } from "../../../lib/codegen";

// export type Selection
export type IFilterDataType = "string" | "number" | "boolean" | "date" | "selection" | "id"

export function normalizeEntityFieldType(type: EntityFieldType): IFilterDataType {
  switch (type) {
    case "TEXT": return "string"
    case "RICHTEXT": return "string"
    case "PHONE": return "string"
    case "EMAIL": return "string"
    case "BOOLEAN": return "boolean"
    case "NUMBER": return "number"
    case "DATE": return "date"
    case "SELECTION": return "selection"
    case "RELATION": return "selection"
    default: return "string"
  }
}