import React, { useState } from "react";
import { useLeaderboardQuery, LeaderboardDateRange, LeaderboardUser } from "../../../lib/codegen";
import { UseState } from "../../../lib/ts/types";
import { Select, Card } from "antd";
import { Title } from "../../../components/display/title/Title";

interface IProps {

}

export const LeaderboardDateRangeSelect = (props: { selectState: UseState<LeaderboardDateRange> }) =>
  <Select
    onChange={(value: LeaderboardDateRange) => props.selectState[1](value)}
    value={props.selectState[0]}
    style={{
      minWidth: 100
    }}
  >
    <Select.Option value={LeaderboardDateRange["Week"]}>This Week</Select.Option>
    <Select.Option value={LeaderboardDateRange["Month"]}>This Month</Select.Option>
    <Select.Option value={LeaderboardDateRange["Year"]}>This Year</Select.Option>
    <Select.Option value={LeaderboardDateRange["AllTime"]}>All Time</Select.Option>
  </Select>

type LimitSelectValue = number | undefined;
const LimitSelect = (props: { selectState: UseState<LimitSelectValue> }) =>
  <Select
    onChange={(value: LimitSelectValue) => props.selectState[1](value)}
    value={props.selectState[0]}
    style={{
      minWidth: 100
    }}
  >
    <Select.Option value={3}>Top 3</Select.Option>
    <Select.Option value={5}>Top 5</Select.Option>
    <Select.Option value={10}>Top 10</Select.Option>
    <Select.Option value={9999}>All</Select.Option>
  </Select>

const UserDisplay = (props: { user: LeaderboardUser, position: number, isLast: boolean }) =>
  <div style={{
    marginBottom: !props.isLast ? 10 : 0
  }}>
    <Title
      size="xs"
      style={{
        color: "#000"
      }}
    >
      #{props.position} - {props.user.userName} ({props.user.meetingsHeld + props.user.meetingsPlanned} meetings)
    </Title>
    <div style={{
      marginLeft: 40
    }}>
      <tr>Planned: {props.user.meetingsPlanned}</tr>
      <tr>Held: {props.user.meetingsHeld}</tr>
    </div>
  </div>

export const UserLeaderboard: React.FC<IProps> = props => {
  const [resultLimit, setResultLimit] = useState<LimitSelectValue>(3);
  const [dateRange, setDateRange] = useState<LeaderboardDateRange>(LeaderboardDateRange["Week"])
  const leaderboardQuery = useLeaderboardQuery({ variables: { data: { dateRange } } })
  const users = leaderboardQuery.data && leaderboardQuery.data.leaderboard ? leaderboardQuery.data.leaderboard : [];

  return (
    <Card
      className="user-leaderboard"
      title={<Title size="md">User Leaderboard</Title>}
      actions={[
        <LeaderboardDateRangeSelect selectState={[dateRange, setDateRange]} />,
        <LimitSelect selectState={[resultLimit, setResultLimit]}/>
      ]}
    >
      {users.filter((user, i) => {
        if (resultLimit && (i > resultLimit - 1)) return false;
        return true;
      }).map((user, i, filteredUsers) => <>
        <UserDisplay user={user} position={i + 1} key={user.userId} isLast={i === (filteredUsers.length - 1)}/>
        {i !== (filteredUsers.length - 1) && <hr style={{ opacity: 0.2 }} />}
      </>)}
    </Card>
  );
}