import React, { useContext, useState } from "react";
import "./FieldItem.scss";
import { EntityField, FieldGroupType } from "../../../../lib/codegen";
import { Draggable } from "react-beautiful-dnd";
import { Card, Icon, Button, Popconfirm } from "antd";
import { getIconFromType } from "../../../../lib/helpers/getIconFromType";
import { FieldManagerContext } from "../../FieldManager";
import { PopForm } from "../../../popform/PopForm";
import { fieldFields } from "./fieldFields";

interface FieldItemProps<T extends EntityField> {
  field: T
}

export function FieldItem<T extends EntityField>(props: FieldItemProps<T>) {
  const { id, name, position, isEnabled, isRequired, type } = props.field;
  const { positionVisibleState, handleUpdateField, fieldType, vtigerModule, handleDeleteField, handleRefreshFields } = useContext(FieldManagerContext)
  const [isEditVisible, setIsEditVisible] = useState(false);
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={position}
      type="field"
    >
      {(provided, snapshot) => (
        <div>
          <PopForm
            title="Edit Field"
            visible={isEditVisible}
            formFields={fieldFields({
              field: props.field,
              isMeetingField: fieldType === FieldGroupType["MeetingField"],
              vtigerModule: vtigerModule ? JSON.parse(vtigerModule) : undefined
            })}
            onClose={() => setIsEditVisible(false)}
            onSubmit={data => handleUpdateField(props.field.id, data)}
          />

          <div className="field-item-wrapper" ref={provided.innerRef} {...provided.draggableProps}>
            <Card className={`field-item ${isEnabled ? "" : "disabled"}`}>

              <span className="name" {...provided.dragHandleProps}>
                <Icon type={getIconFromType(type)} /> {name} {isRequired && <span style={{ color: "crimson" }}>*</span>} {positionVisibleState[0] && <span>({position})</span>}
              </span>

              <span className="actions">
                <Button type="primary" icon="edit" size="small" shape="circle" style={{ marginRight: 5 }} onClick={() => setIsEditVisible(true)} />
                {
                  (handleDeleteField && !isEnabled) &&
                  <Popconfirm
                    title="Permanently delete field and all data?"
                    onConfirm={() => handleDeleteField(id).then(() => handleRefreshFields())} 
                    okText="Delete Field and Data"
                    okButtonProps={{ type: "danger" }}
                    cancelButtonProps={{ type: "primary" }}
                  >
                    <Button type="danger" icon="delete" size="small" shape="circle" style={{ marginRight: 5 }} />
                  </Popconfirm>
                }
              </span>

            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
}