import React, { useState } from "react";
import { useContactsQuery, useDeleteContactMutation, useUpdateContactMutation, useContactQuery, Contact, QueryContactsInput, EntityFieldType } from "../../lib/codegen";
import { ActiveIdState, DataPane } from "../../components/dataPane/DataPane";
import { RouteComponentProps } from "react-router";
import { useContactFields } from "./contactFields/useContactFields";
import EnhancedForm, { EnhnacedFormArgs } from "../../components/enhancedForm/EnhancedForm";
import { getFieldEntriesFromFormData } from "../../lib/helpers/getFieldEntriesFromFormData";
import { CreateContact } from "./createContact/CreateContact";
import { Button, Icon } from "antd";
import { getColumnSearchProps } from "../../components/tableFilter/TableFilterDropdown";
import { getActionColumn } from "../../components/tableFilter/getActionColumn";
import { CompanyRelationField } from "../../components/enhancedForm/relationFields";
import { Favicon } from "../../components/display/favicon/Favicon";
import { CompanyDrawer, CompanyDrawerDisplay } from "../companies/companyDrawer/CompanyDrawer";

interface IProps extends RouteComponentProps<{ contactId?: string }> {

}

export const Contacts: React.FC<IProps> = props => {
  const queryLimit = 20;

  // State
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const activeIdState: ActiveIdState = [props.match.params.contactId, (id: string | null | undefined) => props.history.push(`/contacts/${id ? id : ""}`)];
  const contactsQuery = useContactsQuery({ variables: { data: { offset: 0, limit: queryLimit } } })
  const [deleteContact] = useDeleteContactMutation();
  const [updateContact] = useUpdateContactMutation();
  const contactQuery = useContactQuery({ variables: { id: activeIdState[0] }, skip: !activeIdState[0] });
  const contact = contactQuery.data && contactQuery.data.contact ? contactQuery.data.contact as Contact : undefined;
  const fields = useContactFields(contact)

  // Helpers
  const hanldeRefresh = (data: QueryContactsInput) => contactsQuery.refetch({ data: { ...contactsQuery.variables.data, ...data } })
  const isFiltered = (filterProp: keyof QueryContactsInput) => contactsQuery.variables.data && contactsQuery.variables.data[filterProp] ? true : false
  const handleUpdate = (form: EnhnacedFormArgs) => {
    if (!activeIdState[0]) return;
    updateContact({ variables: { id: activeIdState[0], data: getFieldEntriesFromFormData(form.formData()) as any } })
      .then(() => {
        hanldeRefresh({});
        setIsUpdateLoading(false);
        setIsEditing(false);
      })
      .catch(() => {
        setIsUpdateLoading(false);
      })
    setIsUpdateLoading(true);
  }

  return (
    <EnhancedForm
      isEditing={isEditing}
      formFields={fields}
    >
      {form => (
        <>
          <CreateContact visibleState={[isCreateVisible, setIsCreateVisible]} />
          <DataPane<Contact>
            title="Contacts"
            activeIdState={activeIdState}
            data={contactsQuery.data && contactsQuery.data.contacts ? contactsQuery.data.contacts : { items: [], total: 0 }}
            loading={contactsQuery.loading}
            renderPane={() => <div>{form.renderForm()}</div>}
            onRowClick={() => setIsEditing(false)}
            paneTitle={({ activeItem }) => <>
              {activeItem && activeItem.company ? <Favicon iconBase64={activeItem.company.iconBase64}/> : ''}
              {activeItem ? activeItem.name : ''}
              {!isEditing && activeIdState[0] && <Button onClick={() => setIsEditing(true)} type="ghost" style={{ marginLeft: "auto" }}>Edit</Button>}
              {isEditing && <>
                {!isUpdateLoading && <Button type="ghost" style={{ marginLeft: "auto" }} onClick={() => setIsEditing(false)}>Cancel</Button>}
                <Button type="primary" loading={isUpdateLoading} style={{ marginLeft: isUpdateLoading ? "auto" : 10 }} onClick={() => handleUpdate(form)}>Save</Button>
              </>}
            </>}
            footerActions={<Button onClick={() => setIsCreateVisible(true)}><Icon type="plus" />Contact</Button>}
            tableProps={{
              initialPage: () => {
                const offset = contactsQuery.variables.data && contactsQuery.variables.data.offset ? contactsQuery.variables.data.offset : 0;
                const limit = contactsQuery.variables.data && contactsQuery.variables.data.limit ? contactsQuery.variables.data.limit : queryLimit;
                return (offset / limit) + 1;
              },
              handlePageChange: offset => hanldeRefresh({ offset }),
              pageSize: queryLimit,
              columns: [
                {
                  key: "company",
                  dataIndex: "company",
                  title: "Company",
                  render: (text, record) => record.company ? <CompanyDrawerDisplay company={record.company}/> : "",
                  width: 250,
                  ...getColumnSearchProps({
                    dataIndex: "comapny",
                    isFiltered: () => isFiltered("companyId"),
                    onSearch: searchValue => hanldeRefresh({ companyId: searchValue, offset: 0 }),
                    onReset: () => hanldeRefresh({ companyId: undefined, offset: 0 }),
                    renderMenu: options => <CompanyRelationField
                      field={{
                        key: "company",
                        type: EntityFieldType["Relation"],
                        style: { minWidth: "250px" },
                      }}
                      onChange={value => value ? options.onSearch(value) : options.onReset()}
                    />
                  })
                },
                {
                  key: "name",
                  dataIndex: "name",
                  title: "Name",
                  width: 200,
                  ...getColumnSearchProps({
                    dataIndex: "name",
                    isFiltered: () => isFiltered("name"),
                    onSearch: searchValue => hanldeRefresh({ name: searchValue, offset: 0 }),
                    onReset: () => hanldeRefresh({ name: undefined, offset: 0 }),
                  })
                },
                {
                  key: "email",
                  dataIndex: "email",
                  title: "Email",
                  ...getColumnSearchProps({
                    dataIndex: "email",
                    isFiltered: () => isFiltered("email"),
                    onSearch: searchValue => hanldeRefresh({ email: searchValue, offset: 0 }),
                    onReset: () => hanldeRefresh({ email: undefined, offset: 0 }),
                  })
                },
                getActionColumn<Contact>({
                  onDelete: id => deleteContact({ variables: { id } }),
                  onEdit: id => setIsEditing(true),
                  activeIdState
                })
              ]
            }}
          />
        </>
      )}
    </EnhancedForm>
  );
}