import React, { useState } from "react";
import { DataPane } from "../../../components/dataPane/DataPane";
import { useUsersQuery } from "../../../lib/codegen";
import { getColumnSearchProps } from "../../../components/tableFilter/TableFilterDropdown";
import { Select, Button } from "antd";
import { getUserRoleIcon } from "./userFields";
import { UserDetails } from "./UserDetails";
import { EmptyDataPane, emptyDataPaneCenterStyle } from "../../../components/dataPane/components/EmptyDataPane";
import { CreateUserForm } from "./CreateUserForm";

interface UserPaneProps {

}

export const UserPane: React.FC<UserPaneProps> = props => {
  const [activeId, setActiveId] = useState<string | null | undefined>(null)
  const [createUserVisible, setCreateUserVisible] = useState(false)
  const usersQuery = useUsersQuery()
  return (
    <>
      <CreateUserForm
        visibleState={[createUserVisible, setCreateUserVisible]}
        userQuery={usersQuery}
      />
      <DataPane
        title='Users'
        activeIdState={[activeId, setActiveId]}
        loading={usersQuery.loading}
        data={usersQuery.data && usersQuery.data.users ? usersQuery.data.users : { items: [], total: 0 }}
        footerActions={<Button onClick={() => setCreateUserVisible(true)}>Create User</Button>}
        tableProps={{
          columns: [
            {
              key: "name",
              dataIndex: "name",
              title: "Users",
              ...getColumnSearchProps({
                dataIndex: "Name",
                onSearch: value => usersQuery.refetch({ data: { ...usersQuery.variables.data, name: value } }),
                isFiltered: () => usersQuery.variables ? usersQuery.variables.data ? usersQuery.variables.data.name ? typeof usersQuery.variables.data.name === "string" : false : false : false,
                onReset: () => usersQuery.refetch({ data: { ...usersQuery.variables.data, name: undefined } })
              }),
              render: (text, item) => text
            },
            {
              key: "role",
              dataIndex: "role",
              title: "Role",
              filterIcon: "filter",
              width: 120,
              ...getColumnSearchProps({
                dataIndex: "Role",
                renderMenu: props => (
                  <Select onChange={value => props.onSearch(value as string)} allowClear={true} style={{ width: 140 }} placeholder="No Selection">
                    <Select.Option value="ADMIN">{getUserRoleIcon("ADMIN")} Admin</Select.Option>
                    <Select.Option value="USER">{getUserRoleIcon("USER")} User</Select.Option>
                  </Select>
                ),
                onSearch: (value: any) => usersQuery.refetch({ data: { ...usersQuery.variables.data, role: value } }),
                isFiltered: () => usersQuery.variables ? usersQuery.variables.data ? usersQuery.variables.data.role ? typeof usersQuery.variables.data.role === "string" : false : false : false,
                onReset: () => usersQuery.refetch({ data: { ...usersQuery.variables.data, role: undefined } })
              }),
              render: (text, item) => getUserRoleIcon(text)
            },
            {
              key: "email",
              dataIndex: "email",
              title: "Email",
              ...getColumnSearchProps({
                dataIndex: "Email",
                onSearch: value => usersQuery.refetch({ data: { ...usersQuery.variables.data, email: value } }),
                isFiltered: () => usersQuery.variables ? usersQuery.variables.data ? usersQuery.variables.data.email ? typeof usersQuery.variables.data.email === "string" : false : false : false,
                onReset: () => usersQuery.refetch({ data: { ...usersQuery.variables.data, email: undefined } })
              }),
              render: (text, item) => text
            }
          ],
          handlePageChange: offset => usersQuery.refetch({ data: { ...usersQuery.variables.data, offset } }),
          initialPage: () => 0,
          pageSize: 20
        }}
        renderPane={() => activeId ? <UserDetails userId={activeId} /> : <div style={emptyDataPaneCenterStyle}><EmptyDataPane description='No user selected' /></div>}
      />
    </>
  );
}