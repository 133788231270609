import React from "react";
import { FieldGroup, EntityFieldType, EntityRelationType, MeetingField } from "../../../../lib/codegen";
import { getFieldTypeFromVtigerFieldType } from "../../../../lib/helpers/vtiger/getFieldTypeFromVtigerFieldType";
import { EnhancedFormFieldsObject } from "../../../enhancedForm/withEnhancedForm";
import { Icon } from "antd";
import { getIconFromType } from "../../../../lib/helpers/getIconFromType";
import { EnhnacedFormArgs } from "../../../enhancedForm/EnhancedForm";
import { DescribeModuleResult } from "../../../../lib/ts/vtiger";

export function fieldFields(args: {
  vtigerModule?: DescribeModuleResult,
  field?: MeetingField,
  isMeetingField?: boolean
}) {
  const isCreating = args.field ? false : true;
  const getValue = (key: keyof MeetingField) => args.field ? args.field[key] : undefined
  const fieldIsOfType = (types: EntityFieldType[], props: EnhnacedFormArgs<any>): boolean => {
    let isOfType = false;
    const createFieldType = props.formData().find(field => field.key === "type");
    const existingFieldType = args.field ? args.field.type : undefined;
    let targetType = existingFieldType ? existingFieldType : createFieldType ? createFieldType.value : undefined
    types.forEach(type => { if (type === targetType) isOfType = true });
    return isOfType
  }

  const fields: EnhancedFormFieldsObject<EnhnacedFormArgs<any>> = [
    {
      key: "name",
      name: "Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "Name is required" }],
      getValueFromProps: () => getValue("name")
    },
    {
      key: "type",
      name: "Type",
      type: EntityFieldType["Selection"],
      rules: [{ required: true, message: "Type is required" }],
      selectionOptions: [
        { key: EntityFieldType["Text"], name: <span><Icon type={getIconFromType(EntityFieldType["Text"])} style={{ marginRight: 5 }} />Text</span> },
        { key: EntityFieldType["Richtext"], name: <span><Icon type={getIconFromType(EntityFieldType["Richtext"])} style={{ marginRight: 5 }} />Rich Text</span> },
        { key: EntityFieldType["Email"], name: <span><Icon type={getIconFromType(EntityFieldType["Email"])} style={{ marginRight: 5 }} />Email</span> },
        { key: EntityFieldType["Phone"], name: <span><Icon type={getIconFromType(EntityFieldType["Phone"])} style={{ marginRight: 5 }} />Phone</span> },
        { key: EntityFieldType["Number"], name: <span><Icon type={getIconFromType(EntityFieldType["Number"])} style={{ marginRight: 5 }} />Number</span> },
        { key: EntityFieldType["Boolean"], name: <span><Icon type={getIconFromType(EntityFieldType["Boolean"])} style={{ marginRight: 5 }} />Boolean</span> },
        { key: EntityFieldType["Date"], name: <span><Icon type={getIconFromType(EntityFieldType["Date"])} style={{ marginRight: 5 }} />Date</span> },
        { key: EntityFieldType["Selection"], name: <span><Icon type={getIconFromType(EntityFieldType["Selection"])} style={{ marginRight: 5 }} />Selection</span> },
        { key: EntityFieldType["Relation"], name: <span><Icon type={getIconFromType(EntityFieldType["Relation"])} style={{ marginRight: 5 }} />Relation</span> },
      ],
      isVisible: () => isCreating,
      getValueFromProps: () => getValue("type")
    },
    {
      key: "vtigerFieldId",
      name: "Relate Vtiger Field",
      type: EntityFieldType["Selection"],
      selectionOptions: props => {
        let filteredFields: { key: string, name: string }[] = [];
        if (args.vtigerModule) args.vtigerModule.fields.forEach(field => {
          const vtigerType = getFieldTypeFromVtigerFieldType(field.type.name)
          if (!vtigerType) return;
          let entityFieldType = props.formData().find(field => field.key === "type");
          if (args.field && args.field.type === vtigerType) filteredFields.push({ key: field.name, name: field.label });
          else if (entityFieldType && entityFieldType.value === vtigerType) filteredFields.push({ key: field.name, name: field.label });
        })
        return filteredFields;
      },
      isVisible: props => {
        if (!fieldIsOfType([EntityFieldType["Relation"]], props) && args.vtigerModule) return true
        return false;
      },
      getValueFromProps: () => getValue("vtigerFieldId")
    },
    {
      key: "entityRelationType",
      name: "Relation Type",
      type: EntityFieldType["Selection"],
      isTagSelection: false,
      selectionOptions: [
        { key: "Contact", name: "Contact" },
        { key: "Company", name: "Company" },
        { key: "Client", name: "Client" },
        { key: "Partner", name: "Partner" },
        { key: "ClientContact", name: "ClientContact" },
      ],
      rules: [{ required: true, message: "Please select relation type"}],
      isVisible: props => fieldIsOfType([EntityFieldType["Relation"]], props) && isCreating,
      getValueFromProps: () => getValue("selectionOptions")
    },
    [
      {
        key: "isRequired",
        name: "Required",
        type: EntityFieldType["Boolean"],
        getValueFromProps: () => getValue("isRequired")
      },
      {
        key: "isEnabled",
        name: "Enabled",
        type: EntityFieldType["Boolean"],
        getValueFromProps: () => getValue("isEnabled"),
        isVisible: () => !isCreating
      }
    ],
    {
      title: "Selection",
      fields: [
        {
          key: "selectionOptions",
          name: "Options",
          type: EntityFieldType["Selection"],
          isTagSelection: true,
          isVisible: props => fieldIsOfType([EntityFieldType["Selection"]], props),
          getValueFromProps: () => getValue("selectionOptions")
        },
        {
          key: "isMultiSelection",
          name: "Multiple Selection",
          type: EntityFieldType["Boolean"],
          isVisible: props => fieldIsOfType([EntityFieldType["Selection"], EntityFieldType["Relation"]], props),
          getValueFromProps: () => getValue("isMultiSelection")
        },
      ]
    },
    {
      title: "Conditional Settings",
      fields: [
        [
          {
            key: "isVisibleOnCreate",
            name: "Show On Create",
            type: EntityFieldType["Boolean"],
            getValueFromProps: () => getValue("isVisibleOnCreate")
          },
          {
            key: "isAdminField",
            name: "Admin Field Only",
            type: EntityFieldType["Boolean"],
            getValueFromProps: () => getValue("isAdminField")
          }
        ],
        {
          key: "clientsId",
          name: "Clients",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Client"],
          isMultiSelection: true,
          placeholder: "All Clients",
          isVisible: props => args.isMeetingField ? args.isMeetingField : false,
          getValueFromProps: () => args.field && args.field.clients ? args.field.clients.map(item => item.id) : []
        },
        {
          key: "partnersId",
          name: "Partners",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Partner"],
          isMultiSelection: true,
          placeholder: "All Partners",
          isVisible: props => args.isMeetingField ? args.isMeetingField : false,
          getValueFromProps: () => args.field && args.field.partners ? args.field.partners.map(item => item.id) : []
        },
      ]
    }
  ]
  return fields;
}