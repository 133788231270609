import React from "react";
import EnhancedForm, { EnhnacedFormArgs } from "../enhancedForm/EnhancedForm";
import { FormDataObject, EnhancedFormFieldsObject } from "../enhancedForm/withEnhancedForm";
import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";

interface PopFormProps<P> {
  visible: boolean
  formFields: EnhancedFormFieldsObject<P>
  title?: string | React.ReactNode
  modalProps?: ModalProps
  formRenderProps?: P
  onSubmit: (formData: FormDataObject) => Promise<any>
  onClose: () => void
}


export function PopForm<P = {}>(props: PopFormProps<P>) {
  function onOkay(form: EnhnacedFormArgs<any>) {
    const errors = form.formValidate()
    if (errors.length === 0) {
      props.onSubmit(form.formDataObject({ formatFieldsEntry: true }))
        .then(() => props.onClose())
    }
  }

  return (
    <EnhancedForm formFields={props.formFields} >
      {form => (
        <Modal
          title={props.title}
          visible={props.visible}
          onOk={() => onOkay(form)}
          onCancel={props.onClose}
          {...props.modalProps}
        >
          {form.renderForm({...form, ...props.formRenderProps})}
        </Modal>
      )}
    </EnhancedForm>
  );
}