import React from "react";
import { MeComponent, MeetingFieldsComponent, FieldGroupsComponent, FieldGroupType, Meeting } from "../../../lib/codegen";
import { EnhancedFormFieldsObject } from "../../../components/enhancedForm/withEnhancedForm";
import { getMeetingFields } from "./getMeetingFields";

interface IProps {
  children: (getFields: (meeting?: Meeting) => EnhancedFormFieldsObject, refetch: () => Promise<any>) => React.ReactNode
}

export const MeetingFields: React.FC<IProps> = props => (
  <MeComponent>
    {userQuery => (
      <FieldGroupsComponent variables={{ fieldType: FieldGroupType["MeetingField"] }}>
        {groupsQuery => (
          <MeetingFieldsComponent>
            {fieldsQuery => {
              const loading = userQuery.loading || fieldsQuery.loading || groupsQuery.loading;
              if (loading) return props.children((meeting?: Meeting) => [], groupsQuery.refetch);
              if (!(userQuery.data && userQuery.data.me) || !(fieldsQuery.data && fieldsQuery.data.meetingFields) || !(groupsQuery.data && groupsQuery.data.fieldGroups)) return <div>Errr</div>
              return props.children((meeting?: Meeting) => getMeetingFields({
                user: userQuery.data!.me!,
                fields: fieldsQuery.data!.meetingFields!,
                groups: groupsQuery.data!.fieldGroups!,
                meeting
              }), groupsQuery.refetch) as any
            }}
          </MeetingFieldsComponent>
        )}
      </FieldGroupsComponent>
    )}
  </MeComponent>
)