import React, { useState } from "react";
import { EnhancedFormField } from "../../../../components/enhancedForm/createFieldFromObject";
import { EntityFieldType, Client, DeleteClientComponent, CreateClientComponent, UpdateClientComponent, ClientsComponent, ClientsDocument, ClientQueryVariables, ClientDocument, EntityRelationType, ClientComponent } from "../../../../lib/codegen";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";
import { TableCard } from "../../../../components/tableCard/TableCard";

interface IProps {
  limit?: number
}

function getFields(client?: Client): EnhancedFormField<any>[] {
  return [
    {
      key: "name",
      name: "Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "Partner name is required" }],
      getValueFromProps: () => client ? client.name : undefined
    },
    {
      key: "partnersId",
      name: "Partners",
      type: EntityFieldType["Relation"],
      isMultiSelection: true,
      entityRelationType: EntityRelationType["Partner"],
      getValueFromProps: () => client && client.partners ? client.partners.map(partner => partner.id) : undefined
    }
  ]
}

export const ClientTable: React.FC<IProps> = props => {
  const [entityQueryId, setEntityQueryId] = useState<string | null>(null);
  return (
    <UpdateClientComponent>
      {updateMutation => (
        <DeleteClientComponent>
          {deleteMutation => (
            <CreateClientComponent>
              {createMutation => (
                <ClientComponent variables={{ id: entityQueryId! }} skip={!entityQueryId}>
                  {entityQuery => (
                    <ClientsComponent variables={{ data: { limit: props.limit } }}>
                      {({ loading, error, data, refetch, variables }) => {
                        const reloadDataArgs = { refetchQueries: [{ query: ClientsDocument, variables }], awaitRefetchQueries: true }
                        return (
                          <TableCard<Client>
                            loading={loading}
                            pageSize={variables ? variables.data ? variables.data.limit ? variables.data.limit : 10 : 10 : 10}
                            dataSource={data ? data.clients : undefined}
                            columns={[
                              {
                                key: "name",
                                dataIndex: "name",
                                title: <span className="header-title">Clients</span>,
                                ...getColumnSearchProps({
                                  dataIndex: "Clients",
                                  onSearch: value => refetch({ data: { ...variables.data, name: value } }),
                                  isFiltered: () => variables ? variables.data ? variables.data.name ? typeof variables.data.name === "string" : false : false : false,
                                  onReset: () => refetch({ data: { ...variables.data, name: undefined } })
                                })
                              }
                            ]}
                            getItemProps={item => ({
                              id: item.id
                            })}
                            actionBtn={{ title: "Client", useCreateModel: true }}
                            createModal={{
                              title: "Create Client",
                              fields: getFields(),
                              onSave: (formData, props) => createMutation({ variables: { data: formData as any }, ...reloadDataArgs })
                            }}
                            updateModal={{
                              title: "Update Client",
                              fields: getFields(entityQuery && entityQuery.data && entityQuery.data.client ? entityQuery.data.client : undefined),
                              onSave: (formData, props) => updateMutation({ variables: { id: props.entityId!, data: formData as any, ...reloadDataArgs } })
                            }}
                            handlePageChange={(Pager) => refetch({
                              data: {
                                ...variables.data,
                                limit: Pager.pageSize,
                                offset: (Pager.current ? Pager.current - 1 : 0) * Pager.pageSize!,
                              }
                            })}
                            onRowDelete={item => deleteMutation({ variables: { id: item.id }, ...reloadDataArgs })}
                            onRowEdit={item => setEntityQueryId(item.id)}
                          />
                        )
                      }}
                    </ClientsComponent>
                  )}
                </ClientComponent>
              )}
            </CreateClientComponent>
          )}
        </DeleteClientComponent>
      )}
    </UpdateClientComponent>
  )
}