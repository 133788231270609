import React, { CSSProperties } from "react";
import { Empty } from "antd";

interface EmptyDataPaneProps {
  description: React.ReactNode
  body?: React.ReactNode
  imgSrc?: string
}

export const emptyDataPaneCenterStyle: CSSProperties = { display: "flex", alignItems: "center", justifyContent: "center", height: "-webkit-fill-available", minHeight: 200 }

export const EmptyDataPane: React.FC<EmptyDataPaneProps> = props => {
  return (
    <Empty
      image={props.imgSrc ? props.imgSrc : "https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"}
      imageStyle={{
        height: 60,
      }}
      description={
        <span>{props.description}</span>
      }
    >
      {props.body}
    </Empty>
  );
}