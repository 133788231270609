import React, { CSSProperties } from "react";
import EnhancedForm, { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { MeetingComponent, UpdateMeetingComponent, UpdateMeetingMutation, UpdateMeetingMutationVariables, CreateMeetingInput } from "../../../lib/codegen";
import { MeetingFields } from "../meetingFields/MeetingFields";
import { UseState } from "../../../lib/ts/types";
import { EntityFiltersProps, EntityFilters } from "../../../components/entityFilters/EntityFilters";
import { MeetingPaneEmpty } from "./MeetingPaneEmpty";
import { MutationFunction } from "react-apollo";
import { FormFieldData } from "../../../components/enhancedForm/withEnhancedForm";
import { isUUID } from "../../../lib/helpers/regexValidateors";
import { message, notification } from "antd";
import moment from "moment";
import { FormErrorList } from "../../../components/enhancedForm/formHelpers/getFormErrorMessages";
import { MeetingFilters } from "./meetingFilters/MeetingFilters";

interface IProps {
  meetingId: string | undefined
  isEditingState: UseState<boolean>
  isFiltersVisible: boolean
  filterState: EntityFiltersProps["filterState"]
  createState: UseState<boolean>
  shouldUpdateState: UseState<boolean>
  updateLoadingSate: UseState<boolean>
}

const centerStyle: CSSProperties = { display: "flex", alignItems: "center", justifyContent: "center", height: "-webkit-fill-available", minHeight: 200 }

export const MeetingPane: React.FC<IProps> = props => {
  const [isEditing, setIsEditing] = props.isEditingState;
  const [isUpdateLoading, setIsUpdateLoading] = props.updateLoadingSate;
  const [shouldUpdateMeeting, setShouldUpdateMeeting] = props.shouldUpdateState;

  function handleSave(updateMutation: MutationFunction<UpdateMeetingMutation, UpdateMeetingMutationVariables>, form: EnhnacedFormArgs): Promise<any> {
    setShouldUpdateMeeting(false);
    setIsUpdateLoading(true);
    if (!props.meetingId ) {
      setIsUpdateLoading(false);
      return new Promise((resolve, reject) => resolve());
    }

    const formErrors = form.formValidate()

    if (formErrors.length !== 0) {
      setIsUpdateLoading(false);
      notification.error({ 
        message: <>
          <b>Meeting errors:</b>
          <FormErrorList formErrors={formErrors}/>
        </>,
        duration: 60
      })
      return new Promise((resolve, reject) => resolve());
    }
    
    const getUpdateData = (fieldsData: FormFieldData[]): CreateMeetingInput => {
      let updateData: CreateMeetingInput = {
        fieldsEntry: []
      }
      fieldsData.forEach(fieldData => {
        if (isUUID(fieldData.key)) updateData.fieldsEntry!.push({ fieldId: fieldData.key, value: fieldData.value })
        else if (fieldData.key === "time" && fieldData.value) updateData[fieldData.key] = moment(fieldData.value as any).format("h:mm a")
          //@ts-ignore
        else updateData[fieldData.key] = fieldData.value;
      })
      return updateData;
    }

    const data = getUpdateData(form.formData());

    console.log(data);

    return updateMutation({ variables: { id: props.meetingId, data } })
      .then(result => {
        setIsUpdateLoading(false);
        setIsEditing(false);
        message.success("Meeting Saved")
      })
      .catch((err: Error) => {
        setIsUpdateLoading(false);
        setIsEditing(false);
        message.error("Warning, meeting not saved");
      })
  }
  
  return (
    <MeetingComponent variables={{ id: props.meetingId! }} skip={!props.meetingId}>
      {meetingQuery => (
        <UpdateMeetingComponent>
          {updateMutation => (
            <MeetingFields>
              {fields => (
                <EnhancedForm
                  formFields={fields(meetingQuery.data && meetingQuery.data.meeting ? meetingQuery.data.meeting as any : undefined)}
                  isEditing={props.isEditingState[0]}
                >
                  {form => {
                    if (!isUpdateLoading && shouldUpdateMeeting) 
                      handleSave(updateMutation, form)
                    return (
                      <div className="meeting-pane" style={!props.meetingId && !props.isFiltersVisible ? centerStyle : undefined}>

                        {!props.meetingId && !props.isFiltersVisible && <MeetingPaneEmpty createState={props.createState} />}

                        {(props.meetingId && (props.isEditingState[0] || !props.isFiltersVisible)) && form.renderForm()}

                        {(!props.isEditingState[0] && props.isFiltersVisible) && <MeetingFilters />}

                        {/* {(!props.isEditingState[0] && props.isFiltersVisible) && <EntityFilters
                          filterState={props.filterState}
                          className="filter-wrapper"
                        />} */}
                      </div>
                    )
                  }}
                </EnhancedForm>
              )}
            </MeetingFields>
          )}
        </UpdateMeetingComponent>
      )}
    </MeetingComponent>
  );
}


