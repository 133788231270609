import React, { useState } from "react";
import { UseState } from "../../../lib/ts/types";
import { Drawer, Button, message, Icon } from "antd";
import EnhancedForm from "../../../components/enhancedForm/EnhancedForm";
import { useCompanyQuery, useUpdateCompanyMutation, Company } from "../../../lib/codegen";
import { getFieldEntriesFromFormData } from "../../../lib/helpers/getFieldEntriesFromFormData";
import { useCompanyFields } from "../comapnyFields/useCompanyFields";
import { Favicon } from "../../../components/display/favicon/Favicon";

interface IProps {
  companyId: string
  visibleState: UseState<boolean>
}

export const CompanyDrawerDisplay = (props: { company: Company, style?: React.CSSProperties, className?: string }) => {
  const visibleState = useState(false);
  return <>
    <CompanyDrawer visibleState={visibleState} companyId={props.company.id} />
    <a onClick={() => visibleState[1](true)} style={props.style} className={props.className}>{props.company.name}</a>
  </>
}

export const CompanyDrawer: React.FC<IProps> = props => {
  // state
  const [isVisible, setIsVisible] = props.visibleState;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  // queries
  const [updateCompany] = useUpdateCompanyMutation();
  const companyQuery = useCompanyQuery({ variables: { id: props.companyId } });
  const company = companyQuery.data && companyQuery.data.company ? companyQuery.data.company : undefined;
  // hooks
  const fields = useCompanyFields(company)
  return (
    <EnhancedForm
      formFields={fields}
      isEditing={isEditing}
    >
      {form => (
        <Drawer
          title={
            <div style={{
              display: "flex",
              alignItems: "center",
              marginRight: 30
            }}>
              {company && <Favicon iconBase64={company.iconBase64} />}
              <span>{company ? company.domain : "Company"}</span>
              <div style={{
                marginLeft: "auto",
                fontSize: 14
              }}>
                {!isEditing && <Button onClick={() => setIsEditing(true)} type="ghost"><Icon type="edit"/> Edit</Button>}
                {isEditing && <span>
                  {!updateLoading && <Button style={{ marginRight: 10 }} onClick={() => { setIsEditing(false) }} type="danger"><Icon type="close" /> Cancel</Button>}
                  <Button
                    loading={updateLoading}
                    type="primary"
                    onClick={() => {
                      if (form.formValidate().length === 0 && company) {
                        updateCompany({ variables: { id: company.id, data: getFieldEntriesFromFormData(form.formData()) as any } })
                          .then(() => {
                            message.success("Company Saved")
                            setUpdateLoading(false)
                            setIsEditing(false);
                          })
                          .finally(() => {
                            setUpdateLoading(false);
                            setIsEditing(false);
                          })
                        setUpdateLoading(true)
                      }
                    }}
                  >
                    {!updateLoading && <Icon type="save"/>} Save
                  </Button>
                </span>}
              </div>
            </div>
          }
          visible={isVisible}
          onClose={() => setIsVisible(false)}
          width={600}
        >
          {form.renderForm()}
        </Drawer>
      )}
    </EnhancedForm>
  );
}