import React from "react";
import { EnhancedFormFieldsObject } from "../../../components/enhancedForm/withEnhancedForm";
import { EntityFieldType, EntityRelationType, UserProps, User } from "../../../lib/codegen";
import { Icon } from "antd";

function safelyGetValue(property: keyof User, props: UserProps) {
  //@ts-ignore
  if (props.data && props.data.user) return props.data.user[property]
  return null;
}

export function getUserRoleIcon(role: "USER" | "ADMIN") {
  if (role === "ADMIN") return (
    <Icon type="star" theme="twoTone" twoToneColor="#ffae00" />
  )
  else return (
    <Icon type="user" theme="outlined" style={{
      color: "#1890ff",
      borderRadius: "50%"
    }} />
  )
}

export const userFields = (): EnhancedFormFieldsObject<any & UserProps> => [
  [
    {
      key: "firstName",
      name: "First Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "First name is required" }],
      getValueFromProps: props => safelyGetValue("firstName", props)
    },
    {
      key: "lastName",
      name: "Last Name",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "Last name is required" }],
      getValueFromProps: props => safelyGetValue("lastName", props)
    }
  ],
  {
    key: "email",
    name: "Email",
    type: EntityFieldType["Email"],
    rules: [{ required: true, message: "Email is required" }],
    getValueFromProps: props => safelyGetValue("email", props)
  },
  {
    key: "role",
    name: "Account Type",
    type: EntityFieldType["Selection"],
    selectionOptions: [
      {
        key: "USER", name: <span>{getUserRoleIcon("USER")} User</span>
      },
      { key: "ADMIN", name: <span>{getUserRoleIcon("ADMIN")} Admin</span> },
    ],
    rules: [{ required: true, message: "Role is required" }],
    getValueFromProps: props => safelyGetValue("role", props),
    style: {
      width: 130
    }
  },
  {
    key: "clients",
    name: <span><Icon type="apartment" /> Clients</span>,
    type: EntityFieldType["Relation"],
    entityRelationType: EntityRelationType["Client"],
    isMultiSelection: true,
    getValueFromProps: props => {
      let clients = safelyGetValue("clients", props) as User["clients"];
      if (!clients) return [];
      return clients.map(client => client.id)
    }
  },
  [
    {
      key: "vtigerUsername",
      name: <span><Icon type="cloud-sync" /> Vtiger Username</span>,
      type: EntityFieldType["Text"],
      getValueFromProps: props => safelyGetValue("vtigerUsername", props),
    },
    {
      key: "vtigerAccessKey",
      name: <span><Icon type="cloud-sync" /> Vtiger Access Key</span>,
      type: EntityFieldType["Text"],
      getValueFromProps: props => safelyGetValue("vtigerAccessKey", props),
    }
  ],
]