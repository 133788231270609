import React from "react";
import "./Title.scss"

interface IProps {
  size?: "xs" | "sm" | "md" | "lg"
  className?: string
  style?: React.CSSProperties
}

export const Title: React.FC<IProps> = props => {
  return(
    <div
      className={`title ${props.size ? props.size : "md"} ${props.className ? props.className : ""}`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}