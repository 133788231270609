import React from "react";
import { Modal } from "antd";
import withEnhancedForm, { EnhancedFormProps, FormDataObject, FormFieldData } from "../enhancedForm/withEnhancedForm";
import { EnhancedFormField } from "../enhancedForm/createFieldFromObject";
import { withApollo, WithApolloClient } from "react-apollo";

export interface ListCardModalProps<T> {
  fields: EnhancedFormField<any>[]
  title: string | React.ReactNode
  onSave: (fields: FormDataObject, args: ExtendedProps<T>) => Promise<any>
  getFieldsData?: (args: ExtendedProps<T>) => Promise<FormDataObject|null>
}

interface IProps<T> extends ListCardModalProps<T> {
  isVisible: boolean
  entityId?: string
  handleClose: () => void
}

type ExtendedProps<T> = IProps<T> & EnhancedFormProps & WithApolloClient<ListCardModalProps<T>>;

interface IState {
  lastLoadedEntityId: string | null
}

class ListCardForm<T> extends React.Component<ExtendedProps<T>, IState> {
  constructor(props: ExtendedProps<T>) {
    super(props);
    this.state = {
      lastLoadedEntityId: null
    }
  }

  async onSave() {
    const errors = this.props.formValidate()
    if (errors.length !== 0) return;
    await this.props.onSave(this.props.formDataObject(), this.props)
    this.props.formClear()
    this.setState({ lastLoadedEntityId: null })
    this.props.handleClose()
  }

  onClose() {
    this.setState({ lastLoadedEntityId: null })
    this.props.handleClose()
  }

  componentWillMount() {
    const { getFieldsData } = this.props;
    this.props.formSetFields(this.props.fields)
    if (this.props.entityId && (this.props.entityId !== this.state.lastLoadedEntityId)) {
      if (this.props.getFieldsData) this.props.getFieldsData(this.props).then(fieldsData => {
        this.setState({ lastLoadedEntityId: this.props.entityId! })
        if (!fieldsData) return;
        const formData = Object.entries(fieldsData).map(([key, value]): FormFieldData => ({ key, value }));
        this.props.formSetFieldsValue(formData)
      })
    }
  }

  componentWillReceiveProps(props: ExtendedProps<T>) {
    if (props.entityId && (props.entityId !== this.state.lastLoadedEntityId)) {
      if (props.getFieldsData) props.getFieldsData(props).then(fieldsData => {
        this.setState({ lastLoadedEntityId: props.entityId! })
        if (!fieldsData) return;
        const formData = Object.entries(fieldsData).map(([key, value]): FormFieldData => ({ key, value }));
        this.props.formSetFieldsValue(formData)
      })
    }
  }

  render() {
    return(
      <Modal
        visible={this.props.isVisible}
        title={this.props.title}
        onOk={this.onSave.bind(this)}
        onCancel={this.onClose.bind(this)}
        okText="Save"
      >
        {this.props.renderForm()}
      </Modal>
    );
  };
}

export const ListCardModal = withApollo<IProps<any>>(withEnhancedForm<IProps<any>>({ formFields: []})(ListCardForm))

