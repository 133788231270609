import React from "react"

interface FormErrorListProps {
  formErrors: { key: string, errors: string[] }[]
  className?: string
}

export const FormErrorList: React.FC<FormErrorListProps> = props => (
  <ul className={props.className ? props.className : ""}>
    {getFormErrorMessages(props.formErrors).map((error, i) => <li key={i}>{error}</li>)}
  </ul>
)

export function getFormErrorMessages(errorArray: { key: string, errors: string[] }[]): string[] {
  let errorMessages: string[] = [];
  errorArray.forEach(errorItem => {
    errorItem.errors.forEach(error => {
      errorMessages.push(error);
    })
  })
  return errorMessages;
}