import React, { useState, useContext, useEffect } from "react";
import { ReportColumn } from "../../../../../lib/codegen";
import { ReportContext } from "../../../helpers/ReportContext";
import { AvailableColumns } from "./availableColumns/AvailableColumns";
import { DragDropContext, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { Row, Col } from "antd";
import { SelectedColumns } from "./selectedColumns/SelectedColumns";
import { IReportColumn, IReportColumnFieldType } from "./reportColumn/ReportColumnItem";
import { ReportFilters } from "./reportFilters/ReportFilters";

interface IProps {

}

export type ColumnListState = { available: IReportColumn[], selected: IReportColumn[] }

export const ReportSettings: React.FC<IProps> = props => {
  // State
  const [columnsLoading, setColumnsLoading] = useState<boolean>(false);
  const [columnsLoaded, setColumnsLoaded] = useState<boolean>(false);
  const [columnListState, setColumnListState] = useState<ColumnListState>({ available: [], selected: [] })

  // Context
  const { activeReportIdState, createColumn, columnsQuery, reorderColumn } = useContext(ReportContext);
  const { loading, data, refetch } = columnsQuery;

  // On Report Change
  useEffect(() => {
    setColumnsLoaded(false);
  }, [activeReportIdState, data])

  // On Drag
  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (result.source.droppableId === "available-columns" && result.destination && result.destination.droppableId === "selected-columns") {
      // create new report column
      const foundColumn = columnListState.available.find(col => col.id === result.draggableId);
      if (!foundColumn || !activeReportIdState[0]) return;

      createColumn({
        variables: {
          data: {
            name: foundColumn.name,
            reportId: activeReportIdState[0],
            position: result.destination.index,
            staticFieldKey: foundColumn.columnFieldType === "STATIC" ? foundColumn.id : undefined,
            meetingFieldId: foundColumn.columnFieldType === "MEETING" ? foundColumn.id : undefined,
            contactFieldId: foundColumn.columnFieldType === "CONTACT" ? foundColumn.id : undefined,
            companyFieldId: foundColumn.columnFieldType === "COMPANY" ? foundColumn.id : undefined,
          }
        }
      }).then(() => {
        refetch().then(() => {
          setColumnsLoaded(false)
          setColumnsLoading(false)
        });
      })

      setColumnsLoading(true)

      const selected = [
        ...columnListState.selected,
        {
          id: "temp",
          columnFieldType: foundColumn.columnFieldType,
          name: foundColumn.name,
          position: result.destination.index
        }
      ].sort((a, b) => a.position - b.position)

      // temp state update
      setColumnListState({
        ...columnListState,
        selected
      })
    } else if (result.source.droppableId === "selected-columns" && result.destination && result.destination.droppableId === "selected-columns") {
      // reorder column
      const foundColumn = columnListState.selected.find(col => col.id === result.draggableId);
      if (!foundColumn || !activeReportIdState[0] || !foundColumn.reportColumn) return;

      // temp state update
      setColumnListState({
        ...columnListState,
        selected: [
          ...columnListState.selected,
          {
            id: "temp",
            columnFieldType: "STATIC",
            name: "loading",
            position: result.destination.index
          }
        ]
      })

      setColumnsLoading(true)

      reorderColumn({
        variables: {
          id: foundColumn.reportColumn.id,
          position: result.destination.index
        }
      }).then(() => {
        refetch().then(() => {
          setColumnsLoaded(false)
          setColumnsLoading(false)
        })
      })
    }
  }

  function mapReportColumns(columns: ReportColumn[]): IReportColumn[] {
    const getColumnFieldType = ({ staticFieldKey, meetingField, contactField, companyField }: ReportColumn): IReportColumnFieldType => {
      if (meetingField) return "MEETING"
      if (contactField) return "CONTACT"
      if (companyField) return "COMPANY"
      return "STATIC"
    }

    return columns.map((column): IReportColumn => {
      return ({
        id: column.id,
        name: column.name,
        position: column.position,
        columnFieldType: getColumnFieldType(column),
        reportColumn: column
      })
    }).sort((a, b) => a.position - b.position)
  }

  if (!columnsLoaded && data && data.reportColumns && !loading) {
    setColumnListState({ ...columnListState, selected: mapReportColumns(data.reportColumns as ReportColumn[]) })
    setColumnsLoaded(true);
  }

  return (
    <DragDropContext onDragEnd={(result, provided) => onDragEnd(result, provided)}>
      <Row>
        <Col xs={24} sm={6} >
          <AvailableColumns columnListState={[columnListState, setColumnListState]} />
        </Col>
        <Col xs={0} sm={1} />
        <Col xs={24} sm={6} >
          <SelectedColumns columnListState={[columnListState, setColumnListState]} loading={loading || columnsLoading}/>
        </Col>
        <Col xs={0} sm={1} />
        <Col xs={24} sm={10} >
          <ReportFilters />
        </Col>
      </Row>
    </DragDropContext>
  );
}

