import React, { useState, useEffect } from "react";
import { TablePane } from "../../../../components/tablePane/TablePane";
import { DeleteClientContactComponent, CreateClientContactComponent, UpdateClientContactComponent, ClientContactsComponent, ClientContactsDocument, ClientContact, ClientContactComponent, CreateClientContactInput } from "../../../../lib/codegen";
import { clientContactFields } from "./clientContactFields";
import { getColumnSearchProps } from "../../../../components/tableFilter/TableFilterDropdown";

interface IProps {
  limit?: number
  clientId?: string
}

function renderTextBold<T extends { id: string }>(text: string, item: T, activeId: string | null) { return item.id === activeId ? <b>{text}</b> : <span>{text}</span> }

export const ClientContactPane: React.FC<IProps> = props => {
  const activeIdState = useState<string | null>(null);
  const [activeId, setActiveId] = activeIdState;

  useEffect(() => {
    setActiveId(null);
  }, [props.clientId])

  return (
    <DeleteClientContactComponent>
      {deleteMutation => (
        <CreateClientContactComponent>
          {createMutation => (
            <UpdateClientContactComponent>
              {updateMutation => (
                <ClientContactComponent variables={{ id: activeId! }} skip={!activeId ? true : false}>
                  {entityQuery => (
                    <ClientContactsComponent variables={{ data: { limit: props.limit, clientId: props.clientId } }}>
                      {({ loading, error, data, refetch, variables }) => {
                        if (!loading && !activeId && data && data.clientContacts && data.clientContacts.items.length > 0) setActiveId(data.clientContacts.items[0].id)
                        return (
                          <TablePane
                            cardTitle={`Client Contacts`}
                            paneTitle={entityQuery.data && entityQuery.data.clientContact ? entityQuery.data.clientContact.name : ""}
                            activeIdState={activeIdState}
                            handleSave={formData => updateMutation({ variables: { id: activeId!, data: formData as CreateClientContactInput } })}
                            height={400}
                            tableCardProps={{
                              loading,
                              pageSize: variables ? variables.data ? variables.data.limit ? variables.data.limit : 10 : 10 : 10,
                              dataSource: data ? data.clientContacts : undefined,
                              columns: [
                                {
                                  key: "name",
                                  dataIndex: "name",
                                  title: "Name",
                                  ...getColumnSearchProps({
                                    dataIndex: "Name",
                                    onSearch: value => refetch({ data: { ...variables.data, name: value } }),
                                    isFiltered: () => variables ? variables.data ? variables.data.name ? typeof variables.data.name === "string" : false : false : false,
                                    onReset: () => refetch({ data: { ...variables.data, name: undefined } })
                                  }),
                                  render: (text, item) => renderTextBold(text, item, activeId)
                                },
                                {
                                  key: "title",
                                  dataIndex: "title",
                                  title: "Title",
                                  ...getColumnSearchProps({
                                    dataIndex: "Title",
                                    onSearch: value => refetch({ data: { ...variables.data, title: value } }),
                                    isFiltered: () => variables ? variables.data ? variables.data.title ? typeof variables.data.title === "string" : false : false : false,
                                    onReset: () => refetch({ data: { ...variables.data, title: undefined } })
                                  }),
                                  render: (text, item) => renderTextBold(text, item, activeId)
                                }
                              ],
                              getItemProps: item => ({ id: item.id }),
                              actionBtn: { title: "Client Contact", useCreateModel: true },
                              createModal: {
                                title: "Create Client Contact",
                                fields: clientContactFields(props.clientId ? props.clientId : null),
                                onSave: (formData, props) => createMutation({ variables: { data: formData as any } }).then(() => refetch())
                              },
                              handlePageChange: Pager => refetch({
                                data: {
                                  ...variables.data,
                                  limit: Pager.pageSize,
                                  offset: (Pager.current ? Pager.current - 1 : 0) * Pager.pageSize!,
                                }
                              }),
                              onRowDelete: item => deleteMutation({ variables: { id: item.id } }).then(() => refetch())
                            }}
                            enhancedFormProps={{
                              formFields: clientContactFields(null, entityQuery.data ? entityQuery.data.clientContact ? entityQuery.data.clientContact : undefined : undefined)
                            }}
                          />
                        )
                      }}
                    </ClientContactsComponent>
                  )}
                </ClientContactComponent>
              )}
            </UpdateClientContactComponent>
          )}
        </CreateClientContactComponent>
      )}
    </DeleteClientContactComponent>

  );
}