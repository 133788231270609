import React, { useCallback } from "react";
import "./MeetingLineChart.scss";
import { Line, ChartData } from 'react-chartjs-2';
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { FilterMeetingsInput, EntityRelationType, ComparisonOperator, MeetingStatus } from "../../../lib/codegen";
import moment, { Moment } from "moment";
import chartjs from "chart.js"
import { Title } from "../../../components/display/title/Title";
import { Card } from "antd";
import { getMeetingStatusColor } from "../../meetings/meetingPane/meetingStatusTag/MeetingStatusTag";

type MeetingFragment = {
  id: string,
  date?: Date,
  status?: MeetingStatus
}

const weekCount: number = 8

const getDateRanges = (): Moment[] => {
  let dates: Moment[] = []
  for (let i = weekCount - 1; i > 0; i--)
    dates.push(moment().startOf("day").subtract(i, "weeks"))
  dates.push(moment().startOf("day"))
  return dates;
}

const getLabels = (): string[] => getDateRanges().map((date, i) => {
  if (i === 7) return "Today";
  return date.format("MMM") + ", Week " + ((date.week() % 4) + 1);
})

const getMeetingsOverTime = (args: { meetings: Array<MeetingFragment>, status: MeetingStatus, color: string }): chartjs.ChartDataSets => {
  const { status, color } = args;

  const meetingCountSegments = (): number[] => {
    let segmentsCount: number[] = []
    const segmentDates: Moment[] = getDateRanges()
    let meetings: Array<MeetingFragment | null> = args.meetings.filter(meeting => meeting.date && meeting.status)

    // console.log(getDateRanges())
    // console.log(meetingDates[1]);
    for (let i = 0; i < segmentDates.length; i++) {
      if (!segmentsCount[i]) segmentsCount.push(0);
      for (let m = 0; m < meetings.length; m++) {
        const meeting = meetings[m];
        if (meeting && meeting.status === status) {
          const meetingDate = moment(meeting.date!).startOf("day");
          if (meetingDate.isBetween(segmentDates[i], segmentDates[i + 1])) {
            segmentsCount[i] += 1;
            meetings[m] = null;
          } else if (i === segmentDates.length - 1 && meetingDate.isBetween(segmentDates[i - 1], segmentDates[i])) {
            segmentsCount[i] += 1;
            meetings[m] = null;
          }
        }
      }
    }
    // console.log(segmentsCount)
    return segmentsCount;
  }
  return {
    label: status.toString().replace(/_/g, ""),
    fill: false,
    lineTension: 0.1,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: color,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: meetingCountSegments()
  }
}

export const MeetingLineChart: React.FC = props => {
  const graphqlQueryVariables: FilterMeetingsInput = {
    limit: 9999,
    filters: [
      {
        entityName: EntityRelationType["Meeting"],
        filterValue: moment().startOf("day").subtract(2, "month").format("YYYY-MM-DD"),
        comparisonOperator: ComparisonOperator["GreaterThan"],
        staticFieldKey: "createdDate"
      },
      {
        entityName: EntityRelationType["Meeting"],
        filterValue: moment().startOf("day").format("YYYY-MM-DD"),
        comparisonOperator: ComparisonOperator["LessThan"],
        staticFieldKey: "createdDate"
      },
    ]
  }
  const graphqlQuery = gql`
    query	MeetingsQuery($data: FilterMeetingsInput!) {
      filterMeetings(data: $data) {
        items {
          id
          date
          status
        }
      }
    }
  `;

  type QueryData = {
    filterMeetings?: {
      total: number,
      items: MeetingFragment[]
    }
  }

  const meetingsQuery = useQuery(graphqlQuery, { variables: { data: graphqlQueryVariables } });
  const queryData: QueryData = meetingsQuery.data
  const meetings = queryData && queryData.filterMeetings ? queryData.filterMeetings.items : []

  const getChartData = useCallback((): ChartData<chartjs.ChartData> => ({
    labels: getLabels(),
    datasets: [
      getMeetingsOverTime({
        meetings,
        status: MeetingStatus["Planned"],
        color: getMeetingStatusColor(MeetingStatus["Planned"])
      }),
      getMeetingsOverTime({
        meetings,
        status: MeetingStatus["Held"],
        color: getMeetingStatusColor(MeetingStatus["Held"])
      }),
      getMeetingsOverTime({
        meetings,
        status: MeetingStatus["Cancelled"],
        color: getMeetingStatusColor(MeetingStatus["Cancelled"])
      }),
      getMeetingsOverTime({
        meetings,
        status: MeetingStatus["ToBeRescheduled"],
        color: getMeetingStatusColor(MeetingStatus["ToBeRescheduled"])
      })
    ]
  }), [meetings])

  return (
    <Card
      title={<Title size="md">Meetings Over Time</Title>}
      className="meeting-line-chart"
    >
      <Line data={getChartData()} />
    </Card>
  );
}