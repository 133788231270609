import { IFilterDataType } from "./normalizeEntityFieldType";
import { ComparisonOperator } from "../../../lib/codegen";

export function getComparisonsByType(type: IFilterDataType): ComparisonOperator[] {
  switch (type) {
    case "string": return [ComparisonOperator["Equals"], ComparisonOperator["Contains"]]
    case "number": return [ComparisonOperator["Equals"], ComparisonOperator["GreaterThan"], ComparisonOperator["LessThan"], ComparisonOperator["NotEqual"]]
    case "boolean": return [ComparisonOperator["Equals"], ComparisonOperator["NotEqual"]]
    case "date": return [ComparisonOperator["Equals"], ComparisonOperator["GreaterThan"], ComparisonOperator["LessThan"], ComparisonOperator["NotEqual"]]
    case "selection": return [ComparisonOperator["Equals"]]
    case "id": return [ComparisonOperator["Equals"]]
    default: return []
  }
}