import React from "react";
import { CompanyComponent, ContactComponent, ClientComponent, ClientPartnerComponent, Company, Contact, Client, ClientPartner, ClientContact, ClientContactComponent, CampaignComponent, TradeshowComponent, MeetingTagComponent, UserComponent, MeetingTagsComponent } from "../../../lib/codegen";

export interface RelationDisplayProps<T> {
  id: string
  renderer?: (entity: T) => string | React.ReactNode
}

function handleDisplay<T>(entity: T, props: RelationDisplayProps<any>, defaultRenderer: (entity: T) => string | React.ReactNode): any {
  if (props.renderer) return props.renderer(entity);
  else return defaultRenderer(entity)
}

export const CompanyRelationDisplay: React.FC<RelationDisplayProps<Company>> = props => (
  <CompanyComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.company) ? handleDisplay(data.company, props, entity => entity.name) : null}
  </CompanyComponent>
)

export const ContactRelationDisplay: React.FC<RelationDisplayProps<Contact>> = props => (
  <ContactComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.contact) ? handleDisplay(data.contact, props, entity => entity.name) : null}
  </ContactComponent>
)

export const ClientRelationDisplay: React.FC<RelationDisplayProps<Client>> = props => (
  <ClientComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.client) ? handleDisplay(data.client, props, entity => entity.name) : null}
  </ClientComponent>
)

export const ClientContactRelationDisplay: React.FC<RelationDisplayProps<ClientContact>> = props => (
  <ClientContactComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.clientContact) ? handleDisplay(data.clientContact, props, entity => entity.name) : null}
  </ClientContactComponent>
)

export const PartnerRelationDisplay: React.FC<RelationDisplayProps<ClientPartner>> = props => (
  <ClientPartnerComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.clientPartner) ? handleDisplay(data.clientPartner, props, entity => entity.name) : null}
  </ClientPartnerComponent>
)

export const CampaignRelationDisplay: React.FC<RelationDisplayProps<ClientPartner>> = props => (
  <CampaignComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.camapign) ? handleDisplay(data.camapign, props, entity => entity.name) : null}
  </CampaignComponent>
)

export const TradeshowRelationDisplay: React.FC<RelationDisplayProps<ClientPartner>> = props => (
  <TradeshowComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.tradeshow) ? handleDisplay(data.tradeshow, props, entity => entity.name) : null}
  </TradeshowComponent>
)

export const MeetingTagRelationDisplay: React.FC<RelationDisplayProps<ClientPartner>> = props => {
  return (
    <MeetingTagComponent variables={{ id: props.id }} skip={!props.id}>
      {({ data }) => (data && data.meetingTag) ? handleDisplay(data.meetingTag, props, entity => entity.name) : null}
    </MeetingTagComponent>
  )
}

export const UserRelationDisplay: React.FC<RelationDisplayProps<ClientPartner>> = props => (
  <UserComponent variables={{ id: props.id }} skip={!props.id}>
    {({ data }) => (data && data.user) ? handleDisplay(data.user, props, entity => entity.name) : null}
  </UserComponent>
)