import React, { useContext } from "react";
import "./Navbar.scss";
import { MeComponent, LogoutComponent, Role } from "../../lib/codegen";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import { Layout, Menu, Icon, Dropdown, Button } from 'antd';
import { AppContext } from "../../lib/helpers/AppContext";

interface IProps extends RouteComponentProps {

}

const _Navbar: React.FC<IProps> = props => {
  let { meQuery, darkModeState, responsiveCalc } = useContext(AppContext);

  const user = meQuery.data && meQuery.data.me ? meQuery.data.me : null

  function hanldeLogout(logoutMutation: () => void) {
    logoutMutation();
    setTimeout(() => {
      meQuery.refetch().then(() => { props.history.push("/login"); })
    }, 500)
  }

  function getRouteLocation() {
    let pathname = "";
    const paths = props.location.pathname.split("/");
    if (paths.length >= 1) pathname = `/${paths[1]}`;
    return pathname
  }

  function PublicMenu() {
    return [
      <Menu.Item key="/login"><Link to="/login">Login</Link></Menu.Item>,
    ]
  }

  function UserMenu(logoutMutation: () => void) {
    return [
      <Menu.Item key="/meetings"><Link to="/meetings">Meetings</Link></Menu.Item>,
      <Menu.Item key="/contacts"><Link to="/contacts">Contacts</Link></Menu.Item>,
      <Menu.Item key="/companies"><Link to="/companies">Companies</Link></Menu.Item>,
      user && user.role === Role["Admin"] ? <Menu.Item key="/admin"><Link to="/admin">Admin</Link></Menu.Item> : <span />,
      <Menu.Item key="4" className="account" onClick={() => hanldeLogout(logoutMutation)}>
        <Icon type="logout" /> Logout
      </Menu.Item>
    ]
  }

  const isMobile = responsiveCalc("md", "down")

  return (
    <LogoutComponent>
      {logoutMutation => (
        <MeComponent>
          {meQuery => {
            const me = meQuery.data && meQuery.data.me ? meQuery.data.me : undefined;
            return (
              <div>
                <Layout.Header className="navbar">
                  <Link to="/"><div className="logo"><span>Meeting Tracker</span></div></Link>

                  {!isMobile && <Menu className="menu" theme="dark" mode="horizontal" selectedKeys={[getRouteLocation()]}>
                    {me && me.id ? UserMenu(logoutMutation) : PublicMenu()}
                  </Menu>}

                  {isMobile &&
                    <Dropdown
                      overlay={<Menu className="menu" theme="dark" selectedKeys={[getRouteLocation()]}>{me && me.id ? UserMenu(logoutMutation) : PublicMenu()}</Menu>}
                    >
                      <Button type="ghost" style={{ float: 'right', marginTop: 16 }}>
                        <Icon
                          type="menu"
                          style={{ color: "#fff" }}
                        />
                      </Button>
                    </Dropdown>
                  }

                </Layout.Header>
              </div>
            )
          }}
        </MeComponent>
      )}
    </LogoutComponent>
  );
}

export const Navbar = withRouter<IProps>(_Navbar)