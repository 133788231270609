import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from 'antd';

import { Navbar } from './components/navbar/Navbar';
import App from './components/App';
import Login from "./components/account/Login";
import ConfirmAccount from "./components/account/ConfirmAccount";

import Admin, { AdminSideMenu } from "./pages/admin/Admin";
import { Contacts } from "./pages/contacts/Contacts";
import { Meetings } from "./pages/meetings/Meetings";
import { MeComponent } from "./lib/codegen";
import { Companies } from "./pages/companies/Companies";

import { AppContext } from "./lib/helpers/AppContext";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { useResponsive } from "./lib/helpers/hooks/useResponsive";

interface IProps {

}

export const AppLayout: React.FC<IProps> = props => {
  const { responsiveSize, responsiveCalc } = useResponsive();

  // Dark Mode
  const darkModeState = React.useState(window.localStorage.getItem('dark-mode') === 'true' ? true : false),
    setDarkModeState = (enabled: boolean) => {
      darkModeState[1](enabled);
      window.localStorage.setItem('dark-mode', `${enabled}`);
      const rootElement = document.getElementById('root-html');
      if (rootElement)
        if (enabled) 
          rootElement.classList.add('dark-mode')
        else 
          rootElement.classList.remove('dark-mode')
    }

  return (
    <MeComponent>
      {meQuery => (
        <AppContext.Provider value={{
          user: meQuery.data && meQuery.data.me ? meQuery.data.me : null,
          darkModeState: [darkModeState[0], setDarkModeState],
          meQuery,
          responsiveSize,
          responsiveCalc
        }}>
          <Layout className={`app-layout ${darkModeState[0] ? 'dark-mode' : ''}`}>
            <Navbar />
            <Layout className="menu-content-wrapper">
              <Switch>
                <Layout className="sidemenu-layout">
                  <Route path="/admin" render={() => <AdminSideMenu />} />
                </Layout>
              </Switch>
              <Switch>
                <Layout.Content className="app-layout-content">
                  <Route exact path="/account/confirm/:userId" component={ConfirmAccount} />
                  <Route exact path="/login" component={Login} />
                  <Route path="/meetings/:filters?/:meetingId?" component={Meetings} />
                  <Route path="/companies/:companyId?" component={Companies} />
                  <Route path="/contacts/:contactId?" component={Contacts} />
                  <Route path="/admin" component={Admin} />
                  <Route exact path="/" component={Dashboard} />
                </Layout.Content>
              </Switch>
            </Layout>
          </Layout>
        </AppContext.Provider>
      )}
    </MeComponent>
  );
}