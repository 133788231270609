import React, { useState } from "react";
import { UseState } from "../../../lib/ts/types";
import { withRouter, RouteComponentProps } from "react-router";
import { useContactFields } from "../contactFields/useContactFields";
import { useContactFieldsQuery, useCreateContactMutation } from "../../../lib/codegen";
import EnhancedForm, { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { Drawer, Button } from "antd";
import { getFieldEntriesFromFormData } from "../../../lib/helpers/getFieldEntriesFromFormData";
import { ImportContact } from "./ImportContact";

interface IProps extends RouteComponentProps {
  visibleState: UseState<boolean>
}

export const _CreateContact: React.FC<IProps> = props => {
  const [importVisible, setImportVisible] = useState(false);
  const fields = useContactFields();
  const contactFieldsQuery = useContactFieldsQuery();
  const contactFields = contactFieldsQuery.data && contactFieldsQuery.data.contactFields ? contactFieldsQuery.data.contactFields : [];
  const [createContact] = useCreateContactMutation();

  const handleCreate = (data: any, form: EnhnacedFormArgs) => {
    if (form.formValidate().length !== 0) return;
    createContact({ variables: { data } }).then(result => {
      if (result && result.data && result.data.createContact) props.history.push(`/contacts/${result.data.createContact.id}`)
      props.visibleState[1](false)
    })
  }

  const handleImport = (importData: any, form: EnhnacedFormArgs) => {
    if (!importData || !contactFields) return;
    let mappedValues: { key: string, value: string }[] = [];  
    contactFields.forEach(field => {
      if (field.vtigerFieldId) {
        const mapValue = importData[field.vtigerFieldId]
        if (mapValue) mappedValues.push({ key: field.id, value: mapValue })
      }
    })
    if (importData["firstname"]) mappedValues.push({ key: "firstName", value: importData["firstname"] })
    if (importData["lastname"]) mappedValues.push({ key: "lastName", value: importData["lastname"] })
    if (importData["email"]) mappedValues.push({ key: "email", value: importData["email"] })
    form.formSetFieldsValue(mappedValues);
  }

  return (
    <Drawer
      title={<><span style={{ marginRight: 20 }}>Create Company</span> <Button onClick={() => setImportVisible(true)}>Vtiger Import</Button></>}
      placement="right"
      closable={true}
      maskClosable={false}
      onClose={() => props.visibleState[1](false)}
      visible={props.visibleState[0]}
      width={500}
    >
      <EnhancedForm
        formFields={fields}
      >
        {form => (
          <div>
            <ImportContact
              importVisibleState={[importVisible, setImportVisible]}
              onQueryFinished={data => handleImport(data, form)}
            />

            {form.renderForm()}

            <div style={{ float: "right", marginBottom: 20 }}>
              <Button type="danger" onClick={() => form.formClear()} style={{ marginRight: 20 }}>Clear</Button>
              <Button type="primary" onClick={() => handleCreate(getFieldEntriesFromFormData(form.formData()), form)}>Save</Button>
            </div>
          </div>
        )}
      </EnhancedForm>
    </Drawer>
  );
}

export const CreateContact = withRouter<IProps>(_CreateContact)