import './ZoomCallStats.scss'
import React, { useState } from "react";
import { useZoomCallReportQuery } from "../../../../../lib/codegen";
import { Row, Col, Statistic, Tooltip, Popover, DatePicker, Spin } from "antd";
import { CallsOverTimeChart } from './CallsOverTimeChart';
import moment, { Moment } from 'moment';

interface ZoomCallStatsProps {
  zoomUserId?: string | null
}

const getDuration = (minDuration: number, getTextDuration?: boolean) => {
  minDuration = minDuration / 60
  let hours = Math.round(minDuration / 60)
  let min = Math.round(minDuration % 60)
  if (getTextDuration)
    return `${hours > 0 ? `${hours} hr` : ''} ${min > 0 ? `${min} min` : ''} ${min <= 0 ? '< 1 min' : ''}`.trim()
  else
    return `${hours}:${min < 10 ? '0' : ''}${min}`
}

export const ZoomCallStats: React.FC<ZoomCallStatsProps> = props => {
  const [date, setDate] = useState(moment())
  const dateString = (m: Moment) => m.format('YYYY-MM-DD')

  const { loading, error, data } = useZoomCallReportQuery({
    variables: {
      data: {
        date: dateString(date),
        userId: props.zoomUserId!
      }
    },
    skip: !props.zoomUserId
  })

  if (error) 
    return <div>{error.message}</div>

  if (!(data && data.zoomCallReport))
    return <Spin />

  console.log(data.zoomCallReport)

  let callReport = data && data.zoomCallReport ? data.zoomCallReport : undefined

  if (!callReport)
    return <div />

  const { dateTimes, totalCalls, totalCanceled, totalConnected, totalDuration, totalInbound, totalOutbound, avgDuration } = callReport

  const span: Col['props'] = {
    xxl: 12,
    md: 12,
    sm: 12,
    xs: 12
  }

  return (
    <div className="zoom-call-stats">
      <Row>
        <Col xxl={10} lg={8} md={24}>
          <Row>
            <Col {...span}>
              <Statistic title="Total Calls" value={totalCalls} prefix={<i className="material-icons">import_export</i>} />
            </Col>
            <Col {...span}>
              <Popover title={getDuration(totalDuration, true)}>
                <Statistic title="Total Duration" value={getDuration(totalDuration, true)} prefix={<i className="material-icons">timer</i>} />
              </Popover>
            </Col>
            <Col {...span}>
              <Statistic title="Connected" value={totalConnected} prefix={<i className="material-icons">call</i>} />
            </Col>
            <Col {...span}>
              <Statistic title="Average Duration" value={getDuration(avgDuration, true)} prefix={<i className="material-icons">timelapse</i>} />
            </Col>
            <Col {...span}>
              <Statistic title="Canceled" value={totalCanceled} prefix={<i className="material-icons">call_end</i>} />
            </Col>
            <Col {...span}>
              <Statistic title="Outbound" value={totalOutbound} prefix={<i className="material-icons">call_made</i>} />
            </Col>
            <Col {...span}>
              <Statistic title="Inbound" value={totalInbound} prefix={<i className="material-icons">call_received</i>} />
            </Col>
            <Col {...span}>
              <div className="ant-statistic">
                <div className="ant-statistic-title">
                  Report Date
                </div>
                <div className="ant-statistic-content">
                  <DatePicker style={{ top: -2 }} value={date} onChange={value => value ? setDate(value) : undefined} disabledDate={current => current ? current.isAfter(moment()) : true} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xxl={14} lg={16} md={24}>
          <CallsOverTimeChart dateTimes={dateTimes} />
        </Col>
      </Row>

    </div>
  );
}