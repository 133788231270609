export const dominRegex = /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/mg
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const uuidRegex = /(\d|\w){8}-(\d|\w){4}-(\d|\w){4}-(\d|\w){4}-(\d|\w){12}/
export const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/

export function isDomain(input: string): boolean {
  return dominRegex.test(input);
}

export function isEmail(input: string): boolean {
  return emailRegex.test(input);
}

export function isUUID(input: string): boolean {
  return uuidRegex.test(input);
}

export function isTime(input: string): boolean {
  return timeRegex.test(input);
}
