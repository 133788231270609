import React, { useContext, useState } from "react";
import { PopForm } from "../../../components/popform/PopForm";
import { EntityFieldType, ReportGroupFragment, useReportGroupQuery } from "../../../lib/codegen";
import { UseState } from "../../../lib/ts/types";
import { FormDataObject } from "../../../components/enhancedForm/withEnhancedForm";
import { ReportContext } from "../helpers/ReportContext";
import { Modal } from "antd";
import EnhancedForm from "../../../components/enhancedForm/EnhancedForm";

interface IProps {
  visibleState: UseState<boolean>,
  reportGroupId?: string
}

export const ReportGroupPopForm: React.FC<IProps> = props => {
  const [loadedId, setLoadedId] = useState<null | string>(null)
  const { createReportGroup, updateReportGroup, reportGroupsQuery } = useContext(ReportContext);
  const reportGroupQuery = useReportGroupQuery({ variables: { id: props.reportGroupId! }, skip: !props.reportGroupId })
  const reportGroup: ReportGroupFragment | undefined = reportGroupQuery.data && reportGroupQuery.data.reportGroup ? reportGroupQuery.data.reportGroup : undefined;

  function handleSubmit(formData: FormDataObject): Promise<any> {
    if (reportGroup)
      return updateReportGroup({ variables: { id: reportGroup.id, data: formData as any } }).then(() => {
        reportGroupsQuery.refetch()
        props.visibleState[1](false)
      })
    else
      return createReportGroup({ variables: { data: formData as any } }).then(() => {
        reportGroupsQuery.refetch()
        props.visibleState[1](false)
      })
  }

  if (reportGroupQuery.loading) return <span />

//isSummaryTemplate

  return (
    <EnhancedForm
      formFields={[
        {
          key: "name",
          name: "Name",
          type: EntityFieldType["Text"],
          rules: [{ required: true }],
          getValueFromProps: () => reportGroup ? reportGroup.name : ""
        },
        {
          key: "isSummaryTemplate",
          name: "Summary Template",
          type: EntityFieldType["Boolean"],
          getValueFromProps: () => reportGroup && reportGroup.isSummaryTemplate ? true : false
        }
      ]}
    >
      {form => {
        if ((reportGroup && loadedId !== reportGroup.id)) {
          setLoadedId(reportGroup.id);
          form.formSetFieldsValue([{ key: "name", value: reportGroup.name }])
        }
        return (
          <Modal
          title={`${props.reportGroupId ? "Update" : "Create"} Report Group`}
          onCancel={() => props.visibleState[1](false)}
          onOk={() => {
            if (form.formValidate().length === 0) {
              handleSubmit(form.formDataObject());
              form.formClear();
              setLoadedId(null);
            }
          }}
          visible={props.visibleState[0]}
        >
          {form.renderForm()}
        </Modal>
        )
      }}
    </EnhancedForm>
  );
}