import * as React from "react";
import "./EnhancedForm.scss";
import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { EnhancedFormField } from "./createFieldFromObject"
import { FieldValue, FieldType } from "../../lib/ts/entityFields";
import { renderFields } from "./renderFields";
import { flattenFieldsFromObject } from "./formHelpers/formHelpers";

export type FormFieldData = { key: string, value: FieldValue };
export type FormDataObject = { [key: string]: FieldValue }

interface ExternalProps extends FormComponentProps { }

interface PartialFormProps {
  formData: () => FormFieldData[]
  formDataObject: () => FormDataObject
  formIsValid: () => boolean
  formValidate: () => { key: string, errors: string[] }[]
}

// Props the HOC adds to the wrapped component
export interface EnhancedFormProps<P = "props"> extends PartialFormProps {
  renderForm: () => React.ReactNode[]
  formSetFields: (formFields: EnhancedFormFieldsObject<P>) => void
  formSetFieldsValue: (fieldData: FormFieldData[]) => void
  formClear: () => void
}

export interface EnhancedFormFieldGroup<P> {
  title: string | React.ReactNode,
  fields: Array<EnhancedFormFieldOrFieldArray<P>>
}

export type EnhancedFormFieldOrFieldArray<P = any> = EnhancedFormField<P> | EnhancedFormField<P>[];
export type EnhancedFormFieldsObject<P = any> = Array<EnhancedFormFieldOrFieldArray<P> | EnhancedFormFieldGroup<P>>

// Options for the HOC factory that are not dependent on props values
interface Options<P = "props"> {
  formFields: EnhancedFormFieldsObject<P>
}

function withEnhancedForm<P = any>(options: Options<P & ExternalProps & PartialFormProps>) {
  return (<OriginalProps extends {}>(Component: React.ComponentType<OriginalProps & EnhancedFormProps>) => {
    class EnhancedForm extends React.Component<OriginalProps & ExternalProps & P, { formFields: EnhancedFormFieldsObject<any> }> {
      constructor(props: OriginalProps & ExternalProps & P) {
        super(props);
        this.state = {
          formFields: options.formFields
        }
      }

      // componentDidMount() {
      //   setTimeout(() => {
      //     flattenFieldsFromObject(this.state.formFields).forEach(field => {
      //       console.log(field);
      //       if (field.type === FieldType["DATE"]) this.props.form.setFieldsValue({ [field.key]: undefined });
      //     })
      //   }, 500)
      // }

      formSetFields(formFields: EnhancedFormFieldsObject<any>): void {  // Injects fields into form
        this.setState({ formFields: [...options.formFields, ...formFields] })
      }

      formSetFieldsValue(fieldData: FormFieldData[]) {
        fieldData.forEach(field => this.props.form.setFieldsValue({ [field.key]: field.value }))
      }

      formData(): FormFieldData[] {
        return flattenFieldsFromObject(this.state.formFields).map(field => ({
          key: field.key,
          value: this.props.form.getFieldValue(field.key)
        }));
      }

      formDataObject(): { [key: string]: FieldValue } {
        let dataObject: { [key: string]: FieldValue } = {};
        flattenFieldsFromObject(this.state.formFields).forEach(field => dataObject[field.key] = this.props.form.getFieldValue(field.key));
        return dataObject;
      }

      formValidate(): { key: string, errors: string[] }[] {
        this.props.form.validateFields();
        const formErrors = this.props.form.getFieldsError();
        let errorsArray: { key: string, errors: string[] }[] = [];
        flattenFieldsFromObject(this.state.formFields).forEach(({ key }) => {
          const formError = formErrors[key];
          if (typeof formError === "string") errorsArray.push({ key, errors: [formError] })
          else if (formError) errorsArray.push({ key, errors: formError })
        })
        return errorsArray;
      }

      formIsValid(): boolean {
        const { getFieldError, isFieldTouched } = this.props.form;
        let allowSubmit = false;
        flattenFieldsFromObject(this.state.formFields).forEach(field => {
          const isTouched = isFieldTouched(field.key);
          if (!isTouched) allowSubmit = true;
          if (isTouched) if (getFieldError(field.key)) allowSubmit = true;
        });
        return allowSubmit;
      }

      render() {
        const enhancedFormProps = {
          formData: this.formData.bind(this),
          formDataObject: this.formDataObject.bind(this),
          formIsValid: this.formIsValid.bind(this),
          formValidate: this.formValidate.bind(this),
        }
        //@ts-ignore
        return <Component {...this.props}
          renderForm={() => renderFields<any>(this.state.formFields, this.props.form, { ...this.props, ...enhancedFormProps })}
          formSetFields={this.formSetFields.bind(this)}
          formSetFieldsValue={this.formSetFieldsValue.bind(this)}
          formClear={() => this.props.form.resetFields()}
          {...enhancedFormProps}
        />
      }
    }

    //@ts-ignore
    return Form.create<OriginalProps & ExternalProps>()(EnhancedForm)
  })
}

export default withEnhancedForm