import React, { useContext, useState } from "react";
import { Icon, Modal } from "antd";
import { SelectReportGroup } from "../helpers/SelectReportGroup";
import { useReportQuery } from "../../../lib/codegen";
import { ReportContext } from "../helpers/ReportContext";

interface ReportCopyFormProps {
  copyFromReportId: string | null,
  isOpen: boolean,
  handleClose: () => void
}

export const ReportCopyForm: React.FC<ReportCopyFormProps> = props => {
  const { isOpen, handleClose } = props
  const { copyReport, reportGroupQuery, reportQuery } = useContext(ReportContext)

  // state
  const [copyToGroupId, setCopyToGroupId] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  // report
  // const reportQueryLocal = useReportQuery({
  //   variables: { id: props.copyFromReportId },
  //   skip: !props.copyFromReportId,
  //   onCompleted: (data) => { if (data.report) setCopyToGroupId(data.report.reportGroup.id) },
  //   onError: () => console.log("REPORRR ERROROR")
  // })

  if (props.copyFromReportId && !copyToGroupId)
    reportQuery.refetch({ id: props.copyFromReportId })
      .then(res => { 
        if (res.data && res.data.report)
          setCopyToGroupId(res.data.report.reportGroup.id)})

  return (
    <Modal
      visible={isOpen}
      title={"Copy Report"}
      onCancel={handleClose}
      confirmLoading={loading}
      okText={<span><Icon type="copy" /> Copy</span>}
      onOk={() => {
        setLoading(true)
        if (props.copyFromReportId)
          copyReport({ variables: { data: { copyToGroupId: copyToGroupId, copyFromReportId: props.copyFromReportId } } })
            .then(() => {
              setLoading(false)
              if (typeof copyToGroupId === "string")
                reportGroupQuery.refetch({ id: copyToGroupId }).then(() => handleClose())
              // else if (reportQueryLocal.data && reportQueryLocal.data.report)
              //   reportGroupQuery.refetch({ id: reportQueryLocal.data.report.reportGroup.id }).then(() => handleClose())
              else
                reportGroupQuery.refetch({ ...reportGroupQuery.variables }).then(() => handleClose())

            })
      }}
    >
      <span>Copy to report group: <SelectReportGroup value={copyToGroupId} onChange={(value) => setCopyToGroupId(value)} /></span>
    </Modal>
  );
}