import React, { useState } from "react";
import { UseState } from "../../../lib/ts/types";
import { ApolloClient } from "apollo-boost";
import { GetVtigerContactQueryVariables, GetVtigerContactDocument } from "../../../lib/codegen";
import { ApolloConsumer } from "react-apollo";
import { Modal, Input } from "antd";

interface IProps {
  importVisibleState: UseState<boolean>
  onQueryFinished: (contact: Object | null) => void
}

export const ImportContact: React.FC<IProps> = props => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [importSearchValue, setImportSearchValue] = useState<string>("");
  const [importVisible, setImportVisible] = props.importVisibleState;

  const handleImport = (apolloClient: ApolloClient<object>) => {
    if (importSearchValue.trim().length === 0) return;
    apolloClient.query<{ getVtigerContact: string }, GetVtigerContactQueryVariables>({ query: GetVtigerContactDocument, variables: { email: importSearchValue } })
      .then(result => {
        let data: any;
        try { data = JSON.parse(result.data.getVtigerContact) as Object[] } catch (err) { }
        if (!data || data.length === 0) {
          setErrorMessage("Error, no contact found with that email");
        }
        else {
          props.onQueryFinished(data[0])
          setImportSearchValue("")
          setErrorMessage(null);
          props.importVisibleState[1](false)
        };
      })
      .catch(error => {
        console.error(error)
        props.importVisibleState[1](false)
      })
  }

  return (
    <ApolloConsumer>
      {apolloClient => (
        <Modal
          title="Vitger Import"
          visible={importVisible}
          onCancel={() => setImportVisible(false)}
          onOk={() => handleImport(apolloClient)}
          okText="Search"
          width={400}
        >
          <Input
            value={importSearchValue}
            onChange={e => setImportSearchValue(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Contact Email"
          />
          {errorMessage && <div style={{ color: "crimson" }}>{errorMessage}</div>}
        </Modal>
      )}
    </ApolloConsumer>
  );
}