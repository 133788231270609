import React from "react";
import { EnhancedFormFieldsObject, EnhancedFormFieldOrFieldArray, EnhancedFormFieldGroup } from "../withEnhancedForm";
import { Descriptions, Badge } from 'antd';
import { getNestedFieldObjectType } from "../formHelpers/formHelpers";
import { EnhancedFormField } from "../createFieldFromObject";
import { renderDataItem } from "./renderDataItem";

export interface EnhancedDataDisplayOptions {
  bordered?: boolean
  skipOnValueUndefined?: boolean
  useBasicHtml?: boolean
}

interface EnhancedDataDisplayProps<P> {
  formFields: EnhancedFormFieldsObject<P>
  renderArgs: P
  displayOptions?: EnhancedDataDisplayOptions
}

function getColumnSize<P = any>(parentObject: EnhancedFormFieldOrFieldArray<P>): number {
  const parentType = getNestedFieldObjectType(parentObject);
  let largestArrayLength = 1;
  const setLargestLength = (length: number) => { if (length > largestArrayLength) largestArrayLength = length }
  if (parentType === "FIELD_ARRAY") {
    const fieldsObjectArray = parentObject as Array<EnhancedFormField<P>[] | EnhancedFormField<P>>
    fieldsObjectArray.map(maybeField => {
      const maybeFieldType = getNestedFieldObjectType(maybeField);
      if (maybeFieldType === "FIELD") setLargestLength(1)
      if (maybeFieldType === "FIELD_ARRAY") {
        const fieldsObject = maybeField as EnhancedFormField<P>[];
        setLargestLength(fieldsObject.length)
      }
    })
  }
  return largestArrayLength;
}

function renderFieldOrFieldArray<P>(fieldObject: EnhancedFormFieldOrFieldArray<P>, renderArgs: P, colSize: number, displayOptions?: EnhancedDataDisplayOptions): React.ReactNodeArray {
  const objectType = getNestedFieldObjectType(fieldObject);
  if (objectType === "FIELD") return [renderDataItem(fieldObject as EnhancedFormField<P>, renderArgs, {
    span: colSize,
  })]
  else if (objectType === "FIELD_ARRAY") {
    const fieldsObjectArray = fieldObject as Array<EnhancedFormField<P>[] | EnhancedFormField<P>>
    return fieldsObjectArray.map(maybeField => {
      const maybeFieldType = getNestedFieldObjectType(maybeField);
      if (maybeFieldType === "FIELD") return [renderDataItem(maybeField as EnhancedFormField<P>, renderArgs, {
        span: colSize,
        skipOnValueUndefined: displayOptions && displayOptions.skipOnValueUndefined ? displayOptions.skipOnValueUndefined : false,
        useBasicHtml: displayOptions && displayOptions.useBasicHtml ? displayOptions.useBasicHtml : false,
      })]
      if (maybeFieldType === "FIELD_ARRAY") {
        const fieldsObject = maybeField as EnhancedFormField<P>[];
        let elementArray: React.ReactNodeArray = [];
        fieldsObject.forEach(field => {
          elementArray.push(renderDataItem(field, renderArgs, {
            span: colSize / fieldsObject.length,
            skipOnValueUndefined: displayOptions && displayOptions.skipOnValueUndefined ? displayOptions.skipOnValueUndefined : false,
            useBasicHtml: displayOptions && displayOptions.useBasicHtml ? displayOptions.useBasicHtml : false,
          }))
        })
        return elementArray
      }
    })
  } else return []
}

function renderDescriptions<P>(formFields: EnhancedFormFieldsObject, renderArgs: P, displayOptions?: EnhancedDataDisplayOptions) {
  interface DescriptionItem {
    title?: string
    order: number
    elements: React.ReactNodeArray
    columnSize: number
  }
  let descriptionItems: DescriptionItem[] = []
  let currentNoGroupIndex = 0;

  for (let i = 0; i < formFields.length; i++) {
    // For Each Description
    const fieldObject = formFields[i];
    if (getNestedFieldObjectType(fieldObject) === "GROUP") {
      const fieldGroup = fieldObject as EnhancedFormFieldGroup<P>;
      const columnSize = getColumnSize(fieldGroup.fields as EnhancedFormFieldOrFieldArray<P>);
      descriptionItems.push({
        title: `${fieldGroup.title}`,
        order: i,
        elements: renderFieldOrFieldArray(fieldGroup.fields as EnhancedFormFieldOrFieldArray<P>, renderArgs, columnSize, displayOptions),
        columnSize
      })
    } else {
      if (i === 0 || getNestedFieldObjectType(formFields[i - 1]) === "GROUP") {
        currentNoGroupIndex = descriptionItems.length;
        descriptionItems.push({
          order: i,
          elements: [],
          columnSize: 3
        })
      }
      // todo: first map over no group items to find largest col size
      const colSize = getColumnSize(fieldObject as EnhancedFormFieldOrFieldArray<P>);
      if (descriptionItems[currentNoGroupIndex].columnSize < colSize) descriptionItems[currentNoGroupIndex].columnSize = colSize;
      const elements = renderFieldOrFieldArray(fieldObject as EnhancedFormFieldOrFieldArray<P>, renderArgs, descriptionItems[currentNoGroupIndex].columnSize, displayOptions);
      descriptionItems[currentNoGroupIndex].elements.push(elements)
    }
  }

  // Diplay Options
  if (!displayOptions) displayOptions = {};
  const { bordered, useBasicHtml } = displayOptions;

  return descriptionItems.map((item, index) => {
    const children = item.elements.flat().filter(value => typeof value !== "undefined");
    if (children.length === 0) return <span></span>
    if (useBasicHtml) return (
      <div>
        <h3>{item.title}</h3>
        <ul>
          {children}
        </ul>
      </div>
    )
    return (
      <Descriptions
        column={item.columnSize}
        key={index}
        bordered={typeof bordered === "boolean" ? bordered : true}
        title={item.title}
        size="small"
      >
        {children}
      </Descriptions>
    )
  });
}

export function EnhancedDataDisplay<P = any>(props: EnhancedDataDisplayProps<P>) {
  return (
    <div className="data-display">
      {renderDescriptions(props.formFields, props.renderArgs, props.displayOptions)}
    </div>
  );
}