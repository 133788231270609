import React, { useState, useEffect } from "react";
import "./FieldManager.scss";
import { EntityField, FieldGroup, FieldGroupType, FieldGroupsComponent, ReorderFieldGroupComponent, ReorderFieldGroupMutation, ReorderFieldGroupMutationVariables, CreateFieldGroupComponent } from "../../lib/codegen";
import { DragDropContext, DropResult, ResponderProvided, Droppable } from "react-beautiful-dnd";
import { FieldGroupContainer } from "./components/fieldGroupContainer/FieldGroupContainer";
import { MutationFunction } from "react-apollo";
import { HeaderBar } from "./components/headerBar/HeaderBar";
import { PopForm } from "../popform/PopForm";
import { FormDataObject } from "../enhancedForm/withEnhancedForm";
import { fieldGroupFields } from "./components/fieldGroupContainer/fieldGroupFields";
import { UseState } from "../../lib/ts/types";
import { fieldFields } from "./components/fieldItem/fieldFields";

export interface FieldManagerProps<T extends EntityField> {
  fields: T[]
  fieldType: FieldGroupType
  title: string | React.ReactNode
  vtigerModule?: string
  handleReorderField: (args: { field: T, targetGroup: FieldGroup | undefined, position: number }) => void
  handleUpdateField: (id: string, data: FormDataObject) => Promise<any>
  handleCreateField: (data: FormDataObject) => Promise<any>
  handleDeleteField?: (id: string) => Promise<any>
  handleRefreshFields: () => void
}

export const FieldManagerContext = React.createContext<{
  disabledFieldsVisibleState: UseState<boolean>
  positionVisibleState: UseState<boolean>
  fieldType: FieldGroupType
  handleUpdateField: FieldManagerProps<EntityField>["handleUpdateField"],
  handleDeleteField: FieldManagerProps<EntityField>["handleDeleteField"],
  handleRefreshFields: FieldManagerProps<EntityField>["handleRefreshFields"],
  vtigerModule?: string
}>({
  disabledFieldsVisibleState: [false, () => { }],
  positionVisibleState: [false, () => { }],
  fieldType: FieldGroupType["CompanyField"],
  handleUpdateField: () => new Promise(() => { }),
  handleDeleteField: () => new Promise(() => { }),
  handleRefreshFields: () => { }
})

export function FieldManager<T extends EntityField>(props: FieldManagerProps<T>) {
  const [fields, setFields] = useState<T[]>(props.fields);
  const [groups, setGroups] = useState<FieldGroup[]>([]);

  const [disabledVisible, setDisabledVisible] = useState(false);
  const [isPositionVisible, setIsPositionVisible] = useState(false);

  const [newFieldFormVisible, setNewFieldFormVisible] = useState(false)
  const [newGroupFormVisible, setNewGroupFormVisible] = useState(false)

  useEffect(() => {
    setFields(props.fields);
  }, [props.fields])

  function onDragEnd(result: DropResult, provided: ResponderProvided, reorderGroup: MutationFunction<ReorderFieldGroupMutation, ReorderFieldGroupMutationVariables>) {
    if (result.type === "group" && result.destination) {
      const thisGroup = groups.find(group => group.id === result.draggableId);
      if (!thisGroup) return;
      reorderGroup({ variables: { id: thisGroup.id, position: result.destination.index } });
      setGroups(groups.map(group => {
        const newPosition = result.destination!.index
        const currentPosition = result.source.index
        if (group.id === result.draggableId) group.position = result.destination!.index;
        else if (group.position <= newPosition && group.position >= currentPosition) group.position--;
        else if (group.position >= newPosition && group.position <= currentPosition) group.position++;
        return group;
      }))
    } else if (result.type === "field") {
      const thisField = fields.find(field => field.id === result.draggableId);
      if (!thisField || !result.destination) return;

      const targetGroup = groups.find(group => group.id === result.destination!.droppableId);
      const newPosition = result.destination.index;
      const currentPosition = thisField.position;

      props.handleReorderField({ field: thisField, targetGroup, position: newPosition })

      setFields(fields.map(field => {
        if (field.id === thisField.id) {
          field.group = targetGroup;
          field.position = newPosition;
          return field;
        }

        // Reorder fields in same group
        if ((!field.group && !targetGroup) || (targetGroup && field.group && field.group.id === targetGroup.id)) {
          if (newPosition > currentPosition) {
            if (field.position <= newPosition && field.position >= currentPosition) field.position--;
          } else {
            if (field.position >= newPosition && field.position <= currentPosition) field.position++;
          }
        }
        return field;
      }))
    }
  }

  const renderGrouplessFields = () =>
    <FieldGroupContainer
      group={{
        id: "UNDEFINED",
        fieldType: props.fieldType,
        name: "Ungrouped Fields",
        position: -1
      }}
      fields={fields.filter(field => (!field.group && (field.isEnabled || disabledVisible)))}
    />

  const renderGroups = () => groups.sort((a, b) => a.position - b.position).map(group =>
    <FieldGroupContainer
      key={group.id}
      group={group}
      fields={fields.filter(field => field.group && field.group.id === group.id && (field.isEnabled || disabledVisible))}
    />
  )

  return (
    <FieldManagerContext.Provider value={{
      disabledFieldsVisibleState: [disabledVisible, setDisabledVisible],
      positionVisibleState: [isPositionVisible, setIsPositionVisible],
      fieldType: props.fieldType,
      handleUpdateField: props.handleUpdateField,
      handleDeleteField: props.handleDeleteField,
      handleRefreshFields: props.handleRefreshFields,
      vtigerModule: props.vtigerModule
    }}>
      <FieldGroupsComponent variables={{ fieldType: props.fieldType }}>
        {({ loading, error, data, refetch }) => {
          if (data && data.fieldGroups && data.fieldGroups !== groups) setGroups(data.fieldGroups)
          return (
            <CreateFieldGroupComponent>
              {createFieldGroup => (
                <ReorderFieldGroupComponent>
                  {reorderGroup => (
                    <DragDropContext onDragEnd={(result, provided) => onDragEnd(result, provided, reorderGroup)}>
                      <div className="field-manager">
                        <HeaderBar
                          title={props.title}
                          setNewFieldFormVisible={setNewFieldFormVisible}
                          setNewGroupFormVisible={setNewGroupFormVisible}
                        />

                        <PopForm
                          title="New Field"
                          visible={newFieldFormVisible}
                          formFields={fieldFields({ 
                            isMeetingField: props.fieldType === FieldGroupType["MeetingField"],
                            vtigerModule: props.vtigerModule ? JSON.parse(props.vtigerModule) : undefined
                          })}
                          onClose={() => setNewFieldFormVisible(false)}
                          onSubmit={data => props.handleCreateField(data).then(() => props.handleRefreshFields())}
                        />

                        <PopForm
                          title="New Group"
                          visible={newGroupFormVisible}
                          formFields={fieldGroupFields()}
                          onClose={() => setNewGroupFormVisible(false)}
                          onSubmit={data => createFieldGroup({ variables: { data: { ...data as any, fieldType: props.fieldType } } }).then(() => refetch())}
                        />

                        <Droppable droppableId="groups-container" type="group">
                          {(provided, snapshot) => (
                            <div>
                              {renderGrouplessFields()}
                              <div ref={provided.innerRef}>
                                {renderGroups()}
                                {provided.placeholder}
                              </div>
                            </div>
                          )}
                        </Droppable>

                      </div>
                    </DragDropContext>
                  )}
                </ReorderFieldGroupComponent>
              )}
            </CreateFieldGroupComponent>
          )
        }}
      </FieldGroupsComponent>
    </FieldManagerContext.Provider>
  );
}





