import React from "react";
import { Divider } from "antd";

interface IProps {
  title: string | React.ReactNode
}

export const FieldGroupTitle_OLD: React.FC<IProps> = props => {
  return(
    <Divider>{props.title}</Divider>
  );
}

export const FieldGroupTitle: React.FC<IProps> = props => {
  return(
    <div className="ant-descriptions-title">{props.title}</div>
  );
}

