import React from "react";
import { SummarySection as ISummarySection } from "../../../../lib/codegen";

interface IProps {
  summarySection: ISummarySection
}

export const SummarySection: React.FC<IProps> = props => {
  const { name, fields } = props.summarySection;
  return(
    <div>
      <h3>{name}</h3>
      <ul>
        {fields
          .filter(({ value }) => value !== "" && value !== null)
          .map((field, i) =>
            <li key={i}><b>{field.name}</b>: {field.value}</li>
        )}
      </ul>
    </div>
  );
}