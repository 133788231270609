import React from "react";
import { CreateReportMutation, CreateReportMutationVariables, UpdateReportMutation, UpdateReportMutationVariables, DeleteReportMutation, DeleteReportMutationVariables, ReportsQuery, ReportsQueryVariables, ReportQuery, ReportQueryVariables, CreateReportColumnMutation, CreateReportColumnMutationVariables, UpdateReportColumnMutation, UpdateReportColumnMutationVariables, ReorderReportColumnMutation, ReorderReportColumnMutationVariables, DeleteReportColumnMutation, ReportColumnsQuery, ReportColumnsQueryVariables, ReportColumnQuery, ReportColumnQueryVariables, CreateReportGroupMutation, CreateReportGroupMutationVariables, UpdateReportGroupMutation, UpdateReportGroupMutationVariables, DeleteReportGroupMutation, DeleteReportGroupMutationVariables, ReportGroupsQueryVariables, ReportGroupsQuery, ReportGroupQuery, ReportGroupQueryVariables, CopyReportMutation, CopyReportMutationVariables, MoveReportReportGroupMutation, MoveReportReportGroupMutationVariables } from "../../../lib/codegen";
import { MutationFunction, QueryResult } from "react-apollo";
import { ActiveIdState } from "../../../components/dataPane/DataPane";

export interface IReportContext {
  activeReportIdState: ActiveIdState
  activeReportGroupIdState: ActiveIdState
  // Report
  createReport: MutationFunction<CreateReportMutation, CreateReportMutationVariables>
  updateReport: MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>
  deleteReport: MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>
  copyReport: MutationFunction<CopyReportMutation, CopyReportMutationVariables>
  moveReportReportGroup: MutationFunction<MoveReportReportGroupMutation, MoveReportReportGroupMutationVariables>
  reportsQuery: QueryResult<ReportsQuery, ReportsQueryVariables>
  reportQuery: QueryResult<ReportQuery, ReportQueryVariables>
  // Report Group
  createReportGroup: MutationFunction<CreateReportGroupMutation, CreateReportGroupMutationVariables>
  updateReportGroup: MutationFunction<UpdateReportGroupMutation, UpdateReportGroupMutationVariables>
  deleteReportGroup: MutationFunction<DeleteReportGroupMutation, DeleteReportGroupMutationVariables>
  reportGroupsQuery: QueryResult<ReportGroupsQuery, ReportGroupsQueryVariables>
  reportGroupQuery: QueryResult<ReportGroupQuery, ReportGroupQueryVariables>
  // Columns
  createColumn: MutationFunction<CreateReportColumnMutation, CreateReportColumnMutationVariables>
  updateColumn: MutationFunction<UpdateReportColumnMutation, UpdateReportColumnMutationVariables>
  reorderColumn: MutationFunction<ReorderReportColumnMutation, ReorderReportColumnMutationVariables>
  deleteColumn: MutationFunction<DeleteReportColumnMutation, DeleteReportMutationVariables>
  columnsQuery: QueryResult<ReportColumnsQuery, ReportColumnsQueryVariables>
}

const defaultContext: IReportContext = {
  activeReportIdState: [null, (id) => { }],
  activeReportGroupIdState: [null, (id) => { }],

  // Report
  createReport: () => new Promise(() => { }),
  updateReport: () => new Promise(() => { }),
  deleteReport: () => new Promise(() => { }),
  copyReport: () => new Promise(() => { }),
  moveReportReportGroup: () => new Promise(() => { }),
  reportsQuery: {} as any,
  reportQuery: {} as any,

  // Report
  createReportGroup: () => new Promise(() => { }),
  updateReportGroup: () => new Promise(() => { }),
  deleteReportGroup: () => new Promise(() => { }),
  reportGroupsQuery: {} as any,
  reportGroupQuery: {} as any,

  // Columns
  createColumn: () => new Promise(() => { }),
  updateColumn: () => new Promise(() => { }),
  reorderColumn: () => new Promise(() => { }),
  deleteColumn: () => new Promise(() => { }),
  columnsQuery: {} as any,
}

export const ReportContext = React.createContext<IReportContext>(defaultContext)
