import * as React from "react";
import "./SideMenu.scss";
import { withRouter, RouteComponentProps } from "react-router";
import { Layout, Menu, Icon } from 'antd';
import { Link } from "react-router-dom";
import { ClickParam } from "antd/lib/menu";

const { SubMenu } = Menu;
const { Sider } = Layout;

export interface SideMenuSubOption {
  key: string
  title: string | React.ReactNode
  linkTo: string
  matcher?: RegExp
  onClick?: () => void
}

export interface SideMenuOption {
  key: string
  title: string
  linkTo?: string
  icon?: React.ReactNode
  onClick?: () => void
  matcher?: RegExp
  subOptions?: SideMenuSubOption[]
}

interface IProps extends RouteComponentProps {
  sideMenuOptions: SideMenuOption[]
  optionsLoading?: boolean
}

interface IState {
  openKeys: string[]
}

class SideMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openKeys: []
    }
  }

  componentDidMount() {
    const awaitLoadingDone = setInterval(() => {
      if (!this.props.optionsLoading) {
        const { parentKey } = this.getSelectedKeyFromPath();
        if (parentKey) this.setState({ openKeys: [...this.state.openKeys, parentKey] })
        clearInterval(awaitLoadingDone);
      }
    }, 200);
  }

  getSelectedKeyFromPath(): { key: string, path: string, parentKey?: string } {
    let selectedKey: { key: string, path: string, parentKey?: string } = { key: "", path: "" };
    let links: { key: string, path: string, parentKey?: string }[] = [];
    this.props.sideMenuOptions.forEach(({ key, linkTo, subOptions }) => {
      if (linkTo) links.push({ key, path: linkTo })
      if (subOptions) subOptions.forEach(subOption => {
        if (subOption.linkTo) links.push({ key: subOption.key, path: subOption.linkTo, parentKey: key })
      })
    })
    for (let i = 0; i < links.length; i++) if (links[i].path === this.props.location.pathname) selectedKey = links[i];
    return selectedKey;
  }

  onClick({ keyPath }: ClickParam) {
    if (keyPath.length >= 2) this.setState({ openKeys: [keyPath[keyPath.length - 1]] })
    else this.setState({ openKeys: [] })
  }

  menuOptions() {
    const subOption = ({ key, title, linkTo, onClick }: SideMenuSubOption) => (
      <Menu.Item key={key} onClick={onClick ? () => onClick() : () => { }}>
        {linkTo ? <Link to={linkTo}>{title}</Link> : title}
      </Menu.Item>
    )

    return this.props.sideMenuOptions.map(({ key, title, linkTo, icon, onClick, subOptions }) => {
      const Title = () => <span>{typeof icon === 'string' ? <Icon type={icon} /> : typeof icon !== 'undefined' ? icon : null} {<span className="side-menu-option-title">{title}</span>}</span>
      if (subOptions) return ( // Menu with Sub-Options
        <SubMenu
          key={key}
          title={Title()}
          onTitleClick={onClick ? () => { onClick(); if (linkTo) this.props.history.push(linkTo) } : () => { if (linkTo) this.props.history.push(linkTo) }}
        >
          {subOptions ? subOptions.map(option => subOption(option)) : null}
        </SubMenu>
      );
      else return subOption({ key, onClick, title: Title(), linkTo: linkTo ? linkTo : "" })
    })
  }

  render() {
    const { key, parentKey } = this.getSelectedKeyFromPath();

    const getMatchKey = () => {
      const foundOption = this.props.sideMenuOptions.find(option => option.matcher && option.matcher.test(this.props.location.pathname) ? true : false);
      if (foundOption) return [foundOption.key]
      else return [];
    }

    return (
      <Sider width={200} className="side-menu" collapsible={true}>
        <Menu
          mode="inline"
          openKeys={[...this.state.openKeys, ...getMatchKey()]}
          onOpenChange={openKeys => this.setState({ openKeys })}
          selectedKeys={[...getMatchKey(), key]}
          onClick={param => this.onClick(param)}
          style={{ height: '100%', borderRight: 0 }}
        >
          {this.menuOptions()}
        </Menu>
      </Sider>
    );
  };
}

export default withRouter(SideMenu);