import React from "react";
import { EntityField, FieldGroup } from "../../../lib/codegen";
import { EnhancedFormFieldsObject, EnhancedFormFieldGroup, EnhancedFormFieldOrFieldArray } from "../withEnhancedForm";
import { EnhancedFormField } from "../createFieldFromObject";

interface ISortedFieldGroup<T extends EntityField = EntityField> extends FieldGroup {
  fields: T[]
}

const fieldToEnhnacedField = (args: {
  field: EntityField,
  getValueFromProps?: EnhancedFormField<any>["getValueFromProps"], 
  isVisible?: EnhancedFormField<any>["isVisible"],
}): EnhancedFormField<any> => {
  const { id, type, name, isRequired, isMultiSelection, entityRelationType, selectionOptions } = args.field;
  const { getValueFromProps, isVisible } = args;
  const enhancedField: EnhancedFormField = {
    key: id,
    type, name,
    rules: [{ required: isRequired, message: `${name} is required` }],
    selectionOptions: selectionOptions ? selectionOptions.map(value => ({ key: value, name: value })) : [],
    isMultiSelection: (typeof isMultiSelection === "boolean") ? isMultiSelection : undefined,
    entityRelationType: entityRelationType ? entityRelationType : undefined,
    getValueFromProps,
    isVisible
  }
  return enhancedField;
}

export function mapEntityFieldsToEnhancedFields<T extends EntityField = EntityField, P = {}>(args: {
  entityFields: T[],
  fieldGroups: FieldGroup[],
  getValueFromProps?: EnhancedFormField<P>["getValueFromProps"], 
  isVisible?: EnhancedFormField<P>["isVisible"],
}): EnhancedFormFieldsObject<P> {
  const { getValueFromProps, isVisible } = args;

  const sortedGroups: ISortedFieldGroup[] = args.fieldGroups
    .map((group): ISortedFieldGroup => ({
      ...group,
      fields: args.entityFields
        .filter(field => field.group && field.group.id === group.id && field.isEnabled)
        .sort((a, b) => a.position - b.position)
    })).sort((a, b) => a.position - b.position)

  let enhancedGroups: EnhancedFormFieldGroup<P>[] = sortedGroups.filter(group => group.fields.length > 0).map((group): EnhancedFormFieldGroup<P> => ({
    title: group.name,
    fields: group.fields.map(field => fieldToEnhnacedField({ field, getValueFromProps, isVisible }))
  }));

  let enhancedFields: EnhancedFormFieldOrFieldArray = args.entityFields
    .filter(field => !field.group && field.isEnabled)
    .sort((a, b) => a.position - b.position)
    .map(field => fieldToEnhnacedField({ field, getValueFromProps, isVisible }));

  return [...enhancedFields, ...enhancedGroups];
}