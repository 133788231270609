import React from "react";
import "./ReportPane.scss";
import { TablePaneProps } from "../../../../components/tablePane/TablePane";
import { Report } from "../../../../lib/codegen";
import { ReportSettings } from "./reportSettings/ReportSettings";
import { Tabs } from "antd";
import { ReportResult } from "./reportResult/ReportResult";
import { withRouter, RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps<{ reportId?: string, pageName?: string}> {
 
}

const _ReportPane: React.FC<IProps> = props => {
  function getActiveKey() {
    const { pageName } = props.match.params;
    if (pageName === "settings" || pageName === "result" ) {
      return pageName;
    } else {
      return "settings";
    }
  }
  
  function hanldeClick(key: string) {
    props.history.push(`/admin/reports/${props.match.params.reportId}/${key}`)
  }

  return (
    <Tabs defaultActiveKey="1" size="large" activeKey={getActiveKey()} onTabClick={hanldeClick}>
      <Tabs.TabPane tab="Settings" key="settings">
        <ReportSettings />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Result" key="result">
        <ReportResult />
      </Tabs.TabPane>
    </Tabs>
  )
}

export const ReportPane = withRouter<IProps>(_ReportPane)
