import React from "react";
import { Row, Col } from "antd";
import { UserTable } from "../adminDashboard/userTable/UserTable";
import { UserDetails } from "./UserDetails";
import { RouteComponentProps } from "react-router";
import { UserPane } from "./UserPane";

interface IProps extends RouteComponentProps<{ userId?: string }> {

}

export const UserPage: React.FC<IProps> = props => {
  return (
    <UserPane />
  );
}