import React from "react";
import chartjs from "chart.js";
import { Bar, ChartData } from "react-chartjs-2";
import { LeaderboardDateRange, MeetingStatus, useCampaignStatsQuery } from "../../../lib/codegen";
import { getMeetingStatusColor } from "../../meetings/meetingPane/meetingStatusTag/MeetingStatusTag";
const labelsPlugin = require("chartjs-plugin-labels")
const annotationPlugin = require("chartjs-plugin-annotation")

interface CamapginMeetingBarChartProps {
  campaignId?: string
  width?: number
  height?: number
  dateRange?: LeaderboardDateRange
}

export const CamapginMeetingBarChart: React.FC<CamapginMeetingBarChartProps> = ({ campaignId, dateRange, width, height }) => {
  const { loading, error, data } = useCampaignStatsQuery({ variables: { data: { campaignId, dateRange } } })

  if (loading || error || !(data && data.campaignStats))
    return <div></div>

  const { meetingsStatus, totalMeetings } = data.campaignStats
  const chartData: ChartData<chartjs.ChartData> = {
    labels: [
      'Planned',
      'Held',
      'Reschedule',
      'Cancelled'
    ],
    datasets: [{
      data: [
        meetingsStatus.Planned,
        meetingsStatus.Held,
        meetingsStatus.To_Be_Rescheduled,
        meetingsStatus.Cancelled,
      ],
      backgroundColor: [
        getMeetingStatusColor(MeetingStatus["Planned"]),
        getMeetingStatusColor(MeetingStatus["Held"]),
        getMeetingStatusColor(MeetingStatus["ToBeRescheduled"]),
        getMeetingStatusColor(MeetingStatus["Cancelled"]),
      ],
      hoverBackgroundColor: [
        getMeetingStatusColor(MeetingStatus["Planned"]),
        getMeetingStatusColor(MeetingStatus["Held"]),
        getMeetingStatusColor(MeetingStatus["ToBeRescheduled"]),
        getMeetingStatusColor(MeetingStatus["Cancelled"]),
      ],
    }]
  }

  const totalMeetingsBarColor = meetingsStatus.Held >= totalMeetings ? '#73d13dc7' : '#ff4d4fc7'

  return <Bar
    redraw={true}
    width={width}
    height={height}
    data={chartData}
    plugins={[labelsPlugin, annotationPlugin]}
    options={{
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 1,
            maxTicksLimit: 5,
            suggestedMax: totalMeetings + (totalMeetings * 0.1),
          }
        }]
      },
      layout: {
        padding: {
          top: 6
        }
      },
      title: {
        display: true,
        text: '',
        padding: 1,
        // @ts-ignore
        lineHeight: 0
      },
      legend: {
        display: false
      },
      //@ts-ignore
      annotation: {
        annotations: [
          totalMeetings > 0 ? {
            type: 'line',
            id: 'line-1',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: totalMeetings,
            borderColor: totalMeetingsBarColor,
            borderWidth: 2,
            label: {
              enabled: true,
              content: 'Total Meetings',
              backgroundColor: totalMeetingsBarColor,
              fontStyle: 'normal',
              position: 'right',
              yAdjust: -10,
              cornerRadius: 2
            }
          } : {}
        ]
      },
      plugins: {
        labels: {
          render: 'value',
          showZero: true,
          fontSize: 12,
          fontColor: '#000000a6',
          fontStyle: 'normal',
          fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          position: 'border',
          textMargin: 2
        }
      }
    }}
  />

}