import React, { useState, useEffect } from "react";
import "./DataPane.scss";
import { Table, Pagination } from "antd";
import { ICustomPager } from "../tableCard/TableCard";
import { DataPaneTableProps, DataPaneRenderProps, DataPaneRenderFunc } from "./helpers/dataPaneTypes";
import { ColumnProps } from "antd/lib/table";
import { useRect } from "../../lib/helpers/hooks/useRect";

export type ActiveIdState = [string | null | undefined, (id: string | null | undefined) => void];

export interface DataPaneProps<T> {
  activeIdState: ActiveIdState
  tableProps: DataPaneTableProps<T>
  data: { total: number, items: Array<T> }
  loading: boolean
  renderPane: DataPaneRenderFunc<T>
  height?: number
  title?: string | DataPaneRenderFunc<T>
  paneTitle?: string | DataPaneRenderFunc<T>
  footerActions?: React.ReactNode | DataPaneRenderFunc<T>
  onRowClick?: (props: DataPaneRenderProps<T>) => void
  hideFooter?: boolean
}

const contentRef = React.createRef<HTMLDivElement>();

export function DataPane<T extends { id: string }>(props: DataPaneProps<T>) {
  const contentHeight = useRect<HTMLDivElement>(contentRef).height;
  const [Pager, setPager] = useState<ICustomPager>({
    pageSize: props.tableProps.pageSize ? props.tableProps.pageSize : 0,
    current: props.tableProps.initialPage(),
    total: props.data.total,
    size: "small",
  })

  useEffect(() => {
    if (props.data.total !== Pager.total && !props.loading) setPager({ ...Pager, total: props.data.total })
  }, [props.data])

  const renderProps: DataPaneRenderProps<T> = {
    activeItem: (() => props.data.items.find(item => item.id === props.activeIdState[0]))()
  }

  const getTableWidthStyle = (): React.CSSProperties => props.tableProps.widthPercent ? ({ 
    flex: `0 0 ${props.tableProps.widthPercent}%`,
    width: `${props.tableProps.widthPercent}%`
  }) : ({})
  
  const getPaneWidthStyle = (): React.CSSProperties => props.tableProps.widthPercent ? ({ 
    flex: `0 0 ${100 - props.tableProps.widthPercent}%`,
    width: `${100 - props.tableProps.widthPercent}%`
  }) : ({ })

  return (
    <div className="data-pane" style={{ height: props.height }}>
      <div className="header">
        <div className="title">
          {typeof props.title === "string" && props.title}
          {typeof props.title === "function" && props.title(renderProps)}
        </div>
      </div>
      <div className="content" ref={contentRef}>
        <div className="table" style={getTableWidthStyle()}>
          <Table<T>
            pagination={Pager}
            dataSource={props.data.items}
            columns={props.tableProps.columns.filter(item => typeof item !== "undefined") as ColumnProps<T>[]}
            rowKey={(item) => item.id}
            onRow={item => ({ onClick: () => { props.activeIdState[1](item.id); if (props.onRowClick) props.onRowClick(renderProps) } })}
            rowClassName={(record) => props.activeIdState[0] === record.id ? 'row-active' : ''}
            loading={props.loading}
            bodyStyle={{
              height: contentHeight,
              overflowY: "auto"
            }}
            expandedRowRender={props.tableProps.renderExpandedRow ? record => props.tableProps.renderExpandedRow!(record) : undefined}
          />
        </div>
        <div className="pane-wrapper" style={{
          height: contentHeight,
          ...getPaneWidthStyle()
        }}>
          <div className="header">
            {typeof props.paneTitle === "string" && props.paneTitle}
            {typeof props.paneTitle === "function" && props.paneTitle(renderProps)}
          </div>
          <div className="pane" style={{ height: contentHeight - 54 }}>
            {props.renderPane(renderProps)}
          </div>
        </div>
      </div>
      <div className="footer" style={ props.hideFooter ? { display: 'none' } : undefined }>
        <div className="pager">
          <Pagination {...Pager} key={1} style={{ marginTop: 2 }} onChange={(page, pageSize) => {
            setPager({ ...Pager, current: page })
            props.tableProps.handlePageChange((page - 1) * (pageSize ? pageSize : Pager.pageSize))
          }} />
        </div>
        <div className="actions">
          {typeof props.footerActions === "object" && props.footerActions}
          {typeof props.footerActions === "function" && props.footerActions(renderProps)}
        </div>
      </div>
    </div>
  );
}