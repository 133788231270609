import React from "react";
import { FilterEntryWithId } from "../../../meetingFilterGroups/components/MeetingFilterGroupDrawer";

export interface IMeetingFiltersContext {
  filters: FilterEntryWithId[]
  addFilter: (filter: FilterEntryWithId) => void
  setFilters: (filters: FilterEntryWithId[]) => void
  updateFilter: (filter: FilterEntryWithId) => void
  removeFilter: (filter: FilterEntryWithId) => void
}

const defaultState: IMeetingFiltersContext = {
  filters: [],
  addFilter: (filter) => { },
  setFilters: (filters) => { },
  updateFilter: (filter) => { },
  removeFilter: (filter) => { },
}

export const MeetingFiltersContext = React.createContext<IMeetingFiltersContext>(defaultState)