import React, { useContext } from "react";
import { Card, Switch } from "antd";
import { Title } from "../../../components/display/title/Title";
import { AppContext } from "../../../lib/helpers/AppContext";

interface IProps {

}

export const DarkModeToggle: React.FC<IProps> = props => {
  const { darkModeState } = useContext(AppContext);
  const [isEnabled, setIsEnabled] = darkModeState; 

  return(
    <Card>
      <Title size="sm">Night Mode (beta)</Title>
      <Switch
        checked={isEnabled}
        onChange={value => setIsEnabled(value)}
      />
    </Card>
  );
}