import React from "react";
import { Campaign, EntityFieldType, EntityRelationType } from "../../../../lib/codegen";
import { EnhancedFormFieldsObject } from "../../../../components/enhancedForm/withEnhancedForm";

export const campaignFields = (defaultClientId: string | null | undefined, campaign?: Campaign): EnhancedFormFieldsObject => [
  {
    title: "Details",
    fields: [
      {
        key: "name",
        name: "Name",
        type: EntityFieldType["Text"],
        rules: [{ required: true }],
        getValueFromProps: () => campaign ? campaign.name : undefined
      },
      [
        {
          key: "status",
          name: "Status",
          type: EntityFieldType["Selection"],
          selectionOptions: [
            { key: "Active", name: "Active" },
            { key: "Complete", name: "Complete" },
          ],
          rules: [{ required: true }],
          getValueFromProps: () => {
            if (defaultClientId) return "Active"
            else if (campaign) return campaign.status
          }
        },
        {
          key: "startDate",
          name: "Start Date",
          type: EntityFieldType["Date"],
          getValueFromProps: () => campaign ? campaign.startDate : undefined
        },
      ],
      [
        {
          key: "totalMeetings",
          name: "Total Meetings",
          type: EntityFieldType["Number"],
          getValueFromProps: () => campaign ? campaign.totalMeetings : undefined
        },
        {
          key: "invoiceName",
          name: "Invoice Name",
          type: EntityFieldType["Text"],
          getValueFromProps: () => campaign ? campaign.invoiceName : undefined
        },
      ]
    ]
  },
  {
    title: "Relations",
    fields: [
      [
        {
          key: "clientId",
          name: "Client",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Client"],
          getValueFromProps: () => {
            if (campaign && campaign.client) return campaign.client.id
            else if (defaultClientId) return defaultClientId
          }
        },
        {
          key: "partnerId",
          name: "Partner",
          type: EntityFieldType["Relation"],
          entityRelationType: EntityRelationType["Partner"],
          getValueFromProps: () => campaign && campaign.partner ? campaign.partner.id : undefined
        }
      ]
    ]
  },
]