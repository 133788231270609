import { Company, CompanyField, Role, useMeQuery, useCompanyFieldsQuery, useFieldGroupsQuery, FieldGroupType } from "../../../lib/codegen";
import { EnhancedFormFieldsObject } from "../../../components/enhancedForm/withEnhancedForm";
import { mapEntityFieldsToEnhancedFields } from "../../../components/enhancedForm/formHelpers/mapEntityFieldsToEnhancedFields";
import { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { FieldType } from "../../../lib/ts/entityFields";

export function useCompanyFields(company?: Company): EnhancedFormFieldsObject {
  const groupsQuery = useFieldGroupsQuery({ variables: { fieldType: FieldGroupType["CompanyField"] } })
  const fieldsQuery = useCompanyFieldsQuery();
  const meQuery = useMeQuery();
  const user = meQuery.data && meQuery.data.me ? meQuery.data.me : undefined;
  const fields = fieldsQuery.data && fieldsQuery.data.companyFields ? fieldsQuery.data.companyFields : [];
  const groups = groupsQuery.data && groupsQuery.data.fieldGroups ? groupsQuery.data.fieldGroups : []
  const isCreating = company ? false : true;

  const staticFields: EnhancedFormFieldsObject = [
    {
      key: "domain",
      type: FieldType["TEXT"],
      name: "Domain",
      rules: [{ required: true, message: "Domain is required!" }],
      getValueFromProps: props => company ? company.domain : undefined
    },
    {
      key: "name",
      type: FieldType["TEXT"],
      name: "Name",
      rules: [{ required: true, message: "Name is required!" }],
      getValueFromProps: props => company ? company.name : undefined
    }
  ]

  const customFields = mapEntityFieldsToEnhancedFields<CompanyField, EnhnacedFormArgs>({
    entityFields: fields,
    fieldGroups: groups,
    isVisible: (args) => {
      const { field, formDataObject } = args
      const companyField = fields.find(_field => _field.id === field.key); if (!companyField) return true;

      if (companyField.isAdminField && (user && user.role === Role["User"] || !user)) return false;

      if (isCreating && !companyField.isVisibleOnCreate) return false;

      return true
    },
    getValueFromProps: (args) => {
      if (!company) return;
      const fieldData = company.fieldsData.find(({ fieldId }) => fieldId === args.field.key)
      if (fieldData) return fieldData.value;
    }
  })

  return [...staticFields, ...customFields];
}