import React, { useState } from "react";
import { EntityRelationType, FilterEntry, ReportFieldMetadata, EntityField, ComparisonOperator } from "../../../lib/codegen";
import { FiltersState } from "../FiltersList";
import { IFilterDataType, normalizeEntityFieldType } from "../helpers/normalizeEntityFieldType";
import { SelectionOption } from "../../enhancedForm/createFieldFromObject";
import { Button, Modal } from "antd";
import { FilterForm, FilterFormFields } from "../filterForm/FilterForm";
import { useForm } from "../../../lib/helpers/hooks/useForm";
import { FilterEntryWithId } from "../../../pages/meetings/meetingFilterGroups/components/MeetingFilterGroupDrawer";

export interface FilterOptionProps {
  id: string
  entityName: EntityRelationType
  relationName: string | null
  filterState: FiltersState
  fieldMetadata?: ReportFieldMetadata
  entityField?: EntityField
  optionTaken: boolean
  useFilterEntryId?: boolean
  handleFilterCreate: (filterEntry: (FilterEntry | FilterEntryWithId)) => void
}

export const getFilterOptionName = ({ relationName, entityName, fieldMetadata, entityField }: FilterOptionProps): string => {
  const parentName = relationName ? relationName : entityName;
  const name = fieldMetadata ? fieldMetadata.name : entityField ? entityField.name : "";
  return `${parentName} / ${name}`
}

export const FilterOption: React.FC<FilterOptionProps> = props => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const form = useForm<FilterFormFields>({
    comparisonOperator: ComparisonOperator["Equals"],
    filterValue: ""
  })
  const { entityField, fieldMetadata } = props;
  const type: IFilterDataType = fieldMetadata && fieldMetadata.dataType ? fieldMetadata.dataType as IFilterDataType : entityField ? normalizeEntityFieldType(entityField.type) : "string";
  const selectonOptions: SelectionOption[] | undefined =
    fieldMetadata && fieldMetadata.selectionOptions ? fieldMetadata.selectionOptions as SelectionOption[] :
      entityField && entityField.selectionOptions ? entityField.selectionOptions.map(key => ({ key, name: key })) : undefined
  return(
    <div className="filter-option">
      <Modal
        title={getFilterOptionName(props)}
        visible={isFormVisible}
        onOk={() => {
            props.handleFilterCreate({
              comparisonOperator: form.getFieldValue("comparisonOperator"),
              filterValue: form.getFieldValue("filterValue"),
              entityName: props.entityName,
              staticFieldKey: props.fieldMetadata ? props.fieldMetadata.staticFieldKey : undefined,
              customFieldId: props.entityField ? props.entityField.id : undefined,
              relativeDateDaysOffset: form.getFieldValue("relativeDateDaysOffset"),
              id: props.useFilterEntryId ? props.id : undefined
            });
            setIsFormVisible(false);
        }} 
        onCancel={() => setIsFormVisible(false)}
      >
        <FilterForm
          form={form}
          filterOption={props}
        />
      </Modal>
      <Button
        icon="plus"
        shape="circle"
        size="small"
        // disabled={props.optionTaken}
        onClick={() => setIsFormVisible(true)}
      /> {getFilterOptionName(props)}
    </div>
  );
}