import React, { useState } from "react";
import "./TablePane.scss";
import { Row, Col, Card, Button, Icon, Pagination, message } from "antd";
import { TableCardProps, TableCard, ICustomPager } from "../tableCard/TableCard";
import EnhancedForm, { EnhancedFormComponentProps, EnhnacedFormArgs } from "../enhancedForm/EnhancedForm";
import { Omit } from "../../lib/codegen";
import { FormDataObject, EnhancedFormProps } from "../enhancedForm/withEnhancedForm";
import { ColProps } from "antd/lib/col";

export type TablePaneRenderProps<T = any, P = any> = TablePaneProps<T, P> & { form: EnhnacedFormArgs<any> };

export interface TablePaneProps<T = any, P = any> {
  tableCardProps: TableCardProps<T>
  activeIdState: [string | null, (value: string | null) => void]
  enhancedFormProps?: Omit<EnhancedFormComponentProps<P>, "children">
  isEditingState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  hideEditButton?: boolean
  paneTitle?: string | React.ReactNode
  cardTitle?: string | React.ReactNode
  className?: string
  columnProps?: { table: ColProps, pane: ColProps }
  height?: number
  style?: React.CSSProperties
  handleSave?: (data: FormDataObject) => Promise<any>
  renderPane?: (args: TablePaneRenderProps<T, P>) => React.ReactNode
  renderArgs?: P
}

const paneHeaderRef = React.createRef<HTMLDivElement>()

export function TablePane<T extends { id: string, [key: string]: any }>(props: TablePaneProps) {
  const height = props.height ? props.height : 500;
  const enhancedFormProps: Omit<EnhancedFormComponentProps, "children"> = props.enhancedFormProps ? props.enhancedFormProps : { formFields: [] }
  const paneHeaderHeight = paneHeaderRef.current ? paneHeaderRef.current.getBoundingClientRect().height : 0;
  const localIsEditingState = useState<boolean>(false);
  const [isEditing, setIsEditing] = props.isEditingState ? props.isEditingState : localIsEditingState
  const [activeId, setActiveId] = props.activeIdState;
  const createVisibleState = useState(false);
  const [Pager, setPager] = useState<ICustomPager>({
    pageSize: props.tableCardProps.pageSize,
    current: 1,
    total: props.tableCardProps.dataSource ? props.tableCardProps.dataSource.total : 0,
    size: "small"
  })
  const columnProps: { table: ColProps, pane: ColProps } = props.columnProps ? props.columnProps : {
    table: { sm: 24, md: 12 },
    pane: { sm: 24, md: 12 },
  }

  function handleActionClick() {
    if (props.tableCardProps.actionBtn) {
      const { useCreateModel, onClick } = props.tableCardProps.actionBtn;
      if (onClick) onClick()
      if (useCreateModel) createVisibleState[1](true)
    }
  }

  function handleSave(form: EnhnacedFormArgs) {
    if (props.handleSave) {
      const errors = form.formValidate();
      if (errors.length !== 0) return;
      const formData = form.formDataObject({ formatFieldsEntry: true });
      props.handleSave(formData).then(() => {
        setIsEditing(false);
        message.success("Saved");
      });
    }
  }

  return (
    <EnhancedForm {...enhancedFormProps} isEditing={isEditing}>
      {form => (
        <Card
          className={`table-pane ${props.className ? props.className : ""}`}
          title={props.cardTitle && <div className="header-title">{props.cardTitle}</div>}
          style={props.style}
          actions={[
            <Pagination {...Pager} key={1} style={{ marginTop: 2 }} onChange={page => {
              setPager({ ...Pager, current: page })
              props.tableCardProps.handlePageChange({ ...Pager, current: page })
            }} />,
            props.tableCardProps.actionBtn ? <Button type="ghost" onClick={handleActionClick}><Icon type={props.tableCardProps.actionBtn.icon ? props.tableCardProps.actionBtn.icon : "plus"} /> {props.tableCardProps.actionBtn.title}</Button> : null
          ]}
        >
          <Row>
            <Col className="table" style={{ height: height }} {...columnProps.table}>
              <TableCard<T>
                {...props.tableCardProps}
                customPager={{ Pager, setPager }}
                createVisibleState={createVisibleState}
                cardProps={{ bordered: false }}
                cardActionsHidden={true}
                activeRowId={activeId ? activeId : undefined}
                onRowEdit={item => { setIsEditing(true); form.formClear(); setActiveId(item.id); }}
                onRowClick={item => { if (item.id !== activeId) { setIsEditing(false); setActiveId(item.id); } }}
              />
            </Col>
            <Col className="pane" style={{ height: height }} {...columnProps.pane}>
              <div className="pane-header" ref={paneHeaderRef}>
                <span className="ant-table-column-title">{props.paneTitle}</span>
                <span className="actions">
                  {isEditing && !props.hideEditButton? (<span>
                    <Button type="default" onClick={() => setIsEditing(false)}>Cancel</Button>
                    <Button type="primary" style={{ marginLeft: 10 }} onClick={() => handleSave(form)}>Save</Button>
                  </span>) : (
                      !props.hideEditButton && <Button type="ghost" onClick={() => setIsEditing(true)}>Edit</Button>
                    )}
                </span>
              </div>
              <div className="pane-body" style={{ height: height - paneHeaderHeight }}>
                {!props.renderPane && activeId && form.renderForm(props.renderArgs)}
                {props.renderPane && props.renderPane({
                  form,
                  ...props,
                  isEditingState: [isEditing, setIsEditing],
                })}
              </div>
            </Col>
          </Row>
        </Card>
      )}
    </EnhancedForm>
  );
}
