import React from "react";
import { ColumnList } from "../columnList/ColumnList";
import { ColumnListState } from "../ReportSettings";

interface IProps {
  columnListState: [ColumnListState, React.Dispatch<React.SetStateAction<ColumnListState>>]
  loading?: boolean
}

export const SelectedColumns: React.FC<IProps> = props => {
  const [columnListState, setColumnListState] = props.columnListState;

  return (
    <div>
      <h3>Selected Columns</h3>
      <ColumnList
        columns={columnListState.selected}
        droppableId="selected-columns"
        loading={props.loading}
      />
    </div>
  );
}