import { Icon, Input, Select } from "antd";
import React, { useState } from "react";
import { useReportGroupQuery, useReportGroupsQuery } from "../../../lib/codegen";

interface SelectReportGroupProps {
  onChange: (value: string | null) => void
  value: string | null
} 

export const SelectReportGroup: React.FC<SelectReportGroupProps> = props => {
  const reportGroupsQuery = useReportGroupsQuery({ variables: { data: { limit: 99999 }}})

  let reportGroups = reportGroupsQuery.data && reportGroupsQuery.data.reportGroups ? reportGroupsQuery.data.reportGroups.items : []

  return (
    <Select
      onSelect={(value: any) => { if (typeof value === "string") props.onChange(value as string) }}
      onChange={(value: any) => { if (typeof value === "string") props.onChange(value as string) }}
      showSearch={true}
      style={{ width: '100%' }}
      value={props.value}
    >
      {reportGroups.map(({ id, name }) => <Select.Option key={id} value={id} ref={id}>{name}</Select.Option>)}
    </Select>
  );
}