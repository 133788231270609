import React, { useContext, useState } from "react";
import "./ReportColumn.scss";
import { Card, Popconfirm, Button } from "antd";
import { Draggable } from "react-beautiful-dnd";
import { ReportColumn, ReportEntityMetadataComponent } from "../../../../../../lib/codegen";
import { ReportContext } from "../../../../helpers/ReportContext";
import { PopForm } from "../../../../../../components/popform/PopForm";
import { reportColumnFields, getEntityField } from "./reportColumnFields";

export type IReportColumnFieldType = "MEETING" | "CONTACT" | "COMPANY" | "STATIC";

export interface IReportColumn {
  id: string
  name: string
  columnFieldType: IReportColumnFieldType
  position: number
  reportColumn?: ReportColumn
}

interface ReportColumnProps extends IReportColumn {

}

export const ReportColumnItem: React.FC<ReportColumnProps> = ({ id, name, position, reportColumn }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const { deleteColumn, columnsQuery, updateColumn } = useContext(ReportContext);
  const entityField = reportColumn ? getEntityField(reportColumn) : undefined;
  const entityRelationType = entityField && entityField.entityRelationType ? entityField.entityRelationType : undefined;
  if (!id) return <span />
  return (
    <ReportEntityMetadataComponent variables={{ entityType: entityRelationType }} skip={!entityRelationType}>
      {({ data }) => (
        <Draggable
        key={id}
        draggableId={id}
        index={position}
        type="field"
      >
        {(provided, snapshot) => (
          <div>
            {reportColumn && <PopForm
              title="Edit Column"
              visible={isEditVisible}
                formFields={reportColumnFields({
                  reportColumn,
                  entityMetadata: data && data.reportEntityMetadata ? data.reportEntityMetadata : undefined
              })}
              onClose={() => setIsEditVisible(false)}
              onSubmit={data => updateColumn({ variables: { id, data: data as any } }).then(() => columnsQuery.refetch())}
            />}
  
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <Card className={`report-column`}>
  
                <span className="name" {...provided.dragHandleProps}>
                  {name}
                </span>
  
                {reportColumn && <span className="actions">
                  <Button type="primary" icon="edit" size="small" shape="circle" style={{ marginRight: 5 }} onClick={() => setIsEditVisible(true)} />
                  <Popconfirm
                    title="Delete column?"
                    onConfirm={() => deleteColumn({ variables: { id } }).then(() => columnsQuery.refetch())}
                    okText="Delete"
                    okButtonProps={{ type: "danger" }}
                    cancelButtonProps={{ type: "primary" }}
                  >
                    <Button type="danger" icon="delete" size="small" shape="circle" />
                  </Popconfirm>
                </span>}
  
              </Card>
            </div>
          </div>
        )}
      </Draggable>
      )}
    </ReportEntityMetadataComponent>
  );
}