import React from "react";
import { UpdateUserPasswordComponent, EntityFieldType } from "../../../../lib/codegen";
import { Modal, message } from "antd";
import withEnhancedForm, { EnhancedFormProps } from "../../../../components/enhancedForm/withEnhancedForm";

interface IProps {
  isVisible: boolean
  handleClose: () => void
  userId: string
}

type ExtendedProps = IProps & EnhancedFormProps;

const _UpdatePassword: React.FC<ExtendedProps> = props => (
  <UpdateUserPasswordComponent>
    {updateUserPassword => (
      <Modal
        visible={props.isVisible}
        title="Reset Password"
        onCancel={props.handleClose}
        onOk={() => updateUserPassword({ variables: { id: props.userId, password: props.formDataObject()["password"] as string } }).then(() => {
          props.handleClose();
          message.success("Password updated");
        })}
      >
        {props.renderForm()}
      </Modal>
    )}
  </UpdateUserPasswordComponent>
)

export const UpdatePassword = withEnhancedForm<IProps>({
  formFields: [
    {
      key: "password",
      name: "Password",
      type: EntityFieldType["Text"],
      rules: [{ required: true, message: "Password is required" }]
    }
  ]
})(_UpdatePassword)