import React, { useState } from "react";
import "./ListCard.scss";
import { Card, Button, Icon, List, Popconfirm } from "antd";
import { CardProps } from "antd/lib/card/index";
import { ListProps } from "antd/lib/list/index";
import { Omit } from "../../lib/codegen";
import { EnhancedFormField } from "../enhancedForm/createFieldFromObject";
import { ListCardModalProps, ListCardModal } from "./ListCardModal";

type VoidFunc = (args?: any) => void

export interface RowData<T> {
  key: string
  title: string | React.ReactNode
  useUpdateModal?: boolean
  onEdit?: (item: T) => void 
  onClick?: (item: T) => void
  onDelete?: (item: T) => void
}

export interface ListCardProps<T> {
  title?: string | React.ReactNode
  dataSource: T[]
  rowKey: (item: T) => string
  getRowData: (item: T) => RowData<T>
  loading?: boolean
  createButton?: {
    text?: string
    iconName?: string
    onClick?: VoidFunc | "OPEN_CREATE"
  }
  createModal?: ListCardModalProps<T>
  updateModal?: ListCardModalProps<T>
  cardProps?: CardProps
  listProps?: CustomListProps<T>
}

// omit required prop of dataSource, renderItem
export interface CustomListProps<T> extends Omit<Omit<ListProps<T>, "dataSource">, "renderItem"> {
  dataSource?: ListProps<T>["dataSource"]
  renderItem?: ListProps<T>["renderItem"]
}

export function ListCard<DataItem = any>(props: ListCardProps<DataItem>) {
  // State
  const [activeRowKey, setActiveRowKey] = useState<string | number | null>(null);
  const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
  const [updateItemId, setUpdateItemId] = useState<string | null>(null);

  // Card Props
  let cardProps = props.cardProps ? props.cardProps : {} as CardProps
  cardProps.className = "list-card"
  cardProps.title = props.title
  cardProps.loading = props.loading
  if (props.createButton) cardProps.extra = (() => {
    const { text, iconName, onClick } = props.createButton!;
    return <Button onClick={typeof onClick === "function" ? onClick : () => setIsCreateVisible(true)}><Icon type={iconName ? iconName : "plus"} />{text ? text : "create"}</Button>
  })();

  // List Props
  let listProps = props.listProps ? props.listProps as ListProps<DataItem> : {} as ListProps<DataItem>;
  listProps.itemLayout = "horizontal"
  listProps.dataSource = props.dataSource
  listProps.rowKey = props.rowKey
  listProps.renderItem = item => {
    const rowData = props.getRowData(item);
    const { key, title, onEdit, onDelete, onClick, useUpdateModal } = rowData;
    const isActive = key === activeRowKey;
    const getClassName = () => {
      let className = "";
      if (isActive) className = "active"
      if (onClick) className += " clickable"
      return className;
    }
    return (
      <List.Item key={key} className={getClassName()} onClick={() => onClick && !isActive ? onClick(item) : null}>
        <span>{title}</span>
        <span className="actions">
          {!onEdit && !useUpdateModal? null : 
            <Button onClick={event => { event.stopPropagation(); onRowEdit(item, rowData); }} type="primary" icon="edit" size="small" shape="circle" style={{ marginRight: 5 }} />
          }
          {!onDelete ? null : 
            <Popconfirm
              title="Are you sure you want to delete?"
              onVisibleChange={visible => visible ? setActiveRowKey(key) : setActiveRowKey(null)}
              onConfirm={() => onDelete(item) }
              okButtonProps={{ type: "danger" }}
              okText="Delete"
              cancelButtonProps={{ type: "primary" }}
            >
              <Button onClick={event => event.stopPropagation()} type="danger" icon="delete" size="small" shape="circle" />
            </Popconfirm>
          }
        </span>
      </List.Item>
    )
  }

  async function onRowEdit(item: DataItem, rowData: RowData<DataItem>) {
    if (rowData.useUpdateModal) {
      setUpdateItemId(rowData.key)
    }
    if (rowData.onEdit) {
      rowData.onEdit(item);
    }
  }

  return (
    <Card {...cardProps}>
      {!props.createModal ? null : (
        <ListCardModal 
          fields={props.createModal.fields}
          onSave={props.createModal.onSave}
          title={props.createModal.title}
          handleClose={() => setIsCreateVisible(false)}
          isVisible={isCreateVisible}
        />
      )}
      {!props.updateModal ? null : (
        <ListCardModal 
          fields={props.updateModal.fields}
          onSave={props.updateModal.onSave}
          title={props.updateModal.title}
          handleClose={() => setUpdateItemId(null)}
          isVisible={updateItemId ? true : false}
          entityId={updateItemId ? updateItemId : undefined}
          getFieldsData={props.updateModal.getFieldsData}
        />
      )}
      <List {...listProps} />
    </Card>
  );
}