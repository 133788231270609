import React, { useState, useEffect, useContext } from "react";
import "./TableCard.scss";
import { Card, Table, Button, Icon, Popconfirm, Pagination } from "antd";
import { ColumnProps, PaginationConfig, SorterResult, TableCurrentDataSource } from "antd/lib/table";
import { TableCardModalParentProps, TableCardModalProps, TableCardModal } from "./TableCardModal";
import { Omit } from "antd/lib/_util/type";
import { CardProps } from "antd/lib/card";
import { AppContext } from "../../lib/helpers/AppContext";
import { ResponsiveSize } from "../../lib/helpers/hooks/useResponsive";

export interface ItemProps<T> {
  id: string
}

export interface TableCardProps<T> {
  dataSource: {
    total: number
    items: Array<T>
  } | undefined | null
  title?: string | React.ReactNode
  pageSize: number
  columns: Array<ColumnProps<T>>
  loading?: boolean
  className?: string
  actionBtn?: {
    title: string | React.ReactNode
    icon?: string
    onClick?: () => void
    useCreateModel?: boolean
  }
  createModal?: TableCardModalParentProps<T>
  updateModal?: TableCardModalParentProps<T>
  activeRowId?: string
  cardProps?: CardProps
  customPager?: {
    Pager: ICustomPager
    setPager: (Pager: ICustomPager) => void
  }
  createVisibleState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  cardActionsHidden?: boolean
  getItemProps: (item: T) => ItemProps<T>
  handlePageChange: (Pager: PaginationConfig) => void
  onRowEdit?: (item: T) => void
  onRowDelete?: (item: T) => void
  onRowClick?: (item: T) => void
}

export interface ICustomPager extends Omit<Omit<PaginationConfig, "current">, "pageSize"> {
  current: number
  pageSize: number
}

function mergeColumns<T>(props: TableCardProps<T>, hanldeRowEdit: (item: T) => void, setActiveRowId: (id: string | null) => void, setActionsVisibleId: (id: string | null) => void ): Array<ColumnProps<T>> {
  const actionsColumn: ColumnProps<T> = {
    key: "actions",
    title: <span style={{ fontWeight: 100, marginLeft: 5 }}>Actions</span>,
    width: 85,
    render: (text: null, item) => {
      return (
        <span className="actions">
          {!props.onRowEdit && !props.updateModal ? null :
            <Button onClick={event => { event.stopPropagation(); hanldeRowEdit(item); }} type="primary" icon="edit" size="small" shape="circle" style={{ marginRight: 5 }} />
          }
          {!props.onRowDelete ? null :
            <Popconfirm
              title="Are you sure you want to delete?"
              onVisibleChange={visible => {
                if (visible) {
                  setActiveRowId(props.getItemProps(item).id)
                  setActionsVisibleId(props.getItemProps(item).id)
                } else {
                  setActiveRowId(null)
                  setActionsVisibleId(null)
                }
              }}
              onConfirm={(e) => { if (e) e.stopPropagation();  props.onRowDelete!(item) }}
              okButtonProps={{ type: "danger" }}
              okText="Delete"
              cancelButtonProps={{ type: "primary" }}
              onCancel={(e) => { if (e) e.stopPropagation() }}
            >
              <Button onClick={event => event.stopPropagation()} type="danger" icon="delete" size="small" shape="circle" />
            </Popconfirm>
          }
        </span>
      )
    }
  }
  if (props.onRowEdit || props.updateModal || props.onRowDelete) return [...props.columns, actionsColumn]
  else return props.columns
}

export function TableCard<T = any>(props: TableCardProps<T>) {
  // Context
  const { responsiveSize, responsiveCalc } = useContext(AppContext);

  // State
  const [actionsVisibleId, setActionsVisibleId] = useState<string | null>(null)
  const [updateItemId, setUpdateItemId] = useState<string | null>(null);
  const [activeRowId, setActiveRowId] = useState<string | null>(null)

  // Create Module Visible State
  const localCreateVisableState = useState(false);
  const [isCreateVisable, setIsCreateVisable] = props.createVisibleState ? props.createVisibleState : localCreateVisableState
  
  // Pager
  const [localPager, setLocalPager] = useState<ICustomPager>({
    pageSize: props.pageSize,
    current: 1,
    total: props.dataSource ? props.dataSource.total : 1,
    size: "small"
  })
  const Pager = props.customPager ? props.customPager.Pager : localPager;
  const setPager = props.customPager ? props.customPager.setPager : setLocalPager; 
  
  useEffect(() => {
    if (props.dataSource && props.dataSource.total !== Pager.total) setPager({ ...Pager, total: props.dataSource.total })
  }, [props.dataSource])

  // Handle Edit
  function handleRowEdit(item: T) {
    if (props.updateModal || props.onRowEdit) {
      setActiveRowId(props.getItemProps(item).id)
      
    }
    if (props.updateModal) setUpdateItemId(props.getItemProps(item).id)
    if (props.onRowEdit) props.onRowEdit(item);
  }

  // Handle Create
  function handleActionClick() {
    if (props.actionBtn) {
      const { useCreateModel, onClick } = props.actionBtn;
      if (onClick) onClick()
      if (useCreateModel) setIsCreateVisable(true)
    }
  }

  let cardActions = [
    <Pagination {...Pager} key={1} style={{ marginTop: 2 }} onChange={page => {
    setPager({ ...Pager, current: page })
    props.handlePageChange({ ...Pager, current: page })
    }} />
  ]
  if (props.actionBtn) cardActions.push(<Button type="ghost" onClick={handleActionClick}><Icon type={props.actionBtn.icon ? props.actionBtn.icon : "plus"} /> {props.actionBtn.title}</Button>)

  return (
    <Card
      {...props.cardProps}
      title={props.title}
      className={`table-card ${props.className ? props.className : null}`}
      actions={!props.cardActionsHidden ? cardActions : undefined}
    >
      <Table
        dataSource={props.dataSource ? props.dataSource.items : []}
        columns={mergeColumns(props, handleRowEdit, setActiveRowId, setActionsVisibleId)}
        rowKey={record => props.getItemProps(record).id}
        pagination={false}
        rowClassName={(item) => `
          ${(props.getItemProps(item).id === activeRowId) || (props.getItemProps(item).id === props.activeRowId) ? "active" : ""} 
          ${props.getItemProps(item).id === actionsVisibleId ? "actions-visible" : ""} 
          ${props.onRowClick ? "clickable" : ""}
        `}
        loading={props.loading}
        onRowClick={props.onRowClick}
        size={responsiveCalc(ResponsiveSize["md"], "down") ? "small" : undefined}
      />
      {!props.actionBtn || !props.actionBtn.useCreateModel || !props.createModal ? null : (
        <TableCardModal
          fields={props.createModal.fields}
          title={props.createModal.title}
          isVisible={isCreateVisable}
          entityId={null}
          getItemProps={props.getItemProps}
          onSave={props.createModal.onSave}
          handleClose={() => setIsCreateVisable(false)}
        />
      )}
      {!props.updateModal ? null : (
        <TableCardModal
          fields={props.updateModal.fields}
          title={props.updateModal.title}
          isVisible={updateItemId ? true : false}
          entityId={updateItemId}
          getFieldsData={props.updateModal.getFieldsData}
          getItemProps={props.getItemProps}
          onSave={props.updateModal.onSave}
          handleClose={() => { setActiveRowId(null); setUpdateItemId(null); }}
          clearFormOnClose={true}
        />
      )}
    </Card>
  )
}

