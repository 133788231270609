import * as React from "react";
import { EnhancedFormField } from "../createFieldFromObject";
import { Descriptions } from "antd";
import { FieldValue } from "../../../lib/ts/entityFields";
import { EntityFieldType, EntityRelationType } from "../../../lib/codegen";
import moment from "moment"
import { ClientRelationDisplay, CompanyRelationDisplay, ContactRelationDisplay, PartnerRelationDisplay, ClientContactRelationDisplay, CampaignRelationDisplay, MeetingTagRelationDisplay, TradeshowRelationDisplay, UserRelationDisplay } from "./relationDisplays";

interface RenderOptions {
  span?: number
  skipOnValueUndefined?: boolean
  useBasicHtml?: boolean
}

export function renderDataItem<P>(field: EnhancedFormField<P>, renderArgs: P, options: RenderOptions): React.ReactNode | undefined {
  const isVisible = field.isVisible ? field.isVisible({ ...renderArgs, field }) : true;
  if (!isVisible) return undefined;

  let value = field.getValueFromProps ? field.getValueFromProps({ ...renderArgs, field }) : undefined;
  if (value === null) value = undefined;

  if (typeof value === "undefined" && typeof options.skipOnValueUndefined === "boolean" && options.skipOnValueUndefined) return undefined;

  if (options.useBasicHtml) return (
    <li>
      <b>{field.name ? field.name : field.key}</b>
      <span>: </span>
      <span>{getDisplayValue(value, field, renderArgs)}</span>
    </li>
  )

  return (
    <Descriptions.Item
      key={field.key}
      label={field.name ? field.name : field.key}
      span={options.span}
      prefixCls={typeof value === "undefined" ? "data-item-null" : undefined}
    >
      <span>{getDisplayValue(value, field, renderArgs)}</span>
    </Descriptions.Item>
  )
}

export function getDisplayValue<P>(value: FieldValue, field: EnhancedFormField<P>, renderArgs: P) {
  const { type, entityRelationType, isDateTime, getDisplayValue } = field;
  if (typeof getDisplayValue === "function") return getDisplayValue({...renderArgs, field });
  if (type === EntityFieldType["Relation"] && entityRelationType && typeof value === "string")
    switch (entityRelationType) {
      case EntityRelationType["Company"]: return <CompanyRelationDisplay id={value} />
      case EntityRelationType["Contact"]: return <ContactRelationDisplay id={value} />
      case EntityRelationType["Client"]: return <ClientRelationDisplay id={value} />
      case EntityRelationType["Partner"]: return <PartnerRelationDisplay id={value} />
      case EntityRelationType["ClientContact"]: return <ClientContactRelationDisplay id={value} />
      case EntityRelationType["Campaign"]: return <CampaignRelationDisplay id={value} />
      case EntityRelationType["Tradeshow"]: return <TradeshowRelationDisplay id={value} />
      case EntityRelationType["MeetingTag"]: return <MeetingTagRelationDisplay id={value} />
      case EntityRelationType["User"]: return <UserRelationDisplay id={value} />
    }
  else if (type === EntityFieldType["Date"] && typeof value === "string") {
    if (isDateTime === "time") return value;
    if (isDateTime) return moment(value).format("M/D/YY h:mm a")
    else return moment(value).format("M/D/YY")
  }
  else if (type === EntityFieldType["Selection"]) {
    if (Array.isArray(value)) return value.toString()
    else return value
  }
  else if (type === EntityFieldType["Richtext"]) {
    return <div dangerouslySetInnerHTML={typeof value === "string" ? { __html: value } : undefined}></div>
  }
  else if (type === EntityFieldType["Boolean"]) {
    return value ? "Yes" : "No"
  }
  else
    return value;
}