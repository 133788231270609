import React, { useContext } from "react";
import { FilterEntry, ReportFiltersComponent, CreateReportFilterComponent, UpdateReportFilterComponent, DeleteReportFilterComponent, ReportFilter } from "../../../../../../lib/codegen";
import { ReportContext } from "../../../../helpers/ReportContext";
import { FiltersList, FiltersState } from "../../../../../../components/filtersList/FiltersList";
import { getFilterId } from "../../../../../../components/filtersList/helpers/getFilterId";

interface IProps {

}

function getReportFilter(filterEntry: FilterEntry, reportFilters: ReportFilter[]): ReportFilter | undefined {
  return reportFilters.find(reportFilter => {
    const entryKey = getFilterId(filterEntry);
    const reportFilterKey = getFilterId(reportFilter)
    return entryKey === reportFilterKey;
  })
}

export const ReportFilters: React.FC<IProps> = props => {
  const { activeReportIdState } = useContext(ReportContext);
  
  return (
    <ReportFiltersComponent variables={{ reportId: activeReportIdState[0]! }} skip={!activeReportIdState[0]}>
      {filtersQuery => (
        <CreateReportFilterComponent>
          {createFilter => (
            <UpdateReportFilterComponent>
              {updateFilter => (
                <DeleteReportFilterComponent>
                  {deleteFilter => {
                    const reportFilters = filtersQuery.data && Array.isArray(filtersQuery.data.reportFilters) ? filtersQuery.data.reportFilters : [];
                    const filterState: FiltersState = [reportFilters, (values: FilterEntry[]) => { }]

                    // console.log(filterState);

                    return (
                      <div>
                        <h3>Report Filters</h3>
                        <FiltersList
                          filterState={filterState}
                          handleFilterCreate={filterEntry => {
                            return createFilter({
                              variables: {
                                data: {
                                  ...filterEntry,
                                  reportId: activeReportIdState[0]!
                                }
                              }
                            }).then(() => filtersQuery.refetch())
                          }}
                          handleFilterUpdate={filterEntry => {
                            const reportFilter = getReportFilter(filterEntry, reportFilters as any);
                            if (reportFilter) {
                              updateFilter({
                                variables: {
                                  id: reportFilter.id,
                                  data: filterEntry
                                }
                              }).then(() => filtersQuery.refetch())
                            }
                          }}
                          handleFilterDelete={filterEntry => {
                            const reportFilter = getReportFilter(filterEntry, reportFilters as any);
                            if (reportFilter) {
                              deleteFilter({ variables: { id: reportFilter.id }}).then(() => filtersQuery.refetch())
                            }
                           }}
                        />
                      </div>
                    )
                  }}
                </DeleteReportFilterComponent>
              )}
            </UpdateReportFilterComponent>
          )}
        </CreateReportFilterComponent>
      )}
    </ReportFiltersComponent>
  );
}