import React from "react";
import { Contact, useFieldGroupsQuery, FieldGroupType, useContactFieldsQuery, useMeQuery, EntityFieldType, EntityRelationType, CompanyField, Role, Company } from "../../../lib/codegen";
import { EnhancedFormFieldsObject } from "../../../components/enhancedForm/withEnhancedForm";
import { mapEntityFieldsToEnhancedFields } from "../../../components/enhancedForm/formHelpers/mapEntityFieldsToEnhancedFields";
import { EnhnacedFormArgs } from "../../../components/enhancedForm/EnhancedForm";
import { CompanyDrawerDisplay } from "../../companies/companyDrawer/CompanyDrawer";

export function useContactFields(contact?: Contact): EnhancedFormFieldsObject {
  const groupsQuery = useFieldGroupsQuery({ variables: { fieldType: FieldGroupType["ContactField"] } })
  const fieldsQuery = useContactFieldsQuery();
  const meQuery = useMeQuery();
  const user = meQuery.data && meQuery.data.me ? meQuery.data.me : undefined;
  const fields = fieldsQuery.data && fieldsQuery.data.contactFields ? fieldsQuery.data.contactFields : [];
  const groups = groupsQuery.data && groupsQuery.data.fieldGroups ? groupsQuery.data.fieldGroups : []
  const isCreating = contact ? false : true;

  const staticFields: EnhancedFormFieldsObject = [
    {
      key: "firstName",
      type: EntityFieldType["Text"],
      name: "First Name",
      rules: [{ required: true, message: "First Name is required!" }],
      getValueFromProps: () => contact ? contact.firstName : undefined
    },
    {
      key: "lastName",
      type: EntityFieldType["Text"],
      name: "Last Name",
      rules: [{ required: true, message: "Last Name is required!" }],
      getValueFromProps: () => contact ? contact.lastName : undefined
    },
    {
      key: "email",
      type: EntityFieldType["Text"],
      name: "Email",
      rules: [{ type: "email", message: "Invalid Email" }],
      getValueFromProps: () => contact ? contact.email : undefined
    },
    {
      key: "companyId",
      type: EntityFieldType["Relation"],
      entityRelationType: EntityRelationType["Company"],
      name: "Company",
      getValueFromProps: () => contact && contact.company ? contact.company.id : undefined,
      getDisplayValue: () => contact && contact.company ? <CompanyDrawerDisplay company={contact.company} /> : "---"
    }
  ]

  const customFields = mapEntityFieldsToEnhancedFields<CompanyField, EnhnacedFormArgs>({
    entityFields: fields,
    fieldGroups: groups,
    isVisible: (args) => {
      const { field, formDataObject } = args
      const companyField = fields.find(_field => _field.id === field.key); if (!companyField) return true;

      if (companyField.isAdminField && (user && user.role === Role["User"] || !user)) return false;

      if (isCreating && !companyField.isVisibleOnCreate) return false;

      return true
    },
    getValueFromProps: (args) => {
      if (!contact) return;
      const fieldData = contact.fieldsData.find(({ fieldId }) => fieldId === args.field.key)
      if (fieldData) return fieldData.value;
    }
  })

  return [...staticFields, ...customFields];
}