import React, { useState } from "react";
import { Company, Client, ClientsComponent, CompaniesComponent, ContactsComponent, Contact, ClientPartnersComponent, ClientContactsComponent, CampaignsComponent, TradeshowsComponent, MeetingTagsComponent, UsersComponent, MeComponent, Role, CampaignComponent, Campaign, ContactComponent, CompanyComponent, ClientComponent, ClientPartnerComponent, ClientPartner, Tradeshow, TradeshowComponent, UserComponent, User, ClientContact, useClientContactsQuery } from "../../lib/codegen";
import { RelationField, ParentRelationFieldProps } from "./RelationField";
import { Divider } from "antd";
import { Highlight } from "../display/highlight/Highlight";
import { Debounce } from "../../lib/helpers/helpers";

const maxLimit = 10000
const getValue = (value: ParentRelationFieldProps["value"]) => typeof value === "function" ? value() : value
function mergeEntities<T extends { id: string }>(array: { items: T[] | undefined | null } | undefined, existingArrayOrSingle: T | T[] | undefined | null): T[] {
  let items: T[] = [];
  if (array && array.items) items = array.items;

  if (existingArrayOrSingle && typeof existingArrayOrSingle === "object" && !Array.isArray(existingArrayOrSingle)) {
    let singleExists = false;
    for (let i = 0; i < items.length; i++) {
      if (!existingArrayOrSingle) break;
      if (items[i].id === existingArrayOrSingle.id) {
        singleExists = true;
        break;
      }
    }
    if (existingArrayOrSingle && !singleExists) items.push(existingArrayOrSingle)
  }

  if (existingArrayOrSingle && Array.isArray(existingArrayOrSingle)) {
    let existingArray = existingArrayOrSingle as T[];
    existingArray.forEach(item => {
      let singleExists = false;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === item.id) {
          singleExists = true;
          break;
        }
      }
      if (!singleExists) items.push(item)
    })
  }

  return items;
}


export const CompanyRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  return (
    <CompanyComponent variables={{ id }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <CompaniesComponent>
          {({ loading, data, refetch, variables }) => {
            const searchValue = variables && variables.data && variables.data.name ? variables.data.name : ""
            return (
              <RelationField<Company>
                // data props
                loading={loading || singleQuery.loading}
                entities={mergeEntities<Company>(data && data.companies ? data.companies : undefined, singleQuery.data ? singleQuery.data.company : undefined)}
                getEntityData={({ id, domain, name }) => ({
                  key: id, name: <div>
                    <Highlight
                      text={domain}
                      searchValue={searchValue}
                    />
                    <Divider type="vertical" />
                    <Highlight
                      text={name}
                      searchValue={searchValue}
                    />
                  </div>
                })}
                onSearch={value => refetch({ data: { name: value, domain: value, useOrQuery: true } })}
                // enhanced form props
                value={value}
                field={field}
                onChange={onChange}
                placeholder={placeholder}
              />
            )
          }}
        </CompaniesComponent>
      )}
    </CompanyComponent>
  )
}

export const ContactRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  const ItemRow = (_props: { name: string, email: string, searchValue: string }) => {
    const { name, email, searchValue } = _props;
    return (
      <div>
        <Highlight
          text={name}
          searchValue={searchValue}
        />
        <Divider type="vertical" />
        <Highlight
          text={email}
          searchValue={searchValue}
        />
      </div>
    )
  }
  const [customLoading, setCustomLoading] = useState(false);
  return (
    <ContactComponent variables={{ id }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <ContactsComponent variables={{ data: { useOrQuery: true } }}>
          {({ loading, data, refetch, variables }) => {
            const searchValue = variables && variables.data && variables.data.name ? variables.data.name : ""
            return (
              <RelationField<Contact>
                // data props
                loading={loading || singleQuery.loading || customLoading}
                entities={mergeEntities<Contact>(data && data.contacts ? data.contacts : undefined, singleQuery.data ? singleQuery.data.contact : undefined)}
                getEntityData={({ id, name, email, company }) => ({
                  key: id, name: <ItemRow key={id} email={email} name={name} searchValue={searchValue} />
                })}
                onSearch={value => {
                  refetch({ data: { name: value, email: value, useOrQuery: true } }).finally(() => setCustomLoading(false))
                  setCustomLoading(true);
                }}
                // enhanced form props
                value={value}
                field={field}
                onChange={onChange}
                placeholder={placeholder}
              />
            )
          }}
        </ContactsComponent>
      )}
    </ContactComponent>
  )
}

export const ClientRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  return (
    <ClientComponent variables={{ id: id! }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <ClientsComponent variables={{ data: { limit: maxLimit } }}>
          {({ loading, data, refetch }) => (
            <RelationField<Client>
              // data props
              loading={loading || singleQuery.loading}
              entities={mergeEntities<Client>(data && data.clients ? data.clients : undefined, singleQuery.data ? singleQuery.data.client : undefined)}
              getEntityData={({ id, name }) => ({ key: id, name })}
              onSearch={value => refetch({ data: { name: value } })}
              // enhanced form props
              value={value}
              field={field}
              onChange={onChange}
              placeholder={placeholder}
            />
          )}
        </ClientsComponent>
      )}
    </ClientComponent>
  )
}

export const PartnerRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  return (
    <ClientPartnerComponent variables={{ id: id! }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <ClientPartnersComponent variables={{ data: { limit: maxLimit } }}>
          {({ loading, data, refetch }) => (
            <RelationField<Client>
              // data props
              loading={loading || singleQuery.loading}
              entities={mergeEntities<ClientPartner>(data && data.clientPartners ? data.clientPartners : undefined, singleQuery.data ? singleQuery.data.clientPartner : undefined)}
              getEntityData={({ id, name }) => ({ key: id, name })}
              onSearch={value => refetch({ data: { name: value } })}
              // enhanced form props
              value={value}
              field={field}
              onChange={onChange}
              placeholder={placeholder}
            />
          )}
        </ClientPartnersComponent>
      )}
    </ClientPartnerComponent>
  )
}

export const ClientContactRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const idOrIds = getValue(value) as string | string[] | undefined;
  const ids: string[] | undefined = typeof idOrIds === "string" ? [idOrIds] : Array.isArray(idOrIds) ? idOrIds : undefined;
  const contactsQuery = useClientContactsQuery({ variables: { data: { ids, limit: maxLimit } }, skip: !ids })
  const contacts = contactsQuery.data && contactsQuery.data.clientContacts ? contactsQuery.data.clientContacts.items : [];
  return (
    <ClientContactsComponent>
      {({ loading, data, refetch }) => (
        <RelationField<ClientContact>
          // data props
          loading={loading || contactsQuery.loading}
          entities={mergeEntities<ClientContact>(data && data.clientContacts ? data.clientContacts : undefined, contacts)}
          getEntityData={({ id, name, client, partner }) => ({ key: id, name: `${name} ${partner ? `(${partner.name})` : client ? `(${client.name})` : ""}` })}
          onSearch={value => refetch({ data: { name: value } })}
          // enhanced form props
          value={value}
          field={field}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
    </ClientContactsComponent>
  )
}

export const CampaignRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  return (
    <CampaignComponent variables={{ id: id! }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <CampaignsComponent variables={{ data: { limit: maxLimit } }}>
          {({ loading, data, refetch }) => (
            <RelationField<Campaign>
              // data props
              loading={loading || singleQuery.loading}
              entities={mergeEntities<Campaign>(data && data.campaigns ? data.campaigns : undefined, singleQuery.data ? singleQuery.data.camapign : undefined)}
              getEntityData={({ id, name }) => ({ key: id, name })}
              onSearch={value => refetch({ data: { name: value } })}
              // enhanced form props
              value={value}
              field={field}
              onChange={onChange}
              placeholder={placeholder}
            />
          )}
        </CampaignsComponent>
      )}
    </CampaignComponent>
  )
}

export const TradeshowRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  const id = getValue(value);
  return (
    <TradeshowComponent variables={{ id: id! }} skip={!id || typeof value !== "string"}>
      {singleQuery => (
        <TradeshowsComponent>
          {({ loading, data, refetch }) => (
            <RelationField<Client>
              // data props
              loading={loading || singleQuery.loading}
              entities={mergeEntities<Tradeshow>(data && data.tradeshows ? data.tradeshows : undefined, singleQuery.data ? singleQuery.data.tradeshow : undefined)}
              getEntityData={({ id, name }) => ({ key: id, name })}
              onSearch={value => refetch({ data: { name: value } })}
              // enhanced form props
              value={value}
              field={field}
              onChange={onChange}
              placeholder={placeholder}
            />
          )}
        </TradeshowsComponent>
      )}
    </TradeshowComponent>
  )
}

export const MeetingTagRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => {
  return (
    <MeetingTagsComponent variables={{ data: { limit: maxLimit } }}>
      {({ loading, data, refetch }) => (
        <RelationField<Client>
          // data props
          loading={loading}
          entities={data && data.meetingTags && data.meetingTags.items ? data.meetingTags.items : []}
          getEntityData={({ id, name }) => ({ key: id, name })}
          onSearch={value => refetch({ data: { name: value } })}
          // enhanced form props
          value={value}
          field={field}
          onChange={onChange}
          placeholder={placeholder}
        />
      )}
    </MeetingTagsComponent>
  )
}

export const UserRelationField: React.FC<ParentRelationFieldProps> = ({ field, value, onChange, placeholder }) => (
  <MeComponent>
    {meQuery => {
      if (meQuery.data && meQuery.data.me && meQuery.data.me.role !== Role["Admin"]) return <div>You must be an admin to edit this field.</div>
      const id = getValue(value);
      return (
        <UserComponent variables={{ id: id! }} skip={!id || typeof id !== "string"}>
          {singleQuery => (
            <UsersComponent variables={{ data: { limit: maxLimit } }}>
              {({ loading, data, refetch }) => (
                <RelationField<Client>
                  // data props
                  loading={loading || singleQuery.loading}
                  entities={mergeEntities<User>(data && data.users ? data.users : undefined, singleQuery.data ? singleQuery.data.user : undefined)}
                  getEntityData={({ id, name }) => ({ key: id, name })}
                  onSearch={value => refetch({ data: { name: value } })}
                  // enhanced form props
                  value={value}
                  field={field}
                  onChange={onChange}
                  placeholder={placeholder}
                />
              )}
            </UsersComponent>
          )}
        </UserComponent>
      )
    }}
  </MeComponent>
)