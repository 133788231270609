import React, { useState, useContext } from "react";
import { FilterEntry, EntityFieldType, useUpdateUserMeetingFilterGroupsMutation, UpdateUserMeetingFilterGroupsMutation, UpdateUserMeetingFilterGroupsMutationVariables, User } from "../../../lib/codegen";
import { UseState } from "../../../lib/ts/types";
import { Modal, Drawer, Button, Icon, Input, notification } from "antd";
import { Title } from "../../../components/display/title/Title";
import { uuidv4 } from "../../../lib/helpers/helpers";
import { MeetingFilterGroupDrawer, FilterEntryWithId } from "./components/MeetingFilterGroupDrawer";
import { ListCard } from "../../../components/listCard/ListCard";
import Text from "antd/lib/typography/Text";
import { PopForm } from "../../../components/popform/PopForm";
import { saveAs } from 'file-saver';
import { AppContext } from "../../../lib/helpers/AppContext";

interface IProps {
  visibleState: UseState<boolean>
}

export interface MeetingFilterGroup {
  id: string
  name: string
  filters: FilterEntryWithId[]
}

type SetGroupsFunc = (groups: MeetingFilterGroup[]) => void

const createMeetingFilterGroup = (groups: MeetingFilterGroup[], setGroups: SetGroupsFunc) =>
  setGroups([...groups, { id: uuidv4(), name: 'New Filter Group', filters: [] }])

const updateMeetingFilterGroup = (updateGroup: MeetingFilterGroup, groups: MeetingFilterGroup[], setGroups: SetGroupsFunc) =>
  setGroups(groups.map(group => { if (group.id === updateGroup.id) return updateGroup; else return group }))

const deleteMeetingFilterGroup = (id: string, groups: MeetingFilterGroup[], setGroups: SetGroupsFunc) =>
  setGroups(groups.filter(group => { if (group.id !== id) return group }))

export const getMeetingFilterGroupsFromUser = (user: User): MeetingFilterGroup[] => {
  let groups: MeetingFilterGroup[] = [];
  if (user && user.meetingFilterGroups)
    try {
      groups = JSON.parse(user.meetingFilterGroups)
    } catch (err) { }
  return groups
}

export const MeetingFilterGroups: React.FC<IProps> = props => {
  // group state
  const [activeGroupId, setActiveGroupId] = useState<string | null>(null)
  const [editGroupId, setEditGroupId] = useState<string | null>(null)

  // import state
  const [importVisible, setImportVisible] = useState<boolean>(false)
  const [importText, setImportText] = useState<string | null>(null)

  // parse filter groups
  const { user } = useContext(AppContext)
  const groups = user ? getMeetingFilterGroupsFromUser(user) : []

  // mutation
  const [updateUserMeetingFilterGroups] = useUpdateUserMeetingFilterGroupsMutation();
  const setGroups = (_groups: MeetingFilterGroup[]) => updateUserMeetingFilterGroups({ variables: { jsonData: JSON.stringify(_groups) } })

  // calculated
  const activeGroup = groups.find(group => group.id === activeGroupId)
  const editGroup = groups.find(group => group.id === editGroupId)

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title size="sm">Filter Groups</Title>
          <Button
            onClick={() => createMeetingFilterGroup(groups, setGroups)}
            style={{ marginLeft: 'auto', marginRight: 35 }}
          >
            <Icon type='plus' /> Group
          </Button>
        </div>
      }
      closeIcon={<Icon style={{ top: 4, position: 'relative' }} type='close' />}
      visible={props.visibleState[0]}
      onOk={() => props.visibleState[1](false)}
      onCancel={() => props.visibleState[1](false)}
      style={{ overflow: 'hidden' }}
      maskClosable={false}
      bodyStyle={{
        minHeight: 350,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Drawer
        title={activeGroup ? activeGroup.name : ''}
        placement="right"
        closable={activeGroup ? true : false}
        onClose={() => setActiveGroupId(null)}
        visible={activeGroupId && activeGroup ? true : false}
        getContainer={false}
        style={{ position: 'absolute' }}
        width='100%'
      >
        {activeGroup && <MeetingFilterGroupDrawer
          group={activeGroup}
          updateGroup={(group) => updateMeetingFilterGroup(group, groups, setGroups)}
        />}
      </Drawer>


      <ListCard<MeetingFilterGroup>
        dataSource={groups}
        rowKey={row => row.id}
        getRowData={group => ({
          key: group.id,
          title: group.name,
          onDelete: () => deleteMeetingFilterGroup(group.id, groups, setGroups),
          onClick: () => setActiveGroupId(group.id),
          onEdit: () => setEditGroupId(group.id),
        })}
      />

      <PopForm
        formFields={[
          {
            type: EntityFieldType["Text"],
            key: 'name',
            name: 'Group Name',
            getValueFromProps: () => editGroup ? editGroup.name : ""
          }
        ]}
        modalProps={{ destroyOnClose: true }}
        title={<Title size="sm">Rename Group</Title>}
        visible={editGroupId ? true : false}
        onClose={() => setEditGroupId(null)}
        onSubmit={({ name }) => new Promise((resolve, reject) => { { if (editGroup) updateMeetingFilterGroup({ ...editGroup, name: name as any }, groups, setGroups) }; resolve() })}
      />
      <br />
      <Text type="secondary" disabled style={{ marginTop: 'auto', cursor: 'inherit', marginBottom: 10 }}>
        Please note that meeting, contact, and company fields can be deleted.
        When this occours, filter groups that contain deleted fields will no longer work and may throw an error.
      </Text>

      {/* IMPORT / EXPORT */}

      <Button.Group>
        <Button style={{ width: '50%' }} onClick={() => saveAs(new Blob([JSON.stringify(groups)], { type: "text/plain;charset=utf-8" }), "meeting-filter-groups-export.txt")}>
          <Icon type='download' /> Export Filter Groups
        </Button>
        <Button style={{ width: '50%' }} onClick={() => setImportVisible(true)}>
          <Icon type='cloud-upload' /> Import Filter Groups
        </Button>
      </Button.Group>

      <Modal
        visible={importVisible}
        onCancel={() => setImportVisible(false)}
        onOk={() => {
          let existingGroups = groups;
          let importGroups: MeetingFilterGroup[] = []
          try {
            importGroups = JSON.parse(importText ? importText : '')
            if (!Array.isArray(importGroups)) importGroups = [];
          } catch (err) {
            notification.error({ message: "Error, invalid import data!" })
          }
          existingGroups.map(group => {
            const foundGroup = importGroups.find(_group => group.id === _group.id);
            if (foundGroup) {
              importGroups = importGroups.filter(_group => { if (_group.id !== foundGroup.id) return _group })
              return foundGroup
            }
            else
              return group
          })
          setGroups([...existingGroups, ...importGroups])
          setImportVisible(false)
        }}
        title="Copy and Paste all data from export file"
      >
        <Input.TextArea
          value={importText ? importText : ''}
          onChange={e => setImportText(e.target.value)}
          placeholder='paste import data here'
        />
      </Modal>
    </Modal>
  );
}